import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { formReducer, initialFormState } from 'redux-form-library';

const ACTION_TYPES = {
  SEND_BERICHT: 'conversatie/SEND_BERICHT'
};

export const SEND_BERICHT_ACTION_TYPES = ACTION_TYPES;

export const SEND_BERICHT_FORM = 'SEND_BERICHT_FORM';

export interface ISendBericht {
  loading: boolean;
  errorMessage: string;
  bericht: string;
}

const initialState: ISendBericht = {
  loading: false,
  errorMessage: null,
  bericht: null
};

export interface ISendBerichtForm {
  bericht: string;
}

export const initialSendBerichtFormState = (): ISendBerichtForm => ({
  bericht: ''
});

export const sendBerichtForm = formReducer(SEND_BERICHT_FORM, initialFormState(initialSendBerichtFormState()));

export const sendBerichtenReducer = (state = initialState, action: AnyAction): ISendBericht => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.SEND_BERICHT):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.SEND_BERICHT):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.SEND_BERICHT):
    return {
      ...state,
      loading: false,
      bericht: action.payload.data.inhoud
    };
  default:
    return state;
  }
};

// Actions
export const sendBericht: ActionCreator<any> = (conversatieUid: string, bericht: string) => ({
  type: ACTION_TYPES.SEND_BERICHT,
  payload: axios.post(`/ui/berichten/conversatie/${conversatieUid}`, { bericht })
});
