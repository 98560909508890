import {
  IFormState,
  initialFormState
} from 'redux-form-library';
import { initialCategorieFormState } from 'src/modules/categorie/categorie-form';
import {
  IMateriaalVraagForm,
  initialMateriaalVraagFormState
} from 'src/reducers/vraag/materiaal/materiaal-vraag-form';
import {
  mapToFormStateFormat,
  mapToRequestFormat
} from '../../beschikbaarheid/materiaal-beschikbaarheid';
import { extractHoeveelheidIfToegangPubliek } from '../../materiaal/hoeveelheid-factory';

export const materiaalVraagResponseToFormState = (response): IFormState<IMateriaalVraagForm> =>
  initialFormState({
    taal: response.value.data.taal,
    bronTaal: response.value.data.bronTaal,
    titel: response.value.data.titel,
    beschrijving: response.value.data.beschrijving,
    categorieen: response.value.data.categorieen,
    categorie: initialCategorieFormState(),
    eural: {
      eural: response.value.data.euralCode || ''
    },
    materiaalSamenstelling: response.value.data.materiaalSamenstelling,
    materiaalToestand: response.value.data.toestand,
    kleur: response.value.data.kleur || '',
    beoogdeVerwerkingswijze: response.value.data.beoogdeVerwerkingswijze,
    productieProces: response.value.data.productieProces || '',
    idealeOorsprong: response.value.data.idealeOorsprong || '',
    toegangHoeveelheid: response.value.data.toegangHoeveelheid,
    hoeveelheid: {
      frequentie: response.value.data.frequentie,
      kwantiteit: response.value.data.kwantiteit,
      eenheid: response.value.data.eenheid
    },
    toegangLocatie: response.value.data.toegangLocatie,
    locatienaam: response.value.data.locatienaam,
    adres: {
      straatnaam: response.value.data.straatnaam,
      huisnummer: response.value.data.huisnummer,
      adresLijn2: response.value.data.adresLijn2 || '',
      postcode: response.value.data.postcode,
      gemeente: response.value.data.gemeente,
      land: response.value.data.land
    },
    gevaarlijkeStoffen: {
      gevaarlijkeStoffen: response.value.data.gevaarlijkeStoffen,
      beschrijvingGevaarlijkeStoffen: response.value.data.beschrijvingGevaarlijkeStoffen || ''
    },
    bijkomendeInfo: response.value.data.bijkomendeInfo || '',
    privateBijlagen: response.value.data.privateBijlagen,
    publiekeBijlagen: response.value.data.publiekeBijlagen,
    fotos: response.value.data.fotos,
    beschikbaarheid: mapToFormStateFormat(response.value.data.beschikbaarheid)
  });

export const materiaalVraagFormDataToRequest = (formData: IMateriaalVraagForm) => ({
  taal: formData.taal,
  bronTaal: formData.bronTaal,
  titel: formData.titel,
  beschrijving: formData.beschrijving,
  categorieen: formData.categorieen.map(cat => cat.subCategorie || cat.standaardCategorie || cat.hoofdCategorie),
  euralCode: formData.eural.eural,
  materiaalSamenstelling: formData.materiaalSamenstelling,
  toestand: formData.materiaalToestand,
  kleur: formData.kleur,
  beoogdeVerwerkingswijze: formData.beoogdeVerwerkingswijze,
  productieProces: formData.productieProces,
  idealeOorsprong: formData.idealeOorsprong,
  toegangHoeveelheid: formData.toegangHoeveelheid,
  frequentie: formData.hoeveelheid.frequentie,
  kwantiteit: formData.hoeveelheid.kwantiteit,
  eenheid: formData.hoeveelheid.eenheid,
  toegangLocatie: formData.toegangLocatie,
  straatnaam: formData.adres.straatnaam,
  huisnummer: formData.adres.huisnummer,
  adresLijn2: formData.adres.adresLijn2,
  postcode: formData.adres.postcode,
  gemeente: formData.adres.gemeente,
  land: formData.adres.land,
  gevaarlijkeStoffen: formData.gevaarlijkeStoffen.gevaarlijkeStoffen,
  beschrijvingGevaarlijkeStoffen: formData.gevaarlijkeStoffen.beschrijvingGevaarlijkeStoffen,
  bijkomendeInfo: formData.bijkomendeInfo,
  privateBijlagen: formData.privateBijlagen,
  publiekeBijlagen: formData.publiekeBijlagen,
  fotos: formData.fotos,
  beschikbaarheid: mapToRequestFormat(formData.beschikbaarheid)
});

export const materiaalVraagFromMateriaalAanbodResponse = (response): IFormState<IMateriaalVraagForm> => initialFormState({
  ...initialMateriaalVraagFormState(),
  titel: response.value.data.titel,
  beschrijving: response.value.data.beschrijving,
  categorieen: response.value.data.categorieen,
  eural: {
    eural: response.value.data.euralCode || ''
  },
  materiaalSamenstelling: response.value.data.materiaalSamenstelling,
  materiaalToestand: response.value.data.toestand,
  kleur: response.value.data.kleur || '',
  hoeveelheid: extractHoeveelheidIfToegangPubliek(response),
  beschikbaarheid: mapToFormStateFormat(response.value.data.beschikbaarheid)
});
