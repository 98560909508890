import React from 'react';
import { IFlatCategorie } from './categorie-tree-to-list';
import Categorie from './categorie';

export interface ICategorieListProps extends React.HTMLAttributes<HTMLDivElement> {
  categorieen: IFlatCategorie[];
  handleClick: Function;
  elementsPerLoad: number;
}

const CategorieList = (props: ICategorieListProps) => {
  const loadBlock = Array.from(props.categorieen.slice(0, props.elementsPerLoad - 1));
  const [ listItems, setListItems ] = React.useState(loadBlock);

  const [ isFetching, setIsFetching ] = React.useState(false);

  const handleScroll = () => {
    const modal = document.getElementById('categorie-modal-content');
    if (modal.clientHeight + modal.scrollTop !== modal.offsetHeight || isFetching) {
      setIsFetching(true);
    }
  };

  const fetchMoreListItems = () => {
    setTimeout(() => {
      setListItems(prevState => ([ ...prevState, ...props.categorieen.slice(prevState.length, prevState.length + 100) ]));
      setIsFetching(false);
    }, 2000);
  };

  React.useEffect(() =>
    setListItems(Array.from(loadBlock)), [ props.categorieen ]
  );

  React.useEffect(() => {
    const modal = document.getElementById('categorie-modal-content');
    if (modal) {
      modal.addEventListener('scroll', handleScroll);
      return () => modal.removeEventListener('scroll', handleScroll);
    }
  }, []);

  React.useEffect(() => {
    if (!isFetching) return;
    fetchMoreListItems();
  }, [ isFetching ]);

  return (
    <div className={props.className}>
      {listItems.map((categorie, index) => <Categorie key={index} categorie={categorie}
        handleClick={props.handleClick}/>)}
    </div>
  );
};

export default CategorieList;
