import React from 'react';
import intl from 'react-intl-universal';
import {FormComponent, InputField, Select} from 'webuniversum-react';
import {FormFields, SubFormPropsFactory} from 'redux-form-library';
import {enumOptions} from 'src/modules/form-components/translatable-select-options';
import {UitlegRelevantieBerekening} from 'src/modules/materiaal/uitleg-relevantie-berekening';

export enum MateriaalFrequentie {
  EENMALIG = 'EENMALIG',
  CONTINU = 'CONTINU',
  IN_BATCHES = 'IN_BATCHES'
}

export enum MateriaalEenheid {
  TON = 'TON',
  AANTAL_STUKS = 'AANTAL_STUKS',
  KUBIEKE_METER = 'KUBIEKE_METER',
  VIERKANTE_METER = 'VIERKANTE_METER'
}

export interface IMateriaalHoeveelheid {
  frequentie: string;
  kwantiteit: number;
  eenheid: string;
}

export interface IMateriaalHoeveelheidForm {
  frequentie: MateriaalFrequentie | '';
  kwantiteit: number | '';
  eenheid: MateriaalEenheid | '';
}

export const initialMateriaalHoeveelheidFormState = (): IMateriaalHoeveelheidForm => ({
  frequentie: '',
  kwantiteit: '',
  eenheid: MateriaalEenheid.TON
});

interface IMateriaalHoeveelheidProps {
  title: string;
  fields: FormFields<IMateriaalHoeveelheidForm>;
  formPropsFactory: SubFormPropsFactory<IMateriaalHoeveelheidForm>;
}

export class MateriaalHoeveelheid extends React.Component<IMateriaalHoeveelheidProps> {
  generateTooltipMessages = () => ({
    'required.hoeveelheid.frequentie': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.frequentie.label')
    }),
    'required.hoeveelheid.kwantiteit': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.kwantiteit.label')
    }),
    'required.hoeveelheid.eenheid': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.eenheid.label')
    })
  });

  render(): React.ReactNode {
    const { title, fields, formPropsFactory } = this.props;
    const tooltipMessages = this.generateTooltipMessages();
    const opJaarbasis = (fields.frequentie.value !== MateriaalFrequentie.EENMALIG && fields.frequentie.value !== '') ?
      intl.get('materiaal.edit.form.hoeveelheid.fields.kwantiteit.op-jaarbasis') : '';

    return (
      <>
        <h3 className="vl-h3">{intl.get(title)}</h3>
        <UitlegRelevantieBerekening tekstKey="materiaal.edit.form.hoeveelheid.uitleg-relevantie-berekening"/>
        <div className="vl-u-spacer--tiny">
          <FormComponent id="frequentie" label={intl.get('materiaal.edit.form.hoeveelheid.fields.frequentie.label')}
            required={tooltipMessages[ 'required.hoeveelheid.frequentie' ]}
            error={fields.frequentie.errors} block>
            <Select block {...formPropsFactory.createSelectProps('frequentie')}>
              {enumOptions(MateriaalFrequentie, 'materiaal.edit.form.hoeveelheid.fields.frequentie.values')
                .optionsWithPlaceholder('materiaal.edit.form.hoeveelheid.fields.frequentie.placeholder')}
            </Select>
          </FormComponent>
        </div>
        <div className="vl-grid vl-u-spacer--tiny">
          <div className="vl-col--6-12">
            <FormComponent id="kwantiteit" label={intl.get('materiaal.edit.form.hoeveelheid.fields.kwantiteit.label') + opJaarbasis}
              required={tooltipMessages[ 'required.hoeveelheid.kwantiteit' ]}
              error={fields.kwantiteit.errors} block>
              <InputField type="number" step=".001" min="0.001" max="999999999.999"
                layout="block" {...formPropsFactory.createInputProps('kwantiteit')}/>
            </FormComponent>
          </div>
          <div className="vl-col--6-12">
            <FormComponent id="eenheid" label={intl.get('materiaal.edit.form.hoeveelheid.fields.eenheid.label')}
              required={tooltipMessages[ 'required.hoeveelheid.eenheid' ]}
              error={fields.eenheid.errors} block>
              <Select block {...formPropsFactory.createSelectProps('eenheid')}>
                {enumOptions(MateriaalEenheid, 'materiaal.edit.form.hoeveelheid.fields.eenheid.values').options()}
              </Select>
            </FormComponent>
          </div>
        </div>
      </>
    );
  }

}
