import React from 'react';
import {Badge, InfoTile, InfoTileHeader} from 'webuniversum-react';
import truncate from 'lodash/truncate';

export type MateriaalTileType = 'vraag' | 'vraag-gearchiveerd' | 'aanbod' | 'aanbod-gearchiveerd' | 'technologie';

const logos: Map<MateriaalTileType, string> = new Map<MateriaalTileType, string>([
  ['aanbod', 'aanbod-logo'],
  ['vraag', 'vraag-logo'],
  ['aanbod-gearchiveerd', 'aanbod-logo-gearchiveerd'],
  ['vraag-gearchiveerd', 'vraag-logo-gearchiveerd'],
  ['technologie', 'technologie-logo']
]);

export type MateriaalTileLayout = 'block' | 'bar';

interface ITileData {
  titel: string;
  beschrijving: string;
}

interface IAanbodTileData extends ITileData {
  aanbodReferentie: string;
}

interface IVraagTileData extends ITileData {
  vraagReferentie: string;
}

interface ITechnologieTileData extends ITileData {
  technologieReferentie: string;
}

interface IMateriaalTileProps {
  type: MateriaalTileType;
  data: IAanbodTileData | IVraagTileData | ITechnologieTileData;
  layout: MateriaalTileLayout;
  onClick?: (string) => void;
}

export class MateriaalTile extends React.Component<IMateriaalTileProps> {

  render(): React.ReactNode {
    const {type, data, onClick, layout} = this.props;

    let action: (string) => void;
    if (onClick) {
      if (type === 'aanbod' || type === 'aanbod-gearchiveerd') {
        action = () => onClick((data as IAanbodTileData).aanbodReferentie);
      } else if (type === 'vraag' || type === 'vraag-gearchiveerd') {
        action = () => onClick((data as IVraagTileData).vraagReferentie);
      } else if (type === 'technologie') {
        action = () => onClick((data as ITechnologieTileData).technologieReferentie);
      }
    }

    const beschrijving = truncate(data.beschrijving, {length: 300, separator: ' ', omission: ' ...'});
    return (
      <InfoTile style={{height: layout === 'bar' ? 90 : 210}} onClick={action}>
        <InfoTileHeader key="materiaaltile.header" className="vl-info-tile-header" title={data.titel} subTitle={beschrijving}>
          <Badge size="m"><img className="vl-badge__img" src={`/static/images/${logos.get(type)}.png`} alt={logos.get(type)}/></Badge>
        </InfoTileHeader>
      </InfoTile>
    );
  }
}
