import {IBestand} from 'src/reducers/bestand';
import {DocumentMiniature} from 'webuniversum-react';
import React from 'react';
import {download} from 'src/reducers/bestand/download';
import {AxiosPromise} from 'axios';

export interface IBijlagenLijstProps {
  bijlagen: IBestand[];
  handleDownload: (referentie: string) => AxiosPromise;
}

export const BijlagenLijst = (props: IBijlagenLijstProps) => {
  const handleDownload = (referentie: string): void => {
    props.handleDownload(referentie).then(download);
  };
  return <>
    {props.bijlagen.map(bijlage =>
      <div key={bijlage.referentie}>
        <DocumentMiniature className="clickable"
          docType={bijlage.type}
          bytes={bijlage.grootte}
          onClick={() => handleDownload(bijlage.referentie)}>
          {bijlage.naam}
        </DocumentMiniature>
        <div className="vl-u-spacer--tiny"/>
      </div>)}
  </>;
};
