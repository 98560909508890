import React from 'react';
import intl from 'react-intl-universal';
import { INietRealisatieReden } from 'src/reducers/symbiose/create';
import { Loading } from 'webuniversum-react';
import { DetailField } from '../detail-components/detail-field';
import { translateKey } from '../form-components/translatable-select-options';

export interface IDetailSymbioseNietRealisatieProps {
  niet_realisatie_reden: INietRealisatieReden;
  loading: boolean;
}

const label_column_class = 'vl-col--3-12';
const value_column_class = 'vl-col--9-12';

export class DetailSymbioseNietRealisatie extends React.Component<IDetailSymbioseNietRealisatieProps> {
  render(): React.ReactNode {
    const { niet_realisatie_reden, loading } = this.props;
    return (
      <>
        <article id="reden-niet-realisatie" className="vl-u-spacer--small">
          <Loading loading={loading}>
            <h2 className="vl-h2 vl-u-spacer--small">
              {intl.get('symbiose.detail.reden-niet-gerealiseerd')}
            </h2>
            <DetailField label={intl.get('symbiose.detail.hoeveelheid-niet-gerealiseerd')}
              value={niet_realisatie_reden.kwantiteit.toString() +
                         ' ' + intl.get(translateKey(niet_realisatie_reden.eenheid,
                'materiaal.edit.form.hoeveelheid.fields.eenheid.values'))}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>

            <div className="vl-u-spacer--small"/>

            <DetailField label={intl.get('symbiose.detail.barriere')}
              value={niet_realisatie_reden.barriere &&
                         intl.get(translateKey(niet_realisatie_reden.barriere,
                           'symbiose.niet-gerealiseerd-modal.barriere.enum-values'))}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>

            <div className="vl-u-spacer--small"/>

            <DetailField label={intl.get('symbiose.detail.reden-niet-realisatie')}
              value={niet_realisatie_reden.beschrijving}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>

            <div className="vl-u-spacer--small"/>

            {
              (niet_realisatie_reden.adviesverleners.length > 0) &&
              <DetailField label={intl.get('symbiose.detail.meerwaarde.adviesverleners')}
                value={this.adviesverlenersToString(niet_realisatie_reden.adviesverleners)}
                label_column_class={label_column_class}
                value_column_class={value_column_class}/>
            }
          </Loading>
        </article>
      </>
    );
  }

  private adviesverlenersToString(adviesverleners) {
    let adviesverlenersString = '';
    adviesverleners.forEach(adviesverlener => {
      const translationKey = intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.' + adviesverlener.toLowerCase());
      adviesverlenersString = adviesverlenersString.concat(translationKey + ', ');
    });
    adviesverlenersString = adviesverlenersString.slice(0, -2);
    return adviesverlenersString;
  }
}
