export class EnumWrapper<E> {

  constructor(private enumToWrap: E, private name: string) {
  }

  parse(toParse: string): keyof E {
    const ret = this.enumToWrap[toParse];
    if (!ret) {
      throw new Error(`Ongeldige ${this.name}: ${toParse}`);
    } else {
      return ret;
    }
  }

  values(): E[] {
    return Object.values(this.enumToWrap);
  }
}
