import React, { PropsWithChildren } from 'react';
import 'antd/dist/antd.min.css';
import {
  RcFile,
  UploadFile
} from 'antd/lib/upload/interface';
import { Upload } from 'antd';
import intl from 'react-intl-universal';
import { ValidatieError } from 'src/modules/foto/UploadValidatieError';

export interface IFotoUploadProps extends PropsWithChildren<any> {
  fotos: UploadFile[];
  acceptFileTypes: string;
  handleValidateFotos: Function;
  handleAddFoto: Function;
  handleRemoveFoto: Function;
  handleShowPreview: Function;
}

export interface IFotoUploadState {
  uploadErrors: string[];
}

export class FotoUpload extends React.Component<IFotoUploadProps, IFotoUploadState> {
  constructor(props: Readonly<IFotoUploadProps>) {
    super(props);
    this.state = {
      uploadErrors: []
    };
  }

  render(): React.ReactNode {
    const { fotos, acceptFileTypes, handleShowPreview, handleRemoveFoto, children } = this.props;
    const handlePreview = file => handleShowPreview(file);
    const handleRemove = file => handleRemoveFoto(file);
    return (<>
      <Upload
        listType="picture-card"
        fileList={fotos}
        accept={acceptFileTypes}
        multiple={true}
        beforeUpload={this.handleBeforeUpload}
        customRequest={this.handleUpload}
        onPreview={handlePreview}
        onRemove={handleRemove}
        locale={{
          previewFile: intl.get('materiaal.edit.form.fotos.button.preview'),
          removeFile: intl.get('materiaal.edit.form.fotos.button.remove')
        }}
      >
        {children}
      </Upload>
      {this.state.uploadErrors && <ValidatieError messages={this.state.uploadErrors} />}
    </>);
  }

  handleBeforeUpload = (file: RcFile, fileList: RcFile[]): boolean => {
    this.setState({
      uploadErrors: []
    });

    const uploadErrors = this.props.handleValidateFotos(fileList);
    this.setState({ uploadErrors });
    return uploadErrors.length === 0;
  };

  handleUpload = options => {
    const { file, onSuccess, onError } = options;
    setTimeout(() => Promise.resolve(this.props.handleAddFoto(file))
      .then(() => onSuccess('done'))
      .catch(ex => onError(ex, 'error', file)), 0);
  };
}
