import {IFormState, initialFormState} from 'redux-form-library';
import {ITechnologieForm} from 'src/reducers/technologie/technologie-form';
import {isTerPlaatse, isVerplaatsbaar, toVerplaatsingOptie} from 'src/modules/technologie/verplaatsing-optie';

export const technologieResponseToFormState = (response): IFormState<ITechnologieForm> =>
  initialFormState({
    taal: response.value.data.taal,
    bronTaal: response.value.data.bronTaal,
    titel: response.value.data.titel,
    beschrijving: response.value.data.beschrijving,
    ontwikkelingsstadium: response.value.data.ontwikkelingsstadium,
    beschrijvingTeVerwerkenMateriaal: response.value.data.beschrijvingTeVerwerkenMateriaal,
    oorsprongTeVerwerkenMateriaal: response.value.data.oorsprongTeVerwerkenMateriaal || '',
    beschrijvingVerwerktMateriaal: response.value.data.beschrijvingVerwerktMateriaal,
    mogelijkeBestemmingVerwerktMateriaal: response.value.data.mogelijkeBestemmingVerwerktMateriaal || '',
    terPlaatse: isTerPlaatse(response.value.data.verplaatsingsOptie || ''),
    verplaatsbaar: isVerplaatsbaar(response.value.data.verplaatsingsOptie || ''),
    adres: {
      straatnaam: response.value.data.adres.straatnaam,
      huisnummer: response.value.data.adres.huisnummer,
      adresLijn2: response.value.data.adres.adresLijn2 || '',
      postcode: response.value.data.adres.postcode,
      gemeente: response.value.data.adres.gemeente,
      land: response.value.data.adres.land
    },
    ondernemingsNaam: response.value.data.ondernemingsNaam,
    voornaamContactPersoon: response.value.data.voornaamContactPersoon,
    familienaamContactPersoon: response.value.data.familienaamContactPersoon,
    email: response.value.data.email,
    telefoon: response.value.data.telefoon || '',
    website: response.value.data.website || '',
    bijlagen: response.value.data.bijlagen
  });

export const technologieFormDataToRequest = (formData: ITechnologieForm) => ({
  titel: formData.titel,
  beschrijving: formData.beschrijving,
  ontwikkelingsstadium: formData.ontwikkelingsstadium,
  beschrijvingTeVerwerkenMateriaal: formData.beschrijvingTeVerwerkenMateriaal,
  oorsprongTeVerwerkenMateriaal: formData.oorsprongTeVerwerkenMateriaal,
  beschrijvingVerwerktMateriaal: formData.beschrijvingVerwerktMateriaal,
  mogelijkeBestemmingVerwerktMateriaal: formData.mogelijkeBestemmingVerwerktMateriaal,
  verplaatsingsOptie: toVerplaatsingOptie(formData.terPlaatse, formData.verplaatsbaar),
  straatnaam: formData.adres.straatnaam,
  huisnummer: formData.adres.huisnummer,
  adresLijn2: formData.adres.adresLijn2,
  postcode: formData.adres.postcode,
  gemeente: formData.adres.gemeente,
  land: formData.adres.land,
  ondernemingsNaam: formData.ondernemingsNaam,
  voornaamContactPersoon: formData.voornaamContactPersoon,
  familienaamContactPersoon: formData.familienaamContactPersoon,
  email: formData.email,
  telefoon: formData.telefoon.trim(),
  website: formData.website.trim(),
  bijlagen: formData.bijlagen
});
