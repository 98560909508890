import React from 'react';
import intl from 'react-intl-universal';
import {Alert} from 'webuniversum-react';
import {IMateriaalAanbod} from 'src/reducers/aanbod/materiaal/get';
import {IRelevantie} from 'src/reducers/relevantie';
import {IGetMateriaalVraagState} from 'src/reducers/vraag/materiaal/get';
import {DetailAdres} from 'src/modules/detail-components/detail-adres';
import {DetailClassificatie} from 'src/modules/detail-components/detail-classificatie';
import {DetailField} from 'src/modules/detail-components/detail-field';
import {DetailFooter} from 'src/modules/detail-components/detail-footer';
import {DetailHeader} from 'src/modules/detail-components/detail-header';
import {DetailHoeveelheid} from 'src/modules/detail-components/detail-hoeveelheid';
import {DetailRelevantie} from 'src/modules/detail-components/detail-relevantie';
import {translateKey} from 'src/modules/form-components/translatable-select-options';
import {GevaarlijkeStoffenEnum} from 'src/modules/materiaal/gevaarlijke-stoffen-enum';
import {MateriaalStatusEnum} from 'src/modules/materiaal/materiaal-status-enum';
import {Autotranslated} from 'src/modules/taal/autotranslated';
import {AutotranslationWarning} from 'src/modules/taal/autotranslation-warning';
import {Preformatted} from 'src/modules/detail-components/preformatted';
import {FotoCarrousel} from 'src/modules/foto/foto-carrousel';
import {BijlagenLijst} from 'src/modules/bijlage/bijlagen-lijst';
import {AxiosPromise} from 'axios';
import DetailBeschikbaarheid from '../../detail-components/detail-beschikbaarheid';

export interface IDetailAanbod {
  status?: MateriaalStatusEnum;
  aanbod: IMateriaalAanbod;
  relevantie?: IRelevantie;
  vraag?: IGetMateriaalVraagState;
  afstandRanges?: number[];
  downloadBestand: (aanbodReferentie: string) => AxiosPromise;
}

const label_column_class = 'vl-col--3-12';
const value_column_class = 'vl-col--9-12';

export class DetailMateriaalAanbod extends React.Component<IDetailAanbod> {

  render(): React.ReactNode {
    const {aanbod, downloadBestand, relevantie, vraag, afstandRanges, status} = this.props;
    const autotranslated = <Autotranslated vertaling={aanbod}/>;

    return (
      <>
        <div className="vl-page">
          <div className="vl-col--12-12">
            <DetailHeader
              title_translate_key={'detail.title.aanbod'}
              beschrijving={aanbod.titel}
              img_src={status === MateriaalStatusEnum.GEARCHIVEERD ? '/static/images/aanbod-logo-gearchiveerd.png' : '/static/images/aanbod-logo.png'}>
              {autotranslated}
            </DetailHeader>
          </div>

          {relevantie && <DetailRelevantie relevantie={relevantie}
            titel={intl.get('aanbod.detail.relevante-vraag.titel', {titel: vraag.vraag.titel})}
            afstandRanges={afstandRanges}/>}

          <AutotranslationWarning vertaling={aanbod}/>

          <FotoCarrousel fotos={aanbod.fotos} handleDownload={downloadBestand}/>
          <section id="materiaal-info">

            <h2 className="vl-h2 vl-u-spacer--tiny">{intl.get('detail.materiaal-info.title')}</h2>
            <DetailField label={intl.get('detail.beschrijving.title')}
              value={aanbod.beschrijving}
              label_column_class={label_column_class}
              value_column_class={value_column_class}>
              {autotranslated}
            </DetailField>

            <div className="vl-u-spacer--small"/>

            <DetailClassificatie categorieen={aanbod.categorieen}
              euralCode={aanbod.euralCode}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>

            <div className="vl-u-spacer--small"/>

            <DetailField label={intl.get('detail.materiaal-info.materiaaltoestand.label')}
              value={aanbod.materiaalToestand && intl.get(translateKey(aanbod.materiaalToestand,
                'detail.materiaal-info.materiaaltoestand.values'))}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>

            <div className="vl-u-spacer--small"/>

            {aanbod.kleur &&
              <DetailField label={intl.get('detail.materiaal-info.kleur.label')}
                value={aanbod.kleur}
                label_column_class={label_column_class}
                value_column_class={value_column_class}>
                {autotranslated}
              </DetailField>
            }

            <div className="vl-u-spacer--small"/>

            <DetailField label={intl.get('detail.materiaal-info.huidige-verwerkingswijze.label')}
              value={aanbod.huidigeVerwerkingswijze && intl.get(translateKey(aanbod.huidigeVerwerkingswijze,
                'detail.materiaal-info.huidige-verwerkingswijze.values'))}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>

            <div className="vl-u-spacer--small"/>

            <h3 className="vl-h3">{intl.get('detail.materiaal-samenstelling.title')}</h3>

            <DetailField label={intl.get('detail.materiaal-samenstelling.samenstelling')}
              value={aanbod.materiaalSamenstelling}
              label_column_class={label_column_class}
              value_column_class={value_column_class}>
              {autotranslated}
            </DetailField>

            <div className="vl-u-spacer--small"/>

            {(aanbod.productieProces !== null || aanbod.mogelijkeToepassingen !== null) &&
              <h3 className="vl-h3">{intl.get('detail.oorsprong-doel.title-aanbod')}</h3>
            }
            {aanbod.productieProces &&
              <DetailField label={intl.get('detail.oorsprong-doel.productie-proces')}
                value={aanbod.productieProces}
                label_column_class={label_column_class}
                value_column_class={value_column_class}>
                {autotranslated}
              </DetailField>
            }
            {aanbod.mogelijkeToepassingen &&
              <DetailField label={intl.get('detail.oorsprong-doel.mogelijke-toepassing')}
                value={aanbod.mogelijkeToepassingen}
                label_column_class={label_column_class}
                value_column_class={value_column_class}>
                {autotranslated}
              </DetailField>
            }

            {(aanbod.beschikbaarheid.van || aanbod.beschikbaarheid.tot) &&
              <div className="vl-u-spacer--small"/> &&
              <DetailBeschikbaarheid
                title={intl.get('detail.beschikbaarheid.title')}
                label={intl.get('detail.beschikbaarheid.label')}
                value={aanbod.beschikbaarheid}
                label_column_class={label_column_class}
                value_column_class={value_column_class} />}

            <div className="vl-u-spacer--small"/>

            {aanbod.hoeveelheid.eenheid &&
              <DetailHoeveelheid title={intl.get('detail.materiaal-info.hoeveelheid.title.aanbod')}
                frequentie={aanbod.hoeveelheid.frequentie && intl.get(translateKey(aanbod.hoeveelheid.frequentie,
                  'detail.materiaal-info.hoeveelheid.frequentie.values'))}
                kwantiteit={aanbod.hoeveelheid.kwantiteit}
                eenheid={aanbod.hoeveelheid.eenheid && intl.get(translateKey(aanbod.hoeveelheid.eenheid,
                  'detail.materiaal-info.hoeveelheid.eenheid.values'))}
                label_column_class={label_column_class}
                value_column_class={value_column_class}/>
            }

            <div className="vl-u-spacer--small"/>

            {aanbod.adres.land &&
              <DetailAdres title={intl.get('detail.materiaal-info.adres.title.aanbod')}
                label={intl.get('detail.materiaal-info.adres.label')}
                value={aanbod.adres}
                label_column_class={label_column_class}
                value_column_class={value_column_class}
              />
            }

            <div className="vl-u-spacer--small"/>

            {(aanbod.bijkomendeInfo
                || aanbod.gevaarlijkeStoffen.gevaarlijkeStoffen === GevaarlijkeStoffenEnum.MET_GEVAARLIJKE_STOFFEN
                || aanbod.privateBijlagen.length > 0
                || aanbod.publiekeBijlagen.length > 0) &&
              <h3 className="vl-h3">{intl.get('detail.materiaal-info.meer-info.title')}</h3>
            }

            {aanbod.gevaarlijkeStoffen.gevaarlijkeStoffen === GevaarlijkeStoffenEnum.MET_GEVAARLIJKE_STOFFEN &&
              <>
                <div className="vl-u-spacer--small"/>
                <div className="vl-grid">
                  <div className={label_column_class}>
                    <label>{intl.get('detail.gevaarlijke-stoffen.label.aanbod')}</label>
                  </div>
                  <div className={value_column_class}>
                    <Alert>
                      <div className="alert-zoeken">
                        {intl.get('detail.gevaarlijke-stoffen.value.aanbod')}
                      </div>
                    </Alert>
                  </div>
                </div>
                <DetailField
                  label=""
                  value={aanbod.gevaarlijkeStoffen.beschrijvingGevaarlijkeStoffen}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}>
                  {autotranslated}
                </DetailField>
              </>
            }

            {aanbod.bijkomendeInfo &&
              <>
                <div className="vl-u-spacer--small"/>
                <DetailField label={intl.get('detail.materiaal-info.meer-info.bijkomendeinfo.label')}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}>
                  <Preformatted text={aanbod.bijkomendeInfo}/>
                  {autotranslated}
                </DetailField>
              </>
            }
            {aanbod.publiekeBijlagen.length > 0 &&
              <>
                <div className="vl-u-spacer--small" />
                <DetailField
                  label={intl.get('detail.materiaal-info.meer-info.publieke-bijlagen.label')}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}
                >
                  <BijlagenLijst bijlagen={aanbod.publiekeBijlagen} handleDownload={downloadBestand} />
                </DetailField>
              </>
            }
            {aanbod.privateBijlagen.length > 0 &&
              <>
                <div className="vl-u-spacer--small" />
                <DetailField
                  label={intl.get('detail.materiaal-info.meer-info.private-bijlagen.label')}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}
                >
                  <BijlagenLijst bijlagen={aanbod.privateBijlagen} handleDownload={downloadBestand}/>
                </DetailField>
              </>
            }
          </section>
          <section id="contact-info">

            <div className="vl-u-spacer--small"/>

            {aanbod.onderneming.naam ?
              <>
                <DetailFooter
                  onderneming_naam={aanbod.onderneming.naam}
                  contact_persoon={aanbod.contactPersoon}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}/>
              </>
              :
              <div style={{marginTop: '5rem'}}>
                <Alert title={intl.get('detail.alert.title')}>
                  {intl.get('detail.alert.content')}
                </Alert>
              </div>
            }
          </section>
        </div>
      </>
    );
  }
}
