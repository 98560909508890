import React from 'react';
import {Loading, Spotlight} from 'webuniversum-react';
import {connect} from 'react-redux';
import {RichText} from 'src/modules/rich-text';
import {getIndustrieleSymbiosePaginas, IGetIndustrieleSymbiosePaginasState} from 'src/reducers/informatieve_website/industriele-symbiose-paginas';
import intl from 'react-intl-universal';
import Breadcrumbs from 'src/modules/welkomstpagina/breadcrumbs';
import find from 'lodash/find';
import {ExternalLink} from 'src/modules/detail-components/external-link';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IIndustrieleSymbiosePaginaProps {
  match: any;
  history: any;
  taal: string;
  getIndustrieleSymbiosePaginas: Function;
  industrieleSymbiosePaginas: IGetIndustrieleSymbiosePaginasState;
}

export class IndustrieleSymbiosePagina extends React.Component<IIndustrieleSymbiosePaginaProps, undefined> {
  componentDidMount() {
    this.props.getIndustrieleSymbiosePaginas(this.props.taal);
  }

  componentDidUpdate(prevProps: Readonly<IIndustrieleSymbiosePaginaProps>, prevState: Readonly<undefined>, snapshot?: any): void {
    if (this.props.match.params.referentie !== prevProps.match.params.referentie) {
      window.scrollTo(0, 0);
    }
  }

  render(): React.ReactNode {
    const detail = find(this.props.industrieleSymbiosePaginas.industrieleSymbiosePaginas, pagina => pagina.referentie === this.props.match.params.referentie);
    return <>
      {
        detail &&
        <Loading loading={this.props.industrieleSymbiosePaginas.loading}>
          <Breadcrumbs breadcrumbs={[
            { title: intl.get('welkomstpagina.titel'), location: '/' },
            { title: detail.titel }
          ]}/>
          <div className="vl-grid">
            <div className="vl-col--3-4">
              <div className="vl-u-spacer">
                <h1 className="vl-h1 vl-u-spacer">{detail.titel}</h1>
                <RichText content={detail.inhoud}/>
              </div>
            </div>
            <div className="vl-col--1-4">
              {detail.leesOok.length > 0 &&
              <div className="vl-description-data--bordered vl-u-spacer">
                <h4 className="vl-h4 vl-u-spacer--small">{intl.get('welkomstpagina.industriele-symbiose.lees-ook')}</h4>
                <ul className="vl-link-list">
                  {detail.leesOok.map((leesOok, index) =>
                    <li key={`leesOok-${index}`}>
                      <p className="vl-link--icon--caret vl-link--block"><ExternalLink href={leesOok.url}>{leesOok.beschrijving}</ExternalLink></p>
                    </li>)}
                </ul>
              </div>}
            </div>
          </div>
          <section className="vl-region vl-region--alt">
            <div className="vl-layout vl-layout--wide">
              <h2 className="vl-h2 vl-u-spacer">{intl.get('welkomstpagina.industriele-symbiose.lees-verder')}</h2>
              <ul className="vl-grid vl-grid--is-stacked js-vl-equal-height-container">
                {this.props.industrieleSymbiosePaginas.industrieleSymbiosePaginas
                  .filter(pagina => pagina !== detail)
                  .map(leesVerder =>
                    <li className="vl-col--4-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs"
                      key={`leesVerder-${leesVerder.referentie}`}>
                      <Spotlight title={leesVerder.titel} size="s"
                        onClick={() => this.handleLeesVerder(leesVerder.referentie)}/>
                    </li>
                  )}
              </ul>
            </div>
          </section>
        </Loading>
      }
    </>;
  }

  handleLeesVerder = referentie => {
    this.props.history.push(`/welkomstpagina/industriele-symbiose/${referentie}`);
  };
}

const mapStateToProps = storeState => ({
  industrieleSymbiosePaginas: storeState.informatieveWebsite.industrieleSymbiosePaginas,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  getIndustrieleSymbiosePaginas
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(IndustrieleSymbiosePagina));
