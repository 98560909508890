import React from 'react';
import intl from 'react-intl-universal';
import {Accordion, InfoBlock} from 'webuniversum-react';
import {connect} from 'react-redux';
import {getFaqsPerThema, IFaq} from 'src/reducers/informatieve_website/faqs';
import {RichText} from '../rich-text';
import Breadcrumbs from './breadcrumbs';
import groupBy from 'lodash/groupBy';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IFaqPaginaProps {
  taal: string;
  getFaqsPerThema: Function;
  faqs: IFaq[];
}

export class FaqPagina extends React.Component<IFaqPaginaProps, undefined> {
  componentDidMount() {
    this.props.getFaqsPerThema(this.props.taal);
  }

  render(): React.ReactNode {
    const grouped = groupBy(this.props.faqs, 'thema');
    return <>
      <Breadcrumbs breadcrumbs={[
        { title: intl.get('welkomstpagina.titel'), location: '/' },
        { title: intl.get('welkomstpagina.faq.titel') }
      ]}/>
      <InfoBlock titel={intl.get('welkomstpagina.faq.titel')} layout="faq">
        {
          Object.keys(grouped).map(key =>
            <div key={key}>
              <div className="vl-u-hr">
                <h3 className="vl-h3">{key}</h3>
                <ul className="vl-link-list">
                  {grouped[key].map(faq =>
                    <li key={faq.vraag}>
                      <Accordion key={faq.vraag} layout="accordion" className="relevantie-accordion" title={faq.vraag}
                        defaultOpen={false}>
                        <RichText content={faq.antwoord} />
                      </Accordion>
                    </li>
                  )}
                </ul>
                <div className="vl-u-spacer--small"/>
              </div>
              <div className="vl-u-spacer--small"/>
            </div>
          )}
      </InfoBlock>
    </>;
  }
}

const mapStateToProps = storeState => ({
  faqs: storeState.informatieveWebsite.faqs.faq,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  getFAQsPerThema: getFaqsPerThema
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(FaqPagina));
