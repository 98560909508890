import axios from 'axios';
import intl from 'react-intl-universal';
import { keycloak, unsetAuthorization } from '../reducers/authentication';
import { IError, initialErrorState, setError } from '../reducers/error';
import { getStore } from '../redux-store';

const TIMEOUT = 1000000;
const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    config.timeout = TIMEOUT;
    config.url = `${window.location.origin.replace(/\/$/, '')}${config.url}`;
    if (keycloak.authenticated) {
      return keycloak.updateToken(30).then(() => {
        const token = keycloak.token;
        if (token) {
          config.headers[ 'Authorization' ] = 'Bearer ' + token;
        }
        return Promise.resolve(config);
      }).catch(() => {
        localStorage.removeItem('oidc');
        keycloak.clearToken();
        return Promise.reject('Token refresh failed');
      });
    }
    return Promise.resolve(config);
  };

  const onResponseSuccess = response => response;
  const onResponseError = err => {
    let status;
    if (err && err.status) {
      status = err.status;
    } else if (err && err.response) {
      status = err.response.status;
    }

    if (status === 401) {
      onUnauthenticated();
      forwardingTo('/#/');
    } else if (status === 403) {
      const message = err.response.data?.message || 'error.http.forbidden';
      let forbiddenError;
      if (['error.session.loa.single_unverified_user_only', 'error.session.loa.verified_login_only', 'error.session.loa.verification_failed'].includes(message)) {
        forbiddenError = {
          uuid: err.response.data?.uuid || `uuid-${status}`,
          code: status,
          title: 'error.http.forbidden',
          subtitle: `${message}.subtitle`,
          message: intl.getHTML(`${message}.message`, { url: '/#/welkomstpagina/registratie/instructies/nieuwe-gebruiker' })
        };
      } else {
        forbiddenError = asError(err, status, intl.getHTML(message));
      }
      getStore().dispatch(unsetAuthorization(forbiddenError.message));
      dispatchError(forbiddenError);
      forwardingTo('/#/error');
    } else if (status === 409) {
      dispatchError(asError(err, status, intl.getHTML('error.http.conflict')));
    } else if (status === 400 || (status >= 500 && status < 600)) {
      dispatchError(asError(err, status, intl.getHTML('error.http.internalservererror')));
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

const asError = (err: any, code: string, message: string): IError => (
  mergeErrors(
    {
      ...initialErrorState(),
      uuid: err.response.data?.uuid,
      message: err.response.data?.message && intl.getHTML(err.response.data.message)
    },
    defaultError(code, message)
  )
);

const defaultError = (code: string, message: string): IError => ({
  uuid: `uuid-${code}`,
  code,
  title: 'error.general.title',
  subtitle: 'error.general.subtitle.with-message',
  message
});

const mergeErrors = (primary: IError, secondary: IError): IError => ({
  uuid: primary.uuid || secondary.uuid,
  code: primary.code || secondary.code,
  title: primary.title || secondary.title,
  subtitle: primary.subtitle || secondary.subtitle,
  message: primary.message || secondary.message
});

const dispatchError = (error: IError) => getStore().dispatch(setError(error));

const forwardingTo = (loc: string) => {
  window.location.replace(loc);
};

export default setupAxiosInterceptors;
