import {getStore} from 'src/redux-store';
import {IEural} from './eural-code';

export const isEuralDataBeschikbaar = (): boolean => {
  const euralCodes: IEural[] = (getStore().getState()).euralCodes.data;

  return euralCodes !== null && euralCodes.length > 0;
};

export const isValidEuralCode = (euralCode: string) => {
  const euralCodes: IEural[] = (getStore().getState()).euralCodes.data;
  if (!euralCodes) {
    return true; // Vermijd validatie errors wanneer de euralcode lijst niet beschikbaar is. (Het eural veld is toch read-only)
  }
  if (!euralCode) {
    return false;
  }

  return euralCodes.some(eural => eural.notation === euralCode);
};

export const getEuralCodeByCode = (notation: string): IEural =>
  getEuralCode(eural => eural.notation === notation);

export const getEuralCodeById = (uri: string): IEural =>
  getEuralCode(eural => eural['@id'] === uri);

export const getEuralCode = (predicate: (IEural) => boolean): IEural => {
  const euralCodes: IEural[] = (getStore().getState()).euralCodes.data;
  return euralCodes ? euralCodes.find(predicate) : null;
};
