import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {ButtonGroup, Loading} from 'webuniversum-react';
import {RELEVANT_VRAAG_SYMBIOSE_INVITE, RELEVANTE_VRAAG_HULPVRAAG, RELEVANTE_VRAAG_SEND_BERICHT} from 'src/reducers/aanbod';
import {getMateriaalAanbod, IGetMateriaalAanbodState} from 'src/reducers/aanbod/materiaal/get';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {AuthenticatieNiveau} from 'src/reducers/authentication';
import {createConversatie, createHulpvraagForRelevanteVraag, getHulpvraagForRelevanteVraag, getOverleg, IConversatie} from 'src/reducers/bericht/conversatie';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {getRelevantie, IRelevantie} from 'src/reducers/relevantie';
import {downloadVraagBestand, getMateriaalVraag, IGetMateriaalVraagState} from 'src/reducers/vraag/materiaal/get';
import {RelevanteVraagHulpvraagModal, RelevanteVraagSendBerichtModal} from 'src/modules/bericht/bericht-modal';
import {SymbioseFrame} from 'src/modules/form-components/symbiose-frame';
import {extractUid} from 'src/modules/referentie-uri';
import {InviteModal} from 'src/modules/symbiose/invite-symbiose-modal';
import {DetailMateriaalVraag} from 'src/modules/vraag/materiaal/detail-materiaal-vraag';
import {AxiosPromise} from 'axios';
import {getSymbioseVoorVraagEnAanbod} from 'src/reducers/symbiose/get';
import {ISymbiose} from 'src/reducers/symbiose/create';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {ToonConversatieButton} from 'src/modules/bericht/toon-conversatie-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IRelevanteMateriaalVraagProps {
  queryResult: IGetMateriaalVraagState;
  aanbod: IGetMateriaalAanbodState;
  relevantie: IRelevantie;
  symbiose: ISymbiose;
  conversatie: IConversatie;
  afstandRanges: number[];
  isAuthenticated: boolean;
  getMateriaalVraag: Function;
  getMateriaalAanbod: Function;
  getRelevantie: Function;
  getSymbioseVoorVraagEnAanbod: Function;
  getHulpvraagForRelevanteVraag: Function;
  createHulpvraagForRelevanteVraag: Function;
  getOverleg: Function;
  createConversatie: Function;
  changeHeader: Function;
  showModal: Function;
  hideModal: Function;
  history: any;
  match: any;
}

export class RelevanteMateriaalVraag extends React.Component<IRelevanteMateriaalVraagProps> {
  componentDidMount() {
    this.props.getMateriaalVraag(this.props.match.params.vraagUid);
    this.props.getMateriaalAanbod(this.props.match.params.aanbodUid);
    this.props.getRelevantie(this.props.match.params.aanbodUid, this.props.match.params.vraagUid);
    this.props.getSymbioseVoorVraagEnAanbod(this.props.match.params.vraagUid, this.props.match.params.aanbodUid);
    this.props.getOverleg(this.props.match.params.aanbodUid, this.props.match.params.vraagUid);

    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'detail.header.return.vraag',
      '',
      [
        {
          title: 'detail.header.title.vraag',
          isActive: true
        }
      ],
      () => {
        this.props.history.push('../vragen');
      });
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
    this.props.hideModal(RELEVANTE_VRAAG_SEND_BERICHT);
    this.props.hideModal(RELEVANTE_VRAAG_HULPVRAAG);
  }

  getVraagUid = () => extractUid('vraag', this.props.queryResult.vraag.vraagReferentie);

  getAanbodUid = () => extractUid('aanbod', this.props.aanbod.aanbod.aanbodReferentie);

  downloadBestand = (uid: string): AxiosPromise => downloadVraagBestand(this.getVraagUid(), extractUid('bestand', uid));

  private handleOpenBerichtModal = () => {
    this.props.getOverleg(this.getAanbodUid(), this.getVraagUid())
      .then(() => {
        if (!this.props.conversatie.referentie) {
          this.props.createConversatie(this.getAanbodUid(), this.getVraagUid());
        }
      });

    this.props.showModal(RELEVANTE_VRAAG_SEND_BERICHT, {
      cancel: () => this.props.hideModal(RELEVANTE_VRAAG_SEND_BERICHT)
    });
  };

  private handleOpenHulpvraagModal = () => {
    this.props.getHulpvraagForRelevanteVraag(this.getAanbodUid(), this.getVraagUid())
      .then(() => {
        if (!this.props.conversatie.referentie) {
          this.props.createHulpvraagForRelevanteVraag(this.getAanbodUid(), this.getVraagUid());
        }
      });

    this.props.showModal(RELEVANTE_VRAAG_HULPVRAAG, {
      cancel: () => {
        this.props.hideModal(RELEVANTE_VRAAG_HULPVRAAG);
        this.props.getOverleg(this.getAanbodUid(), this.getVraagUid());
      }
    });
  };

  private createSymbioseOpenModal = () => {
    this.props.showModal(RELEVANT_VRAAG_SYMBIOSE_INVITE, {
      submitText: intl.get('detail.symbiose.invite.send.label'),
      submit: () => this.props.getSymbioseVoorVraagEnAanbod(this.getVraagUid(), this.getAanbodUid()),
      cancel: () => this.props.hideModal(RELEVANT_VRAAG_SYMBIOSE_INVITE),
      cancelText: intl.get('detail.symbiose.invite.cancel.label'),
      title: intl.get('detail.symbiose.invite.label'),
      text: intl.get('detail.symbiose.invite.symbiose-invite.label.vraag')
    });
  };

  render(): React.ReactNode {
    const { queryResult, symbiose } = this.props;
    const { loading, vraag } = queryResult;
    return (
      <>
        {this.props.isAuthenticated &&
        <div className="vl-grid">
          <Loading loading={loading && vraag !== null}>
            <div className="vl-col--8-12">
              <div className="vl-grid">
                <div className="vl-col--12-12">
                  <DetailMateriaalVraag vraag={vraag}
                    downloadBestand={this.downloadBestand}
                    relevantie={this.props.relevantie}
                    aanbod={this.props.aanbod}
                    afstandRanges={this.props.afstandRanges}/>
                </div>
              </div>
            </div>

            <div className="vl-col--3-12 vl-push--1-12">
              <ButtonGroup>
                <VraagHulpButton handleClick={this.handleOpenHulpvraagModal} className="bericht-button" />
                <ToonConversatieButton conversatie={this.props.conversatie} handleClick={this.handleOpenBerichtModal} />
              </ButtonGroup>
              <SymbioseFrame onClickButton={this.createSymbioseOpenModal} symbiose={symbiose}/>
            </div>
          </Loading>

          <RelevanteVraagSendBerichtModal/>
          <RelevanteVraagHulpvraagModal/>
          <InviteModal vraagReferentie={this.getVraagUid()}
            aanbodReferentie={this.getAanbodUid()}
            warning={vraag?.onderneming?.authenticatieNiveau !== AuthenticatieNiveau.GEVERIFIEERD && intl.get('detail.symbiose.invite.warning.unverified')}/>
        </div>
        }
      </>
    );
  }
}

const mapStateToProps = storeState => ({
  queryResult: storeState.vraag.details,
  aanbod: storeState.aanbod.details,
  relevantie: storeState.relevantie.relevantie,
  symbiose: storeState.symbiose.get.symbiose,
  conversatie: storeState.bericht.bericht.conversatie,
  afstandRanges: storeState.application.application.afstandRanges,
  isAuthenticated: storeState.authentication.security.isAuthenticated
});

const mapDispatchToProps = {
  getMateriaalVraag,
  getMateriaalAanbod,
  getRelevantie,
  getSymbioseVoorVraagEnAanbod,
  getHulpvraagForRelevanteVraag,
  createHulpvraagForRelevanteVraag,
  getOverleg,
  createConversatie,
  changeHeader,
  showModal,
  hideModal
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(RelevanteMateriaalVraag));
