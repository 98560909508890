import React from 'react';
import { Alert, Radiobutton } from 'webuniversum-react';
import { IInstructieVraag } from 'src/reducers/informatieve_website/instructies-nieuwegebruiker';
import { RichText } from '../rich-text';

export interface IInstructieProps {
  vraag: IInstructieVraag;
  level?: number;
}

interface IInstructieState {
  selectedOptie: string;
}

export class Instructie extends React.Component<IInstructieProps, IInstructieState> {
  state = {
    selectedOptie: undefined
  };

  render(): React.ReactNode {
    const { vraag, level = 1 } = this.props;
    return (vraag &&
      <>
        <div><RichText content={vraag.uitleg} /></div>
        <div className="vl-u-spacer--small"/>
        {vraag.tekst && <Alert>
          <p className="welkompagina-titel">{vraag.tekst}</p>
          <div className="vl-u-spacer--small"/>
          {vraag.antwoordOpties.map((optie, index) =>
            <Radiobutton key={`vraag${level}_optie${index}`}
              name={`vraag${level}_opties`}
              value={`vraag${level}_optie${index}`}
              onChange={this.handleRadiobuttonSelect} block>
              {optie.tekst}
            </Radiobutton>
          )}
        </Alert>}
        {vraag.antwoordOpties.map((optie, index) =>
          this.state.selectedOptie === `vraag${level}_optie${index}` && <div key={`vraag${level}_optie${index}_block`}>
            {optie.uitleg && <div><RichText content={optie.uitleg} /></div>}
            {optie.vervolgVraag && <Instructie vraag={optie.vervolgVraag} level={level + 1} />}
          </div>
        )}
      </>
    );
  }

  private handleRadiobuttonSelect = event => {
    this.setState({ selectedOptie: event.target.value });
  };
}
