import axios, { AxiosPromise } from 'axios';
import {
  ActionCreator,
  AnyAction
} from 'redux';
import { Land } from 'src/modules/adres/adres-form';
import { EnumWrapper } from 'src/modules/form-components/enum-wrapper';
import {
  FAILURE,
  REQUEST,
  SUCCESS
} from 'src/reducers/action-type.util';
import {
  IAdres,
  initialAdres
} from 'src/reducers/adres';
import {
  initialOnderneming,
  IOnderneming
} from 'src/reducers/authentication';
import { IVertaling } from 'src/reducers/taal/vertalingen';
import { VerplaatsingOptie } from 'src/modules/technologie/verplaatsing-optie';
import { Ontwikkelingsstadium } from 'src/modules/technologie/ontwikkelingsstadium';
import {
  filesToBestandenFormData,
  IBestand
} from 'src/reducers/bestand';

export const ACTION_TYPES = {
  FETCH_TECHNOLOGIE: 'technologie/FETCH_TECHNOLOGIE',
  CREATE_TECHNOLOGIE: 'technologie/CREATE_TECHNOLOGIE',
  UPDATE_TECHNOLOGIE: 'technologie/UPDATE_TECHNOLOGIE',
  DELETE_TECHNOLOGIE: 'technologie/DELETE_TECHNOLOGIE'
};

export interface ITechnologie extends IVertaling {
  technologieReferentie: string;
  taal: string;
  bronTaal: string;
  vertalingStatus: string;
  onderneming: IOnderneming;
  titel: string;
  beschrijving: string;
  ontwikkelingsstadium: string;
  beschrijvingTeVerwerkenMateriaal: string;
  oorsprongTeVerwerkenMateriaal: string;
  beschrijvingVerwerktMateriaal: string;
  mogelijkeBestemmingVerwerktMateriaal: string;
  verplaatsingsOptie: string;
  adres: IAdres;
  ondernemingsNaam: string;
  voornaamContactPersoon: string;
  familienaamContactPersoon: string;
  email: string;
  telefoon: string;
  website: string;
  bijlagen: IBestand[];
}

export interface IGetTechnologieState {
  loading: boolean;
  errorMessage: string;
  technologie: ITechnologie;
}

export const initialGetTechnologieState: IGetTechnologieState = {
  loading: false,
  errorMessage: null,
  technologie: {
    technologieReferentie: '',
    taal: '',
    bronTaal: '',
    vertalingStatus: '',
    onderneming: initialOnderneming(),
    titel: '',
    beschrijving: '',
    ontwikkelingsstadium: '',
    beschrijvingTeVerwerkenMateriaal: '',
    oorsprongTeVerwerkenMateriaal: '',
    beschrijvingVerwerktMateriaal: '',
    mogelijkeBestemmingVerwerktMateriaal: '',
    verplaatsingsOptie: '',
    adres: initialAdres(),
    ondernemingsNaam: '',
    voornaamContactPersoon: '',
    familienaamContactPersoon: '',
    email: '',
    telefoon: '',
    website: '',
    bijlagen: []
  }
};

export const getTechnologieReducer = (state = initialGetTechnologieState, action: AnyAction): IGetTechnologieState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_TECHNOLOGIE):
  case REQUEST(ACTION_TYPES.CREATE_TECHNOLOGIE):
  case REQUEST(ACTION_TYPES.UPDATE_TECHNOLOGIE):
  case REQUEST(ACTION_TYPES.DELETE_TECHNOLOGIE):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_TECHNOLOGIE):
  case FAILURE(ACTION_TYPES.CREATE_TECHNOLOGIE):
  case FAILURE(ACTION_TYPES.UPDATE_TECHNOLOGIE):
  case FAILURE(ACTION_TYPES.DELETE_TECHNOLOGIE):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_TECHNOLOGIE):
  case SUCCESS(ACTION_TYPES.CREATE_TECHNOLOGIE):
  case SUCCESS(ACTION_TYPES.UPDATE_TECHNOLOGIE):
  case SUCCESS(ACTION_TYPES.DELETE_TECHNOLOGIE):
    return {
      ...state,
      loading: false,
      technologie: {
        technologieReferentie: action.payload.data.technologieReferentie,
        taal: action.payload.data.taal,
        bronTaal: action.payload.data.bronTaal,
        vertalingStatus: action.payload.data.vertalingStatus,
        onderneming: action.payload.data.onderneming,
        titel: action.payload.data.titel,
        beschrijving: action.payload.data.beschrijving,
        ontwikkelingsstadium: action.payload.data.ontwikkelingsstadium ?
          new EnumWrapper(Ontwikkelingsstadium, 'Ontwikkelingsstadium').parse(action.payload.data.ontwikkelingsstadium) : null,
        beschrijvingTeVerwerkenMateriaal: action.payload.data.beschrijvingTeVerwerkenMateriaal,
        oorsprongTeVerwerkenMateriaal: action.payload.data.oorsprongTeVerwerkenMateriaal,
        beschrijvingVerwerktMateriaal: action.payload.data.beschrijvingVerwerktMateriaal,
        mogelijkeBestemmingVerwerktMateriaal: action.payload.data.mogelijkeBestemmingVerwerktMateriaal,
        verplaatsingsOptie: action.payload.data.verplaatsingsOptie ? new EnumWrapper(VerplaatsingOptie, 'VerplaatsingOptie').parse(action.payload.data.verplaatsingsOptie) : null,
        adres: {
          locatienaam: action.payload.data.adres.locatienaam,
          straatnaam: action.payload.data.adres.straatnaam,
          huisnummer: action.payload.data.adres.huisnummer,
          adresLijn2: action.payload.data.adres.adresLijn2,
          postcode: action.payload.data.adres.postcode,
          gemeente: action.payload.data.adres.gemeente,
          land: action.payload.data.adres.land ? new EnumWrapper(Land, 'Land').parse(action.payload.data.adres.land) : null
        },
        ondernemingsNaam: action.payload.data.ondernemingsNaam,
        voornaamContactPersoon: action.payload.data.voornaamContactPersoon,
        familienaamContactPersoon: action.payload.data.familienaamContactPersoon,
        email: action.payload.data.email,
        telefoon: action.payload.data.telefoon,
        website: action.payload.data.website,
        bijlagen: action.payload.data.bijlagen
      }
    };
  default:
    return state;
  }
};

export const getTechnologie: ActionCreator<any> = (taal: string, technologieUid: string) => ({
  type: ACTION_TYPES.FETCH_TECHNOLOGIE,
  payload: axios.get(`/ui/technologie/${technologieUid}/${taal}`)
});

export const createTechnologie: ActionCreator<any> = (technologie: any) => ({
  type: ACTION_TYPES.CREATE_TECHNOLOGIE,
  payload: axios.post('/ui/technologie/', technologie)
});

export const updateTechnologie: ActionCreator<any> = (technologieUid: string, technologie: any) => ({
  type: ACTION_TYPES.UPDATE_TECHNOLOGIE,
  payload: axios.put(`/ui/technologie/${technologieUid}`, technologie)
});

export const deleteTechnologie: ActionCreator<any> = (technologieUid: string) => ({
  type: ACTION_TYPES.DELETE_TECHNOLOGIE,
  payload: axios.delete(`/ui/technologie/${technologieUid}`)
});

export const downloadTechnologieBestand = (technologieUid: string, bestandUid: string): AxiosPromise =>
  axios.get(`/ui/technologie/${technologieUid}/bestanden/${bestandUid}/download`, { responseType: 'blob' });

export const uploadTechnologieBestanden = (files: File[], technologieUid = ''): AxiosPromise =>
  axios.post(technologieUid ? `/ui/technologie/${technologieUid}/bestanden` : '/ui/technologie/bestanden', filesToBestandenFormData(files), {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
