import React from 'react';

interface IPercentageProps {
  value: number;
}

export const Percentage: React.FunctionComponent<IPercentageProps> = (props: IPercentageProps) => (
  <>{Number(props.value / 100).toLocaleString('nl-BE', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })}</>
);
