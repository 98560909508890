import React from 'react';
import {FOTO_PREVIEW_MODAL, IBestand} from 'src/reducers/bestand';
import {FotoUpload} from 'src/modules/foto/foto-upload';
import {UploadFile} from 'antd/es/upload/interface';
import {UploadFotoButton} from 'src/modules/foto/upload-foto-button';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {connect} from 'react-redux';
import FotoPreviewModal from 'src/modules/foto/foto-preview-modal';
import intl from 'react-intl-universal';
import isEqual from 'lodash/isEqual';
import {extractUid} from 'src/modules/referentie-uri';
import {RcFile} from 'antd/lib/upload/interface';

export interface IFotoUploadContainerProps {
  maxCount: number;
  maxSizeMb: number;
  acceptFileTypes: string;
  fotos: IBestand[];
  uploadFoto: Function;
  downloadFoto: Function;
  handleFotoChange: (fotos: IBestand[]) => void;
  showModal: Function;
  hideModal: Function;
}

export interface IFotoUploadContainerState {
  fotos: IUploadFoto[];
  preview: UploadFile;
}

export interface IUploadFoto {
  file: UploadFile;
  referentie: string;
}

export class FotoUploadContainer extends React.Component<IFotoUploadContainerProps, IFotoUploadContainerState> {
  public static defaultProps: Partial<IFotoUploadContainerProps> = {
    maxCount: 10,
    maxSizeMb: 25,
    acceptFileTypes: 'image/jpeg, image/png'
  };

  _isMounted = false;

  constructor(props: Readonly<IFotoUploadContainerProps>) {
    super(props);
    this.state = {
      fotos: [],
      preview: null
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: Readonly<IFotoUploadContainerProps>, prevState: Readonly<IFotoUploadContainerState>, snapshot?: any) {
    if (!isEqual(prevProps.fotos, this.props.fotos)) {
      this.bestandenToFotos(this.props.fotos).catch(ex => console.error(ex));
    }
  }

  render(): React.ReactNode {
    const {maxCount, acceptFileTypes} = this.props;
    const {fotos, preview} = this.state;
    const index = preview ? fotos.findIndex(foto => foto.file.uid === preview.uid) : -1;
    return (
      <>
        <FotoUpload fotos={fotos.map(foto => foto.file)}
          acceptFileTypes={acceptFileTypes}
          handleValidateFotos={this.validateFotos}
          handleAddFoto={this.handleAddFoto}
          handleRemoveFoto={this.handleRemoveFoto}
          handleShowPreview={this.handleShowPreview}>
          {fotos.length < maxCount ? <UploadFotoButton/> : null}
        </FotoUpload>
        <FotoPreviewModal preview={preview}>
          {index === 0 && <p className="startpagina-tile">{intl.get('materiaal.edit.form.fotos.modal.current-cover')}</p>}
          {index > 0 &&
          <p className="startpagina-tile">
            <a onClick={() => this.handleMakeCover(preview)}>{intl.get('materiaal.edit.form.fotos.modal.make-cover')}</a>
          </p>}
        </FotoPreviewModal>
      </>
    );
  }

  private validateFotos = (selectedFiles: RcFile[]): string[] => {
    const {validFiles: validFiles1, errorMessages: msg1} = this.validateMaxCount(selectedFiles);
    const {validFiles: validFiles2, errorMessages: msg2} = this.validateFileType(validFiles1);
    const {errorMessages: msg3} = this.validateMaxSize(validFiles2);

    return msg1.concat(msg2).concat(msg3);
  };

  private validateMaxCount = (fileList: RcFile[]): {validFiles: RcFile[]; errorMessages: string[]} => {
    const {fotos, maxCount} = this.props;
    if (fotos.length + fileList.length > maxCount) {
      return this.validationResult([], [intl.get('materiaal.edit.form.fotos.errors.max-count-exceeded', {
        maxCount
      })]);
    }
    return this.validationResult(fileList);
  };

  private validateFileType = (fileList: RcFile[]): {validFiles: RcFile[]; errorMessages: string[]} => {
    const supportedTypes = this.props.acceptFileTypes.split(/, ?/);
    const wrongType = fileList.filter(file => !supportedTypes.includes(file.type));
    if (wrongType.length > 0) {
      const errors = wrongType.map(file => intl.get('materiaal.edit.form.fotos.errors.filetype-unsupported', {
        name: file.name,
        acceptFileTypes: this.props.acceptFileTypes
      }));
      return this.validationResult(fileList.filter(file => !wrongType.includes(file)), errors);
    }
    return this.validationResult(fileList);
  };

  private validateMaxSize = (fileList: RcFile[]): {validFiles: RcFile[]; errorMessages: string[]} => {
    const tooBig = fileList.filter(file => file.size > this.props.maxSizeMb * 1024 * 1024);
    if (tooBig.length > 0) {
      const errors = tooBig.map(file => intl.get('materiaal.edit.form.fotos.errors.max-size-exceeded', {
        name: file.name,
        maxSize: `${this.props.maxSizeMb} MB`
      }));
      return this.validationResult(fileList.filter(file => !tooBig.includes(file)), errors);
    }
    return this.validationResult(fileList);
  };

  private validationResult = (validFiles: RcFile[], errorMessages: string[] = []) => ({validFiles, errorMessages});

  private handleAddFoto = (file: UploadFile): void => {
    this.props.uploadFoto([file])
      .then(response => this.changeFotoList(copy => copy.push({file, referentie: response.data[0].referentie})));
  };

  private handleRemoveFoto = (file: UploadFile): void => {
    const index = this.state.fotos.findIndex(foto => foto.file.uid === file.uid);
    if (index !== -1) {
      this.changeFotoList(copy => copy.splice(index, 1));
    } else {
      console.error(`could not remove picture with uid ${file.uid}`, file, this.state.fotos);
    }
  };

  private changeFotoList = (arrayChange: (copy: IUploadFoto[]) => void): void => {
    const copy = [
      ...this.state.fotos
    ];
    arrayChange(copy);
    this.props.handleFotoChange(this.fotosToBestanden(copy));
  };

  private handleShowPreview = (file: UploadFile) => {
    this.setState({
      preview: file
    });
    this.props.showModal(FOTO_PREVIEW_MODAL, {
      cancel: () => this.props.hideModal(FOTO_PREVIEW_MODAL)
    });
  };

  private handleMakeCover = (file: UploadFile) => {
    const index = this.state.fotos.findIndex(foto => foto.file.uid === file.uid);
    if (index !== -1) {
      this.changeFotoList(copy => {
        const extracted = copy.splice(index, 1);
        copy.unshift(...extracted);
      });
    } else {
      console.error(`could not make picture with uid ${file.uid} the cover picture`, file, this.state.fotos);
    }
  };

  private fotosToBestanden = (fotos: IUploadFoto[]): IBestand[] =>
    fotos.map(foto => ({
      referentie: foto.referentie,
      naam: foto.file.name,
      type: foto.file.type,
      grootte: foto.file.size,
      uid: foto.file.uid,
      url: foto.file.url || URL.createObjectURL(foto.file as any)
    }));

  private bestandenToFotos = async (bestanden: IBestand[]) =>
    Promise.all(bestanden.map(this.toFoto))
      .then(fotos => {
        if (this._isMounted) {
          this.setState({fotos});
        }
      });

  private toFoto = (bestand: IBestand): Promise<IUploadFoto> =>
    this.props.downloadFoto(bestand)
      .then(url => ({
        file: ({
          uid: bestand.uid || extractUid('bestand', bestand.referentie),
          name: bestand.naam,
          status: 'success',
          url,
          thumbUrl: url,
          type: bestand.type,
          size: bestand.grootte
        }),
        referentie: bestand.referentie
      }))
      .catch(ex => console.error(ex));
}

const mapDispatchToProps = {
  showModal,
  hideModal
};

export default connect(undefined, mapDispatchToProps)(FotoUploadContainer);
