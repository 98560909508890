import React from 'react';
import intl from 'react-intl-universal';
import {NewsTeaser, NewsTeaserLabel, NewsTeaserText, NewsTeaserTitle} from 'webuniversum-react';
import {connect} from 'react-redux';
import {getNieuws, INieuws} from 'src/reducers/informatieve_website/nieuws';
import Breadcrumbs from 'src/modules/welkomstpagina/breadcrumbs';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface INieuwsProps {
  history: any;
  taal: string;
  getNieuws: Function;
  nieuws: INieuws[];
}

export class NieuwsPagina extends React.Component<INieuwsProps, undefined> {
  componentDidMount() {
    this.props.getNieuws(this.props.taal);
  }

  render(): React.ReactNode {
    return <>
      <Breadcrumbs breadcrumbs={[
        { title: intl.get('welkomstpagina.titel'), location: '/' },
        { title: intl.get('welkomstpagina.nieuws.titel') }
      ]}/>
      <h1 className="vl-h1 vl-u-spacer">{intl.get('welkomstpagina.nieuws.titel')}</h1>
      <section className="vl-region">
        <div className="vl-layout vl-layout--wide">
          <ul className="vl-grid vl-grid--is-stacked js-vl-equal-height-container vl-u-spacer">
            {
              this.props.nieuws.map(nieuws =>
                <li className="vl-col--3-12" key={`nieuws-${nieuws.referentie}`}>
                  <NewsTeaser onClick={() => this.handleOpenNieuws(nieuws.referentie)} type="default">
                    <NewsTeaserLabel>{intl.get('validation.date.format', { date: nieuws.datum })}</NewsTeaserLabel>
                    <NewsTeaserTitle>{nieuws.titel}</NewsTeaserTitle>
                    <NewsTeaserText>{nieuws.header}</NewsTeaserText>
                  </NewsTeaser>
                </li>
              )}
          </ul>
        </div>
      </section>
    </>;
  }

  handleOpenNieuws = referentie => {
    this.props.history.push(`/welkomstpagina/nieuws/${referentie}`);
  };
}

const mapStateToProps = storeState => ({
  nieuws: storeState.informatieveWebsite.nieuws.nieuws,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  getNieuws
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(NieuwsPagina));
