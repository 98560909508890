import intl from 'react-intl-universal';

import aanbod_en from './en/aanbod.en.json';
import applicatie_en from './en/applicatie.en.json';
import bericht_en from './en/bericht.en.json';
import categorieen_en from './en/categorie.lov.en.json';
import detail_en from './en/detail.en.json';
import error_en from './en/error.en.json';
import materiaal_en from './en/materiaal.en.json';
import symbiose_en from './en/symbiose.en.json';
import technologie_en from './en/technologie.en.json';
import validation_en from './en/validation.en.json';
import vraag_en from './en/vraag.en.json';
import algemene_voorwaarden_en from './en/algemene.voorwaarden.en.json';
import welkomstpagina_en from './en/welkomstpagina.en.json';
import beschikbaarheid_en from './en/beschikbaarheid.en.json';

import aanbod_nl from './nl/aanbod.nl.json';
import applicatie_nl from './nl/applicatie.nl.json';
import bericht_nl from './nl/bericht.nl.json';
import categorieen_nl from './nl/categorie.lov.nl.json';
import detail_nl from './nl/detail.nl.json';
import error_nl from './nl/error.nl.json';
import materiaal_nl from './nl/materiaal.nl.json';
import symbiose_nl from './nl/symbiose.nl.json';
import technologie_nl from './nl/technologie.nl.json';
import validation_nl from './nl/validation.nl.json';
import vraag_nl from './nl/vraag.nl.json';
import algemene_voorwaarden_nl from './nl/algemene.voorwaarden.nl.json';
import welkomstpagina_nl from './nl/welkomstpagina.nl.json';
import beschikbaarheid_nl from './nl/beschikbaarheid.nl.json';

export const mergeJSON = (...files) => {
  let totalJson = {};
  files.forEach(file => {
    totalJson = { ...totalJson, ...file };
  });

  return flattenMessages(totalJson);
};

const flattenMessages = (nestedMessages, prefix = '') =>
  Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[ key ];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[ prefixedKey ] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});

export const loadLocales = (language = 'nl') =>
  intl.init({
    currentLocale: language,
    locales
  });

const locales = {
  nl: mergeJSON(
    aanbod_nl,
    applicatie_nl,
    bericht_nl,
    categorieen_nl,
    detail_nl,
    error_nl,
    materiaal_nl,
    symbiose_nl,
    technologie_nl,
    validation_nl,
    vraag_nl,
    algemene_voorwaarden_nl,
    welkomstpagina_nl,
    beschikbaarheid_nl),
  en: mergeJSON(
    aanbod_en,
    applicatie_en,
    bericht_en,
    categorieen_en,
    detail_en,
    error_en,
    materiaal_en,
    symbiose_en,
    technologie_en,
    validation_en,
    vraag_en,
    algemene_voorwaarden_en,
    welkomstpagina_en,
    beschikbaarheid_en)
};

export const formatNumber = (number: number) => intl.get('validation.number.format', { num: number });

export const formatPriceEuro = (number: string) => intl.get('validation.price.euro.format', { price: number });
