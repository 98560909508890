import axios from 'axios';
import {ActionCreator, AnyAction} from 'redux';
import {FAILURE, REQUEST, SUCCESS} from 'src/reducers/action-type.util';
import {IBegrensdeWaarde} from 'src/reducers/relevantie';
import {IFoundVraag} from 'src/reducers/vraag/materiaal/search';
import {IFoundAanbod} from 'src/reducers/aanbod/materiaal/search';

export const ACTION_TYPES = {
  FETCH_RELEVANTE_VRAGEN: 'aanbod/FETCH_RELEVANTE_VRAGEN'
};

export interface IRelevanteVraag extends IFoundVraag {
  relevantieScore: IBegrensdeWaarde;
  afstand: IBegrensdeWaarde;
}

export interface IRelevanteVragenList extends IFoundAanbod {
  loading: boolean;
  errorMessage: any;
  aanbodReferentie: string;
  titel: string;
  beschrijving: string;
  topRelevanties: IRelevanteVraag[];
  interessanteRelevanties: IRelevanteVraag[];
  andereRelevanties: IRelevanteVraag[];
}

const initialState: IRelevanteVragenList = {
  loading: false,
  errorMessage: null,
  aanbodReferentie: null,
  taal: null,
  coverFotoReferentie: null,
  onderneming: null,
  titel: null,
  beschrijving: null,
  huidigeVerwerkingswijze: null,
  topRelevanties: [],
  interessanteRelevanties: [],
  andereRelevanties: []
};

export const relevanteVragenListReducer = (state = initialState, action: AnyAction): IRelevanteVragenList => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_RELEVANTE_VRAGEN):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_RELEVANTE_VRAGEN):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_RELEVANTE_VRAGEN):
    return {
      ...state,
      loading: false,
      aanbodReferentie: action.payload.data.aanbodReferentie,
      taal: action.payload.data.taal,
      coverFotoReferentie: action.payload.data.coverFotoReferentie,
      titel: action.payload.data.titel,
      beschrijving: action.payload.data.beschrijving,
      onderneming: action.payload.data.onderneming,
      topRelevanties: action.payload.data.topRelevanties,
      interessanteRelevanties: action.payload.data.interessanteRelevanties,
      andereRelevanties: action.payload.data.andereRelevanties
    };
  default:
    return state;
  }
};

export const getRelevanteVragen: ActionCreator<any> = (aanbodUid: string) => ({
  type: ACTION_TYPES.FETCH_RELEVANTE_VRAGEN,
  payload: axios.get(`/ui/aanbiedingen/${aanbodUid}/relevante-vragen`)
});
