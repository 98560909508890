import React from 'react';
import { IModalContainerProps } from 'src/reducers/modal/modal';
import { Alert, Button, ButtonGroup, Modal, ModalButtons, ModalContent, ModalTitle } from 'webuniversum-react';
import { connect } from 'react-redux';

export interface IChangeSymbioseStatusModalProps {
  modalProps: IModalContainerProps;
  warning?: string;
  text?: string;
}

export class ChangeSymbioseStatusModal extends React.Component<IChangeSymbioseStatusModalProps> {
  render(): React.ReactNode {
    const { visible, submitText, cancelText, title, submit, cancel } = this.props.modalProps;
    const { warning } = this.props;
    return (
      <Modal hidden={!visible} overlayHidden={!visible} style={{ width: '65rem' }} onClose={cancel}>
        <ModalTitle>{title}</ModalTitle>
        <ModalContent>
          {warning && <Alert type="alert">{warning}</Alert>}
          {this.props.text}
        </ModalContent>
        <ModalButtons>
          <ButtonGroup>
            <Button type="button" onClick={submit}>
              {submitText}
            </Button>
            <Button type="button" color="secondary" onClick={cancel}>
              {cancelText}
            </Button>
          </ButtonGroup>
        </ModalButtons>
      </Modal>
    );
  }
}

const mapBeantwoordStateToProps = storeState => ({
  modalProps: storeState.symbiose.beantwoordSymbioseModal
});

const mapNegeerStateToProps = storeState => ({
  modalProps: storeState.symbiose.negeerSymbioseModal
});

export const BeantwoordSymbioseModal = connect(mapBeantwoordStateToProps)(ChangeSymbioseStatusModal);
export const NegeerSymbioseModal = connect(mapNegeerStateToProps)(ChangeSymbioseStatusModal);
