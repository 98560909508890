import {combineReducers} from 'redux';
import {app} from 'src/reducers/app';
import {aanbod} from 'src/reducers/aanbod';
import {vraag} from 'src/reducers/vraag';
import {categorie} from 'src/reducers/categorie';
import {euralCodes} from 'src/reducers/eural/list';
import {bericht} from 'src/reducers/bericht';
import {symbiose} from 'src/reducers/symbiose';
import {RESET_STATE} from 'src/reducers/reset-state-action';
import {errorReducer} from 'src/reducers/error';
import {initialModalState, modalReducer} from 'src/reducers/modal/modal';
import {algemeneVoorwaarden} from 'src/reducers/algemene_voorwaarden';
import {authenticationReducer} from 'src/reducers/authentication';
import {application} from 'src/reducers/application';
import {startpagina} from 'src/reducers/startpagina';
import {relevantie} from 'src/reducers/relevantie';
import {informatieveWebsite} from 'src/reducers/informatieve_website';
import {technologie} from 'src/reducers/technologie';
import {bestand} from 'src/reducers/bestand';

export const ALGEMENE_VOORWAARDEN = 'header/algemene-voorwaarden';

const appReducer = combineReducers({
  app,
  application,
  authentication: authenticationReducer,
  aanbod,
  algemeneVoorwaarden,
  bestand,
  informatieveWebsite,
  vraag,
  categorie,
  bericht,
  symbiose,
  startpagina,
  technologie,
  euralCodes,
  error: errorReducer,
  algemeneVoorwaardenModal: modalReducer(ALGEMENE_VOORWAARDEN, initialModalState()),
  relevantie
});

export default (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined; // https://redux.js.org/recipes/structuringreducers/initializingstate
  }
  return appReducer(state, action);
};
