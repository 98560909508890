import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { initialSymbioseState, ISymbioseState } from './create';
import { formReducer, initialFormState } from 'redux-form-library';
import { MateriaalEenheid } from 'src/modules/materiaal/materiaal-hoeveelheid';
import { GerealiseerdeVerwerkingswijzeSymbiose } from 'src/modules/symbiose/gerealiseerde-verwerkingswijze-symbiose-enum';

const ACTION_TYPES = {
  BEANTWOORD_UITNODIGING: 'symbiose/BEANTWOORD_UITNODIGING',
  NEGEER_UITNODIGING: 'symbiose/NEGEER_UITNODIGING',
  SYMBIOSE_GEREALISEERD: 'symbiose/REALISEER_SYMBIOSE',
  DOCUMENTEER_MEERWAARDE: 'symbiose/DOCUMENTEER_MEERWAARDE',
  SYMBIOSE_NIET_GEREALISEERD: 'symbiose/SLUIT_SYMBIOSE_AF',
  DOCUMENTEER_NIET_REALISATIE: 'symbiose/DOCUMENTEER_NIET_REALISATIE'
};
export { ACTION_TYPES as SYMBIOSE_STATUS_ACTION_TYPES };

export const DOCUMENTEER_MEERWAARDE_FORM = 'DOCUMENTEER_MEERWAARDE_FORM';
export const DOCUMENTEER_NIET_REALISATIE_FORM = 'DOCUMENTEER_NIET_REALISATIE_FORM';

export enum Duurtijd {
  EENMALIG = 'EENMALIG',
  TIJDELIJK = 'TIJDELIJK',
  ONBEPAALDE_DUUR = 'ONBEPAALDE DUUR'
}

export interface IDocumenteerMeerwaardeForm {
  duurtijd: Duurtijd | '';
  aantalJaar: number | '';
  hoeveelheid: number | '';
  eenheid: MateriaalEenheid | '';
  gerealiseerdeVerwerkingswijze: GerealiseerdeVerwerkingswijzeSymbiose | '';
  besparing: number | '';
  inkomsten: number | '';
  investeringenNodig: boolean;
  investeringen: number | '';
  beschrijving: string;
  adviesverleners: string[];
  regelgeving: string;

}

export const initialDocumenteerMeerwaardeFormState = (): IDocumenteerMeerwaardeForm => ({
  duurtijd: '',
  aantalJaar: '',
  hoeveelheid: '',
  eenheid: MateriaalEenheid.TON,
  gerealiseerdeVerwerkingswijze: '',
  besparing: '',
  inkomsten: '',
  investeringenNodig: false,
  investeringen: '',
  beschrijving: '',
  adviesverleners: [],
  regelgeving: ''
});

export const documenteerMeerwaardeForm = formReducer(DOCUMENTEER_MEERWAARDE_FORM, initialFormState(initialDocumenteerMeerwaardeFormState()));

export enum Barriere {
  FINANCIEEL = 'FINANCIEEL',
  LOGISTIEK = 'LOGISTIEK',
  TECHNOLOGISCH = 'TECHNOLOGISCH',
  JURIDISCH = 'JURIDISCH',
  HOEVEELHEID = 'HOEVEELHEID',
  ANDERE = 'ANDERE'
}

export interface IDocumenteerNietRealisatieForm {
  kwantiteit: number | '';
  eenheid: MateriaalEenheid | '';
  barriere: Barriere | '';
  beschrijving: string;
  adviesverleners: string[];
}

export const initialDocumenteerNietRealisatieFormState = (): IDocumenteerNietRealisatieForm => ({
  kwantiteit: '',
  eenheid: MateriaalEenheid.TON,
  barriere: '',
  beschrijving: '',
  adviesverleners: []
});

export const documenteerNietRealisatieForm = formReducer(DOCUMENTEER_NIET_REALISATIE_FORM, initialFormState(initialDocumenteerNietRealisatieFormState()));

export const changeStatusReducer = (state = initialSymbioseState, action: AnyAction): ISymbioseState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.BEANTWOORD_UITNODIGING):
  case REQUEST(ACTION_TYPES.NEGEER_UITNODIGING):
  case REQUEST(ACTION_TYPES.SYMBIOSE_GEREALISEERD):
  case REQUEST(ACTION_TYPES.DOCUMENTEER_MEERWAARDE):
  case REQUEST(ACTION_TYPES.SYMBIOSE_NIET_GEREALISEERD):
  case REQUEST(ACTION_TYPES.DOCUMENTEER_NIET_REALISATIE):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.BEANTWOORD_UITNODIGING):
  case FAILURE(ACTION_TYPES.NEGEER_UITNODIGING):
  case FAILURE(ACTION_TYPES.SYMBIOSE_GEREALISEERD):
  case FAILURE(ACTION_TYPES.DOCUMENTEER_MEERWAARDE):
  case FAILURE(ACTION_TYPES.SYMBIOSE_NIET_GEREALISEERD):
  case FAILURE(ACTION_TYPES.DOCUMENTEER_NIET_REALISATIE):
    return {
      ...state,
      errorMessage: action.payload,
      loading: false
    };
  case SUCCESS(ACTION_TYPES.BEANTWOORD_UITNODIGING):
  case SUCCESS(ACTION_TYPES.NEGEER_UITNODIGING):
  case SUCCESS(ACTION_TYPES.SYMBIOSE_GEREALISEERD):
  case SUCCESS(ACTION_TYPES.DOCUMENTEER_MEERWAARDE):
  case SUCCESS(ACTION_TYPES.SYMBIOSE_NIET_GEREALISEERD):
  case SUCCESS(ACTION_TYPES.DOCUMENTEER_NIET_REALISATIE):
    return {
      ...state,
      loading: false,
      symbiose: {
        ...action.payload.data,
        datumStatus: new Date(action.payload.data.datumStatus)
      }
    };
  default:
    return state;
  }
};

export const
  beantwoordSymbiose: ActionCreator<any> = (symbioseUid: string) => ({
    type: ACTION_TYPES.BEANTWOORD_UITNODIGING,
    payload: axios.put('/ui/symbioses/' + symbioseUid + '/beantwoord-uitnodiging')
  });

export const negeerSymbiose: ActionCreator<any> = (symbioseUid: string) => ({
  type: ACTION_TYPES.BEANTWOORD_UITNODIGING,
  payload: axios.put('/ui/symbioses/' + symbioseUid + '/negeer-uitnodiging')
});

export const symbioseGerealiseerd: ActionCreator<any> = (symbioseUid: string) => ({
  type: ACTION_TYPES.SYMBIOSE_GEREALISEERD,
  payload: axios.put('/ui/symbioses/' + symbioseUid + '/realiseer')
});

export const symbioseDocumenteerMeerwaarde: ActionCreator<any> = (symbioseUid: string, meerwaarde: any) => ({
  type: ACTION_TYPES.SYMBIOSE_GEREALISEERD,
  payload: axios.put('/ui/symbioses/' + symbioseUid + '/documenteer-meerwaarde', meerwaarde)
});

export const symbioseNietGerealiseerd: ActionCreator<any> = (symbioseUid: string, nietRealisatieReden: any) => ({
  type: ACTION_TYPES.SYMBIOSE_NIET_GEREALISEERD,
  payload: axios.put('/ui/symbioses/' + symbioseUid + '/sluit-af', nietRealisatieReden)
});

export const symbioseDocumenteerNietRealisatie: ActionCreator<any> = (symbioseUid: string, nietRealisatieReden: any) => ({
  type: ACTION_TYPES.DOCUMENTEER_NIET_REALISATIE,
  payload: axios.put('/ui/symbioses/' + symbioseUid + '/documenteer-niet-realisatie', nietRealisatieReden)
});
