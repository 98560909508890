import React, {FunctionComponent} from 'react';
import {Percentage} from 'src/modules/detail-components/percentage';
import intl from 'react-intl-universal';
import {Tooltip} from 'webuniversum-react';
import {IBegrensdeWaarde} from 'src/reducers/relevantie';

interface IIconValueProps {
  label: string;
  className: string;
}

export const IconValue: FunctionComponent<IIconValueProps> = (props: React.PropsWithChildren<IIconValueProps>) => (
  <Tooltip placement="top" content={props.label}>
    <i className={`${props.className} icon-value`}/>{props.children}
  </Tooltip>
);

interface IRelevantieScoreProps {
  value: number;
}

export const RelevantieScore = (props: IRelevantieScoreProps) => (
  <IconValue label={intl.get('detail.overzicht-tegel.relevantie')} className="fas fa-trophy"><Percentage value={props.value} /></IconValue>
);

interface IAfstandProps {
  value: IBegrensdeWaarde;
}

export const Afstand = (props: IAfstandProps) => {
  const afstandTekst = props.value.value != null ? toonExacteAfstand(props.value) : toonAfstandRange(props.value);
  return (<IconValue label={intl.get('detail.overzicht-tegel.afstand')} className="fas fa-map-marker-alt">{afstandTekst}</IconValue>);
};

const toonExacteAfstand = (afstand: IBegrensdeWaarde): string => intl.get('detail.overzicht-tegel.afstand-exact', {value: afstand.value});

const toonAfstandRange = (afstand: IBegrensdeWaarde): string => {
  if (!afstand.lowerBound) {
    return intl.get('detail.overzicht-tegel.afstand-range.max', { max: afstand.upperBound });
  } else if (!afstand.upperBound) {
    return intl.get('detail.overzicht-tegel.afstand-range.min', { min: afstand.lowerBound });
  } else {
    return intl.get('detail.overzicht-tegel.afstand-range.between', {min: afstand.lowerBound, max: afstand.upperBound});
  }
};

interface IVerwerkingswijzeProps {
  value: string;
}

export const Verwerkingswijze = (props: IVerwerkingswijzeProps) => (
  <IconValue label={intl.get('detail.overzicht-tegel.verwerkingswijze')} className="fas fa-sort-amount-up">{props.value}</IconValue>
);

interface IOndernemingProps {
  naam: string;
}

export const Onderneming = (props: IOndernemingProps) => (
  <IconValue label={intl.get('detail.overzicht-tegel.onderneming')} className="far fa-building">{props.naam}</IconValue>
);
