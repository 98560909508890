import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  bindActionCreators,
  Dispatch
} from 'redux';
import {
  formEdit,
  formEditPropertyValue,
  formFieldsToObject,
  formState,
  formValidate,
  IFormActions,
  IFormState,
  initialFormState
} from 'redux-form-library';
import { Alert } from 'webuniversum-react';
import { isSymbioseTeam } from 'src/has-permission';
import {
  COPY_MATERIAAL_VRAAG_FORM,
  VRAAG_KOPIEREN_ONDERNEMING_HULPVRAAG
} from 'src/reducers/vraag';
import {
  copyMateriaalVraag,
  downloadVraagBestand,
  getMateriaalVraag,
  IGetMateriaalVraagState,
  uploadMateriaalVraagBestanden
} from 'src/reducers/vraag/materiaal/get';
import {
  createHulpvraagForOnderneming,
  getHulpvraagForOnderneming,
  IConversatie
} from 'src/reducers/bericht/conversatie';
import {
  ICategorieenMap,
  resetCategorieen,
  updateHoofdCategorie,
  updateStandaardCategorie
} from 'src/reducers/categorie/list';
import { MateriaalVraagForm } from 'src/modules/vraag/materiaal/materiaal-vraag-form';
import {
  hideModal,
  showModal
} from 'src/reducers/modal/modal';
import {
  changeHeader,
  HeaderType
} from 'src/reducers/app/header/change';
import {
  IMateriaalVraagForm,
  initialMateriaalVraagFormState
} from 'src/reducers/vraag/materiaal/materiaal-vraag-form';
import {
  materiaalVraagFormDataToRequest,
  materiaalVraagResponseToFormState
} from 'src/modules/vraag/materiaal/materiaal-vraag-factory';
import { VraagCopyOndernemingHulpvraagModal } from 'src/modules/bericht/bericht-modal';
import { extractUid } from 'src/modules/referentie-uri';
import { blobUrl } from 'src/reducers/bestand/download';
import { VraagHulpButton } from 'src/modules/bericht/vraag-hulp-button';
import { withErrorClear } from 'src/modules/error/error-clearer';
import { Bestandstype } from '../../bestand/bestandstype';

export interface ICopyMateriaalVraagProps extends IFormActions<IMateriaalVraagForm>, RouteComponentProps<{ vraagUid: string }> {
  materiaalVraag: IGetMateriaalVraagState;
  showModal: Function;
  hideModal: Function;
  conversatie: IConversatie;
  createHulpvraagForOnderneming: Function;
  getHulpvraagForOnderneming: Function;
  getMateriaalVraag: Function;
  updateHoofdCategorie: Function;
  updateStandaardCategorie: Function;
  categorieQueryResult: ICategorieenMap;
  form: IFormState<IMateriaalVraagForm>;
  dispatch: Dispatch;
  formEditPropertyValue: Function;
  resetCategorieen: Function;
  changeHeader: Function;
  copyMateriaalVraag: Function;
}

export class CopyMateriaalVraag extends React.Component<ICopyMateriaalVraagProps> {

  private changeHeader(backUrl: string, backTranslation) {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      backTranslation,
      '',
      [
        {
          title: 'materiaal.edit.header.title.vraag.create',
          isActive: true
        }
      ],
      () => {
        this.resetFormAndRedirect(backUrl);
      });
  }

  componentDidMount(): void {
    this.changeHeader('/vraag/overzicht', 'materiaal.edit.header.return.vraag.update.mijn');
    this.props.getHulpvraagForOnderneming();
    this.props.getMateriaalVraag(this.props.match.params.vraagUid)
      .then(response => this.props.formState(materiaalVraagResponseToFormState(response)));
  }

  componentWillUnmount(): void {
    this.props.formState(initialFormState(initialMateriaalVraagFormState()));
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  render(): React.ReactNode {
    const formValues: IMateriaalVraagForm = formFieldsToObject(this.props.form.fields);
    const languageMismatch = formValues.taal !== formValues.bronTaal;
    return (
      <>
        <div id="formSection">
          {languageMismatch && <Alert type="error">{intl.get('error.vraag.editeren.taal_mismatch')}</Alert>}
          <Alert type="alert">{intl.get('materiaal.edit.form.copy.vraag.alert')}</Alert>
          <div className="vl-grid">
            <h1
              className="vl-h1 vl-col--6-12 vl-u-spacer--tiny">{intl.get('materiaal.edit.form.title.create.vraag')}</h1>
            {
              !isSymbioseTeam() &&
              <div className="vl-col--6-12">
                <VraagHulpButton handleClick={this.handleOpenModal} />
              </div>
            }
          </div>
          <fieldset disabled={languageMismatch}>
            <MateriaalVraagForm
              categorieQueryResult={this.props.categorieQueryResult}
              materiaalVraag={this.props.materiaalVraag}
              form={this.props.form}
              dispatch={this.props.dispatch}
              handleSave={this.handleSave}
              handleUpload={this.handleUpload}
              handleDownload={this.handleFotoDownload}
              updateHoofdCategorie={this.props.updateHoofdCategorie}
              updateStandaardCategorie={this.props.updateStandaardCategorie}
              resetCategorieen={this.props.resetCategorieen}
              formEditPropertyValue={this.props.formEditPropertyValue}
              handleCancel={this.handleCancel}
              formSelector={formSelector}
              formName={COPY_MATERIAAL_VRAAG_FORM}
              formEdit={this.props.formEdit}
              formState={this.props.formState}
              formValidate={this.props.formValidate}
            />
          </fieldset>
        </div>
        <VraagCopyOndernemingHulpvraagModal />
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }
    this.props.showModal(VRAAG_KOPIEREN_ONDERNEMING_HULPVRAAG, {
      cancel: () => this.props.hideModal(VRAAG_KOPIEREN_ONDERNEMING_HULPVRAAG)
    });
  };

  handleSave = formData => {
    this.props.copyMateriaalVraag(this.props.match.params.vraagUid, materiaalVraagFormDataToRequest(formData))
      .then(response => {
        const vraagUid = extractUid('vraag', response.value.data.vraagReferentie);
        this.resetFormAndRedirect(`/vraag/${vraagUid}/aanbiedingen`);
      });
  };

  handleUpload = (files: File[], bestandstype: Bestandstype) => uploadMateriaalVraagBestanden(files, bestandstype);

  handleFotoDownload = foto => foto.url ?
    Promise.resolve(foto.url) :
    downloadVraagBestand(this.props.match.params.vraagUid, extractUid('bestand', foto.referentie)).then(blobUrl);

  handleCancel = () => {
    this.resetFormAndRedirect('/vraag/overzicht');
  };

  resetFormAndRedirect = (redirectUrl: string) => {
    this.props.formState(initialFormState(initialMateriaalVraagFormState()));
    this.props.resetCategorieen();
    this.props.history.push(redirectUrl);
  };
}

const formSelector = storeState => storeState.vraag.copyMateriaalVraagForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState),
  materiaalVraag: storeState.vraag.details,
  conversatie: storeState.bericht.bericht.conversatie,
  categorieQueryResult: storeState.categorie.categorieenList
});

const mapDispatchToProps = dispatch => {
  const actions = {
    showModal,
    hideModal,
    createHulpvraagForOnderneming,
    getHulpvraagForOnderneming,
    getMateriaalVraag,
    updateHoofdCategorie,
    updateStandaardCategorie,
    resetCategorieen,
    changeHeader,
    copyMateriaalVraag,
    formState: formState(COPY_MATERIAAL_VRAAG_FORM),
    formValidate: formValidate<IMateriaalVraagForm>(COPY_MATERIAAL_VRAAG_FORM),
    formEdit: formEdit(COPY_MATERIAAL_VRAAG_FORM),
    formEditPropertyValue: formEditPropertyValue(COPY_MATERIAAL_VRAAG_FORM)
  };
  return { ...bindActionCreators(actions, dispatch), dispatch };
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(CopyMateriaalVraag));
