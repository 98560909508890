import React, {PropsWithChildren} from 'react';
import {IModalContainerProps} from 'src/reducers/modal/modal';
import {connect} from 'react-redux';
import {Modal, ModalContent} from 'webuniversum-react';
import {UploadFile} from 'antd/es/upload/interface';

export interface IFotoPreviewModalProps {
  modalProps: IModalContainerProps;
  preview: UploadFile;
}

export class FotoPreviewModal extends React.PureComponent<PropsWithChildren<IFotoPreviewModalProps>, any> {
  render(): React.ReactNode {
    const {modalProps, preview, children} = this.props;
    const { visible} = modalProps;
    return preview && (
      <Modal className="foto-preview-modal" hidden={!visible} overlayHidden={!visible} onClose={this.cancel}>
        <ModalContent>
          <img alt="example" className="vl-u-spacer--tiny" src={preview.url} />
          {children}
        </ModalContent>
      </Modal>
    );
  }

  private cancel = () => {
    this.props.modalProps.cancel();
  };
}

const mapStateToProps = storeState => ({
  modalProps: storeState.bestand.fotoPreviewModal
});

export default connect(mapStateToProps)(FotoPreviewModal);
