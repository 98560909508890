import React from 'react';
import {DatePicker, FormComponent} from 'webuniversum-react';
import {FormFields, SubFormPropsFactory} from 'redux-form-library';
import intl from 'react-intl-universal';
import {
  changeDateFormat,
  composeDatepickerPlaceholder,
  DATEPICKER_DATE,
  formatDate,
  ISO_LOCAL_DATE,
  parseDate
} from '../../dates';

export interface IMateriaalBeschikbaarheid {
  van: string;
  tot: string;
}

export interface IMateriaalBeschikbaarheidForm {
  van: string;
  tot: string;
}

export const initialMateriaalBeschikbaarheidFormState = (): IMateriaalBeschikbaarheidForm => ({
  van: null,
  tot: null
});

interface IMateriaalBeschikbaarheidProps {
  title: string;
  subtitle: string;
  fields: FormFields<IMateriaalBeschikbaarheidForm>;
  formPropsFactory: SubFormPropsFactory<IMateriaalBeschikbaarheidForm>;
  formEditPropertyValue: Function;
}

export const mapToFormStateFormat = (beschikbaarheid: IMateriaalBeschikbaarheid) => ({
  van: beschikbaarheid?.van ? changeDateFormat(beschikbaarheid.van, ISO_LOCAL_DATE, DATEPICKER_DATE) : null,
  tot: beschikbaarheid?.tot ? changeDateFormat(beschikbaarheid.tot, ISO_LOCAL_DATE, DATEPICKER_DATE) : null
});
export const mapToRequestFormat = (beschikbaarheid: IMateriaalBeschikbaarheidForm) => ({
  van: beschikbaarheid.van ? changeDateFormat(beschikbaarheid.van) : null,
  tot: beschikbaarheid.tot ? changeDateFormat(beschikbaarheid.tot) : null
});

class MateriaalBeschikbaarheid extends React.Component<IMateriaalBeschikbaarheidProps> {
  createDatePickerOnChange = fieldName => (date => this.props.formEditPropertyValue(fieldName, formatDate(date, DATEPICKER_DATE)));

  createDatePickerValue = fieldName => parseDate(this.props.fields[fieldName]?.value);

  render() {
    const {title, subtitle, formPropsFactory} = this.props;
    const vanInputProps = formPropsFactory.createInputProps('van');
    vanInputProps.onChange = this.createDatePickerOnChange('beschikbaarheid.van');
    vanInputProps.value = this.createDatePickerValue('van');
    const totInputProps = formPropsFactory.createInputProps('tot');
    totInputProps.onChange = this.createDatePickerOnChange('beschikbaarheid.tot');
    totInputProps.value = this.createDatePickerValue('tot');
    return (
      <>
        <h3 className="vl-h3">{intl.get(title)}</h3>
        <h4 className="vl-h4">{intl.get(subtitle)}</h4>
        <div className="vl-grid vl-u-spacer--tiny">
          <div className="vl-col--2-12">
            <FormComponent id="van"
              label={intl.get('materiaal.edit.form.beschikbaarheid.fields.van.label')}
              block
              error={this.props.fields.van.errors}>
              <DatePicker
                {...vanInputProps}
                format={DATEPICKER_DATE}
                placeholder={composeDatepickerPlaceholder()}
                id="van"
                error={this.props.fields.van.errors.length >0}/>
            </FormComponent>
          </div>
          <div className="vl-col--2-12">
            <FormComponent id="tot"
              label={intl.get('materiaal.edit.form.beschikbaarheid.fields.tot.label')}
              block
              error={this.props.fields.tot.errors}>
              <DatePicker
                {...totInputProps}
                format={DATEPICKER_DATE}
                placeholder={composeDatepickerPlaceholder()}
                id="tot"
                error={this.props.fields.tot.errors.length >0}/>
            </FormComponent>
          </div>
        </div>
      </>
    );
  }
}

export default MateriaalBeschikbaarheid;

