import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  bindActionCreators,
  Dispatch
} from 'redux';
import {
  formEdit,
  formEditPropertyValue,
  formFieldsToObject,
  formState,
  formValidate,
  IFormActions,
  IFormState,
  initialFormState
} from 'redux-form-library';
import {
  changeHeader,
  HeaderType
} from 'src/reducers/app/header/change';
import {
  technologieFormDataToRequest,
  technologieResponseToFormState
} from 'src/modules/technologie/technologie-factory';
import { TechnologieForm } from 'src/modules/technologie/technologie-form';
import {
  getTechnologie,
  IGetTechnologieState,
  updateTechnologie,
  uploadTechnologieBestanden
} from 'src/reducers/technologie/get';
import {
  initialTechnologieFormState,
  ITechnologieForm
} from 'src/reducers/technologie/technologie-form';
import { withErrorClear } from 'src/modules/error/error-clearer';
import { Alert } from 'webuniversum-react';
import { UPDATE_TECHNOLOGIE_FORM } from 'src/reducers/technologie';

export interface IUpdateTechnologieProps extends IFormActions<ITechnologieForm>, RouteComponentProps<{ technologieUid: string }> {
  dispatch: Dispatch;
  form: IFormState<ITechnologieForm>;
  formEditPropertyValue: Function;
  taal: string;
  updateTechnologieState: IGetTechnologieState;
  getTechnologie: Function;
  updateTechnologie: Function;
  changeHeader: Function;
}

export class UpdateTechnologie extends React.Component<IUpdateTechnologieProps> {

  componentDidMount(): void {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'technologie.edit.header.breadcrumbs.overview',
      '',
      [
        {
          title: 'technologie.edit.header.breadcrumbs.detail',
          isActive: false,
          redirectUrl: `/technologie/${this.props.match.params.technologieUid}`
        },
        {
          title: 'technologie.edit.header.title.update',
          isActive: true
        }
      ],
      this.backToOverview
    );

    this.props.getTechnologie(this.props.taal, this.props.match.params.technologieUid)
      .then(response => this.props.formState(technologieResponseToFormState(response)));
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  handleBijlagenUpload = (files: File[]) => uploadTechnologieBestanden(files, this.props.match.params.technologieUid);

  handleSave = formData => {
    this.props.updateTechnologie(this.props.match.params.technologieUid, technologieFormDataToRequest(formData))
      .then(this.resetFormAndRedirect);
  };

  handleCancel = () => {
    this.resetFormAndRedirect();
  };

  resetFormAndRedirect = () => {
    this.props.formState(initialFormState(initialTechnologieFormState()));
    this.props.history.push(`/technologie/${this.props.match.params.technologieUid}`);
  };

  backToOverview = () => {
    this.props.history.push('/technologie/overzicht');
  };

  render(): React.ReactNode {
    const formValues: ITechnologieForm = formFieldsToObject(this.props.form.fields);

    const languageMismatch = formValues.taal !== formValues.bronTaal;
    return (
      <div id="formSection">
        <div className="vl-grid">
          <h1 className="vl-h1 vl-col--6-12 vl-u-spacer--tiny">{intl.get('technologie.edit.form.title.update')}</h1>
        </div>
        {languageMismatch && <Alert type="error">{intl.get('technologie.edit.error.taal_mismatch')}</Alert>}
        <fieldset disabled={languageMismatch}>
          <TechnologieForm pageState={this.props.updateTechnologieState}
            form={this.props.form}
            formSelector={formSelector}
            formName={UPDATE_TECHNOLOGIE_FORM}
            dispatch={this.props.dispatch}
            handleSave={this.handleSave}
            handleBijlagenUpload={this.handleBijlagenUpload}
            formEditPropertyValue={this.props.formEditPropertyValue}
            handleCancel={this.handleCancel}
            formEdit={this.props.formEdit}
            formState={this.props.formState}
            formValidate={this.props.formValidate}/>
        </fieldset>
      </div>
    );
  }
}

const formSelector = storeState => storeState.technologie.updateTechnologieForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState),
  updateTechnologieState: storeState.technologie.details,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = dispatch => {
  const actions = {
    updateTechnologie,
    getTechnologie,
    changeHeader,
    formState: formState(UPDATE_TECHNOLOGIE_FORM),
    formValidate: formValidate<ITechnologieForm>(UPDATE_TECHNOLOGIE_FORM),
    formEdit: formEdit(UPDATE_TECHNOLOGIE_FORM),
    formEditPropertyValue: formEditPropertyValue(UPDATE_TECHNOLOGIE_FORM)
  };
  return {...bindActionCreators(actions, dispatch), dispatch};
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(UpdateTechnologie));
