import {
  IMateriaalHoeveelheidForm,
  initialMateriaalHoeveelheidFormState
} from './materiaal-hoeveelheid';

export const extractHoeveelheidIfToegangPubliek = (response): IMateriaalHoeveelheidForm =>
  response.value.data.toegangHoeveelheid === 'PUBLIEK'
    ? {
      frequentie: response.value.data.frequentie,
      kwantiteit: response.value.data.kwantiteit,
      eenheid: response.value.data.eenheid
    }
    : initialMateriaalHoeveelheidFormState();
