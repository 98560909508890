import React from 'react';
import {DetailField, IGridColumnFields} from './detail-field';
import {IMateriaalBeschikbaarheid} from '../beschikbaarheid/materiaal-beschikbaarheid';
import intl from 'react-intl-universal';
import {changeDateFormat, DATEPICKER_DATE, ISO_LOCAL_DATE} from '../../dates';

export interface IDetailBeschikbaarheidProps extends IGridColumnFields {
  title?: string;
  label: string;
  value: IMateriaalBeschikbaarheid;
}

const toBeschikbaarheidArray = (beschikbaarheid: IMateriaalBeschikbaarheid) => [
  beschikbaarheid.van ? intl.get('detail.beschikbaarheid.van', {van: changeDateFormat(beschikbaarheid.van, ISO_LOCAL_DATE, DATEPICKER_DATE)}) : '',
  beschikbaarheid.tot ? intl.get('detail.beschikbaarheid.tot', {tot: changeDateFormat(beschikbaarheid.tot, ISO_LOCAL_DATE, DATEPICKER_DATE)}) : ''
];

class DetailBeschikbaarheid extends React.Component<IDetailBeschikbaarheidProps> {
  render() {
    const {title, label, label_column_class, value_column_class, value} = this.props;
    return (
      <>
        {title && <h3 className="vl-h3">{title}</h3>}
        <DetailField label={label}
          label_column_class={label_column_class}
          value_column_class={value_column_class}>
          <ul>
            {toBeschikbaarheidArray(value).map(line => <li key={line}>{line}</li>)}
          </ul>
        </DetailField>
      </>
    );
  }
}

export default DetailBeschikbaarheid;
