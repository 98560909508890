import React from 'react';
import {IModalContainerProps} from 'src/reducers/modal/modal';
import {Alert, Button, ButtonGroup, Modal, ModalButtons, ModalContent, ModalTitle} from 'webuniversum-react';
import {connect} from 'react-redux';

export interface IArchiveerModalProps {
  modalProps: IModalContainerProps;
  error?: string;
}

export class ArchiveerModal extends React.Component<IArchiveerModalProps> {
  render(): React.ReactNode {
    const { visible, text, submitText, cancelText, title, submit, cancel } = this.props.modalProps;
    return (
      <Modal hidden={!visible} overlayHidden={!visible} style={{ width: '65rem' }} onClose={cancel}>
        <ModalTitle>{title}</ModalTitle>
        {this.props.error && <Alert type="error">{this.props.error}</Alert>}
        <ModalContent>
          <p>{text}</p>
        </ModalContent>
        <ModalButtons>
          <ButtonGroup>
            <Button type="button" onClick={submit} disabled={!!this.props.error}>
              {submitText}
            </Button>
            <Button type="button" color="secondary" onClick={cancel}>
              {cancelText}
            </Button>
          </ButtonGroup>
        </ModalButtons>
      </Modal>
    );
  }
}

const mapArchiveerAanbodStateToProps = storeState => ({
  modalProps: storeState.aanbod.archiveerAanbodModal
});

const mapArchiveerVraagStateToProps = storeState => ({
  modalProps: storeState.vraag.archiveerVraagModal
});

const mapDeleteTechnologieStateToProps = storeState => ({
  modalProps: storeState.technologie.deleteTechnologieModal
});

export const ArchiveerAanbodModal = connect(mapArchiveerAanbodStateToProps)(ArchiveerModal);

export const ArchiveerVraagModal = connect(mapArchiveerVraagStateToProps)(ArchiveerModal);

export const DeleteTechnologieModal = connect(mapDeleteTechnologieStateToProps)(ArchiveerModal);
