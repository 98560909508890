import React from 'react';
import intl from 'react-intl-universal';
import {IRelevantAanbod} from 'src/reducers/vraag/materiaal/relevante-aanbiedingen';
import {Accordion} from 'webuniversum-react';
import {OverzichtTegel} from 'src/modules/materiaal/overzicht-tegel';
import {translateKey} from 'src/modules/form-components/translatable-select-options';
import {downloadAanbodBestand} from 'src/reducers/aanbod/materiaal/get';
import {extractUid} from 'src/modules/referentie-uri';
import {blobUrl} from 'src/reducers/bestand/download';

export interface IRelevanteAanbiedingenAccordionProps {
  title: string;
  defaultOpen?: boolean;
  aanbiedingen: IRelevantAanbod[];
  navigateToAanbieding: Function;
}

export class RelevanteAanbiedingenAccordion extends React.Component<IRelevanteAanbiedingenAccordionProps, undefined> {
  render(): React.ReactNode {
    const { title, defaultOpen, aanbiedingen, navigateToAanbieding } = this.props;
    return aanbiedingen.length > 0 && (
      <Accordion layout="toggle" className="relevantie-accordion" title={title} defaultOpen={defaultOpen}>
        {aanbiedingen.map(aanbod =>
          <OverzichtTegel
            {...aanbod}
            key={aanbod.aanbodReferentie}
            coverFotoUrlFetcher={() => this.resolveFotoSourceUrl(aanbod)}
            verwerkingswijze={intl.get(
              translateKey(aanbod.huidigeVerwerkingswijze,
                'vraag.relevante-aanbiedingen.overview.table.rows.huidige-verwerkingswijze.values'))
            }
            onClick={() => navigateToAanbieding(aanbod.aanbodReferentie)}
            beschikbaarheid={aanbod.beschikbaarheid}
          />
        )}
      </Accordion>
    );
  }

  private resolveFotoSourceUrl = (aanbod: IRelevantAanbod): Promise<string> =>
    aanbod.coverFotoReferentie ?
      downloadAanbodBestand(extractUid('aanbod', aanbod.aanbodReferentie), extractUid('bestand', aanbod.coverFotoReferentie)).then(blobUrl) :
      Promise.reject(`no cover photo for relevant aanbod ${aanbod.aanbodReferentie}`);
}
