import React from 'react';
import { connect } from 'react-redux';
import { getAlgemeneVoorwaarden } from 'src/reducers/algemene_voorwaarden/getPdf';

export interface IAlgemeneVoorwaardenProps {
  location: any;
  history: any;
  algemeneVoorwaardenPdf: any;
  getAlgemeneVoorwaarden: Function;
}

export class AlgemeneVoorwaardenView extends React.Component<IAlgemeneVoorwaardenProps, undefined> {
  componentDidMount(): void {
    this.props.getAlgemeneVoorwaarden(this.props.location.pathname.slice(-2))
      .then(() => window.open(this.props.algemeneVoorwaardenPdf, '_blank'))
      .finally(() => this.props.history.goBack());
  }

  render(): React.ReactNode {
    return <div/>;
  }
}

const mapStateToProps = storeState => ({
  algemeneVoorwaardenPdf: storeState.algemeneVoorwaarden.get.pdf
});

const mapDispatchToProps = {
  getAlgemeneVoorwaarden
};

export default connect(mapStateToProps, mapDispatchToProps)(AlgemeneVoorwaardenView);
