import React from 'react';
import { Badge, InfoTile, InfoTileFooter, InfoTileHeader } from 'webuniversum-react';

export interface IZoekInfoTileProps {
  id: string;
  title: string;
  description: string;
  img: string;
  onClick: () => void;
}

export class ZoekInfoTile extends React.Component<IZoekInfoTileProps> {
  render(): React.ReactNode {
    const { id, title, description, img, onClick } = this.props;
    return (
      <InfoTile size="m" center onClick={onClick}>
        <InfoTileHeader key={`${id}.header`} title={title}>
          <Badge size="l">
            <img className="vl-badge__img" src={img} alt={title}/>
          </Badge>
        </InfoTileHeader>
        <InfoTileFooter key={`${id}.footer`}>
          <div className="vl-u-spacer--small">{description}</div>
        </InfoTileFooter>
      </InfoTile>
    );
  }
}
