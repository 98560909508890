import {combineReducers} from 'redux';
import {aanbiedingenListReducer, gearchiveerdeAanbiedingenListReducer} from 'src/reducers/aanbod/materiaal/list';
import {relevanteVragenListReducer} from 'src/reducers/aanbod/materiaal/relevante-vragen';
import {getMateriaalAanbodReducer} from 'src/reducers/aanbod/materiaal/get';
import {initialModalState, modalReducer} from 'src/reducers/modal/modal';
import {searchMateriaalAanbodForm, searchMateriaalAanbodReducer} from 'src/reducers/aanbod/materiaal/search';
import {formReducer, initialFormState} from 'redux-form-library';
import {initialMateriaalAanbodFormState} from 'src/reducers/aanbod/materiaal/materiaal-aanbod-form';

export const CREATE_MATERIAAL_AANBOD_FORM = 'CREATE_MATERIAAL_AANBOD_FORM';
export const UPDATE_MATERIAAL_AANBOD_FORM = 'UPDATE_MATERIAAL_AANBOD_FORM';
export const COPY_MATERIAAL_AANBOD_FORM = 'COPY_MATERIAAL_AANBOD_FORM';
export const CREATE_MATERIAAL_AANBOD_FROM_VRAAG_FORM = 'CREATE_MATERIAAL_AANBOD_FROM_VRAAG_FORM';

export const AANBOD_OVERVIEW = 'aanbod-overzicht/conversatie/hulpvraag';
export const GEARCHIVEERD_AANBOD_OVERVIEW = 'gearchiveerd-aanbod-overzicht/conversatie/hulpvraag';
export const AANBOD_DETAIL_HULPVRAAG = 'aanbod-bewerken/conversatie/hulpvraag';
export const GEARCHIVEERD_AANBOD_DETAIL_HULPVRAAG = 'gearchiveerd-aanbod/conversatie/hulpvraag';
export const AANBOD_BEWERKEN_ONDERNEMING_HULPVRAAG = 'aanbod-bewerken/onderneming/conversatie/hulpvraag';
export const AANBOD_KOPIEREN_ONDERNEMING_HULPVRAAG = 'aanbod-kopieren/onderneming/conversatie/hulpvraag';
export const AANBOD_MAKEN_UIT_VRAAG_ONDERNEMING_HULPVRAAG = 'aanbod-maken-uit-vraag/onderneming/conversatie/hulpvraag';
export const AANBOD_BEWERKEN_AANBOD_HULPVRAAG = 'aanbod-bewerken/aanbod/conversatie/hulpvraag';
export const RELEVANTE_VRAGEN_OVERVIEW = 'relevante-vragen-overzicht/conversatie/hulpvraag';
export const RELEVANTE_VRAAG_HULPVRAAG = 'relevante-vraag/conversatie/hulpvraag';
export const RELEVANTE_VRAAG_SEND_BERICHT = 'relevant-aanbod/conversatie/send';
export const RELEVANT_VRAAG_SYMBIOSE_INVITE = 'symbiose/create';
export const ARCHIVEER_AANBOD = 'aanbod/archiveer';

export const aanbod = combineReducers({
  details: getMateriaalAanbodReducer,
  createMateriaalAanbodForm: formReducer(CREATE_MATERIAAL_AANBOD_FORM, initialFormState(initialMateriaalAanbodFormState())),
  updateMateriaalAanbodForm: formReducer(UPDATE_MATERIAAL_AANBOD_FORM, initialFormState(initialMateriaalAanbodFormState())),
  copyMateriaalAanbodForm: formReducer(COPY_MATERIAAL_AANBOD_FORM, initialFormState(initialMateriaalAanbodFormState())),
  createMateriaalAanbodFromVraagForm: formReducer(CREATE_MATERIAAL_AANBOD_FROM_VRAAG_FORM, initialFormState(initialMateriaalAanbodFormState())),
  searchMateriaalAanbodForm,
  materiaal: combineReducers({
    search: searchMateriaalAanbodReducer
  }),
  aanbiedingenList: aanbiedingenListReducer,
  gearchiveerdeAanbiedingenList: gearchiveerdeAanbiedingenListReducer,
  relevanteVragenList: relevanteVragenListReducer,
  aanbodOverviewModal: modalReducer(AANBOD_OVERVIEW, initialModalState()),
  gearchiveerdAanbodOverviewModal: modalReducer(GEARCHIVEERD_AANBOD_OVERVIEW, initialModalState()),
  aanbodDetailHulpvraagModal: modalReducer(AANBOD_DETAIL_HULPVRAAG, initialModalState()),
  gearchiveerdAanbodDetailHulpvraagModal: modalReducer(GEARCHIVEERD_AANBOD_DETAIL_HULPVRAAG, initialModalState()),
  aanbodBewerkenOndernemingHulpvraagModal: modalReducer(AANBOD_BEWERKEN_ONDERNEMING_HULPVRAAG, initialModalState()),
  aanbodCopyOndernemingHulpvraagModal: modalReducer(AANBOD_KOPIEREN_ONDERNEMING_HULPVRAAG, initialModalState()),
  aanbodMakenUitVraagOndernemingHulpvraagModal: modalReducer(AANBOD_MAKEN_UIT_VRAAG_ONDERNEMING_HULPVRAAG, initialModalState()),
  aanbodBewerkenAanbodHulpvraagModal: modalReducer(AANBOD_BEWERKEN_AANBOD_HULPVRAAG, initialModalState()),
  relevanteVragenOverviewModal: modalReducer(RELEVANTE_VRAGEN_OVERVIEW, initialModalState()),
  relevanteVraagHulpvraagModal: modalReducer(RELEVANTE_VRAAG_HULPVRAAG, initialModalState()),
  relevanteVraagModal: modalReducer(RELEVANTE_VRAAG_SEND_BERICHT, initialModalState()),
  symbioseCreateModal: modalReducer(RELEVANT_VRAAG_SYMBIOSE_INVITE, initialModalState()),
  archiveerAanbodModal: modalReducer(ARCHIVEER_AANBOD, initialModalState())
});
