import React from 'react';
import { clearError } from 'src/reducers/error';
import { connect } from 'react-redux';

export interface IErrorClearerProps {
  clearError: Function;
}

const mapDispatchToProps = {
  clearError
};

export const withErrorClear = (Component: React.ComponentType<any>) =>
  connect(undefined, mapDispatchToProps)(
    class WithErrorClearing extends React.Component<IErrorClearerProps> {
      componentWillUnmount(): void {
        this.props.clearError();
      }

      render(): React.ReactNode {
        const { ...props } = this.props;
        return <Component {...props} />;
      }
    }
  );
