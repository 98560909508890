import {Button, ButtonGroup, Loading} from 'webuniversum-react';
import {MateriaalStatusEnum} from '../../materiaal/materiaal-status-enum';
import {isSymbioseTeam} from 'src/has-permission';
import intl from 'react-intl-universal';
import {GearchiveerdVraagDetailHulpvraagModal} from 'src/modules/bericht/bericht-modal';
import React from 'react';
import {extractUid} from 'src/modules/referentie-uri';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {connect} from 'react-redux';
import {downloadVraagBestand, getMateriaalVraag, IGetMateriaalVraagState} from 'src/reducers/vraag/materiaal/get';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {createHulpvraagForVraag, getHulpvraagForVraag, IConversatie} from 'src/reducers/bericht/conversatie';
import {IContactPersoon} from 'src/reducers/authentication';
import {DetailMateriaalVraag} from 'src/modules/vraag/materiaal/detail-materiaal-vraag';
import {GEARCHIVEERDE_VRAAG_DETAIL_HULPVRAAG} from 'src/reducers/vraag';
import {RouteComponentProps} from 'react-router';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IMateriaalGearchiveerdVraagDetailPaginaProps extends RouteComponentProps<{ vraagUid: string }> {
  getMateriaalVraag: Function;
  getHulpvraagForVraag: Function;
  createHulpvraagForVraag: Function;
  queryResult: IGetMateriaalVraagState;
  conversatie: IConversatie;
  isAuthenticated: boolean;
  currentUser: IContactPersoon;
  showModal: Function;
  hideModal: Function;
  changeHeader: Function;
}

export class MateriaalGearchiveerdVraagDetailPagina extends React.Component<IMateriaalGearchiveerdVraagDetailPaginaProps> {
  componentDidMount(): void {
    this.props.getHulpvraagForVraag(this.props.match.params.vraagUid);
    this.props.getMateriaalVraag(this.props.match.params.vraagUid);
    this.changeHeader();
  }

  componentDidUpdate(prevProps: Readonly<IMateriaalGearchiveerdVraagDetailPaginaProps>, prevState: Readonly<{}>, snapshot?: any): void {
    this.changeHeader();
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
    this.props.hideModal(GEARCHIVEERDE_VRAAG_DETAIL_HULPVRAAG);
  }

  render(): React.ReactNode {
    const { queryResult } = this.props;
    const { loading, vraag } = queryResult;
    return (
      <>
        {this.props.isAuthenticated &&
        <>
          <Loading loading={loading && vraag !== null}>
            <div className="vl-grid">
              <div className="vl-col--10-12">
                <DetailMateriaalVraag status={MateriaalStatusEnum.GEARCHIVEERD} vraag={vraag} downloadBestand={this.downloadBestand}/>
              </div>
              <div className="vl-col--2-12 detail-buttons">
                {(vraag?.onderneming?.referentie === this.props.currentUser.onderneming.referentie || isSymbioseTeam()) &&
                <ButtonGroup>
                  <VraagHulpButton handleClick={this.handleOpenModal} />
                  <Button type="button" color="secondary" onClick={this.handleCopyVraag}>
                    {intl.get('vraag.detail.vraag-copy')}
                  </Button>
                </ButtonGroup>}
              </div>
            </div>
          </Loading>
          <GearchiveerdVraagDetailHulpvraagModal/>
        </>
        }
      </>
    );
  }

  private getVraagUid = () => extractUid('vraag', this.props.queryResult.vraag.vraagReferentie);

  private downloadBestand = (uid: string) => downloadVraagBestand(this.getVraagUid(), extractUid('bestand', uid));

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForVraag(this.getVraagUid());
    } else {
      this.props.getHulpvraagForVraag(this.getVraagUid());
    }

    this.props.showModal(GEARCHIVEERDE_VRAAG_DETAIL_HULPVRAAG, {
      cancel: () => this.props.hideModal(GEARCHIVEERDE_VRAAG_DETAIL_HULPVRAAG)
    });
  };

  private handleCopyVraag = () => {
    this.props.history.push(`/vraag/${this.getVraagUid()}/copy`);
  };

  private changeHeader() {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'vraag.detail-gearchiveerd.header.overzicht-vraag',
      '',
      [
        {
          title: 'vraag.detail-gearchiveerd.header.overzicht-gearchiveerd-vraag',
          isActive: false,
          redirectUrl: '/vraag/gearchiveerd/overzicht'
        },
        {
          title: 'vraag.detail-gearchiveerd.header.active',
          isActive: true
        }
      ],
      () => {
        this.props.history.push('/vraag/overzicht');
      });
  }
}

const mapStateToProps = storeState => ({
  queryResult: storeState.vraag.details,
  isAuthenticated: storeState.authentication.security.isAuthenticated,
  conversatie: storeState.bericht.bericht.conversatie,
  currentUser: storeState.authentication.contactPersoon
});

const mapDispatchToProps = {
  getMateriaalVraag,
  showModal,
  hideModal,
  changeHeader,
  getHulpvraagForVraag,
  createHulpvraagForVraag
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(MateriaalGearchiveerdVraagDetailPagina));
