import React from 'react';
import {IRelevantie} from 'src/reducers/relevantie';
import {Afstand, RelevantieScore} from 'src/modules/detail-components/icon-value';

export interface IDetailRelevantie {
  relevantie: IRelevantie;
  titel: string;
  afstandRanges: number[];
}

export class DetailRelevantie extends React.Component<IDetailRelevantie> {
  render(): React.ReactNode {
    return (
      <>
        <div className="vl-u-hr vl-u-spacer--small"/>
        <div className="vl-grid vl-u-spacer--small">
          <p className="vl-col--12-12">{this.props.titel}</p>
          <div className="vl-icon-wrapper vl-col--2-12">
            <RelevantieScore value={Number(this.props.relevantie.relevantieScore)} />
          </div>
          <div className="vl-icon-wrapper vl-col--8-12">
            <Afstand value={this.props.relevantie.afstand} />
          </div>
        </div>
        <div className="vl-u-hr vl-u-spacer--small"/>
      </>
    );
  }
}
