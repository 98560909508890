import React from 'react';
import intl from 'react-intl-universal';
import {IMeerwaarde} from 'src/reducers/symbiose/create';
import {Loading} from 'webuniversum-react';
import {DetailField} from '../detail-components/detail-field';
import {translateKey} from '../form-components/translatable-select-options';
import {formatNumber, formatPriceEuro} from 'src/translations/i18n-utils';

export interface IDetailSymbioseMeerwaardeProps {
  meerwaarde: IMeerwaarde;
  loading: boolean;
}

const label_column_class = 'vl-col--3-12';
const value_column_class = 'vl-col--9-12';

export class DetailSymbioseMeerwaarde extends React.Component<IDetailSymbioseMeerwaardeProps> {
  render(): React.ReactNode {
    const {meerwaarde, loading} = this.props;
    const url = meerwaarde.regelgeving.toLowerCase() === 'zelftoetsing' ?
      'https://ovam.vlaanderen.be/materialendecreet-vlarema' :
      'https://ovam.vlaanderen.be/grondstofverklaringen-wanneer-en-hoe-';
    return (
      <>
        <article id="meerwaarde" className="vl-u-spacer--small">
          <Loading loading={loading}>
            <h2 className="vl-h2 vl-u-spacer--small">
              {intl.get('symbiose.detail.gerealiseerde-meerwaarde')}
            </h2>

            <section id="materiaal-info">
              <DetailField label={intl.get('symbiose.detail.meerwaarde.duurtijd.label')}
                value={intl.get(translateKey(meerwaarde.duurtijd,
                  'symbiose.detail.meerwaarde.duurtijd.values'))}
                label_column_class={label_column_class}
                value_column_class={value_column_class}/>

              <div className="vl-u-spacer--small"/>

              {
                meerwaarde.duurtijd === 'TIJDELIJK' &&
                <>
                  <DetailField label={intl.get('symbiose.detail.meerwaarde.aantal-jaar')}
                    value={formatNumber(meerwaarde.aantalJaar)}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}/>

                  <div className="vl-u-spacer--small"/>
                </>

              }

              <DetailField label={intl.get('symbiose.detail.meerwaarde.hoeveelheid')}
                value={`${formatNumber(meerwaarde.hoeveelheid)} ${intl.get(translateKey(meerwaarde.eenheid,
                  'symbiose.detail.meerwaarde.eenheid.values'))}`}
                label_column_class={label_column_class}
                value_column_class={value_column_class}/>

              <div className="vl-u-spacer--small"/>

              <DetailField label={intl.get('symbiose.detail.meerwaarde.gerealiseerde-verwerkingswijze.label')}
                value={intl.get(translateKey(meerwaarde.gerealiseerdeVerwerkingswijze,
                  'symbiose.detail.meerwaarde.gerealiseerde-verwerkingswijze.values'))}
                label_column_class={label_column_class}
                value_column_class={value_column_class}/>

              <div className="vl-u-spacer--small"/>

              {
                meerwaarde.besparing !== null &&
                <>
                  <DetailField label={intl.get('symbiose.detail.meerwaarde.besparing')}
                    value={formatPriceEuro(meerwaarde.besparing.toString())}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}/>

                  <div className="vl-u-spacer--small"/>
                </>
              }

              {
                meerwaarde.inkomsten !== null &&
                <>
                  <DetailField label={intl.get('symbiose.detail.meerwaarde.inkomsten')}
                    value={formatPriceEuro(meerwaarde.inkomsten.toString())}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}/>

                  <div className="vl-u-spacer--small"/>
                </>
              }

              {
                meerwaarde.investeringen !== null &&
                <>
                  <DetailField label={intl.get('symbiose.detail.meerwaarde.investeringen')}
                    value={formatPriceEuro(meerwaarde.investeringen.toString())}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}/>

                  <div className="vl-u-spacer--small"/>
                </>
              }

              <DetailField label={intl.get('symbiose.detail.meerwaarde.beschrijving')}
                value={meerwaarde.beschrijving}
                label_column_class={label_column_class}
                value_column_class={value_column_class}/>

              <div className="vl-u-spacer--small"/>

              {
                (meerwaarde.adviesverleners.length > 0) &&
                <>
                  <DetailField label={intl.get('symbiose.detail.meerwaarde.adviesverleners')}
                    value={this.adviesverlenersToString(meerwaarde.adviesverleners)}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}/>

                  <div className="vl-u-spacer--small"/>
                </>
              }

              <DetailField label={intl.get('symbiose.detail.meerwaarde.regelgeving')}
                value={intl.getHTML('symbiose.documenteer-meerwaarde-modal.regelgeving.enum-values.' + meerwaarde.regelgeving.toLowerCase(), { url })}
                label_column_class={label_column_class}
                value_column_class={value_column_class}/>
            </section>
          </Loading>
        </article>
      </>
    );
  }

  public adviesverlenersToString(adviesverleners) {
    let adviesverlenersString = '';
    adviesverleners.forEach(adviesverlener => {
      const translationKey = intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.' + adviesverlener.toLowerCase());
      adviesverlenersString = adviesverlenersString.concat(translationKey + ', ');
    });
    adviesverlenersString = adviesverlenersString.slice(0, -2);
    return adviesverlenersString;
  }
}
