import React from 'react';
import { FormFields, SubFormPropsFactory } from 'redux-form-library';
import { FormComponent, InputField, Radiobutton } from 'webuniversum-react';
import intl from 'react-intl-universal';
import { getEuralCodeByCode } from '../eural/eural-filter';
import { GevaarlijkeStoffenEnum } from './gevaarlijke-stoffen-enum';
import { UitlegRelevantieBerekening } from './uitleg-relevantie-berekening';

export interface IGevaarlijkeStoffenForm {
  gevaarlijkeStoffen: GevaarlijkeStoffenEnum | '';
  beschrijvingGevaarlijkeStoffen: string;
}

export const initialGevaarlijkeStoffenFormState = (): IGevaarlijkeStoffenForm => ({
  gevaarlijkeStoffen: GevaarlijkeStoffenEnum.ZONDER_GEVAARLIJKE_STOFFEN,
  beschrijvingGevaarlijkeStoffen: ''
});

interface IGevaarlijkeStoffenProps {
  fields: FormFields<IGevaarlijkeStoffenForm>;
  formPropsFactory: SubFormPropsFactory<IGevaarlijkeStoffenForm>;
  euralCode: string;
  formEditPropertyValue: Function;
  isVraag: boolean;
}

export class GevaarlijkeStoffen extends React.Component<IGevaarlijkeStoffenProps> {
  generateTooltipMessages = () => ({
    'required.gevaarlijkeStoffen.beschrijvingGevaarlijkeStoffen': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.beschrijving.name')
    })
  });

  render(): React.ReactNode {
    const { euralCode, formPropsFactory, formEditPropertyValue } = this.props;
    const eural = getEuralCodeByCode(euralCode);
    const tooltipMessages = this.generateTooltipMessages();

    const euralIsGevaarlijk = eural && eural.hazardous;

    if (euralIsGevaarlijk) {
      this.props.fields.gevaarlijkeStoffen.value !== GevaarlijkeStoffenEnum.MET_GEVAARLIJKE_STOFFEN &&
      formEditPropertyValue('gevaarlijkeStoffen.gevaarlijkeStoffen', GevaarlijkeStoffenEnum.MET_GEVAARLIJKE_STOFFEN);
    }

    if (this.props.fields.gevaarlijkeStoffen.value === GevaarlijkeStoffenEnum.ZONDER_GEVAARLIJKE_STOFFEN && this.props.fields.beschrijvingGevaarlijkeStoffen.value !== '') {
      formEditPropertyValue('gevaarlijkeStoffen.beschrijvingGevaarlijkeStoffen', '');
    }

    return (
      <>
        <FormComponent id="gevaarlijkeStoffen"
          label={this.props.isVraag ? intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.keuze.label.vraag') :
            intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.keuze.label.aanbod')}
          required={tooltipMessages[ 'required.gevaarlijkeStoffen.gevaarlijkeStoffen' ]}
          error={this.props.fields.gevaarlijkeStoffen.errors}
          block>
          <UitlegRelevantieBerekening tekstKey="materiaal.edit.form.gevaarlijke-stoffen.uitleg-relevantie-berekening"/>
          <Radiobutton name="gevaarlijkeStoffen"
            block {...formPropsFactory.createRadiobuttonProps('gevaarlijkeStoffen',
              GevaarlijkeStoffenEnum.MET_GEVAARLIJKE_STOFFEN)} disabled={eural && eural.hazardous}>
            {intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.keuze.values.ja')}
          </Radiobutton>
          <Radiobutton name="gevaarlijkeStoffen"
            block {...formPropsFactory.createRadiobuttonProps('gevaarlijkeStoffen',
              GevaarlijkeStoffenEnum.ZONDER_GEVAARLIJKE_STOFFEN)} disabled={eural && eural.hazardous}>
            {intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.keuze.values.nee')}
          </Radiobutton>
          {(euralIsGevaarlijk && this.props.fields.beschrijvingGevaarlijkeStoffen.value === '') &&
          <p className="gevaarlijke-stoffen-melding">{intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.beschrijving.eural')}</p>
          }
        </FormComponent>
        {(this.props.fields.gevaarlijkeStoffen.value === GevaarlijkeStoffenEnum.MET_GEVAARLIJKE_STOFFEN || euralIsGevaarlijk) &&
        <FormComponent id="beschrijvingGevaarlijkeStoffen"
          label={this.props.isVraag ? intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.beschrijving.label.vraag') :
            intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.beschrijving.label.aanbod')}
          required={tooltipMessages[ 'required.gevaarlijkeStoffen.beschrijvingGevaarlijkeStoffen' ]}
          error={this.props.fields.beschrijvingGevaarlijkeStoffen.errors}
          block>
          <InputField {...formPropsFactory.createInputProps('beschrijvingGevaarlijkeStoffen')}
            layout="block"/>
        </FormComponent>
        }
      </>
    );
  }
}
