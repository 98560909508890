export const CONTENT_APPLICATION_LD_JSON = 'application/ld+json';

export interface IGraph {
  '@graph': IResource[];
  '@context'?: string | object;
}

export interface IResource {
  '@id'?: string;
  '@type'?: string;
  '@context'?: string | object;
}

export interface ILanguageLabel {
  '@value': string;
  '@language'?: string;
}

export interface ILanguageLabelMap {
  [language: string]: string;
}

export type Resource = IResource | string;
export type Label = ILanguageLabel | string;

export interface ISkosConcept extends IResource {
  notation?: string;
  inScheme?: Resource;
  topConceptOf?: Resource;
  prefLabel?: Label[] | Label | ILanguageLabelMap;
  altLabel?: Label[] | Label | ILanguageLabelMap;
  broader?: Resource;
  narrower?: Resource[];
}
