import React from 'react';
import intl from 'react-intl-universal';
import { getPropertyFromObject, ICellGenerator } from 'webuniversum-react';

export class DateCellGenerator implements ICellGenerator<any> {
  private readonly accessor: string;

  constructor(accessor: string) {
    this.accessor = accessor;
  }

  generateCell(row: any): string | React.ReactElement<HTMLElement> | null {
    return <>{intl.get('validation.date.format', { date: new Date(getPropertyFromObject(this.accessor, row)) })}</>;
  }
}
