import React from 'react';
import intl from 'react-intl-universal';

export interface IUitlegRelevantieBerekeningProps {
  tekstKey: string;
}

export class UitlegRelevantieBerekening extends React.Component<IUitlegRelevantieBerekeningProps, undefined> {
  render(): React.ReactNode {
    return (
      <p className="uitleg-relevantie-berekening">
        <span className="fas fa-trophy relevantie-icon" aria-hidden="true"/>
        {intl.get(this.props.tekstKey)}
      </p>
    );
  }
}
