import axios, { AxiosPromise } from 'axios';
import {
  ActionCreator,
  AnyAction
} from 'redux';
import { HuidigeVerwerkingswijzeAanbod } from 'src/modules/aanbod/materiaal/huidige-verwerkingswijze-aanbod-enum';
import { Land } from 'src/modules/adres/adres-form';
import { EnumWrapper } from 'src/modules/form-components/enum-wrapper';
import { IGevaarlijkeStoffenForm } from 'src/modules/materiaal/gevaarlijke-stoffen';
import { GevaarlijkeStoffenEnum } from 'src/modules/materiaal/gevaarlijke-stoffen-enum';
import { ICategorie } from 'src/modules/materiaal/materiaal-categorie';
import {
  IMateriaalHoeveelheid,
  MateriaalEenheid,
  MateriaalFrequentie
} from 'src/modules/materiaal/materiaal-hoeveelheid';
import { MateriaalToestand } from 'src/modules/materiaal/materiaal-toestand-enum';
import { Vertrouwelijkheid } from 'src/modules/materiaal/privaat-publiek-switch';
import {
  FAILURE,
  REQUEST,
  SUCCESS
} from 'src/reducers/action-type.util';
import { IAdres } from 'src/reducers/adres';
import {
  initialOnderneming,
  IOnderneming
} from 'src/reducers/authentication';
import {
  filesToBestandenFormData,
  IBestand
} from 'src/reducers/bestand';
import { IVertaling } from 'src/reducers/taal/vertalingen';
import { Bestandstype } from '../../../modules/bestand/bestandstype';
import {IMateriaalBeschikbaarheidForm} from '../../../modules/beschikbaarheid/materiaal-beschikbaarheid';

export const ACTION_TYPES = {
  FETCH_MATERIAAL_AANBOD: 'aanbod/FETCH_MATERIAAL_AANBOD',
  CREATE_MATERIAAL_AANBOD: 'aanbod/CREATE_MATERIAAL_AANBOD',
  UPDATE_MATERIAAL_AANBOD: 'aanbod/UPDATE_MATERIAAL_AANBOD',
  ARCHIVEER_MATERIAAL_AANBOD: 'aanbod/ARCHIVEER_MATERIAAL_AANBOD',
  COPY_MATERIAAL_AANBOD: 'aanbod/COPY_MATERIAAL_AANBOD'
};

export interface IMateriaalAanbod extends IVertaling {
  aanbodReferentie: string;
  titel: string;
  beschrijving: string;
  categorieen: ICategorie[];
  euralCode: string;
  materiaalToestand: string;
  kleur: string;
  huidigeVerwerkingswijze: string;
  materiaalSamenstelling: string;
  productieProces: string;
  mogelijkeToepassingen: string;
  fotos: IBestand[];
  toegangHoeveelheid: string;
  hoeveelheid: IMateriaalHoeveelheid;
  toegangLocatie: string;
  adres: IAdres;
  gevaarlijkeStoffen: IGevaarlijkeStoffenForm;
  bijkomendeInfo: string;
  privateBijlagen: IBestand[];
  publiekeBijlagen: IBestand[];
  contactPersoon: string;
  onderneming: IOnderneming;
  beschikbaarheid: IMateriaalBeschikbaarheidForm;
}

export interface IGetMateriaalAanbodState {
  loading: boolean;
  errorMessage: string;
  aanbod: IMateriaalAanbod;
}

export const initialState: IGetMateriaalAanbodState = {
  loading: false,
  errorMessage: null,
  aanbod: {
    aanbodReferentie: '',
    contactPersoon: '',
    onderneming: initialOnderneming(),
    taal: '',
    bronTaal: '',
    vertalingStatus: '',
    titel: '',
    beschrijving: '',
    toegangHoeveelheid: '',
    hoeveelheid: {
      frequentie: '',
      kwantiteit: null,
      eenheid: MateriaalEenheid.TON
    },
    huidigeVerwerkingswijze: '',
    toegangLocatie: '',
    adres: {
      locatienaam: '',
      straatnaam: '',
      huisnummer: '',
      adresLijn2: '',
      postcode: '',
      gemeente: '',
      land: ''
    },
    categorieen: [
      {
        code: '',
        hoofdCategorie: '',
        standaardCategorie: '',
        subCategorie: ''
      }
    ],
    euralCode: '',
    materiaalToestand: '',
    kleur: '',
    gevaarlijkeStoffen: {
      gevaarlijkeStoffen: GevaarlijkeStoffenEnum.ZONDER_GEVAARLIJKE_STOFFEN,
      beschrijvingGevaarlijkeStoffen: ''
    },
    bijkomendeInfo: '',
    privateBijlagen: [],
    publiekeBijlagen: [],
    fotos: [],
    materiaalSamenstelling: '',
    productieProces: '',
    mogelijkeToepassingen: '',
    beschikbaarheid:
      {
        van: null,
        tot: null
      }
  }
};

export const getMateriaalAanbodReducer = (state = initialState, action: AnyAction): IGetMateriaalAanbodState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_MATERIAAL_AANBOD):
  case REQUEST(ACTION_TYPES.CREATE_MATERIAAL_AANBOD):
  case REQUEST(ACTION_TYPES.UPDATE_MATERIAAL_AANBOD):
  case REQUEST(ACTION_TYPES.ARCHIVEER_MATERIAAL_AANBOD):
  case REQUEST(ACTION_TYPES.COPY_MATERIAAL_AANBOD):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_MATERIAAL_AANBOD):
  case FAILURE(ACTION_TYPES.CREATE_MATERIAAL_AANBOD):
  case FAILURE(ACTION_TYPES.UPDATE_MATERIAAL_AANBOD):
  case FAILURE(ACTION_TYPES.COPY_MATERIAAL_AANBOD):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case FAILURE(ACTION_TYPES.ARCHIVEER_MATERIAAL_AANBOD):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload?.response?.data?.message || 'error.general.subtitle.without-message'
    };
  case SUCCESS(ACTION_TYPES.FETCH_MATERIAAL_AANBOD):
  case SUCCESS(ACTION_TYPES.CREATE_MATERIAAL_AANBOD):
  case SUCCESS(ACTION_TYPES.UPDATE_MATERIAAL_AANBOD):
  case SUCCESS(ACTION_TYPES.ARCHIVEER_MATERIAAL_AANBOD):
  case SUCCESS(ACTION_TYPES.COPY_MATERIAAL_AANBOD):
    return {
      ...state,
      loading: false,
      aanbod: {
        aanbodReferentie: action.payload.data.aanbodReferentie,
        contactPersoon: action.payload.data.contactPersoon,
        onderneming: action.payload.data.onderneming,
        taal: action.payload.data.taal,
        bronTaal: action.payload.data.bronTaal,
        vertalingStatus: action.payload.data.vertalingStatus,
        titel: action.payload.data.titel,
        beschrijving: action.payload.data.beschrijving,
        toegangHoeveelheid: action.payload.data.toegangHoeveelheid ? new EnumWrapper(Vertrouwelijkheid, 'Vertrouwelijkheid').parse(action.payload.data.toegangHoeveelheid) : null,
        hoeveelheid: {
          frequentie: action.payload.data.frequentie ? new EnumWrapper(MateriaalFrequentie, 'MateriaalFrequentie').parse(action.payload.data.frequentie) : null,
          kwantiteit: action.payload.data.kwantiteit,
          eenheid: action.payload.data.eenheid ? new EnumWrapper(MateriaalEenheid, 'MateriaalEenheid').parse(action.payload.data.eenheid) : null
        },
        huidigeVerwerkingswijze: action.payload.data.huidigeVerwerkingswijze ?
          new EnumWrapper(HuidigeVerwerkingswijzeAanbod, 'HuidigeVerwerkingswijzeAanbod').parse(action.payload.data.huidigeVerwerkingswijze) : null,
        toegangLocatie: action.payload.data.toegangLocatie ? new EnumWrapper(Vertrouwelijkheid, 'Vertrouwelijkheid').parse(action.payload.data.toegangLocatie) : null,
        adres: {
          locatienaam: action.payload.data.locatienaam,
          straatnaam: action.payload.data.straatnaam,
          huisnummer: action.payload.data.huisnummer,
          adresLijn2: action.payload.data.adresLijn2,
          postcode: action.payload.data.postcode,
          gemeente: action.payload.data.gemeente,
          land: action.payload.data.land ? new EnumWrapper(Land, 'Land').parse(action.payload.data.land) : null
        },
        categorieen: action.payload.data.categorieen.map(cat => (
          {
            code: cat.code,
            hoofdCategorie: cat.hoofdCategorie,
            standaardCategorie: cat.standaardCategorie,
            subCategorie: cat.subCategorie
          })),
        euralCode: action.payload.data.euralCode,
        materiaalToestand: action.payload.data.toestand ? new EnumWrapper(MateriaalToestand, 'MateriaalToestand').parse(action.payload.data.toestand) : null,
        kleur: action.payload.data.kleur,
        gevaarlijkeStoffen: {
          gevaarlijkeStoffen: action.payload.data.gevaarlijkeStoffen,
          beschrijvingGevaarlijkeStoffen: action.payload.data.beschrijvingGevaarlijkeStoffen
        },
        bijkomendeInfo: action.payload.data.bijkomendeInfo,
        privateBijlagen: action.payload.data.privateBijlagen,
        publiekeBijlagen: action.payload.data.publiekeBijlagen,
        fotos: action.payload.data.fotos,
        materiaalSamenstelling: action.payload.data.materiaalSamenstelling,
        productieProces: action.payload.data.productieProces,
        mogelijkeToepassingen: action.payload.data.mogelijkeToepassingen,
        beschikbaarheid: action.payload.data.beschikbaarheid
      }
    };
  default:
    return state;
  }
};

export const getMateriaalAanbod: ActionCreator<any> = (aanbodUid: string) => ({
  type: ACTION_TYPES.FETCH_MATERIAAL_AANBOD,
  payload: axios.get(`/ui/aanbiedingen/${aanbodUid}`)
});

export const createMateriaalAanbod: ActionCreator<any> = (aanbod: any) => ({
  type: ACTION_TYPES.CREATE_MATERIAAL_AANBOD,
  payload: axios.post('/ui/aanbiedingen/materiaal', aanbod)
});

export const updateMateriaalAanbod: ActionCreator<any> = (aanbodUid: string, aanbod: any) => ({
  type: ACTION_TYPES.UPDATE_MATERIAAL_AANBOD,
  payload: axios.put(`/ui/aanbiedingen/${aanbodUid}`, aanbod)
});

export const archiveerMateriaalAanbod: ActionCreator<any> = (aanbodUid: string) => ({
  type: ACTION_TYPES.ARCHIVEER_MATERIAAL_AANBOD,
  payload: axios.put(`/ui/aanbiedingen/${aanbodUid}/archiveer`)
});

export const copyMateriaalAanbod: ActionCreator<any> = (bronAanbodUid: string, aanbod: any) => ({
  type: ACTION_TYPES.COPY_MATERIAAL_AANBOD,
  payload: axios.post(`/ui/aanbiedingen/${bronAanbodUid}/kopieer`, aanbod)
});

export const downloadAanbodBestand = (aanbodUid: string, bestandUid: string): AxiosPromise =>
  axios.get(`/ui/aanbiedingen/${aanbodUid}/bestanden/${bestandUid}/download`, {responseType: 'blob'});

export const uploadMateriaalAanbodBestanden = (files: File[], bestandstype: Bestandstype, aanbodUid = 'materiaal'): AxiosPromise =>
  axios.post(`/ui/aanbiedingen/${aanbodUid}/bestanden`, filesToBestandenFormData(files), {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      bestandstype
    }
  });
