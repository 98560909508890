import React from 'react';
import { Spotlight } from 'webuniversum-react';

export interface IActieBlockProps {
  title: string;
  children: React.ReactElement<React.ButtonHTMLAttributes<HTMLButtonElement>>[] | React.ReactElement<React.ButtonHTMLAttributes<HTMLButtonElement>>;
}

export class ActieBlock extends React.Component<IActieBlockProps, any> {
  render(): React.ReactNode {
    const { title, children } = this.props;
    const childCount = React.Children.count(children);

    return (
      <Spotlight title={title} alt size="l">
        <div className="vl-grid vl-u-spacer" style={{ marginTop: '2rem' }}>
          {
            React.Children.map(children, (child, index) =>
              <div className={`vl-col--1-1${index !== childCount - 1 ? ' vl-u-spacer--tiny' : ''}`}>{child}</div>
            )
          }
        </div>
      </Spotlight>
    );
  }
}
