import React from 'react';

interface IExternalLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  className?: string;
}

export const ExternalLink = (props: IExternalLinkProps) => (
  <a
    className={`${props.className || ''} vl-link--icon--external vl-link--icon--after`}
    href={props.href}
    target="_blank"
    rel="noopener noreferrer"
  >{props.children}</a>
);
