import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  bindActionCreators,
  Dispatch
} from 'redux';
import {
  formEdit,
  formEditPropertyValue,
  formState,
  formValidate,
  IFormActions,
  IFormState,
  initialFormState
} from 'redux-form-library';
import { isSymbioseTeam } from 'src/has-permission';
import {
  changeHeader,
  HeaderType
} from 'src/reducers/app/header/change';
import { IContactPersoon } from 'src/reducers/authentication';
import {
  createHulpvraagForOnderneming,
  getHulpvraagForOnderneming,
  IConversatie
} from 'src/reducers/bericht/conversatie';
import {
  ICategorieenMap,
  resetCategorieen,
  updateHoofdCategorie,
  updateStandaardCategorie
} from 'src/reducers/categorie/list';
import {
  hideModal,
  showModal
} from 'src/reducers/modal/modal';
import {
  CREATE_MATERIAAL_VRAAG_FORM,
  VRAAG_BEWERKEN_ONDERNEMING_HULPVRAAG
} from 'src/reducers/vraag';
import {
  createMateriaalVraag,
  IGetMateriaalVraagState,
  uploadMateriaalVraagBestanden
} from 'src/reducers/vraag/materiaal/get';
import {
  IMateriaalVraagForm,
  initialMateriaalVraagFormState
} from 'src/reducers/vraag/materiaal/materiaal-vraag-form';
import { VraagBewerkenOndernemingHulpvraagModal } from 'src/modules/bericht/bericht-modal';
import { extractUid } from 'src/modules/referentie-uri';
import { materiaalVraagFormDataToRequest } from 'src/modules/vraag/materiaal/materiaal-vraag-factory';
import { MateriaalVraagForm } from 'src/modules/vraag/materiaal/materiaal-vraag-form';
import { withErrorClear } from 'src/modules/error/error-clearer';
import { VraagHulpButton } from 'src/modules/bericht/vraag-hulp-button';
import { Bestandstype } from '../../bestand/bestandstype';

export interface ICreateMateriaalVraagProps extends IFormActions<IMateriaalVraagForm>, RouteComponentProps {
  conversatie: IConversatie;
  dispatch: Dispatch;
  createMateriaalVraag: Function;
  resetCategorieen: Function;
  updateHoofdCategorie?: Function;
  updateStandaardCategorie?: Function;
  categorieQueryResult: ICategorieenMap;
  form: IFormState<IMateriaalVraagForm>;
  formEditPropertyValue: Function;
  materiaalVraag: IGetMateriaalVraagState;
  gebruiker: IContactPersoon;
  getHulpvraagForOnderneming: Function;
  createHulpvraagForOnderneming: Function;
  changeHeader: Function;
  showModal: Function;
  hideModal: Function;
}

export class CreateMateriaalVraag extends React.Component<ICreateMateriaalVraagProps> {

  componentDidMount(): void {
    this.props.formEditPropertyValue('locatienaam', this.props.gebruiker.onderneming.naam);
    this.props.getHulpvraagForOnderneming();
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'materiaal.edit.header.return.vraag.create',
      '',
      [
        {
          title: 'materiaal.edit.header.title.vraag.create',
          isActive: true
        }
      ],
      () => {
        this.resetFormAndRedirect('/vraag/overzicht');
      });
  }

  componentWillUnmount(): void {
    this.props.formState(initialFormState(initialMateriaalVraagFormState()));
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  handleSave = formData => {
    this.props.createMateriaalVraag(materiaalVraagFormDataToRequest(formData))
      .then(response => {
        const vraagUid = extractUid('vraag', response.value.data.vraagReferentie);
        this.resetFormAndRedirect(`${vraagUid}/aanbiedingen`);
      }).catch(ex => console.error(ex));
  };

  handleUpload = (files: File[], bestandstype: Bestandstype) => uploadMateriaalVraagBestanden(files, bestandstype);

  handleFotoDownload = foto => Promise.resolve(foto.url);

  handleCancel = () => {
    this.resetFormAndRedirect('/vraag/overzicht');
  };

  resetFormAndRedirect = (redirectUrl: string) => {
    this.props.formState(initialFormState(initialMateriaalVraagFormState()));
    this.props.resetCategorieen();
    this.props.history.push(redirectUrl);
  };

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }
    this.props.showModal(VRAAG_BEWERKEN_ONDERNEMING_HULPVRAAG, {
      cancel: () => this.props.hideModal(VRAAG_BEWERKEN_ONDERNEMING_HULPVRAAG)
    });
  };

  render(): React.ReactNode {
    return (
      <div id="formSection">
        <div className="vl-grid">
          <h1
            className="vl-h1 vl-col--6-12 vl-u-spacer--tiny">{intl.get('materiaal.edit.form.title.create.vraag')}</h1>
          {
            !isSymbioseTeam() &&
            <div className="vl-col--6-12">
              <VraagHulpButton handleClick={this.handleOpenModal} />
            </div>
          }
        </div>
        <MateriaalVraagForm categorieQueryResult={this.props.categorieQueryResult}
          materiaalVraag={this.props.materiaalVraag}
          form={this.props.form}
          dispatch={this.props.dispatch}
          handleSave={this.handleSave}
          handleUpload={this.handleUpload}
          handleDownload={this.handleFotoDownload}
          updateHoofdCategorie={this.props.updateHoofdCategorie}
          updateStandaardCategorie={this.props.updateStandaardCategorie}
          resetCategorieen={this.props.resetCategorieen}
          formEditPropertyValue={this.props.formEditPropertyValue}
          handleCancel={this.handleCancel}
          formSelector={formSelector}
          formName={CREATE_MATERIAAL_VRAAG_FORM}
          formEdit={this.props.formEdit}
          formState={this.props.formState}
          formValidate={this.props.formValidate}/>
        <VraagBewerkenOndernemingHulpvraagModal/>
      </div>
    );
  }
}

const formSelector = storeState => storeState.vraag.createMateriaalVraagForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState),
  categorieQueryResult: storeState.categorie.categorieenList,
  euralCodes: storeState.euralCodes.data,
  conversatie: storeState.bericht.bericht.conversatie,
  materiaalVraag: storeState.vraag.details,
  gebruiker: storeState.authentication.contactPersoon
});

const mapDispatchToProps = dispatch => {
  const actions = {
    createMateriaalVraag,
    resetCategorieen,
    updateHoofdCategorie,
    updateStandaardCategorie,
    changeHeader,
    getHulpvraagForOnderneming,
    createHulpvraagForOnderneming,
    showModal,
    hideModal,
    formState: formState(CREATE_MATERIAAL_VRAAG_FORM),
    formValidate: formValidate<IMateriaalVraagForm>(CREATE_MATERIAAL_VRAAG_FORM),
    formEdit: formEdit(CREATE_MATERIAAL_VRAAG_FORM),
    formEditPropertyValue: formEditPropertyValue(CREATE_MATERIAAL_VRAAG_FORM)
  };
  return {...bindActionCreators(actions, dispatch), dispatch};
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(CreateMateriaalVraag));
