import React from 'react';
import Analytics from 'react-router-ga';

export interface IGoogleAnalyticsProps {
  id: string;
}

const GoogleAnalytics = (props: {id; children}) => props.id ? <Analytics id={props.id}>{props.children}</Analytics> : props.children;

export default GoogleAnalytics;
