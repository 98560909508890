import intl from 'react-intl-universal';
import {translateKey} from 'src/modules/form-components/translatable-select-options';

export interface IAdres {
  locatienaam: string;
  straatnaam: string;
  huisnummer: string;
  adresLijn2: string;
  postcode: string;
  gemeente: string;
  land: string;
}

export const initialAdres = (): IAdres => ({
  locatienaam: '',
  straatnaam: '',
  huisnummer: '',
  adresLijn2: '',
  postcode: '',
  gemeente: '',
  land: ''
});

export const toArray = (adres: IAdres): string[] => [
  adres.locatienaam,
  `${adres.straatnaam || ''} ${adres.huisnummer || ''}`,
  adres.adresLijn2,
  `${adres.postcode || ''} ${adres.gemeente || ''}`,
  adres.land && intl.get(translateKey(adres.land, 'detail.materiaal-info.adres.land.values'))
].filter(value => value?.trim());
