import React from 'react';

export interface IValidatieErrorProps {
  messages: string[];
}

export const ValidatieError = (props: IValidatieErrorProps) => (
  <div className="vl-form__error">
    <ul className="fc-error">
      {props.messages.map((message, idx) => <li key={`upload-msg-${idx}`}>{message}</li>)}
    </ul>
  </div>
);
