import axios from 'axios';
import {ActionCreator, AnyAction} from 'redux';
import {FAILURE, REQUEST, SUCCESS} from 'src/reducers/action-type.util';

export const ACTION_TYPES = {
  FETCH_RELEVANTIE: 'relevantie/FETCH'
};

export interface IBegrensdeWaarde {
  value: number;
  lowerBound: number;
  upperBound: number;
}

export interface IRelevantie {
  relevantieScore: string;
  afstand: IBegrensdeWaarde;
}

export interface IGetRelevantieState {
  loading: boolean;
  errorMessage: string;
  relevantie: IRelevantie;
}

export const initialState: IGetRelevantieState = {
  loading: false,
  errorMessage: '',
  relevantie: {
    relevantieScore: '',
    afstand: {
      value: null,
      lowerBound: null,
      upperBound: null
    }
  }
};

export const relevantie = (state = initialState, action: AnyAction): IGetRelevantieState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_RELEVANTIE):
    return {
      ...state,
      loading: true,
      errorMessage: null
    };

  case FAILURE(ACTION_TYPES.FETCH_RELEVANTIE):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };

  case SUCCESS(ACTION_TYPES.FETCH_RELEVANTIE):
    return {
      ...state,
      loading: false,
      relevantie: action.payload.data
    };

  default:
    return state;
  }
};

export const getRelevantie: ActionCreator<any> = (aanbodUid: string, vraagUid: string) => ({
  type: ACTION_TYPES.FETCH_RELEVANTIE,
  payload: axios.get(`/ui/relevanties/vraag/${vraagUid}/aanbod/${aanbodUid}`)
});
