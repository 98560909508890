import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  bindActionCreators,
  Dispatch
} from 'redux';
import {
  formEdit,
  formEditPropertyValue,
  formFieldsToObject,
  formState,
  formValidate,
  IFormActions,
  IFormState,
  initialFormState
} from 'redux-form-library';
import { Alert } from 'webuniversum-react';
import { isSymbioseTeam } from 'src/has-permission';
import {
  AANBOD_BEWERKEN_AANBOD_HULPVRAAG,
  UPDATE_MATERIAAL_AANBOD_FORM
} from 'src/reducers/aanbod';
import {
  downloadAanbodBestand,
  getMateriaalAanbod,
  IGetMateriaalAanbodState,
  updateMateriaalAanbod,
  uploadMateriaalAanbodBestanden
} from 'src/reducers/aanbod/materiaal/get';
import {
  IMateriaalAanbodForm,
  initialMateriaalAanbodFormState
} from 'src/reducers/aanbod/materiaal/materiaal-aanbod-form';
import {
  changeHeader,
  HeaderType
} from 'src/reducers/app/header/change';
import {
  createHulpvraagForAanbod,
  getHulpvraagForAanbod,
  IConversatie
} from 'src/reducers/bericht/conversatie';
import {
  ICategorieenMap,
  resetCategorieen,
  updateHoofdCategorie,
  updateStandaardCategorie
} from 'src/reducers/categorie/list';
import {
  hideModal,
  showModal
} from 'src/reducers/modal/modal';
import { AanbodBewerkenAanbodHulpvraagModal } from 'src/modules/bericht/bericht-modal';
import {
  materiaalAanbodFormDataToRequest,
  materiaalAanbodResponseToFormState
} from 'src/modules/aanbod/materiaal/materiaal-aanbod-factory';
import { MateriaalAanbodForm } from 'src/modules/aanbod/materiaal/materiaal-aanbod-form';
import { withErrorClear } from 'src/modules/error/error-clearer';
import { extractUid } from 'src/modules/referentie-uri';
import { blobUrl } from 'src/reducers/bestand/download';
import { IBestand } from 'src/reducers/bestand';
import { VraagHulpButton } from 'src/modules/bericht/vraag-hulp-button';
import { Bestandstype } from '../../bestand/bestandstype';

export interface IUpdateMateriaalAanbodProps extends IFormActions<IMateriaalAanbodForm>, RouteComponentProps<{ aanbodUid: string }> {
  conversatie: IConversatie;
  dispatch: Dispatch;
  updateMateriaalAanbod: Function;
  resetCategorieen: Function;
  updateHoofdCategorie: Function;
  updateStandaardCategorie: Function;
  categorieQueryResult: ICategorieenMap;
  getMateriaalAanbod: Function;
  form: IFormState<IMateriaalAanbodForm>;
  formEditPropertyValue: Function;
  materiaalAanbod: IGetMateriaalAanbodState;
  getHulpvraagForAanbod: Function;
  createHulpvraagForAanbod: Function;
  changeHeader: Function;
  showModal: Function;
  hideModal: Function;
}

export class UpdateMateriaalAanbod extends React.Component<IUpdateMateriaalAanbodProps, undefined> {

  private changeHeader(backUrl: string, backTranslation) {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      backTranslation,
      '',
      [
        {
          title: 'materiaal.edit.header.title.aanbod.update',
          isActive: false,
          redirectUrl: './vragen'
        },
        {
          title: 'materiaal.edit.header.bewerken.aanbod',
          isActive: true
        }
      ],
      () => {
        this.resetFormAndRedirect(backUrl);
      });
  }

  componentDidMount(): void {
    if (this.props.location.pathname.startsWith('/aanbod/zoeken')) {
      this.changeHeader('/aanbod/zoeken', 'materiaal.edit.header.return.aanbod.update.zoeken');
    } else {
      this.changeHeader('/aanbod/overzicht', 'materiaal.edit.header.return.aanbod.update.mijn');
    }

    this.props.getHulpvraagForAanbod(this.props.match.params.aanbodUid);
    this.props.getMateriaalAanbod(this.props.match.params.aanbodUid)
      .then(response => this.props.formState(materiaalAanbodResponseToFormState(response)));
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  handleSave = formData => {
    this.props.updateMateriaalAanbod(this.props.match.params.aanbodUid, materiaalAanbodFormDataToRequest(formData))
      .then(() => this.resetFormAndRedirect(`/aanbod/${this.props.match.params.aanbodUid}/vragen`))
      .catch(ex => console.error(ex));
  };

  handleUpload = (files: File[], bestandstype: Bestandstype) => uploadMateriaalAanbodBestanden(files, bestandstype, this.props.match.params.aanbodUid);

  handleFotoDownload = (foto: IBestand): Promise<string> => foto.url ?
    Promise.resolve(foto.url) :
    downloadAanbodBestand(this.props.match.params.aanbodUid, extractUid('bestand', foto.referentie)).then(blobUrl);

  handleCancel = () => {
    this.resetFormAndRedirect(`/aanbod/${this.props.match.params.aanbodUid}/vragen`);
  };

  resetFormAndRedirect = (redirectUrl: string) => {
    this.props.formState(initialFormState(initialMateriaalAanbodFormState()));
    this.props.resetCategorieen();
    this.props.history.push(redirectUrl);
  };

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForAanbod(this.props.match.params.aanbodUid);
    }
    this.props.showModal(AANBOD_BEWERKEN_AANBOD_HULPVRAAG, {
      cancel: () => this.props.hideModal(AANBOD_BEWERKEN_AANBOD_HULPVRAAG)
    });
  };

  render(): React.ReactNode {
    const formValues: IMateriaalAanbodForm = formFieldsToObject(this.props.form.fields);
    const languageMismatch = formValues.taal !== formValues.bronTaal;
    return (
      <>
        <div id="formSection">
          {languageMismatch && <Alert type="error">{intl.get('error.aanbod.editeren.taal_mismatch')}</Alert>}
          <div className="vl-grid">
            <h1
              className="vl-h1 vl-col--6-12 vl-u-spacer--tiny">{intl.get('materiaal.edit.form.title.update.aanbod')}</h1>
            {
              !isSymbioseTeam() &&
              <div className="vl-col--6-12">
                <VraagHulpButton handleClick={this.handleOpenModal} />
              </div>
            }
          </div>
          <fieldset disabled={languageMismatch}>
            <MateriaalAanbodForm categorieQueryResult={this.props.categorieQueryResult}
              materiaalAanbod={this.props.materiaalAanbod}
              form={this.props.form}
              dispatch={this.props.dispatch}
              handleSave={this.handleSave}
              handleUpload={this.handleUpload}
              handleDownload={this.handleFotoDownload}
              formEditPropertyValue={this.props.formEditPropertyValue}
              handleCancel={this.handleCancel}
              formEdit={this.props.formEdit}
              formState={this.props.formState}
              formValidate={this.props.formValidate}
              resetCategorieen={this.props.resetCategorieen}
              updateHoofdCategorie={this.props.updateHoofdCategorie}
              updateStandaardCategorie={this.props.updateStandaardCategorie}
              formSelector={formSelector}
              formName={UPDATE_MATERIAAL_AANBOD_FORM}/>
          </fieldset>
        </div>
        <AanbodBewerkenAanbodHulpvraagModal/>
      </>
    );
  }
}

const formSelector = storeState => storeState.aanbod.updateMateriaalAanbodForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState),
  categorieQueryResult: storeState.categorie.categorieenList,
  euralCodes: storeState.euralCodes.data,
  conversatie: storeState.bericht.bericht.conversatie,
  materiaalAanbod: storeState.aanbod.details
});

const mapDispatchToProps = dispatch => {
  const actions = {
    updateMateriaalAanbod,
    resetCategorieen,
    updateHoofdCategorie,
    updateStandaardCategorie,
    getMateriaalAanbod,
    getHulpvraagForAanbod,
    createHulpvraagForAanbod,
    changeHeader,
    showModal,
    hideModal,
    formState: formState(UPDATE_MATERIAAL_AANBOD_FORM),
    formValidate: formValidate<IMateriaalAanbodForm>(UPDATE_MATERIAAL_AANBOD_FORM),
    formEdit: formEdit(UPDATE_MATERIAAL_AANBOD_FORM),
    formEditPropertyValue: formEditPropertyValue(UPDATE_MATERIAAL_AANBOD_FORM)
  };
  return {...bindActionCreators(actions, dispatch), dispatch};
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(UpdateMateriaalAanbod));
