import {IAdresForm, initialAdresFormState} from 'src/modules/adres/adres-form';
import {Ontwikkelingsstadium} from 'src/modules/technologie/ontwikkelingsstadium';
import {IBestand} from 'src/reducers/bestand';

export interface ITechnologieForm {
  taal: string;
  bronTaal: string;
  titel: string;
  beschrijving: string;
  ontwikkelingsstadium: Ontwikkelingsstadium | '';
  beschrijvingTeVerwerkenMateriaal: string;
  oorsprongTeVerwerkenMateriaal: string;
  beschrijvingVerwerktMateriaal: string;
  mogelijkeBestemmingVerwerktMateriaal: string;
  terPlaatse: boolean;
  verplaatsbaar: boolean;
  adres: IAdresForm;
  ondernemingsNaam: string;
  voornaamContactPersoon: string;
  familienaamContactPersoon: string;
  email: string;
  telefoon: string;
  website: string;
  bijlagen: IBestand[];
}

export const initialTechnologieFormState = (): ITechnologieForm => ({
  taal: '',
  bronTaal: '',
  titel: '',
  beschrijving: '',
  ontwikkelingsstadium: '',
  beschrijvingTeVerwerkenMateriaal: '',
  oorsprongTeVerwerkenMateriaal: '',
  beschrijvingVerwerktMateriaal: '',
  mogelijkeBestemmingVerwerktMateriaal: '',
  terPlaatse: false,
  verplaatsbaar: false,
  adres: initialAdresFormState(),
  ondernemingsNaam: '',
  voornaamContactPersoon: '',
  familienaamContactPersoon: '',
  email: '',
  telefoon: '',
  website: '',
  bijlagen: []
});
