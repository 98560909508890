import React from 'react';
import intl from 'react-intl-universal';
import {SpotlightDocument} from 'webuniversum-react';
import {connect} from 'react-redux';
import Breadcrumbs from 'src/modules/welkomstpagina/breadcrumbs';
import {getAllePublicaties, IGetPublicatiesState} from 'src/reducers/informatieve_website/publicaties';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IPublicatiesProps {
  history: any;
  taal: string;
  getAllePublicaties: Function;
  publicaties: IGetPublicatiesState;
}

export class PublicatiesPagina extends React.Component<IPublicatiesProps, undefined> {
  componentDidMount() {
    this.props.getAllePublicaties(this.props.taal);
  }

  render(): React.ReactNode {
    const pubs = this.props.publicaties.publicaties;
    return <>
      <Breadcrumbs breadcrumbs={[
        { title: intl.get('welkomstpagina.titel'), location: '/' },
        { title: pubs.titel }
      ]}/>
      <h1 className="vl-h1 vl-u-spacer">{pubs.titel}</h1>
      <section className="vl-region vl-u-spacer">
        <div className="vl-layout vl-layout--wide vl-u-spacer--tiny">
          <div className="vl-grid vl-grid--is-stacked vl-u-spacer js-vl-equal-height-container">
            {pubs.bestanden.map((bestand, index) =>
              <div key={`publicatie${index}`}
                className="vl-col--4-12 vl-col--6-12--m vl-col--1-1--xs js-vl-equal-height">
                <SpotlightDocument docType={bestand.extension} bytes={bestand.size}
                  onClick={() => window.open(bestand.url, '_blank')}>
                  {bestand.title}
                </SpotlightDocument>
              </div>
            )}
          </div>
        </div>
      </section>
    </>;
  }
}

const mapStateToProps = storeState => ({
  publicaties: storeState.informatieveWebsite.publicaties,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  getAllePublicaties
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(PublicatiesPagina));
