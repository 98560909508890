import React from 'react';
import intl from 'react-intl-universal';
import { ICategorie } from '../materiaal/materiaal-categorie';

export interface IDetailCategorieProps {
  categorie: ICategorie;
}

export class DetailCategorie extends React.Component<IDetailCategorieProps> {
  render(): React.ReactNode {
    const { categorie } = this.props;
    return <div>
      <ul className="vl-u-spacer--small">
        {categorie.hoofdCategorie && <li>
          <span className="vl-h5 relevantie-icon">{categorie.code.length === 6 ? `${categorie.code.slice(0, 4)} ${categorie.code.slice(-2)}` : categorie.code}</span>
          {intl.get(categorie.hoofdCategorie)}
        </li>}
        {categorie.standaardCategorie && <li><span className="fas fa-angle-right relevantie-icon"
          aria-hidden="true"/>{intl.get(categorie.standaardCategorie)}</li>}
        {categorie.subCategorie && <li><span className="fas fa-angle-double-right relevantie-icon"
          aria-hidden="true"/>{intl.get(categorie.subCategorie)}</li>}
      </ul>
    </div>;
  }
}
