import {
  changeDateFormat,
  composeDatepickerPlaceholder,
  DATEPICKER_DATE,
  formatDate,
  parseDate
} from 'src/dates';
import { isSymbioseTeam } from 'src/has-permission';
import {
  clearSearchResults,
  findMateriaalAanbod,
  IFoundAanbiedingenList,
  IFoundAanbod,
  ISearchMateriaalAanbodForm,
  SEARCH_MATERIAAL_AANBOD_FORM
} from 'src/reducers/aanbod/materiaal/search';
import {
  changeHeader,
  HeaderType
} from 'src/reducers/app/header/change';
import {
  ICategorieenMap,
  resetCategorieen,
  updateHoofdCategorie,
  updateStandaardCategorie
} from 'src/reducers/categorie/list';
import {
  hideModal,
  showModal
} from 'src/reducers/modal/modal';
import { getStore } from 'src/redux-store';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import {
  bindActionCreators,
  Dispatch
} from 'redux';
import {
  createValidateFunction,
  formEdit,
  formEditPropertyValue,
  FormFields,
  formFieldsToObject,
  FormPropsFactory,
  FormPropsFactoryBuilder,
  formState,
  formValidate,
  IFormActions,
  IFormState,
  ValidationMethod
} from 'redux-form-library';
import {
  DatePicker,
  FormComponent,
  InputField,
  Loading
} from 'webuniversum-react';
import { CategorieForm } from 'src/modules/categorie/categorie-form';
import CategorieModal from 'src/modules/categorie/categorie-modal';
import { translateKey } from 'src/modules/form-components/translatable-select-options';
import { SearchFilterMetResetButton } from 'src/modules/materiaal/search-filter-met-reset-button';
import { extractUid } from 'src/modules/referentie-uri';
import { RegistratieHint } from 'src/modules/welkomstpagina/registratie-hint';
import { OverzichtTegel } from 'src/modules/materiaal/overzicht-tegel';
import { blobUrl } from 'src/reducers/bestand/download';
import { downloadAanbodBestand } from 'src/reducers/aanbod/materiaal/get';
import { withErrorClear } from 'src/modules/error/error-clearer';
import moment from 'moment';
import capitalize from 'lodash/capitalize';

export interface ISearchMateriaalAanbodProps extends IFormActions<ISearchMateriaalAanbodForm> {
  dispatch: Dispatch;
  findMateriaalAanbod?: Function;
  resetCategorieen: Function;
  updateHoofdCategorie?: Function;
  updateStandaardCategorie?: Function;
  categorieQueryResult: ICategorieenMap;
  form: IFormState<ISearchMateriaalAanbodForm>;
  formEditPropertyValue: Function;
  queryResult: IFoundAanbiedingenList;
  history: any;
  changeHeader: Function;
  isAuthenticated: boolean;
  taal: string;
  clearSearchResults: Function;
  showModal: Function;
  hideModal: Function;
}

export class SearchMateriaalAanbod extends React.Component<ISearchMateriaalAanbodProps, undefined> {
  private readonly searchFormRef: any;

  constructor(props: ISearchMateriaalAanbodProps) {
    super(props);
    this.searchFormRef = React.createRef();
  }

  componentDidMount(): void {
    const formData: ISearchMateriaalAanbodForm = formFieldsToObject(this.props.form.fields);
    this.props.updateHoofdCategorie(formData.categorie.hoofdCategorie);
    this.props.updateStandaardCategorie(formData.categorie.standaardCategorie);

    if (this.props.queryResult.searched) {
      this.performSearch(formData);
    } else {
      this.resetSearchComponent();
    }

    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      this.props.isAuthenticated ? 'applicatie.zoekpagina.terug' : 'welkomstpagina.terug',
      '',
      [
        {
          title: 'applicatie.tablink.symbioseteam.aanbod',
          isActive: true
        },
        {
          title: 'applicatie.tablink.symbioseteam.vraag',
          isActive: false,
          redirectUrl: '/vraag/zoeken'
        },
        {
          title: 'applicatie.tablink.symbioseteam.technologie',
          isActive: false,
          redirectUrl: '/technologie/zoeken'
        }
      ], () => {
        this.props.history.push(this.props.isAuthenticated ? '/zoekpagina' : '/');
      });
  }

  private generateErrorMessages = () => ({
    'is_after.beschikbaarheidTot': intl.get('validation.form.field.date.is_after', {
      NAME: `'${capitalize(intl.get('aanbod.search.form.beschikbaarheid.fields.tot.label'))}'`,
      OTHER: `'${capitalize(intl.get('aanbod.search.form.beschikbaarheid.fields.van.label'))}'`
    })
  });

  private createValidationRules = () => ({
    beschikbaarheidTot: this.props.form.fields.beschikbaarheidVan.value && this.props.form.fields.beschikbaarheidTot.value
      ? `is_after:${this.props.form.fields.beschikbaarheidVan.value}`
      : 'string'
  });

  private createValidation = (errormessages, validationRules) => createValidateFunction(validationRules, errormessages);

  private createValidationFunction = () => this.createValidation(this.generateErrorMessages(), this.createValidationRules());

  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.formValidate(
      this.createValidationFunction(),
      ValidationMethod.ValidateAll,
      formSelector(getStore().getState())
    );
    const state: IFormState<ISearchMateriaalAanbodForm> = formSelector(getStore().getState());
    const formFields: FormFields<ISearchMateriaalAanbodForm> = state.fields;
    if (state.valid) {
      this.performSearch(formFieldsToObject(formFields));
    } else {
      this.searchFormRef.current.scrollIntoView();
    }
  };

  performSearch = formData => {
    const searchparams: any = {
      titel: formData.titel,
      onderneming: formData.onderneming,
      categorie: formData.categorie.subCategorie || formData.categorie.standaardCategorie || formData.categorie.hoofdCategorie,
      beginDatum: changeDateFormat(formData.beginDatum) || '',
      eindDatum: changeDateFormat(formData.eindDatum) || '',
      beschikbaarheidVan: changeDateFormat(formData.beschikbaarheidVan) || '',
      beschikbaarheidTot: changeDateFormat(formData.beschikbaarheidTot) || '',
      taal: this.props.taal
    };

    this.props.findMateriaalAanbod(searchparams);
  };

  resetField = fieldName => {
    this.props.formEditPropertyValue('categorie.' + fieldName, '');
  };

  createDatePickerOnChange = fieldName => (date => {
    this.props.formEditPropertyValue(fieldName, formatDate(date, DATEPICKER_DATE));
  });

  createDatePickerValue = fieldName => parseDate(this.props.form.fields[fieldName].value);

  private navigateToAanbod = aanbodReferentie => {
    if (isSymbioseTeam()) {
      this.props.history.push(`/aanbod/zoeken/${extractUid('aanbod', aanbodReferentie)}/vragen`);
    } else {
      if (this.props.isAuthenticated) {
        this.props.history.push(`/aanbod/zoeken/${extractUid('aanbod', aanbodReferentie)}`);
      }
    }
  };

  resetSearchComponent = () => {
    this.props.formEditPropertyValue('titel', '');
    this.props.formEditPropertyValue('onderneming', '');
    this.props.formEditPropertyValue('categorie.hoofdCategorie', '');
    this.props.formEditPropertyValue('categorie.standaardCategorie', '');
    this.props.formEditPropertyValue('categorie.subCategorie', '');
    this.props.resetCategorieen();
    this.props.formEditPropertyValue('beginDatum', '');
    this.props.formEditPropertyValue('eindDatum', '');
    this.props.formEditPropertyValue('beschikbaarheidVan', moment().format(DATEPICKER_DATE));
    this.props.formEditPropertyValue('beschikbaarheidTot', '');
    this.props.clearSearchResults();
  };

  render(): React.ReactNode {
    const { hoofdCategorieen, standaardCategorieen, subCategorieen } = this.props.categorieQueryResult;

    const fields = this.props.form.fields;
    const formPropsFactory: FormPropsFactory<ISearchMateriaalAanbodForm> = new FormPropsFactoryBuilder()
      .setFieldsObject(fields)
      .setDispatch(this.props.dispatch)
      .setValidate(this.createValidation(this.generateErrorMessages(), this.createValidationRules()))
      .setSelector(formSelector)
      .setStore(getStore())
      .setFormName(SEARCH_MATERIAAL_AANBOD_FORM)
      .build();

    const beschikbaarheidVanInputProps = formPropsFactory.createInputProps('beschikbaarheidVan');
    beschikbaarheidVanInputProps.onChange = this.createDatePickerOnChange('beschikbaarheidVan');
    beschikbaarheidVanInputProps.value = this.createDatePickerValue('beschikbaarheidVan');
    const beschikbaarheidTotInputProps = formPropsFactory.createInputProps('beschikbaarheidTot');
    beschikbaarheidTotInputProps.onChange = this.createDatePickerOnChange('beschikbaarheidTot');
    beschikbaarheidTotInputProps.value = this.createDatePickerValue('beschikbaarheidTot');

    const beginDatumInputProps = formPropsFactory.createInputProps('beginDatum');
    beginDatumInputProps.onChange = this.createDatePickerOnChange('beginDatum');
    beginDatumInputProps.value = this.createDatePickerValue('beginDatum');
    const eindDatumInputProps = formPropsFactory.createInputProps('eindDatum');
    eindDatumInputProps.onChange = this.createDatePickerOnChange('eindDatum');
    eindDatumInputProps.value = this.createDatePickerValue('eindDatum');

    return (
      <>
        <h1 className="vl-h1 vl-u-spacer--tiny">{intl.get('aanbod.search.form.title.aanbod')}</h1>
        {!this.props.isAuthenticated && <RegistratieHint />}

        <SearchFilterMetResetButton
          onSubmit={this.handleSubmit}
          searchButtonText={intl.get('aanbod.search.form.button.search')}
          onReset={this.resetSearchComponent}
          resetButtonText={intl.get('aanbod.search.form.button.reset')}>
          <div className="vl-u-spacer--tiny" ref={this.searchFormRef}>
            <FormComponent
              id="titel"
              label={intl.get('aanbod.search.form.titel.fields.titel.label')}
              block
            >
              <InputField {...formPropsFactory.createInputProps('titel')} layout="block" />
            </FormComponent>
          </div>
          {isSymbioseTeam() &&
            <div className="vl-u-spacer--tiny">
              <FormComponent
                id="onderneming"
                label={intl.get('aanbod.search.form.onderneming.fields.onderneming.label')}
                block
              >
                <InputField {...formPropsFactory.createInputProps('onderneming')} layout="block" />
              </FormComponent>
            </div>
          }

          <CategorieForm
            fields={fields.categorie}
            formPropsFactory={formPropsFactory.createSubFormPropsFactory('categorie')}
            hoofdCategorieen={hoofdCategorieen}
            standaardCategorieen={standaardCategorieen}
            subCategorieen={subCategorieen}
            updateHoofdCategorie={this.props.updateHoofdCategorie}
            updateStandaardCategorie={this.props.updateStandaardCategorie}
            resetField={this.resetField}
            hideModal={this.props.hideModal}
            showModal={this.props.showModal}
          />

          {isSymbioseTeam() &&
            <div className="vl-grid vl-u-spacer--tiny">
              <FormComponent
                id="beginDatum"
                label={intl.get('aanbod.search.form.begin-datum.fields.begin-datum.label')}
                block
              >
                <DatePicker
                  id="beginDatum"
                  format={DATEPICKER_DATE}
                  className="vl-col--6-12"
                  placeholder={composeDatepickerPlaceholder()}
                  {...beginDatumInputProps}
                />
              </FormComponent>

              <FormComponent
                id="eindDatum"
                label={intl.get('aanbod.search.form.eind-datum.fields.eind-datum.label')}
                block
              >
                <DatePicker
                  id="eindDatum"
                  format={DATEPICKER_DATE}
                  className="vl-col--6-12"
                  placeholder={composeDatepickerPlaceholder()}
                  {...eindDatumInputProps}
                />
              </FormComponent>
            </div>
          }

          <div className="vl-grid vl-u-spacer--tiny">
            <FormComponent
              id="beschikbaarheidVan"
              label={intl.get('aanbod.search.form.beschikbaarheid.fields.van.label')}
              block
            >
              <DatePicker
                id="beschikbaarheidVan"
                format={DATEPICKER_DATE}
                className="vl-col--6-12"
                placeholder={composeDatepickerPlaceholder()}
                {...beschikbaarheidVanInputProps}
              />
            </FormComponent>

            <FormComponent
              id="beschikbaarheidTot"
              label={intl.get('aanbod.search.form.beschikbaarheid.fields.tot.label')}
              error={this.props.form && this.props.form.fields && this.props.form.fields.beschikbaarheidTot.errors}
              block
            >
              <DatePicker
                id="beschikbaarheidTot"
                format={DATEPICKER_DATE}
                className="vl-col--6-12"
                placeholder={composeDatepickerPlaceholder()}
                {...beschikbaarheidTotInputProps}
              />
            </FormComponent>
          </div>
        </SearchFilterMetResetButton>
        <div className="vl-u-spacer" />
        {this.props.queryResult.aanbiedingen.length === 0 && this.props.queryResult.searched && <div>
          <p><strong>{intl.get('aanbod.search.overview.no-results.message')}</strong></p>
          <ul style={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
            <li>{intl.get('aanbod.search.overview.no-results.suggestie1')}</li>
            <li>{intl.get('aanbod.search.overview.no-results.suggestie2')}</li>
            <li>{intl.get('aanbod.search.overview.no-results.suggestie3')}</li>
            <li>{intl.get('aanbod.search.overview.no-results.suggestie4')}</li>
          </ul>
        </div>}

        {this.props.queryResult.aanbiedingen.length > 0 &&
          <Loading loading={this.props.queryResult.loading}>
            {this.props.queryResult.aanbiedingen.map(aanbod =>
              <OverzichtTegel
                {...aanbod}
                key={aanbod.aanbodReferentie}
                coverFotoUrlFetcher={() => this.resolveFotoSourceUrl(aanbod)}
                verwerkingswijze={intl.get(
                  translateKey(aanbod.huidigeVerwerkingswijze,
                    'aanbod.search.overview.table.rows.huidige-verwerkingswijze.values'))
                }
                onClick={this.props.isAuthenticated ? () => this.navigateToAanbod(aanbod.aanbodReferentie) : null}
                beschikbaarheid={aanbod.beschikbaarheid}
              />
            )}
          </Loading>
        }
        <CategorieModal formEditPropertyValue={this.props.formEditPropertyValue} />
      </>
    );
  }

  private resolveFotoSourceUrl = (aanbod: IFoundAanbod): Promise<string> =>
    aanbod.coverFotoReferentie ?
      downloadAanbodBestand(extractUid('aanbod', aanbod.aanbodReferentie), extractUid('bestand', aanbod.coverFotoReferentie)).then(blobUrl) :
      Promise.reject(`no cover photo for aanbod ${aanbod.aanbodReferentie}`);
}

const formSelector = storeState => storeState.aanbod.searchMateriaalAanbodForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState),
  categorieQueryResult: storeState.categorie.categorieenList,
  queryResult: storeState.aanbod.materiaal.search,
  isAuthenticated: storeState.authentication.security.isAuthenticated,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = dispatch => {
  const actions = {
    findMateriaalAanbod,
    resetCategorieen,
    updateHoofdCategorie,
    updateStandaardCategorie,
    changeHeader,
    showModal,
    hideModal,
    formState: formState(SEARCH_MATERIAAL_AANBOD_FORM),
    formEdit: formEdit(SEARCH_MATERIAAL_AANBOD_FORM),
    formEditPropertyValue: formEditPropertyValue(SEARCH_MATERIAAL_AANBOD_FORM),
    formValidate: formValidate<ISearchMateriaalAanbodForm>(SEARCH_MATERIAAL_AANBOD_FORM),
    clearSearchResults
  };
  return { ...bindActionCreators(actions, dispatch), dispatch };
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(SearchMateriaalAanbod));
