import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { Document } from '@contentful/rich-text-types';

export const ACTION_TYPES = {
  GET: 'informatieve-website/instructies/nieuwegebruiker/GET'
};

export interface IInstructieOptie {
  tekst: string;
  uitleg?: Document;
  vervolgVraag?: IInstructieVraag;
}

export interface IInstructieVraag {
  tekst: string;
  uitleg: Document;
  antwoordOpties: IInstructieOptie[];
}

export interface IGetInstructiesNieuweGebruikerState {
  loading: boolean;
  errorMessage: any;
  startInstructie: IInstructieVraag;
}

export const initialState: IGetInstructiesNieuweGebruikerState = {
  loading: false,
  errorMessage: null,
  startInstructie: null
};

export const instructiesNieuweGebruikerReducer = (state = initialState, action: AnyAction): IGetInstructiesNieuweGebruikerState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET):
    return {
      ...state,
      errorMessage: null,
      loading: true,
      startInstructie: null
    };
  case FAILURE(ACTION_TYPES.GET):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET):
    return {
      ...state,
      loading: false,
      startInstructie: action.payload.data
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite';

export const getInstructiesNieuweGebruiker: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET,
  payload: axios.get(`${apiUrl}/instructies/nieuwegebruiker/${taal}`)
});
