import React from 'react';
import {
  Route,
  Switch
} from 'react-router';
import Header from 'src/header';
import CopyMateriaalAanbod from 'src/modules/aanbod/materiaal/copy-materiaal-aanbod';
import CreateMateriaalAanbod from 'src/modules/aanbod/materiaal/create-materiaal-aanbod';
import MateriaalAanbodDetailPagina from 'src/modules/aanbod/materiaal/materiaal-aanbod-detail-pagina';
import MateriaalGearchiveerdAanbodDetailPagina from 'src/modules/aanbod/materiaal/materiaal-gearchiveerd-aanbod-detail-pagina';
import OverviewGearchiveerdMateriaalAanbod from 'src/modules/aanbod/materiaal/overview-gearchiveerd-materiaal-aanbod';
import OverviewMateriaalAanbod from 'src/modules/aanbod/materiaal/overview-materiaal-aanbod';
import RelevantMateriaalAanbod from 'src/modules/aanbod/materiaal/relevant-materiaal-aanbod';
import RelevanteVragenMateriaalAanbod from 'src/modules/aanbod/materiaal/relevante-vragen-materiaal-aanbod';
import SearchMateriaalAanbod from 'src/modules/aanbod/materiaal/search-materiaal-aanbod';
import UpdateMateriaalAanbod from 'src/modules/aanbod/materiaal/update-materiaal-aanbod';
import AlgemeneVoorwaardenView from 'src/modules/algemene-voorwaarden/view-algemene-voorwaarden';
import OverviewBerichten from 'src/modules/bericht/overview-berichten';
import OverviewAfgeslotenConversaties from 'src/modules/bericht/overview-afgesloten-conversaties';
import ErrorPagina from 'src/modules/error/error-pagina';
import InvalidSession from 'src/modules/error/invalid-session';
import Startpagina from 'src/modules/startpagina/startpagina';
import DetailSymbiose from 'src/modules/symbiose/detail-symbiose';
import MijnSymbioses from 'src/modules/symbiose/mijn-symbioses';
import Taalkeuze from 'src/modules/taal/taalkeuze';
import CopyMateriaalVraag from 'src/modules/vraag/materiaal/copy-materiaal-vraag';
import CreateMateriaalVraag from 'src/modules/vraag/materiaal/create-materiaal-vraag';
import MateriaalGearchiveerdVraagDetailPagina from 'src/modules/vraag/materiaal/materiaal-gearchiveerd-vraag-detail-pagina';
import MateriaalVraagDetailPagina from 'src/modules/vraag/materiaal/materiaal-vraag-detail-pagina';
import OverviewGearchiveerdMateriaalVraag from 'src/modules/vraag/materiaal/overview-gearchiveerd-materiaal-vraag';
import OverviewMateriaalVraag from 'src/modules/vraag/materiaal/overview-materiaal-vraag';
import RelevanteAanbiedingenMateriaalVraag from 'src/modules/vraag/materiaal/relevante-aanbiedingen-materiaal-vraag';
import RelevanteMateriaalVraag from 'src/modules/vraag/materiaal/relevante-materiaal-vraag';
import SearchMateriaalVraag from 'src/modules/vraag/materiaal/search-materiaal-vraag';
import UpdateMateriaalVraag from 'src/modules/vraag/materiaal/update-materiaal-vraag';
import FAQPagina from 'src/modules/welkomstpagina/faq';
import IndustrieleSymbiosePagina from 'src/modules/welkomstpagina/industriele-symbiose-pagina';
import NieuweGebruiker from 'src/modules/welkomstpagina/nieuwe-gebruiker';
import NieuwsPagina from 'src/modules/welkomstpagina/nieuws';
import NieuwsItemPagina from 'src/modules/welkomstpagina/nieuws-item';
import PublicatiesPagina from 'src/modules/welkomstpagina/publicaties';
import RegistratiePagina from 'src/modules/welkomstpagina/registratie-pagina';
import WelkomstHeader from 'src/modules/welkomstpagina/welkomstheader';
import Welkomstpagina from 'src/modules/welkomstpagina/welkomstpagina';
import WorkshopsPagina from 'src/modules/welkomstpagina/workshops';
import { PrivateRoute } from 'src/private-route';
import CreateTechnologie from 'src/modules/technologie/create-technologie';
import OverviewTechnologie from 'src/modules/technologie/overview-technologie';
import DetailTechnologie from 'src/modules/technologie/detail-technologie';
import SearchTechnologie from 'src/modules/technologie/search-technologie';
import Zoekpagina from 'src/modules/startpagina/zoekpagina';
import UpdateTechnologie from 'src/modules/technologie/update-technologie';
import CreateVraagFromAanbod from 'src/modules/vraag/materiaal/create-vraag-from-aanbod';
import CreateAanbodFromVraag from './modules/aanbod/materiaal/create-aanbod-from-vraag';

export const HeaderRoutes = () => (
  <Switch>
    <PrivateRoute exact path="/home" component={Header} />
    <Route path="/welkomstpagina" component={WelkomstHeader} />
    <Route exact path="/home" component={WelkomstHeader} />
    <Route exact path="/" component={WelkomstHeader} />
    <Route path="/" component={Header} />
  </Switch>
);

export const AppRoutes = () => (
  <div className="view-routes">
    <Switch>
      <Route path="/welkomstpagina/registratie/instructies/nieuwe-gebruiker" component={NieuweGebruiker} />
      <Route path="/welkomstpagina/registratie" component={RegistratiePagina} />
      <Route path="/welkomstpagina/faq" component={FAQPagina} />
      <Route path="/welkomstpagina/industriele-symbiose/:referentie" component={IndustrieleSymbiosePagina} />
      <Route path="/welkomstpagina/nieuws/:referentie" component={NieuwsItemPagina} />
      <Route path="/welkomstpagina/nieuws" component={NieuwsPagina} />
      <Route path="/welkomstpagina/workshops" component={WorkshopsPagina} />
      <Route path="/welkomstpagina/publicaties" component={PublicatiesPagina} />
      <Route path="/welkomstpagina" component={Welkomstpagina} />
      <Route path="/zoekpagina" component={Zoekpagina} />
      <Route path="/error" component={ErrorPagina} />
      <Route path="/session/invalid" component={InvalidSession} />
      <PrivateRoute exact path="/startpagina" component={Startpagina} />
      <Route exact path="/taal/nl" component={Taalkeuze} />
      <Route exact path="/taal/en" component={Taalkeuze} />
      <Route exact path="/algemenevoorwaarden/nl" component={AlgemeneVoorwaardenView} />
      <Route exact path="/algemenevoorwaarden/en" component={AlgemeneVoorwaardenView} />
      <PrivateRoute exact path="/aanbod/overzicht" component={OverviewMateriaalAanbod} />
      <PrivateRoute exact path="/aanbod/gearchiveerd/overzicht" component={OverviewGearchiveerdMateriaalAanbod} />
      <PrivateRoute exact path="/aanbod/nieuw" component={CreateMateriaalAanbod} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/aanbod/nieuw-vanuit-vraag/:vraagUid" component={CreateAanbodFromVraag} errorPath="/aanbod/overzicht" />
      <Route exact path="/aanbod/zoeken" component={SearchMateriaalAanbod} />
      <PrivateRoute exact path="/aanbod/zoeken/:aanbodUid" component={MateriaalAanbodDetailPagina} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/aanbod/gearchiveerd/:aanbodUid" component={MateriaalGearchiveerdAanbodDetailPagina} errorPath="/aanbod/gearchiveerd-overzicht" />
      <PrivateRoute exact path="/aanbod/zoeken/:aanbodUid/vragen" component={RelevanteVragenMateriaalAanbod} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/aanbod/zoeken/:aanbodUid/update" component={UpdateMateriaalAanbod} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/aanbod/zoeken/:aanbodUid/relevante-vraag/:vraagUid" component={RelevanteMateriaalVraag} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/aanbod/:aanbodUid/vragen" component={RelevanteVragenMateriaalAanbod} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/aanbod/:aanbodUid" component={MateriaalAanbodDetailPagina} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/aanbod/:aanbodUid/update" component={UpdateMateriaalAanbod} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/aanbod/:aanbodUid/copy" component={CopyMateriaalAanbod} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/aanbod/:aanbodUid/relevante-vraag/:vraagUid" component={RelevanteMateriaalVraag} errorPath="/aanbod/overzicht" />
      <PrivateRoute exact path="/vraag/overzicht" component={OverviewMateriaalVraag} />
      <PrivateRoute exact path="/vraag/gearchiveerd/overzicht" component={OverviewGearchiveerdMateriaalVraag} />
      <PrivateRoute exact path="/vraag/nieuw" component={CreateMateriaalVraag} errorPath="/vraag/overzicht" />
      <PrivateRoute exact path="/vraag/nieuw-vanuit-aanbod/:aanbodUid" component={CreateVraagFromAanbod} errorPath="/vraag/overzicht" />
      <Route exact path="/vraag/zoeken" component={SearchMateriaalVraag} />
      <PrivateRoute exact path="/vraag/zoeken/:vraagUid" component={MateriaalVraagDetailPagina} errorPath="/vraag/overzicht" />
      <PrivateRoute exact path="/vraag/gearchiveerd/:vraagUid" component={MateriaalGearchiveerdVraagDetailPagina} errorPath="/vraag/gearchiveerd/overzicht" />
      <PrivateRoute exact path="/vraag/zoeken/:vraagUid/aanbiedingen" component={RelevanteAanbiedingenMateriaalVraag} errorPath="/vraag/overzicht" />
      <PrivateRoute exact path="/vraag/zoeken/:vraagUid/update" component={UpdateMateriaalVraag} errorPath="/vraag/overzicht" />
      <PrivateRoute exact path="/vraag/:vraagUid/copy" component={CopyMateriaalVraag} errorPath="/vraag/overzicht" />
      <PrivateRoute exact path="/vraag/zoeken/:vraagUid/relevant-aanbod/:aanbodUid" component={RelevantMateriaalAanbod} errorPath="/vraag/overzicht" />
      <PrivateRoute exact path="/vraag/:vraagUid/aanbiedingen" component={RelevanteAanbiedingenMateriaalVraag} errorPath="/vraag/overzicht" />
      <PrivateRoute exact path="/vraag/:vraagUid" component={MateriaalVraagDetailPagina} errorPath="/vraag/overzicht" />
      <PrivateRoute exact path="/vraag/:vraagUid/update" component={UpdateMateriaalVraag} errorPath="/vraag/overzicht" />
      <PrivateRoute exact path="/vraag/:vraagUid/relevant-aanbod/:aanbodUid" component={RelevantMateriaalAanbod} errorPath="/vraag/overzicht" />
      <Route exact path="/technologie/zoeken/:technologieUid/:taal" component={DetailTechnologie} />
      <Route path="/technologie/zoeken" component={SearchTechnologie} />
      <PrivateRoute exact path="/technologie/overzicht" component={OverviewTechnologie} />
      <PrivateRoute exact path="/technologie/nieuw" component={CreateTechnologie} errorPath="/technologie/overzicht" />
      <PrivateRoute exact path="/technologie/:technologieUid" component={DetailTechnologie} errorPath="/technologie/overzicht" />
      <PrivateRoute exact path="/technologie/:technologieUid/update" component={UpdateTechnologie} errorPath="/technologie/overzicht" />
      <PrivateRoute exact path="/berichten/overzicht" component={OverviewBerichten} />
      <PrivateRoute exact path="/berichten/afgesloten-overzicht" component={OverviewAfgeslotenConversaties} />
      <PrivateRoute exact path="/symbiose/overzicht" component={MijnSymbioses} />
      <PrivateRoute exact path="/symbiose/:symbioseUid" component={DetailSymbiose} />
      <PrivateRoute exact path="/home" component={Startpagina} />
      <Route exact path="/" component={Welkomstpagina} />
      <Route path="/" component={Startpagina} />
    </Switch>
  </div>
);
