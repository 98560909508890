import React from 'react';
import intl from 'react-intl-universal';
import {IError} from 'src/reducers/error';
import {Alert} from 'webuniversum-react';
import {connect} from 'react-redux';

export interface IErrorMessageProps {
  error: IError;
}

export class ErrorMessage extends React.Component<IErrorMessageProps, undefined> {
  render(): React.ReactNode {
    const { error } = this.props;
    if (!error?.message) {
      return null;
    }
    const subtitle = intl.get(error.subtitle || (error.message ? 'error.general.subtitle.with-message' : 'error.general.subtitle.without-message'));
    return <Alert type="error" title={intl.get(error.title || 'error.general.title')}>
      <p className="vl-u-spacer--small">{subtitle}</p>
      {error.message && <p className="italic">{error.message}</p>}
    </Alert>;
  }
}

const mapStateToProps = storeState => ({
  error: storeState.error
});

export default connect(mapStateToProps)(ErrorMessage);
