import React from 'react';
import intl from 'react-intl-universal';
import {FormFields, SubFormPropsFactory} from 'redux-form-library';
import {FormComponent, SelectSearch} from 'webuniversum-react';
import {ICategorie} from 'src/reducers/categorie/list';
import {CATEGORIE_MODAL} from 'src/reducers/categorie';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {connect} from 'react-redux';

export interface ICategorieForm {
  code: string;
  hoofdCategorie: string;
  standaardCategorie: string;
  subCategorie: string;
}

export const initialCategorieFormState = (): ICategorieForm => ({
  code: '',
  hoofdCategorie: '',
  standaardCategorie: '',
  subCategorie: ''
});

interface ICategorieProps {
  title?: string;
  required?: boolean;
  fields: FormFields<ICategorieForm>;
  formPropsFactory: SubFormPropsFactory<ICategorieForm>;
  hoofdCategorieen: ICategorie[];
  standaardCategorieen: ICategorie[];
  subCategorieen: ICategorie[];
  updateHoofdCategorie?: Function;
  updateStandaardCategorie?: Function;
  resetField: Function;
  showModal: Function;
  hideModal: Function;
}

export class CategorieForm extends React.Component<ICategorieProps> {
  generateTooltipMessages = () =>
    this.props.required ? {
      'required.hoofdcategorie': intl.get('validation.form.field.required', {
        NAME: intl.get('materiaal.edit.form.classificatie.fields.hoofdcategorie.label')
      }),
      'required.standaardcategorie': intl.get('validation.form.field.required', {
        NAME: intl.get('materiaal.edit.form.classificatie.fields.standaardcategorie.label')
      }),
      'required.subcategorie': intl.get('validation.form.field.required', {
        NAME: intl.get('materiaal.edit.form.classificatie.fields.subcategorie.label')
      })
    } : {};

  render(): React.ReactNode {
    const { title, fields, formPropsFactory, hoofdCategorieen, standaardCategorieen, subCategorieen, updateHoofdCategorie, updateStandaardCategorie } = this.props;

    const filterStandaardCategorieen = (event: any) => {
      this.props.resetField('standaardCategorie');
      this.props.resetField('subCategorie');
      updateHoofdCategorie(event.target.value);
    };

    const filterSubCategorieen = (event: any) => {
      this.props.resetField('subCategorie');
      updateStandaardCategorie(event.target.value);
    };

    const sortByCode = (categorieen: ICategorie[]): ICategorie[] => [...categorieen].sort((a, b) => a.code.localeCompare(b.code));

    const hoofdCategorieProps = formPropsFactory.createSelectProps('hoofdCategorie');
    const standaardCategorieProps = formPropsFactory.createSelectProps('standaardCategorie');
    const tooltipMessages = this.generateTooltipMessages();

    return (
      <>
        <a className="right clickable"
          onClick={this.handleOpenCategorieModal}>{intl.get('materiaal.edit.form.classificatie.zoek')}</a>
        {
          title &&
          <h2 className="vl-h2">{intl.get(title)}</h2>
        }
        <div className="vl-u-spacer--tiny">
          <FormComponent id="hoofdCategorie"
            label={intl.get('materiaal.edit.form.classificatie.fields.hoofdcategorie.label')}
            required={tooltipMessages[ 'required.hoofdcategorie' ]}
            error={fields.hoofdCategorie.errors} block>
            <SelectSearch {...hoofdCategorieProps}
              retainFilteredValues={false}
              onChange={event => {
                hoofdCategorieProps.onChange(event);
                filterStandaardCategorieen(event);
              }}
              options={hoofdCategorieen.map(categorie => ({
                label: categorie.code + ' ' + intl.get(categorie.referentie),
                value: categorie.referentie
              }))}
              search={(options: any[], query: string) => options.filter(opt => opt.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()))}
              error={fields.hoofdCategorie.errors.length > 0}/>
          </FormComponent>
        </div>
        {standaardCategorieen.length !== 0 && <div className="vl-u-spacer--tiny">
          <FormComponent id="standaardCategorie"
            label={intl.get('materiaal.edit.form.classificatie.fields.standaardcategorie.label')}
            required={standaardCategorieen.length > 0 ? tooltipMessages[ 'required.standaardcategorie' ] : ''}
            error={fields.standaardCategorie.errors} block>
            <SelectSearch {...standaardCategorieProps}
              disabled={standaardCategorieen.length === 0}
              retainFilteredValues={false}
              onChange={event => {
                standaardCategorieProps.onChange(event);
                filterSubCategorieen(event);
              }}
              options={sortByCode(standaardCategorieen).map(categorie => ({
                label: categorie.code + ' ' + intl.get(categorie.referentie),
                value: categorie.referentie
              }))}
              search={(options: any[], query: string) => options.filter(opt => opt.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()))}/>
          </FormComponent>
        </div>}
        {subCategorieen.length !== 0 && <div className="vl-u-spacer--tiny">
          <FormComponent id="subCategorie"
            label={intl.get('materiaal.edit.form.classificatie.fields.subcategorie.label')}
            required={subCategorieen.length > 0 ? tooltipMessages[ 'required.subcategorie' ] : ''}
            error={fields.subCategorie.errors} block>
            <SelectSearch {...formPropsFactory.createSelectProps('subCategorie')}
              disabled={subCategorieen.length === 0}
              retainFilteredValues={false}
              options={sortByCode(subCategorieen).map(categorie => ({
                label: categorie.code.slice(0, 4) + ' ' + categorie.code.slice(-2) + ' ' + intl.get(categorie.referentie),
                value: categorie.referentie
              }))}
              search={(options: any[], query: string) => options.filter(opt => opt.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()))}/>
          </FormComponent>
        </div>}
      </>
    );
  }

  private handleOpenCategorieModal = () => {
    this.props.showModal(CATEGORIE_MODAL, {
      cancel: () => this.props.hideModal(CATEGORIE_MODAL),
      title: intl.get('materiaal.edit.form.classificatie.modal.title')
    });
  };
}

const mapDispatchToProps = {
  showModal,
  hideModal
};

export default connect(undefined, mapDispatchToProps)(CategorieForm);
