import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import {
  InfoBlock,
  Loading
} from 'webuniversum-react';
import {
  getInstructiesNieuweGebruiker,
  IGetInstructiesNieuweGebruikerState
} from 'src/reducers/informatieve_website/instructies-nieuwegebruiker';
import { Instructie } from './instructie';
import Breadcrumbs from './breadcrumbs';
import ContactInformatieBlock from './contact-informatie';

export interface INieuweGebruikerProps {
  taal: string;
  getInstructiesNieuweGebruiker: Function;
  instructiesNieuweGebruiker: IGetInstructiesNieuweGebruikerState;
}

export class NieuweGebruiker extends React.Component<INieuweGebruikerProps, undefined> {
  componentDidMount() {
    this.props.getInstructiesNieuweGebruiker(this.props.taal);
  }

  render(): React.ReactNode {
    const start = this.props.instructiesNieuweGebruiker.startInstructie;
    return <>
      <Breadcrumbs breadcrumbs={[
        { title: intl.get('welkomstpagina.titel'), location: '/' },
        { title: intl.get('welkomstpagina.registratie.titel'), location: '/welkomstpagina/registratie' },
        { title: intl.get('welkomstpagina.registratie.geverifieerd.instructies.titel') }
      ]}/>

      <Loading loading={this.props.instructiesNieuweGebruiker.loading}>
        {start && <InfoBlock titel={intl.get('welkomstpagina.registratie.geverifieerd.instructies.titel')} layout="contact">
          <Instructie vraag={start}/>
        </InfoBlock>}
      </Loading>

      <ContactInformatieBlock/>
    </>;
  }
}

const mapStateToProps = storeState => ({
  instructiesNieuweGebruiker: storeState.informatieveWebsite.instructies.nieuweGebruiker,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  getInstructiesNieuweGebruiker
};

export default connect(mapStateToProps, mapDispatchToProps)(NieuweGebruiker);
