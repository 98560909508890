import React from 'react';
import intl from 'react-intl-universal';

export enum Vertrouwelijkheid {
  PRIVAAT = 'PRIVAAT',
  PUBLIEK = 'PUBLIEK'
}

export interface IPrivaatPubliekSwitchProps {
  initialValue: Vertrouwelijkheid;
  onChange: Function;
}

export interface IPrivaatPubliekSwitchState {
  toegang: Vertrouwelijkheid;
  labelKey: string;
  linkKey: string;
  icon: string;
  toggle: () => IPrivaatPubliekSwitchState;
}

export class PrivaatPubliekSwitch extends React.Component<IPrivaatPubliekSwitchProps, IPrivaatPubliekSwitchState> {
  constructor(props: Readonly<IPrivaatPubliekSwitchProps>) {
    super(props);
    this.state = this.stateFromProps(props);
  }

  componentDidUpdate(prevProps: Readonly<IPrivaatPubliekSwitchProps>, prevState: Readonly<IPrivaatPubliekSwitchState>, snapshot?: any) {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState(this.stateFromProps(this.props));
    }
  }

  render(): React.ReactNode {
    const { labelKey, linkKey, icon } = this.state;
    return (
      <>
        <i className={`fas ${icon} relevantie-icon`} aria-hidden="true"/>
        <span className="relevantie-icon">{intl.get(labelKey)}</span>
        <a className="clickable" onClick={this.toggleToegang}>{intl.get(linkKey)}</a>
      </>
    );
  }

  toggleToegang = () => {
    const toggled = this.state.toggle();
    this.setState(toggled);
    this.props.onChange(toggled.toegang);
  };

  stateFromProps = (props: IPrivaatPubliekSwitchProps): IPrivaatPubliekSwitchState => (
    props.initialValue === Vertrouwelijkheid.PUBLIEK ? this.publicState() : this.privateState()
  );

  privateState = (): IPrivaatPubliekSwitchState => ({
    toegang: Vertrouwelijkheid.PRIVAAT,
    labelKey: 'materiaal.edit.form.section.instellen.private.label',
    linkKey: 'materiaal.edit.form.section.instellen.private.link',
    icon: 'fa-lock',
    toggle: this.publicState
  });

  publicState = (): IPrivaatPubliekSwitchState => ({
    toegang: Vertrouwelijkheid.PUBLIEK,
    labelKey: 'materiaal.edit.form.section.instellen.public.label',
    linkKey: 'materiaal.edit.form.section.instellen.public.link',
    icon: 'fa-lock-open',
    toggle: this.privateState
  });
}
