import { combineReducers } from 'redux';
import { conversatieReducer } from './conversatie';
import { conversatiesReducer } from './conversaties';
import { sendBerichtenReducer, sendBerichtForm } from './send';
import { initialModalState, modalReducer } from '../modal/modal';
import { berichtenGelezenReducer } from './berichten-gelezen';
import { aantalOngelezenBerichtenReducer } from './ongelezen-berichten';
import { hulpvraagAfsluitenForm, hulpvraagAfsluitenReducer } from './afsluiten';

export const BERICHT_OVERVIEW = 'bericht-overzicht/conversatie/hulpvraag';
export const CONVERSATIES_SEND_BERICHT = 'conversaties/conversatie/send';

export const bericht = combineReducers({
  berichtenList: conversatiesReducer,
  bericht: conversatieReducer,
  sendBerichtForm,
  hulpvraagAfsluitenForm,
  send: sendBerichtenReducer,
  sluitAf: hulpvraagAfsluitenReducer,
  conversatieBerichtModal: modalReducer(CONVERSATIES_SEND_BERICHT, initialModalState()),
  berichtOverviewModal: modalReducer(BERICHT_OVERVIEW, initialModalState()),
  berichtenGelezen: berichtenGelezenReducer,
  aantalOngelezenBerichten: aantalOngelezenBerichtenReducer
});
