import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { Document } from '@contentful/rich-text-types';

export const ACTION_TYPES = {
  GET_HIGHLIGHTED: 'informatieve-website/faqs/GET_HIGHLIGHTED',
  GET_ALL_PER_THEMA: 'informatieve-website/faqs/GET_ALL_PER_THEMA'
};

export interface IFaq {
  thema: string;
  vraag: string;
  antwoord: Document;
}

export interface IGetFaqsState {
  loading: boolean;
  errorMessage: any;
  faq: IFaq[];
}

export const initialState: IGetFaqsState = {
  loading: false,
  errorMessage: null,
  faq: []
};

export const faqsReducer = (state = initialState, action: AnyAction): IGetFaqsState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET_HIGHLIGHTED):
  case REQUEST(ACTION_TYPES.GET_ALL_PER_THEMA):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.GET_HIGHLIGHTED):
  case FAILURE(ACTION_TYPES.GET_ALL_PER_THEMA):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET_HIGHLIGHTED):
  case SUCCESS(ACTION_TYPES.GET_ALL_PER_THEMA):
    return {
      ...state,
      loading: false,
      faq: action.payload.data.map(faq => ({
        thema: faq.thema,
        vraag: faq.vraag,
        antwoord: faq.antwoord
      }))
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite';

export const getHighlightedFaqs: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET_HIGHLIGHTED,
  payload: axios.get(`${apiUrl}/highlighted-faqs/${taal}`)
});

export const getFaqsPerThema: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET_ALL_PER_THEMA,
  payload: axios.get(`${apiUrl}/faqs-per-thema/${taal}`)
});
