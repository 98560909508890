import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import React from 'react';
import intl from 'react-intl-universal';
import {Link} from 'react-router-dom';
import {Button, Loading} from 'webuniversum-react';
import {connect} from 'react-redux';
import {getMijnTechnologie, ITechnologieList} from 'src/reducers/technologie/list';
import {MateriaalTile} from 'src/modules/materiaal/materiaal-tile';
import {extractUid} from 'src/modules/referentie-uri';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface ITechnologieOverviewProps {
  mijnTechnologie: ITechnologieList;
  changeHeader: Function;
  getMijnTechnologie: Function;
  history: any;
}

export class OverviewTechnologie extends React.Component<ITechnologieOverviewProps, any> {
  componentDidMount() {
    this.props.getMijnTechnologie();
    this.props.changeHeader(
      HeaderType.TAB_NAVIGATION
    );
  }

  render(): React.ReactNode {
    return (
      <>
        <div id="buttonSection" className="vl-u-spacer vl-grid">
          <h2 className="vl-h2 vl-col--6-12">{intl.get('technologie.overview.title')}</h2>
          <div className="vl-col--6-12 vl-grid">
            <div className="vl-col--12-12">
              <Link to="/technologie/nieuw">
                <Button type="button" className="nieuw-button buttons-overview">
                  {intl.get('technologie.overview.buttons.nieuwe-technologie')}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <Loading loading={this.props.mijnTechnologie.loading}>
          {this.props.mijnTechnologie.results.length > 0 ?
            <div className="vl-grid vl-grid--is-stacked vl-u-spacer">
              {this.props.mijnTechnologie.results.map(technologie =>
                <div className="vl-col--4-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs"
                  key={technologie.technologieReferentie}>
                  <MateriaalTile layout="block" type="technologie" data={technologie} onClick={this.navigateToDetail}/>
                </div>)}
            </div>
            :
            <div>
              <h3 className="vl-h3">{intl.get('technologie.overview.no-results.title')}</h3>
              <p className="vl-u-spacer--tiny">{intl.getHTML('technologie.overview.no-results.paragraph-1', { url: '/#/technologie/nieuw' })}</p>
              <p className="vl-u-spacer--tiny">{intl.get('technologie.overview.no-results.paragraph-2')}</p>
            </div>
          }
        </Loading>
      </>
    );
  }

  private navigateToDetail = (technologieReferentie: string) => {
    this.props.history.push(`/technologie/${extractUid('technologie', technologieReferentie)}`);
  };
}

const mapStateToProps = storeState => ({
  mijnTechnologie: storeState.technologie.mijnTechnologie
});

const mapDispatchToProps = {
  changeHeader,
  getMijnTechnologie
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(OverviewTechnologie));
