import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import initStore from './reducers/store';
import setupAxiosInterceptors from './config/axios-interceptor';
import AppComponent from './app';
import { setStore } from './redux-store';
import { registerValidators } from './modules/form-components/validation';
import { logout } from './reducers/authentication';

const store = initStore();
setupAxiosInterceptors(() => store.dispatch(logout()));
setStore(store);

const rootEl = document.getElementById('root');

registerValidators();

ReactDOM.render(
  <Provider store={store}>
    <div className="content">
      <AppComponent />
    </div>
  </Provider>,
  rootEl
);
