import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { MateriaalEenheid } from 'src/modules/materiaal/materiaal-hoeveelheid';
import { GerealiseerdeVerwerkingswijzeSymbiose } from 'src/modules/symbiose/gerealiseerde-verwerkingswijze-symbiose-enum';
import { Duurtijd } from './change-status';

const ACTION_TYPES = {
  CREATE_SYMBIOSE: 'symbiose/CREATE_SYMBIOSE'
};
export { ACTION_TYPES as CREATE_SYMBIOSE_ACTION_TYPES };

export interface ISymbiose {
  symbioseReferentie: string;
  vraagReferentie: string;
  aanbodReferentie: string;
  genodigdeReferentie: string;
  status: string;
  datumStatus: Date;
  meerwaardes: IMeerwaarde[];
  nietRealisatieRedenen: INietRealisatieReden[];
}

export interface IMeerwaarde {
  duurtijd: Duurtijd;
  aantalJaar: number;
  hoeveelheid: number;
  eenheid: MateriaalEenheid;
  gerealiseerdeVerwerkingswijze: GerealiseerdeVerwerkingswijzeSymbiose;
  besparing: number;
  inkomsten: number;
  investeringenNodig: boolean;
  investeringen: number;
  beschrijving: string;
  ondernemingsReferentie: string;
  adviesverleners: [];
  regelgeving: string;
}

export interface INietRealisatieReden {
  ondernemingsReferentie: string;
  kwantiteit: number;
  eenheid: string;
  barriere: string;
  beschrijving: string;
  adviesverleners: [];
}

export interface ISymbioseState {
  loading: boolean;
  errorMessage: any;
  symbiose: ISymbiose;
}

export const initialSymbioseState: ISymbioseState = {
  loading: false,
  errorMessage: null,
  symbiose: {
    symbioseReferentie: '',
    vraagReferentie: '',
    aanbodReferentie: '',
    genodigdeReferentie: '',
    status: '',
    datumStatus: new Date(),
    meerwaardes: [],
    nietRealisatieRedenen: []
  }
};

export const createSymbioseReducer = (state = initialSymbioseState, action: AnyAction): ISymbioseState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.CREATE_SYMBIOSE):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.CREATE_SYMBIOSE):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.CREATE_SYMBIOSE):
    return {
      ...state,
      loading: false,
      symbiose: action.payload.data
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/symbioses/';

// Actions
export const createSymbiose: ActionCreator<any> = (symbiose: any) => ({
  type: ACTION_TYPES.CREATE_SYMBIOSE,
  payload: axios.post(apiUrl, symbiose)
});
