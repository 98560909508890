import {IApplication} from 'src/reducers/application';
import {IModalContainerProps} from 'src/reducers/modal/modal';
import {
  DOCUMENTEER_MEERWAARDE_FORM,
  Duurtijd,
  IDocumenteerMeerwaardeForm,
  initialDocumenteerMeerwaardeFormState,
  symbioseDocumenteerMeerwaarde
} from 'src/reducers/symbiose/change-status';
import {getStore} from 'src/redux-store';
import cloneDeep from 'lodash/cloneDeep';
import xor from 'lodash/xor';
import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {
  createValidateFunction,
  formEdit,
  FormFields,
  FormPropsFactoryBuilder,
  formState,
  formValidate,
  IFormActions,
  IFormState,
  initialFormState,
  ValidationMethod
} from 'redux-form-library';
import {Button, ButtonGroup, Checkbox, FormComponent, InputField, Modal, ModalButtons, ModalContent, ModalTitle, Radiobutton, Select, Textarea} from 'webuniversum-react';
import {componentOffset} from 'src/modules/form-components/componentOffset';
import {enumOptions} from 'src/modules/form-components/translatable-select-options';
import {MateriaalEenheid} from 'src/modules/materiaal/materiaal-hoeveelheid';
import {Adviesverleners} from 'src/modules/symbiose/adviesverleners';
import {GerealiseerdeVerwerkingswijzeSymbiose} from 'src/modules/symbiose/gerealiseerde-verwerkingswijze-symbiose-enum';
import {Regelgeving, translateRegelgeving} from 'src/modules/symbiose/regelgeving';

export interface IDocumenteerMeerwaardeModalProps extends IFormActions<IDocumenteerMeerwaardeForm> {
  symbioseDocumenteerMeerwaarde: Function;
  symbioseReferentie: string;
  modalProps: IModalContainerProps;
  dispatch: Dispatch;
  form: IFormState<IDocumenteerMeerwaardeForm>;
  applicationSettings: IApplication;
}

export class MeerwaardeModal extends React.Component<IDocumenteerMeerwaardeModalProps> {

  createValidationRules = (duurtijd, investeringenNodig) => ({
    duurtijd: 'required',
    aantalJaar: duurtijd === 'TIJDELIJK' ? 'required|numeric|min:0.1|maxScale:2|max:99999.99' : 'numeric|min:0.1|maxScale:2|max:99999.99',
    hoeveelheid: 'required|numeric|min:0.001|max:9999999999999.999|maxScale:3',
    eenheid: 'required',
    gerealiseerdeVerwerkingswijze: 'required|string',
    besparing: 'numeric|max:999999999.99|maxScale:2',
    inkomsten: 'numeric|max:999999999.99|maxScale:2',
    investeringenNodig: 'required',
    investeringen: investeringenNodig ? 'required|numeric|max:999999999.99|maxScale:2' : 'numeric|max:999999999.99|maxScale:2',
    beschrijving: 'required|max:4000',
    regelgeving: 'required'
  });

  generateErrorMessages = () => ({
    'required.duurtijd': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.gerealiseerd-modal.duurtijd.label')
    }),
    'required.aantalJaar': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.gerealiseerd-modal.aantal-jaar.label')
    }),
    'max.aantalJaar': intl.get('validation.form.field.numeric.max', {
      NAME: intl.get('symbiose.gerealiseerd-modal.aantal-jaar.label'),
      MAX: '10.000'
    }),

    'maxScale.aantalJaar': intl.get('validation.form.field.numeric.maxscale', {
      NAME: intl.get('symbiose.gerealiseerd-modal.aantal-jaar.label'),
      MAXSCALE: '2'
    }),

    'required.hoeveelheid': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.gerealiseerd-modal.hoeveelheid.label')
    }),
    'min.hoeveelheid': intl.get('validation.form.field.numeric.min', {
      NAME: intl.get('symbiose.gerealiseerd-modal.hoeveelheid.label'),
      MIN: '0,001'
    }),
    'max.hoeveelheid': intl.get('validation.form.field.numeric.max', {
      NAME: intl.get('symbiose.gerealiseerd-modal.hoeveelheid.label'),
      MAX: `1 ${intl.get('validation.form.field.numeric.values.trillion')}`
    }),
    'maxScale.hoeveelheid': intl.get('validation.form.field.numeric.maxscale', {
      NAME: intl.get('symbiose.gerealiseerd-modal.hoeveelheid.label'),
      MAXSCALE: '3'
    }),
    'required.eenheid': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.gerealiseerd-modal.eenheid.label')
    }),
    'required.gerealiseerdeVerwerkingswijze': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.gerealiseerd-modal.gerealiseerde-verwerkingswijze.label')
    }),
    'maxScale.besparing': intl.get('validation.form.field.numeric.maxscale', {
      NAME: intl.get('symbiose.gerealiseerd-modal.besparing.label'),
      MAXSCALE: '2'
    }),
    'max.besparing': intl.get('validation.form.field.numeric.max', {
      NAME: intl.get('symbiose.gerealiseerd-modal.besparing.label'),
      MAX: `1 ${intl.get('validation.form.field.numeric.values.billion')}`
    }),
    'maxScale.inkomsten': intl.get('validation.form.field.numeric.maxscale', {
      NAME: intl.get('symbiose.gerealiseerd-modal.inkomsten.label'),
      MAXSCALE: '2'
    }),
    'max.inkomsten': intl.get('validation.form.field.numeric.max', {
      NAME: intl.get('symbiose.gerealiseerd-modal.inkomsten.label'),
      MAX: `1 ${intl.get('validation.form.field.numeric.values.billion')}`
    }),
    'required.investeringen': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.gerealiseerd-modal.investeringen.label')
    }),
    'maxScale.investeringen': intl.get('validation.form.field.numeric.maxscale', {
      NAME: intl.get('symbiose.gerealiseerd-modal.investeringen.label'),
      MAXSCALE: '2'
    }),
    'max.investeringen': intl.get('validation.form.field.numeric.max', {
      NAME: intl.get('symbiose.gerealiseerd-modal.investeringen.label'),
      MAX: `1 ${intl.get('validation.form.field.numeric.values.billion')}`
    }),
    'required.beschrijving': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.gerealiseerd-modal.beschrijving.label')
    }),
    'max.beschrijving': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('symbiose.gerealiseerd-modal.beschrijving.label'),
      MAX: '4000'
    }),
    'required.regelgeving': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.documenteer-meerwaarde-modal.regelgeving.field-name')
    })
  });

  private readonly myrefs: {
    duurtijd: any;
    aantalJaar: any;
    hoeveelheid: any;
    eenheid: any;
    gerealiseerdeVerwerkingswijze: any;
    besparing: any;
    inkomsten: any;
    investeringenNodig: any;
    investeringen: any;
    beschrijving: any;
    adviesverleners: any;
    regelgeving: any;
  };

  constructor(props: IDocumenteerMeerwaardeModalProps) {
    super(props);
    this.myrefs = {
      duurtijd: React.createRef(),
      aantalJaar: React.createRef(),
      hoeveelheid: React.createRef(),
      eenheid: React.createRef(),
      gerealiseerdeVerwerkingswijze: React.createRef(),
      besparing: React.createRef(),
      inkomsten: React.createRef(),
      investeringenNodig: React.createRef(),
      investeringen: React.createRef(),
      beschrijving: React.createRef(),
      adviesverleners: React.createRef(),
      regelgeving: React.createRef()
    };
  }

  render(): React.ReactNode {
    const { visible, submitText, cancelText, title } = this.props.modalProps;
    const fields = this.props.form.fields;
    const errorMessages = this.generateErrorMessages();
    const formPropsFactory = new FormPropsFactoryBuilder()
      .setFieldsObject(this.props.form.fields)
      .setDispatch(this.props.dispatch)
      .setValidate(createValidateFunction(this.createValidationRules(fields.duurtijd.value, fields.investeringenNodig.value), errorMessages))
      .setSelector(formSelector)
      .setStore(getStore())
      .setFormName(DOCUMENTEER_MEERWAARDE_FORM)
      .build();
    const settings = this.props.applicationSettings;

    return (
      <Modal hidden={!visible} overlayHidden={!visible} style={{ width: '90rem', height: '85%' }} onClose={this.cancel}>
        <ModalTitle>{title}</ModalTitle>
        <form onSubmit={this.submit}>
          <ModalContent>
            <p
              className="public-text vl-u-spacer--small">{intl.getHTML('symbiose.documenteer-meerwaarde-modal.introtekst')}</p>
            <div id="meerwaarde-formSection">
              <div ref={this.myrefs.duurtijd}>
                <FormComponent id="meerwaarde-duurtijd"
                  label={intl.get('symbiose.gerealiseerd-modal.duurtijd.label')}
                  error={fields.duurtijd.errors}
                  required={errorMessages[ 'required.duurtijd' ]}
                  block>
                  <Select {...formPropsFactory.createInputProps('duurtijd')} block>
                    {enumOptions(Duurtijd, 'symbiose.gerealiseerd-modal.duurtijd.values')
                      .optionsWithPlaceholder('symbiose.gerealiseerd-modal.duurtijd.label')}
                  </Select>
                </FormComponent>
              </div>

              {fields.duurtijd.value === 'TIJDELIJK' &&
              <div ref={this.myrefs.aantalJaar}>
                <FormComponent id="meerwaarde-aantalJaar"
                  label={intl.get('symbiose.gerealiseerd-modal.aantal-jaar.label')}
                  error={fields.aantalJaar.errors}
                  required={fields.duurtijd.value === 'TIJDELIJK' ? errorMessages[ 'required.aantalJaar' ] : ''}
                  block>
                  <InputField type="number"
                    step=".01"
                    layout="block" {...formPropsFactory.createInputProps('aantalJaar')}/>
                </FormComponent>
              </div>
              }

              <div ref={this.myrefs.hoeveelheid}>
                <FormComponent id="meerwaarde-hoeveelheid"
                  label={(fields.duurtijd.value === 'TIJDELIJK' || fields.duurtijd.value === 'ONBEPAALDE_DUUR') ?
                    intl.get('symbiose.gerealiseerd-modal.hoeveelheid.per-jaar.label') :
                    intl.get('symbiose.gerealiseerd-modal.hoeveelheid.label')}
                  error={fields.hoeveelheid.errors}
                  required={errorMessages[ 'required.hoeveelheid' ]}
                  block>
                  <InputField type="number" step=".001"
                    layout="block" {...formPropsFactory.createInputProps('hoeveelheid')}/>
                </FormComponent>
              </div>

              <div ref={this.myrefs.eenheid}>
                <FormComponent id="meerwaarde-eenheid" label={intl.get('symbiose.gerealiseerd-modal.eenheid.label')}
                  required={errorMessages[ 'required.eenheid' ]}
                  error={fields.eenheid.errors} block>
                  <Select block {...formPropsFactory.createSelectProps('eenheid')}>
                    {enumOptions(MateriaalEenheid, 'symbiose.gerealiseerd-modal.eenheid.values')
                      .optionsWithPlaceholder('symbiose.gerealiseerd-modal.eenheid.label')}
                  </Select>
                </FormComponent>
              </div>

              <div ref={this.myrefs.gerealiseerdeVerwerkingswijze}>
                <FormComponent id="meerwaarde-gerealiseerdeVerwerkingswijze"
                  label={intl.get('symbiose.gerealiseerd-modal.gerealiseerde-verwerkingswijze.label')}
                  required={errorMessages[ 'required.gerealiseerdeVerwerkingswijze' ]}
                  error={fields.gerealiseerdeVerwerkingswijze.errors} block>
                  <Select {...formPropsFactory.createInputProps('gerealiseerdeVerwerkingswijze')} block>
                    {enumOptions(GerealiseerdeVerwerkingswijzeSymbiose, 'symbiose.gerealiseerd-modal.gerealiseerde-verwerkingswijze.values')
                      .optionsWithPlaceholder('symbiose.gerealiseerd-modal.gerealiseerde-verwerkingswijze.label')}
                  </Select>
                </FormComponent>
              </div>

              <div className="vl-u-spacer--small"/>
              <h2 className="vl-h5">{intl.get('symbiose.gerealiseerd-modal.mijn-onderneming.subtitle')}</h2>

              <div ref={this.myrefs.besparing}>
                <FormComponent id="meerwaarde-besparing"
                  label={intl.get('symbiose.gerealiseerd-modal.besparing.label')}
                  error={fields.besparing.errors}
                  block>
                  <InputField type="number" step="0.01"
                    layout="block" {...formPropsFactory.createInputProps('besparing')}
                    placeholder="0,00"/>
                </FormComponent>
              </div>

              <div ref={this.myrefs.inkomsten}>
                <FormComponent id="meerwaarde-inkomsten"
                  label={intl.get('symbiose.gerealiseerd-modal.inkomsten.label')}
                  error={fields.inkomsten.errors}
                  block>
                  <InputField type="number" step="0.01"
                    layout="block" {...formPropsFactory.createInputProps('inkomsten')}
                    placeholder="0,00"/>
                </FormComponent>
              </div>

              <div ref={this.myrefs.investeringenNodig}>
                <Checkbox {...formPropsFactory.createCheckboxProps('investeringenNodig')}>
                  {intl.get('symbiose.gerealiseerd-modal.investeringen-nodig.label')}
                </Checkbox>
              </div>

              {fields.investeringenNodig.value &&
              <div ref={this.myrefs.investeringen}>
                <FormComponent id="meerwaarde-investeringen"
                  label={intl.get('symbiose.gerealiseerd-modal.investeringen.label')}
                  error={fields.investeringen.errors}
                  required={fields.investeringenNodig.value ? errorMessages[ 'required.investeringen' ] : ''}
                  block>
                  <InputField type="number" step="0.01"
                    layout="block" {...formPropsFactory.createInputProps('investeringen')}
                    placeholder="0,00"/>
                </FormComponent>
              </div>
              }

              <div ref={this.myrefs.beschrijving}>
                <FormComponent id="meerwaarde-beschrijving"
                  error={fields.beschrijving.errors}
                  required={errorMessages[ 'required.beschrijving' ]}
                  label={intl.get('symbiose.gerealiseerd-modal.beschrijving.label')}
                  block>
                  <Textarea {...formPropsFactory.createInputProps('beschrijving')}
                    style={{ width: '100%', height: '100px' }}
                    placeholder={intl.get('symbiose.gerealiseerd-modal.beschrijving.placeholder')}/>
                </FormComponent>
              </div>
              <div ref={this.myrefs.adviesverleners}>
                <FormComponent label={intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.label')}
                  id="meerwaarde-adviesverleners"
                  error={fields.adviesverleners.errors}
                  block>
                  <div className="vl-grid">
                    <div className="vl-col--4-12">
                      <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.SYMBIOSE_TEAM)}
                        checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.SYMBIOSE_TEAM)}
                        block>
                        {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.symbiose_team')}
                      </Checkbox>
                    </div>
                    <div className="vl-col--4-12">
                      <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.KENNISCENTRUM)}
                        checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.KENNISCENTRUM)}
                        block>
                        {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.kenniscentrum')}
                      </Checkbox>
                    </div>
                    <div className="vl-col--4-12">
                      <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.MILIEUPROFESSIONAL)}
                        checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.MILIEUPROFESSIONAL)}
                        block>
                        {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.milieuprofessional')}
                      </Checkbox>
                    </div>
                    <div className="vl-col--4-12">
                      <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.ADVOCAAT)}
                        checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.ADVOCAAT)}
                        block>
                        {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.advocaat')}
                      </Checkbox>
                    </div>
                    <div className="vl-col--4-12">
                      <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.ANDERE)}
                        checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.ANDERE)}
                        block>
                        {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.andere')}
                      </Checkbox>
                    </div>
                  </div>
                </FormComponent>
              </div>
              <div ref={this.myrefs.regelgeving}>
                <FormComponent label={intl.get('symbiose.documenteer-meerwaarde-modal.regelgeving.label')}
                  id="meerwaarde-regelgeving"
                  error={fields.regelgeving.errors}
                  required={errorMessages[ 'required.regelgeving' ]}
                  block>
                  <Radiobutton name="regelgeving"
                    block {...formPropsFactory.createRadiobuttonProps('regelgeving', Regelgeving.ZELFTOETSING)}>
                    {intl.getHTML(translateRegelgeving(Regelgeving.ZELFTOETSING), { url: settings.documentUrls.checklistVoorRegelgeving[ settings.general.taal ] })}
                  </Radiobutton>
                  <Radiobutton name="regelgeving"
                    block {...formPropsFactory.createRadiobuttonProps('regelgeving', Regelgeving.GRONDSTOFVERKLARING)}>
                    {intl.getHTML(translateRegelgeving(Regelgeving.GRONDSTOFVERKLARING), { url: 'https://ovam.vlaanderen.be/grondstofverklaringen-wanneer-en-hoe-' })}
                  </Radiobutton>
                  <p>{intl.get('symbiose.documenteer-meerwaarde-modal.regelgeving.onduidelijkheid')}</p>
                </FormComponent>
              </div>
            </div>
          </ModalContent>
          <ModalButtons>
            <ButtonGroup>
              <Button type="submit" disabled={!this.props.form.dirty}>
                {submitText}
              </Button>
              <Button type="button" color="secondary" onClick={this.cancel}>
                {cancelText}
              </Button>
            </ButtonGroup>
          </ModalButtons>
        </form>
      </Modal>
    );
  }

  private selectAdviesverlener = value => () => {
    const form = cloneDeep(this.props.form);
    form.fields.adviesverleners.value = xor(form.fields.adviesverleners.value, [ value ]);
    form.fields.adviesverleners.dirty = true;
    form.dirty = true;
    this.props.formState(form);
  };

  private cancel = () => {
    this.props.formState(initialFormState(initialDocumenteerMeerwaardeFormState()));
    this.props.modalProps.cancel();
  };

  private submit = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.formValidate(
      createValidateFunction(this.createValidationRules(this.props.form.fields.duurtijd.value, this.props.form.fields.investeringenNodig.value),
        this.generateErrorMessages()),
      ValidationMethod.ValidateAll, formSelector(getStore().getState())
    );
    const state: IFormState<IDocumenteerMeerwaardeForm> = formSelector(getStore().getState());
    const formFields: FormFields<IDocumenteerMeerwaardeForm> = state.fields;
    if (state.valid) {
      this.props.modalProps.submit();
    } else {
      const ref = componentOffset(formFields, this.myrefs, true);
      this.myrefs[ ref ].current.scrollIntoView();
    }
  };
}

const formSelector = storeState => storeState.symbiose.documenteerMeerwaardeForm;

const mapDocumenteerMeerwaardeStateToProps = storeState => ({
  form: formSelector(storeState),
  modalProps: storeState.symbiose.documenteerMeerwaardeModal,
  applicationSettings: storeState.application.application
});

const mapDispatchToProps = dispatch => {
  const actions = {
    symbioseDocumenteerMeerwaarde,
    formState: formState(DOCUMENTEER_MEERWAARDE_FORM),
    formValidate: formValidate<IDocumenteerMeerwaardeForm>(DOCUMENTEER_MEERWAARDE_FORM),
    formEdit: formEdit(DOCUMENTEER_MEERWAARDE_FORM)
  };
  return { ...bindActionCreators(actions, dispatch), dispatch };
};

export const DocumenteerMeerwaardeModal = connect(mapDocumenteerMeerwaardeStateToProps, mapDispatchToProps)(MeerwaardeModal);
