export const RESET_STATE = 'RESET_STATE';

export const resetState = () => ({
  type: RESET_STATE
});

export interface IPageState {
  loading: boolean;
  errorMessage: string;
}
