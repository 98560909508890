import React from 'react';
import { IEural } from '../eural/eural-code';
import intl from 'react-intl-universal';
import { getEuralCodeByCode, getEuralCodeById, isEuralDataBeschikbaar } from '../eural/eural-filter';

export interface IDetailEuralTreeProps {
  eural: string;
}

export class DetailEuralTree extends React.Component<IDetailEuralTreeProps> {

  static getEuralCodeLabel(code: IEural) {
    return code.altLabel[intl.getInitOptions().currentLocale];
  }

  render(): React.ReactNode {
    if (!isEuralDataBeschikbaar()) {
      return null;
    }

    let euralCode: IEural = getEuralCodeByCode(this.props.eural);
    if (!euralCode) {
      return null;
    }

    const beschrijvingen: React.ReactNode[] = [];
    beschrijvingen.push(
      <p key={euralCode.notation}>
        {euralCode.notation + (euralCode.hazardous ? '*' : '') + ' - ' + DetailEuralTree.getEuralCodeLabel(euralCode)}
      </p>);

    while (euralCode.broader) {
      euralCode = getEuralCodeById(euralCode.broader);
      beschrijvingen.push(<p key={euralCode.notation}>
        {euralCode.notation} - {DetailEuralTree.getEuralCodeLabel(euralCode)}
      </p>);
    }

    return <>{beschrijvingen.reverse()}</>;
  }

}
