export enum VerplaatsingOptie {
  GEEN = 'GEEN',
  TER_PLAATSE = 'TER_PLAATSE',
  OP_VERPLAATSING = 'OP_VERPLAATSING',
  BEIDEN_MOGELIJK = 'BEIDEN_MOGELIJK'
}

export const isTerPlaatse = (optie: string): boolean => optie === VerplaatsingOptie.TER_PLAATSE || optie === VerplaatsingOptie.BEIDEN_MOGELIJK;

export const isVerplaatsbaar = (optie: string): boolean => optie === VerplaatsingOptie.OP_VERPLAATSING || optie === VerplaatsingOptie.BEIDEN_MOGELIJK;

export const toVerplaatsingOptie = (terPlaatse: boolean, verplaatsbaar: boolean): VerplaatsingOptie => terPlaatse ?
  (verplaatsbaar ? VerplaatsingOptie.BEIDEN_MOGELIJK : VerplaatsingOptie.TER_PLAATSE) :
  (verplaatsbaar ? VerplaatsingOptie.OP_VERPLAATSING : VerplaatsingOptie.GEEN);
