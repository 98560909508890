import React from 'react';
import {MateriaalTile} from 'src/modules/materiaal/materiaal-tile';
import intl from 'react-intl-universal';

export type DetailSymbioseProductAlignment = 'left' | 'right';

export interface IDetailSymbioseHeaderProps {
  aanbod: any;
  aanbod_alignment: DetailSymbioseProductAlignment;
  vraag: any;
  vraag_alignment: DetailSymbioseProductAlignment;
}

export class DetailSymbioseHeader extends React.Component<IDetailSymbioseHeaderProps> {
  render(): React.ReactNode {
    const { aanbod, aanbod_alignment, vraag, vraag_alignment } = this.props;
    return (
      <>
        <div className="vl-page">
          <div className={`vl-col--6-12 ${aanbod_alignment}`}>
            <div className="detail-symbiose-materiaal">
              <h1 className="vl-s-h3">{aanbod_alignment === 'left' ? intl.get('symbiose.detail.aanbod.mijn-aanbod') : intl.get('symbiose.detail.vraag.mijn-aanbod')}</h1>
              <MateriaalTile layout="block" type="aanbod" data={aanbod}/>
            </div>
          </div>
          <div className={`vl-col--6-12 ${vraag_alignment}`}>
            <div className="detail-symbiose-materiaal">
              <h1 className="vl-s-h3">{vraag_alignment === 'left' ? intl.get('symbiose.detail.vraag.mijn-vraag') : intl.get('symbiose.detail.aanbod.mijn-vraag')}</h1>
              <MateriaalTile layout="block" type="vraag" data={vraag}/>
            </div>
          </div>
        </div>
      </>
    );
  }
}
