import React from 'react';
import intl from 'react-intl-universal';
import './text-tile.css';
import { Pill } from 'webuniversum-react';

export interface IActieStatusPillProps {
  actieStatus: string;
}

export class ActieStatusPill extends React.Component<IActieStatusPillProps> {
  render(): React.ReactNode {
    const { actieStatus } = this.props;

    return (
      <Pill type="pill" className={`pill--actie-status-${actieStatus.toLowerCase()}`}>{intl.get(`bericht.open-vraag.status.values.${actieStatus}`)}</Pill>
    );
  }
}
