import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {CellGenerator, DataTable, IColumns, Loading, SortDirection} from 'webuniversum-react';
import {isSymbioseTeam} from 'src/has-permission';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {IAuthenticationState} from 'src/reducers/authentication';
import {CONVERSATIES_SEND_BERICHT} from 'src/reducers/bericht';
import {getConversatie, IConversatie} from 'src/reducers/bericht/conversatie';
import {getAfgeslotenConversaties, IGetConversatiesState} from 'src/reducers/bericht/conversaties';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {extractUid} from 'src/modules/referentie-uri';
import {ActieStatusCellGenerator} from 'src/modules/table-components/actie-status-cell-generator';
import {DateCellGenerator} from 'src/modules/table-components/date-cell-generator';
import {PartijenCellGenerator} from 'src/modules/table-components/partijen-cell-generator';
import {TitelSubtitelCellGenerator} from 'src/modules/table-components/titel-subtitel-cell-generator';
import {BerichtOverviewHulpvraagModal, ConversatieSendBerichtModal} from 'src/modules/bericht/bericht-modal';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IOverviewAfgeslotenConversatiesProps extends RouteComponentProps {
  authentication: IAuthenticationState;
  getAfgeslotenConversaties: Function;
  queryResult: IGetConversatiesState;
  getConversatie: Function;
  showModal: Function;
  hideModal: Function;
  changeHeader: Function;
}

export interface IRow {
  referentie: string;
  titel: string;
  subTitel: string;
  titelType: string;
  type: string;
  actieStatus: string;
  gesprekPartners: string[];
  timestamp: string;
  bericht: string;
  afsluitOnderwerp: string;
  afsluitOpmerkingen: string;
}

export interface ICoversatieOverviewState {
  data: IRow[];
  sort: {
    actieStatus: SortDirection;
    gesprekPartners: SortDirection;
    timestamp: SortDirection;
  };
}

export class OverviewAfgeslotenConversaties extends React.Component<IOverviewAfgeslotenConversatiesProps, ICoversatieOverviewState> {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sort: this.getCleanSortState()
    };
  }

  private mapConversatieToRow(conversatie: IConversatie): IRow {
    const bericht = conversatie.berichten[ conversatie.berichten.length - 1 ];
    return {
      referentie: conversatie.referentie,
      titel: conversatie.titel,
      subTitel: conversatie.subTitel,
      titelType: conversatie.titelType,
      type: conversatie.type,
      actieStatus: conversatie.actieStatus,
      gesprekPartners: conversatie.ondernemingen
        .filter(onderneming => onderneming.referentie !== this.props.authentication.contactPersoon.onderneming.referentie)
        .map(onderneming => onderneming.naam),
      timestamp: bericht.timestamp,
      bericht: bericht.type === 'GEBRUIKERS_BERICHT' ? bericht.inhoud : intl.get(bericht.inhoud),
      afsluitOnderwerp: intl.get('bericht.sluit-af.form.fields.onderwerp.values.' + conversatie.afsluitOnderwerp.toLowerCase()),
      afsluitOpmerkingen: conversatie.afsluitOpmerkingen
    };
  }

  componentDidMount(): void {
    this.changeHeader();
    this.refresh();
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  private changeHeader() {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'bericht.overview.afgesloten-conversaties.header.back',
      '',
      [
        {
          title: 'bericht.overview.afgesloten-conversaties.header.gesloten-hulpvragen',
          isActive: true
        }
      ],
      () => {
        this.props.history.push('/berichten/overzicht');
      }
    );
  }

  handleOpenModal = (conversatieReferentie: string) => {
    this.props.getConversatie(extractUid('conversatie', conversatieReferentie));
    this.props.showModal(CONVERSATIES_SEND_BERICHT, {
      cancel: () => {
        this.props.hideModal(CONVERSATIES_SEND_BERICHT);
        this.refresh();
      }
    });
  };

  private refresh() {
    this.props.getAfgeslotenConversaties()
      .then(() => this.changeSort('descending', 'timestamp', 'timestamp'));
  }

  getCleanSortState = (): {
    actieStatus: SortDirection;
    gesprekPartners: SortDirection;
    timestamp: SortDirection;
  } => ({
    actieStatus: 'none',
    gesprekPartners: 'none',
    timestamp: 'none'
  });

  private changeSort = (direction, column, datatype?) => {
    this.setState((prevState, props) => {
      const newSortState = this.getCleanSortState();
      newSortState[ column ] = direction;

      const newData = props.queryResult.conversaties
        .map(conversatie => this.mapConversatieToRow(conversatie))
        .sort((a, b) => OverviewAfgeslotenConversaties.sort(a[ column ], b[ column ], datatype));

      return {
        data: direction === 'ascending' ? newData : direction === 'descending' ? newData.reverse() : props.queryResult.conversaties
          .map(conversatie => this.mapConversatieToRow(conversatie)),
        sort: newSortState
      };
    });
  };

  private static sort(a, b, datatype?) {
    if (!a) {
      return -1;
    }

    if (!b) {
      return +1;
    }
    if (datatype === 'timestamp') {
      return new Date(a).getTime() - new Date(b).getTime();
    }
    if (datatype === 'array') {
      return OverviewAfgeslotenConversaties.sort(a[ 0 ], b[ 0 ]);
    }

    return a.localeCompare(b);
  }

  render(): React.ReactNode {
    const columnDefinition: IColumns<IRow>[] = [
      {
        cellObject: new TitelSubtitelCellGenerator(), header: {
          sortable: false,
          title: intl.get('bericht.overview.table.header.aanbod-vraag')
        }
      },
      {
        cellObject: new PartijenCellGenerator(), header: {
          sortable: true,
          sortDirection: this.state.sort.gesprekPartners,
          onSortChange: direction => {
            this.changeSort(direction, 'gesprekPartners', 'array');
          },
          title: intl.get('bericht.overview.table.header.gesprekspartner')
        }
      },
      ...(isSymbioseTeam() ?
        [
          {
            cellObject: new ActieStatusCellGenerator(), header: {
              sortable: true,
              sortDirection: this.state.sort.actieStatus,
              onSortChange: direction => {
                this.changeSort(direction, 'actieStatus');
              },
              title: intl.get('bericht.overview.table.header.status')
            }
          }
        ] :
        []
      ),
      {
        cellObject: new DateCellGenerator('timestamp'), header: {
          sortable: true,
          sortDirection: this.state.sort.timestamp,
          onSortChange: direction => {
            this.changeSort(direction, 'timestamp', 'timestamp');
          },
          title: intl.get('bericht.overview.table.header.datum')
        }
      },
      {
        cellObject: new CellGenerator('afsluitOnderwerp'), header: {
          sortable: false,
          title: intl.get('bericht.overview.table.header.onderwerp')
        }
      },
      {
        cellObject: new CellGenerator('afsluitOpmerkingen'), header: {
          sortable: false,
          title: intl.get('bericht.overview.table.header.opmerkingen')
        }
      }
    ];

    return (
      <>
        <div id="buttonSection" className="vl-u-spacer vl-grid">
          <h2 className="vl-h2 vl-col--6-12">{intl.get('bericht.overview.afgesloten-conversaties.header.gesloten-hulpvragen')}</h2>
        </div>
        <Loading loading={this.props.queryResult.loading && this.props.queryResult.conversaties.length > 0}>
          <DataTable data={this.state.data}
            columns={columnDefinition}
            rowIdAccessor="referentie"
            onRowClick={conversatie => this.handleOpenModal(conversatie)}/>
        </Loading>
        <ConversatieSendBerichtModal/>
        <BerichtOverviewHulpvraagModal/>
      </>
    );
  }
}

const mapStateToProps = storeState => ({
  queryResult: storeState.bericht.berichtenList,
  authentication: storeState.authentication
});

const mapDispatchToProps = {
  getAfgeslotenConversaties,
  getConversatie,
  showModal,
  hideModal,
  changeHeader
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(OverviewAfgeslotenConversaties));
