import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import flatMap from 'lodash/flatMap';

export const ACTION_TYPES = {
  FETCH_CATEGORIEEN: 'categorie/FETCH_CATEGORIEEN',
  RESET_CATEGORIEEN: 'categorie/RESET_CATEGORIEEN',
  UPDATE_HOOFDCATEGORIE: 'categorie/UPDATE_HOOFDCATEGORIE',
  UPDATE_STANDAARDCATEGORIE: 'categorie/UPDATE_STANDAARDCATEGORIE'
};

export interface ICategorie {
  code: string;
  referentie: string;
  childcategorieen: ICategorie[];
}

export interface ICategorieenMap {
  loading: boolean;
  errorMessage: any;
  hoofdCategorieen: ICategorie[];
  standaardCategorieen: ICategorie[];
  subCategorieen: ICategorie[];
  code: string;
}

const initialState: ICategorieenMap = {
  loading: false,
  errorMessage: null,
  hoofdCategorieen: [],
  standaardCategorieen: [],
  subCategorieen: [],
  code: ''
};

export const categorieenListReducer = (state = initialState, action: AnyAction): ICategorieenMap => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_CATEGORIEEN):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_CATEGORIEEN):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_CATEGORIEEN):
    return {
      ...state,
      loading: false,
      hoofdCategorieen: action.payload.data,
      standaardCategorieen: [],
      subCategorieen: []
    };
  case ACTION_TYPES.RESET_CATEGORIEEN:
    return {
      ...state,
      loading: false,
      standaardCategorieen: [],
      subCategorieen: []
    };
  case ACTION_TYPES.UPDATE_HOOFDCATEGORIE:
    return {
      ...state,
      loading: false,
      standaardCategorieen: flatMap(filterCategorie(state.hoofdCategorieen, action.payload).map(hoofdCategorie => hoofdCategorie.childCategories)),
      subCategorieen: []
    };
  case ACTION_TYPES.UPDATE_STANDAARDCATEGORIE:
    return {
      ...state,
      loading: false,
      subCategorieen: flatMap(filterCategorie(state.standaardCategorieen, action.payload).map(standaardCategorie => standaardCategorie.childCategories))
    };
  default:
    return state;
  }
};

const filterCategorie = (categorieen, selectedCategorie: string) => categorieen.filter(categorie => categorie.referentie === selectedCategorie);

const apiUrl = '/ui/categorieen/materiaal';

// Actions
export const getCategorieen: ActionCreator<any> = () => ({
  type: ACTION_TYPES.FETCH_CATEGORIEEN,
  payload: axios.get(`${apiUrl}`)
});

export const resetCategorieen: ActionCreator<any> = () => ({
  type: ACTION_TYPES.RESET_CATEGORIEEN
});

export const updateHoofdCategorie: ActionCreator<any> = (hoofdCategorie: string) => ({
  type: ACTION_TYPES.UPDATE_HOOFDCATEGORIE,
  payload: hoofdCategorie
});

export const updateStandaardCategorie: ActionCreator<any> = (standaardCategorie: string) => ({
  type: ACTION_TYPES.UPDATE_STANDAARDCATEGORIE,
  payload: standaardCategorie
});
