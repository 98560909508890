import React, { PropsWithChildren } from 'react';
import intl from 'react-intl-universal';
import { Badge } from 'webuniversum-react';

export interface IDetailHeaderProps extends PropsWithChildren<any> {
  title_translate_key: string;
  beschrijving: string;
  img_src: string;
}

export class DetailHeader extends React.Component<IDetailHeaderProps> {

  render(): React.ReactNode {
    const { title_translate_key, beschrijving, img_src, children } = this.props;

    return (
      <header className="vl-grid">
        <div className="vl-col--1-12">
          <Badge size="l" className="detail-header-titel-image">
            <img className="vl-badge__img" src={img_src} alt={intl.get(title_translate_key)} />
          </Badge>
        </div>
        <div className="vl-col--10-12" style={{ marginLeft: '1rem' }}>
          <h1 className="vl-h1 vl-u-spacer--small detail-header-titel-text">
            {`${intl.get(title_translate_key)}: ${beschrijving}`}</h1>
          {children}
        </div>
      </header>
    );
  }
}
