import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';

export const ACTION_TYPES = {
  GET: 'algemene-voorwaarden/GET'
};

export interface IGet {
  loading: boolean;
  errorMessage: any;
  pdf: any;
}

export const initialState: IGet = {
  loading: false,
  errorMessage: null,
  pdf: null
};

export const getAlgemeneVoorwaardenReducer = (state = initialState, action: AnyAction): IGet => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.GET):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET):
    return {
      ...state,
      loading: false,
      pdf: action.payload.data
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite/algemenevoorwaarden/';

export const getAlgemeneVoorwaarden: ActionCreator<any> = (taal: string) => dispatch =>
  dispatch({
    type: ACTION_TYPES.GET,
    payload: axios.get(`${apiUrl}${taal}`)
  });
