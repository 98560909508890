import React from 'react';
import intl from 'react-intl-universal';
import { getPropertyFromObject, ICellGenerator } from 'webuniversum-react';

export class PartijenCellGenerator implements ICellGenerator<any> {

  generateCell(row: any): string | React.ReactElement<HTMLElement> | null {
    const gesprekPartners = getPropertyFromObject('gesprekPartners', row);

    return (
      <>
        {
          gesprekPartners ?
            gesprekPartners.map(gesprekPartner =>
              gesprekPartner ? <p key={row + gesprekPartner}>{gesprekPartner}</p> :
                <p key={row}>{intl.get('bericht.send.bericht.sender.anonymous.label')}</p>
            ) :
            <p key={row}>{intl.get('bericht.send.bericht.sender.anonymous.label')}</p>
        }
      </>
    );
  }
}
