import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { formReducer, initialFormState } from 'redux-form-library';

export const ACTION_TYPES = {
  SLUIT_AF: 'conversatie/SLUIT_AF'
};

export const SLUIT_AF_FORM = 'SLUIT_AF_FORM';

export interface ISluitAf {
  loading: boolean;
  errorMessage: string;
}

const initialState: ISluitAf = {
  loading: false,
  errorMessage: null
};

export interface ISluitAfForm {
  onderwerp: string;
  opmerkingen: string;
}

export const initialSluitHulpvraagAfFormState = (): ISluitAfForm => ({
  onderwerp: '',
  opmerkingen: ''
});

export const hulpvraagAfsluitenForm = formReducer(SLUIT_AF_FORM, initialFormState(initialSluitHulpvraagAfFormState()));

export const hulpvraagAfsluitenReducer = (state = initialState, action: AnyAction): ISluitAf => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.SLUIT_AF):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.SLUIT_AF):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.SLUIT_AF):
    return {
      ...state,
      errorMessage: null,
      loading: false
    };
  default:
    return state;
  }
};

// Actions
export const sluitHulpvraagAf: ActionCreator<any> = (conversatieUid: string, onderwerp: string, opmerkingen: string) => ({
  type: ACTION_TYPES.SLUIT_AF,
  payload: axios.post(`/ui/berichten/conversatie/${conversatieUid}/afsluiten`, { onderwerp, opmerkingen })
});
