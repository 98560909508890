import React from 'react';
import { getPropertyFromObject, ICellGenerator, Pill } from 'webuniversum-react';
import './pill-cell-generator.css';

export class PillCellGenerator implements ICellGenerator<any> {
  private accessor: string;
  private className: string;

  constructor(accessor: string, className: string) {
    this.accessor = accessor;
    this.className = className;
  }

  generateCell(row: any): string | React.ReactElement<HTMLElement> | null {
    const property = getPropertyFromObject(this.accessor, row);
    return (
      !!property &&
      <Pill className={this.className} type="pill">{property}</Pill>
    );
  }
}
