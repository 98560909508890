import {changeHeader, HeaderType, INavigationTab} from 'src/reducers/app/header/change';
import React from 'react';
import intl from 'react-intl-universal';
import {Button, ButtonGroup, Checkbox, Loading} from 'webuniversum-react';
import {deleteTechnologie, downloadTechnologieBestand, getTechnologie, IGetTechnologieState} from 'src/reducers/technologie/get';
import {RouteComponentProps} from 'react-router';
import {DetailHeader} from 'src/modules/detail-components/detail-header';
import {DetailField} from 'src/modules/detail-components/detail-field';
import {connect} from 'react-redux';
import {DetailAdres} from 'src/modules/detail-components/detail-adres';
import {ExternalLink} from 'src/modules/detail-components/external-link';
import {isTerPlaatse, isVerplaatsbaar} from 'src/modules/technologie/verplaatsing-optie';
import {translateKey} from 'src/modules/form-components/translatable-select-options';
import {isSymbioseTeam} from 'src/has-permission';
import {IContactPersoon} from 'src/reducers/authentication';
import {Autotranslated} from 'src/modules/taal/autotranslated';
import {AutotranslationWarning} from 'src/modules/taal/autotranslation-warning';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {DeleteTechnologieModal} from 'src/modules/aanbod/materiaal/archiveer-modal';
import {DELETE_TECHNOLOGIE_MODAL} from 'src/reducers/technologie';
import {extractUid} from 'src/modules/referentie-uri';
import {BijlagenLijst} from 'src/modules/bijlage/bijlagen-lijst';
import {Mailto} from 'src/modules/technologie/mailto';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IDetailTechnologieProps extends RouteComponentProps<{ technologieUid: string }> {
  queryResult: IGetTechnologieState;
  isAuthenticated: boolean;
  currentUser: IContactPersoon;
  taal: string;
  getTechnologie: Function;
  changeHeader: Function;
  showModal: Function;
  hideModal: Function;
  deleteTechnologie: Function;
}

export class DetailTechnologie extends React.Component<IDetailTechnologieProps, undefined> {
  componentDidMount(): void {
    this.props.getTechnologie(this.props.taal, this.props.match.params.technologieUid);
    this.changeHeader();
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  render(): React.ReactNode {
    const { queryResult } = this.props;
    const { loading, technologie } = queryResult;

    const label_column_class = 'vl-col--3-12';
    const value_column_class = 'vl-col--9-12';

    const autotranslated = <Autotranslated vertaling={technologie}/>;

    return (
      <Loading loading={loading}>
        <div className="vl-grid">
          <div className="vl-col--9-12">
            <div className="vl-page">
              <div className="vl-col--12-12">
                <DetailHeader
                  title_translate_key={'technologie.detail.page.title'}
                  beschrijving={technologie.titel}
                  img_src={'/static/images/technologie-logo.png'}>
                  {autotranslated}
                </DetailHeader>
              </div>

              <AutotranslationWarning vertaling={technologie}/>

              <section id="algemeen" className="vl-region">
                <DetailField label={intl.get('technologie.edit.form.algemeen.fields.beschrijving.label')}
                  value={technologie.beschrijving}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}>
                  {autotranslated}
                </DetailField>

                <div className="vl-u-spacer--tiny"/>
                <DetailField label={intl.get('technologie.edit.form.algemeen.fields.ontwikkelingsstadium.label')}
                  value={technologie.ontwikkelingsstadium && intl.get(translateKey(technologie.ontwikkelingsstadium,
                    'technologie.edit.form.algemeen.fields.ontwikkelingsstadium.values'))}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}/>
              </section>

              <section id="te-verwerken-materiaal" className="vl-region">
                <h2 className="vl-h2 vl-u-spacer--tiny">{intl.get('technologie.edit.form.te-verwerken-materiaal.title')}</h2>
                <DetailField label={intl.get('technologie.edit.form.te-verwerken-materiaal.fields.beschrijving.label')}
                  value={technologie.beschrijvingTeVerwerkenMateriaal}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}>
                  {autotranslated}
                </DetailField>

                {technologie.oorsprongTeVerwerkenMateriaal && <>
                  <div className="vl-u-spacer--tiny"/>
                  <DetailField label={intl.get('technologie.edit.form.te-verwerken-materiaal.fields.oorsprong.label')}
                    value={technologie.oorsprongTeVerwerkenMateriaal}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}>
                    {autotranslated}
                  </DetailField>
                </>}
              </section>

              <section id="verwerkt-materiaal" className="vl-region">
                <h2 className="vl-h2 vl-u-spacer--tiny">{intl.get('technologie.edit.form.verwerkt-materiaal.title')}</h2>
                <DetailField label={intl.get('technologie.edit.form.verwerkt-materiaal.fields.beschrijving.label')}
                  value={technologie.beschrijvingVerwerktMateriaal}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}>
                  {autotranslated}
                </DetailField>

                {technologie.mogelijkeBestemmingVerwerktMateriaal && <>
                  <div className="vl-u-spacer--tiny"/>
                  <DetailField label={intl.get('technologie.edit.form.verwerkt-materiaal.fields.bestemming.label')}
                    value={technologie.mogelijkeBestemmingVerwerktMateriaal}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}>
                    {autotranslated}
                  </DetailField>
                </>}
              </section>

              <section id="locatie" className="vl-region">
                <h2 className="vl-h2 vl-u-spacer--tiny">{intl.get('technologie.edit.form.locatie.title')}</h2>
                <DetailField label="" label_column_class={label_column_class} value_column_class={value_column_class}>
                  <Checkbox checked={isTerPlaatse(technologie.verplaatsingsOptie)} disabled block>
                    {intl.get('technologie.edit.form.locatie.fields.ter-plaatse.label')}
                  </Checkbox>
                </DetailField>
                <DetailField label="" label_column_class={label_column_class} value_column_class={value_column_class}>
                  <Checkbox checked={isVerplaatsbaar(technologie.verplaatsingsOptie)} disabled block>
                    {intl.get('technologie.edit.form.locatie.fields.verplaatsbaar.label')}
                  </Checkbox>
                </DetailField>

                <div className="vl-u-spacer--small"/>
                <DetailAdres
                  label={intl.get('technologie.edit.form.locatie.fields.adres.label')}
                  value={technologie.adres}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}/>
              </section>

              {technologie.bijlagen.length > 0 &&
              <section id="bijlagen" className="vl-region">
                <h2 className="vl-h2 vl-u-spacer--tiny">{intl.get('technologie.edit.form.meer-informatie.title')}</h2>
                <div className="vl-u-spacer--small"/>
                <DetailField label={intl.get('technologie.edit.form.meer-informatie.fields.bijlagen.label')}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}>
                  <BijlagenLijst bijlagen={technologie.bijlagen} handleDownload={this.downloadBestand} />
                </DetailField>
              </section>}

              <section id="contactinformatie" className="vl-region">
                <h2 className="vl-h2 vl-u-spacer--tiny">{intl.get('technologie.edit.form.contactinformatie.title')}</h2>
                <div className="vl-properties">
                  <DetailField label={intl.get('technologie.edit.form.contactinformatie.fields.onderneming.label')}
                    value={technologie.ondernemingsNaam}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}/>

                  <div className="vl-u-spacer--tiny"/>
                  <DetailField label={intl.get('technologie.edit.form.contactinformatie.fields.contactpersoon.label')}
                    value={`${technologie.voornaamContactPersoon} ${technologie.familienaamContactPersoon}`}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}/>

                  <div className="vl-u-spacer--tiny"/>
                  <DetailField label={intl.get('technologie.edit.form.contactinformatie.fields.email.label')}
                    label_column_class={label_column_class}
                    value_column_class={value_column_class}>
                    <Mailto email={technologie.email}>{technologie.email}</Mailto>
                  </DetailField>

                  {technologie.telefoon && <>
                    <div className="vl-u-spacer--tiny"/>
                    <DetailField label={intl.get('technologie.edit.form.contactinformatie.fields.telefoon.label')}
                      value={technologie.telefoon}
                      label_column_class={label_column_class}
                      value_column_class={value_column_class}/>
                  </>}

                  {technologie.website && <>
                    <div className="vl-u-spacer--tiny"/>
                    <DetailField label={intl.get('technologie.edit.form.contactinformatie.fields.website.label')}
                      label_column_class={label_column_class}
                      value_column_class={value_column_class}>
                      <ExternalLink href={technologie.website}>{technologie.website}</ExternalLink>
                    </DetailField>
                  </>}
                </div>
              </section>
            </div>
          </div>
          <div className="vl-col--3-12 detail-buttons">
            {this.props.isAuthenticated &&(technologie.onderneming.referentie === this.props.currentUser.onderneming.referentie || isSymbioseTeam()) &&
            <ButtonGroup>
              <Button type="button" color="primary" onClick={this.navigateToUpdate}>
                {intl.get('technologie.detail.buttons.update')}
              </Button>
              <Button type="button" color="secondary" onClick={this.openDeleteModal}>
                {intl.get('technologie.detail.buttons.delete')}
              </Button>
            </ButtonGroup>}
          </div>
        </div>
        <DeleteTechnologieModal />
      </Loading>
    );
  }

  private changeHeader = () => {
    const tabNavigationTitles: INavigationTab[] = [{
      title: 'technologie.detail.header.title',
      isActive: true
    }];

    if (this.props.location.pathname.startsWith('/technologie/zoeken')) {
      this.props.changeHeader(HeaderType.BACK_TAB_NAVIGATION, 'technologie.detail.header.breadcrumbs.search', '', tabNavigationTitles, () => {
        this.props.history.push('/technologie/zoeken');
      });
    } else {
      this.props.changeHeader(HeaderType.BACK_TAB_NAVIGATION, 'technologie.detail.header.breadcrumbs.overview', '', tabNavigationTitles, () => {
        this.props.history.push('/technologie/overzicht');
      });
    }
  };

  private downloadBestand = (uid: string) => downloadTechnologieBestand(this.props.match.params.technologieUid, extractUid('bestand', uid));

  private navigateToUpdate = () => {
    this.props.history.push(`/technologie/${this.props.match.params.technologieUid}/update`);
  };

  private openDeleteModal = () => {
    this.props.showModal(DELETE_TECHNOLOGIE_MODAL, {
      submit: () => {
        this.props.deleteTechnologie(this.props.match.params.technologieUid)
          .then(() => this.props.history.push('/technologie/overzicht'))
          .finally(() => this.props.hideModal(DELETE_TECHNOLOGIE_MODAL));
      },
      cancel: () => this.props.hideModal(DELETE_TECHNOLOGIE_MODAL),
      submitText: intl.get('technologie.edit.delete.modal.submit'),
      cancelText: intl.get('technologie.edit.delete.modal.cancel'),
      title: intl.get('technologie.edit.delete.modal.title'),
      text: intl.get('technologie.edit.delete.modal.text')
    });
  };
}

const mapStateToProps = storeState => ({
  queryResult: storeState.technologie.details,
  isAuthenticated: storeState.authentication.security.isAuthenticated,
  currentUser: storeState.authentication.contactPersoon,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  getTechnologie,
  changeHeader,
  showModal,
  hideModal,
  deleteTechnologie
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(DetailTechnologie));
