import React from 'react';
import intl from 'react-intl-universal';
import {getRelevanteVragen, IRelevanteVragenList} from 'src/reducers/aanbod/materiaal/relevante-vragen';
import {connect} from 'react-redux';
import {Loading} from 'webuniversum-react';
import {extractUid} from 'src/modules/referentie-uri';
import {MateriaalTile} from 'src/modules/materiaal/materiaal-tile';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {IContactPersoon} from 'src/reducers/authentication';
import {createHulpvraagForAanbod, getHulpvraagForAanbod, IConversatie} from 'src/reducers/bericht/conversatie';
import {RELEVANTE_VRAGEN_OVERVIEW} from 'src/reducers/aanbod';
import {RelevanteVragenOverviewHulpvraagModal} from 'src/modules/bericht/bericht-modal';
import {relevantieTekst} from 'src/modules/table-components/relevantie-begeleidende-tekst';
import {RelevanteVragenAccordion} from './relevante-vragen-accordion';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IMateriaalAanbodOverviewProps {
  getRelevanteVragen: Function;
  conversatie: IConversatie;
  createHulpvraagForAanbod: Function;
  getHulpvraagForAanbod: Function;
  queryResult: IRelevanteVragenList;
  history: any;
  location: any;
  match: any;
  isAuthenticated: boolean;
  currentUser: IContactPersoon;
  showModal: Function;
  hideModal: Function;
  changeHeader: Function;
}

export class RelevanteVragenMateriaalAanbod extends React.Component<IMateriaalAanbodOverviewProps, undefined> {

  componentDidMount() {
    this.props.getHulpvraagForAanbod(this.props.match.params.aanbodUid);
    this.props.getRelevanteVragen(this.props.match.params.aanbodUid);
    if (this.props.location.pathname.startsWith('/aanbod/zoeken')) {
      this.changeHeader('/aanbod/zoeken', 'aanbod.relevante-vragen.header.return.zoeken');
    } else {
      this.changeHeader('/aanbod/overzicht', 'aanbod.relevante-vragen.header.return.mijn');
    }
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
    this.props.hideModal(RELEVANTE_VRAGEN_OVERVIEW);
  }

  private changeHeader(backUrl: string, backTranslation) {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      backTranslation,
      '',
      [
        {
          title: 'aanbod.relevante-vragen.header.title',
          isActive: true
        },
        {
          title: 'aanbod.detail.title',
          isActive: false,
          redirectUrl: '/aanbod/' + this.props.match.params.aanbodUid
        }
      ],
      () => {
        this.props.history.push(backUrl);
      });
  }

  render(): React.ReactNode {
    return (
      <>
        {this.props.isAuthenticated &&
        <div>
          <div id="buttonSection" className="vl-u-spacer vl-grid" style={{ marginBottom: '0' }}>
            <h2 className="vl-h2 vl-col--6-12">{intl.get('aanbod.relevante-vragen.overview.title')}</h2>
            <div className="vl-col--6-12">
              <VraagHulpButton handleClick={this.handleOpenModal} />
            </div>
          </div>
          <Loading loading={this.props.queryResult.loading}>
            <MateriaalTile layout="bar" type="aanbod" data={this.props.queryResult} onClick={this.navigateToMijnMateriaalAanbod}/>
            <div className="vl-u-spacer"/>
            <p>{intl.get(`aanbod.relevante-vragen.relevantie-tekst.${relevantieTekst(this.props.queryResult)}`)}</p>

            <RelevanteVragenAccordion title={intl.get('aanbod.relevante-vragen.title.top-relevantie')}
              defaultOpen={this.props.queryResult.topRelevanties.length > 0}
              vragen={this.props.queryResult.topRelevanties}
              navigateToVraag={this.navigateToRelevanteVraag}/>

            <RelevanteVragenAccordion title={intl.get('aanbod.relevante-vragen.title.interessante-relevantie')}
              defaultOpen={this.props.queryResult.interessanteRelevanties.length > 0}
              vragen={this.props.queryResult.interessanteRelevanties}
              navigateToVraag={this.navigateToRelevanteVraag}/>

            <RelevanteVragenAccordion title={intl.get('aanbod.relevante-vragen.title.andere-relevantie')}
              vragen={this.props.queryResult.andereRelevanties}
              navigateToVraag={this.navigateToRelevanteVraag}/>
          </Loading>

          <RelevanteVragenOverviewHulpvraagModal/>
        </div>
        }
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForAanbod(this.props.match.params.aanbodUid);
    } else {
      this.props.getHulpvraagForAanbod(this.props.match.params.aanbodUid);
    }

    this.props.showModal(RELEVANTE_VRAGEN_OVERVIEW, {
      cancel: () => this.props.hideModal(RELEVANTE_VRAGEN_OVERVIEW)
    });
  };

  private navigateToMijnMateriaalAanbod = (aanbodReferentie: string) => {
    this.props.history.push(`/aanbod/${extractUid('aanbod', aanbodReferentie)}`);
  };

  private navigateToRelevanteVraag = data => {
    this.props.history.push(`relevante-vraag/${extractUid('vraag', data)}`);
  };
}

const mapStateToProps = storeState => ({
  queryResult: storeState.aanbod.relevanteVragenList,
  conversatie: storeState.bericht.bericht.conversatie,
  isAuthenticated: storeState.authentication.security.isAuthenticated,
  currentUser: storeState.authentication.contactPersoon
});

const mapDispatchToProps = {
  getRelevanteVragen,
  createHulpvraagForAanbod,
  getHulpvraagForAanbod,
  showModal,
  hideModal,
  changeHeader
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(RelevanteVragenMateriaalAanbod));
