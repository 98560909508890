import React from 'react';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {connect} from 'react-redux';
import {ISymbiose} from 'src/reducers/symbiose/create';
import {Badge, Button, ButtonGroup, Label} from 'webuniversum-react';
import intl from 'react-intl-universal';
import {DOCUMENTEER_MEERWAARDE} from 'src/reducers/symbiose';
import {DOCUMENTEER_MEERWAARDE_FORM, initialDocumenteerMeerwaardeFormState, symbioseDocumenteerMeerwaarde} from 'src/reducers/symbiose/change-status';
import {extractUid} from 'src/modules/referentie-uri';
import {formFieldsToObject, formState, initialFormState} from 'redux-form-library';

export interface IActieGevraagdDocumenteerMeerwaardeProps {
  symbiose: ISymbiose;
  showModal: Function;
  hideModal: Function;
  symbioseDocumenteerMeerwaarde: Function;
  documenteerMeerwaarde: any;
  meerwaardeFormState: any;
}

export class ActieGevraagdDocumenteerMeerwaarde extends React.Component<IActieGevraagdDocumenteerMeerwaardeProps> {
  render(): React.ReactNode {
    return (
      <div className="detail-symbiose-actie-gevraagd">
        <div className="detail-symbiose-actie-gevraagd-wraper">
          <div className="detail-symbiose-actie-gevraagd-element">
            <Badge size="s" type="initials" className="detail-symbiose-actie-gevraagd-badge">
              <span>
                <p className="detail-symbiose-actie-gevraagd-badge-content">!</p>
              </span>
            </Badge>
          </div>
          <div>
            <Label className="detail-symbiose-actie-gevraagd-label">{intl.get('symbiose.actie-gevraagd.titel')}</Label>
          </div>
        </div>
        <div className="vl-u-spacer--small">
          <p>{intl.get('symbiose.actie-gevraagd.tekst-documenteer-meerwaarde')}</p>
        </div>
        <ButtonGroup>
          <Button className="detail-symbiose-button" onClick={this.documenteerMeerwaardeOpenModal}>{intl.get('symbiose.detail.documenteer-meerwaarde')}</Button>
        </ButtonGroup>
      </div>
    );
  }

  private documenteerMeerwaardeOpenModal = () => {
    this.props.showModal(DOCUMENTEER_MEERWAARDE, {
      submit: () => {
        this.props.symbioseDocumenteerMeerwaarde(extractUid('symbiose', this.props.symbiose.symbioseReferentie), formFieldsToObject(this.props.documenteerMeerwaarde.fields));
        this.props.hideModal(DOCUMENTEER_MEERWAARDE);
        this.props.meerwaardeFormState(initialFormState(initialDocumenteerMeerwaardeFormState()));
      },
      cancel: () => this.props.hideModal(DOCUMENTEER_MEERWAARDE),
      submitText: intl.get('symbiose.documenteer-meerwaarde-modal.submit-text'),
      cancelText: intl.get('detail.bericht.send.cancel.label'),
      title: intl.get('symbiose.documenteer-meerwaarde-modal.titel')
    });
  };
}

const mapStateToProps = storeState => ({
  documenteerMeerwaarde: storeState.symbiose.documenteerMeerwaardeForm
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  symbioseDocumenteerMeerwaarde,
  meerwaardeFormState: formState(DOCUMENTEER_MEERWAARDE_FORM)
};

export default connect(mapStateToProps, mapDispatchToProps)(ActieGevraagdDocumenteerMeerwaarde);
