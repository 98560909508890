import { ISecurity } from './reducers/authentication';
import { getStore } from './redux-store';

export const isFullyAuthenticated = (security: ISecurity): boolean => security.isAuthenticated && security.hasMinimumRequiredLevelOfAuthentication;

export const hasPermission = (neededPermission: PermissiesEnum, security = getStore().getState().authentication.security): boolean =>
  isFullyAuthenticated(security) && security.permissies.includes(neededPermission);

export const isSymbioseTeam = (): boolean => hasPermission(PermissiesEnum.LOGIN_SYMBIOSETEAM);

export enum PermissiesEnum {
  LOGIN_SYMBIOSETEAM = 'LOGIN_SYMBIOSETEAM',
  READ_MIJN_AANBOD = 'READ_MIJN_AANBOD',
  READ_MIJN_VRAAG = 'READ_MIJN_VRAAG',
  READ_MIJN_TECHNOLOGIE = 'READ_MIJN_TECHNOLOGIE',
  READ_BERICHTEN = 'READ_BERICHTEN',
  READ_MIJN_SYMBIOSES = 'READ_MIJN_SYMBIOSES'
}
