import React from 'react';
import { getPropertyFromObject, ICellGenerator } from 'webuniversum-react';
import './pill-cell-generator.css';
import { ActieStatusPill } from '../bericht/actie-status-pill';

export class ActieStatusCellGenerator implements ICellGenerator<any> {
  generateCell(row: any): string | React.ReactElement<HTMLElement> | null {
    const actieStatus = getPropertyFromObject('actieStatus', row);
    return (
      <>
        {
          actieStatus &&
          <ActieStatusPill actieStatus={actieStatus}/>
        }
      </>
    );
  }
}
