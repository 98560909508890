import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { IMedia } from './publicaties';

export const ACTION_TYPES = {
  GET_ALL: 'informatieve-website/quotes/GET_ALL'
};

export interface IQuote {
  tekst: string;
  auteur: string;
  functie: string;
  organisatie: string;
  beeld: IMedia;
}

export interface IGetQuotesState {
  loading: boolean;
  errorMessage: any;
  quotes: IQuote[];
}

export const initialState: IGetQuotesState = {
  loading: false,
  errorMessage: null,
  quotes: []
};

export const quotesReducer = (state = initialState, action: AnyAction): IGetQuotesState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      quotes: action.payload.data
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite';

export const getQuotes: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET_ALL,
  payload: axios.get(`${apiUrl}/quotes/${taal}`)
});
