import React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route
} from 'react-router-dom';
import {
  hasPermission,
  PermissiesEnum
} from './has-permission';

export interface IPrivateRouteProps {
  component: React.ComponentClass<any> | React.FunctionComponent<any>;
  neededPermission?: PermissiesEnum;
  isAuthenticated: boolean;
  errorPath?: string;

  path: string;

  [key: string]: any;
}

export class PrivateRouteComponent extends React.Component<IPrivateRouteProps, undefined> {

  constructor(props: IPrivateRouteProps) {
    super(props);
  }

  redirect = () => (
    <Redirect
      to={{ pathname: this.props.errorPath }}
    />
  );

  component = () => {
    const Component = this.props.component;
    return (<Component {...this.props} />);
  };

  permissionBasedRender = () => {
    if (this.props.neededPermission && hasPermission(this.props.neededPermission) || !this.props.neededPermission) {
      return (<Route path={this.props.path} {...this.props} />);
    } else {
      return (<Route render={() => this.redirect()} />);
    }
  };

  render(): React.ReactNode {
    if (!this.props.component) throw new Error(`A component needs to be specified for private route for path ${(this.props as any).path}`);

    if (this.props.isAuthenticated) {
      return this.permissionBasedRender();
    } else {
      return (<Route render={() => this.redirect()} />);
    }
  }
}

const mapStoreToProps = storeState => ({
  isAuthenticated: storeState.authentication.security.isAuthenticated
});

/**
 * A route wrapped in an authentication check so that routing happens only when you are authenticated.
 * Accepts same props as React router Route.
 */
export const PrivateRoute = connect(mapStoreToProps, null, null, { pure: false })(PrivateRouteComponent);
