import React, { JSXElementConstructor } from 'react';
import intl from 'react-intl-universal';
import {Badge, Button, ButtonGroup, Label} from 'webuniversum-react';
import {BEANTWOORD_SYMBIOSE, NEGEER_SYMBIOSE} from 'src/reducers/symbiose';
import {extractUid} from 'src/modules/referentie-uri';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {connect} from 'react-redux';
import {beantwoordSymbiose, negeerSymbiose} from 'src/reducers/symbiose/change-status';
import {getMateriaalVraag, IMateriaalVraag} from 'src/reducers/vraag/materiaal/get';
import {getMateriaalAanbod, IMateriaalAanbod} from 'src/reducers/aanbod/materiaal/get';
import {BeantwoordSymbioseModal, NegeerSymbioseModal} from 'src/modules/symbiose/change-symbiose-status-modal';
import {ISymbiose} from 'src/reducers/symbiose/create';
import {AuthenticatieNiveau} from 'src/reducers/authentication';
import {getOverleg} from 'src/reducers/bericht/conversatie';

export interface IActieGevraagdAanvaardNegeerProps {
  symbiose: ISymbiose;
  showModal: Function;
  hideModal: Function;
  beantwoordSymbiose: Function;
  negeerSymbiose: Function;
  vraag: IMateriaalVraag;
  aanbod: IMateriaalAanbod;
  currentOndernemingReferentie: string;
  getMateriaalVraag: Function;
  getMateriaalAanbod: Function;
  getConversatieForAanbodAndVraag: Function;
}

export interface IActieGevraagdAanvaardNegeerState {
  modalText: string;
  modalWarning: string;
}

export class ActieGevraagdAanvaardNegeer extends React.Component<IActieGevraagdAanvaardNegeerProps, IActieGevraagdAanvaardNegeerState> {
  constructor(props: IActieGevraagdAanvaardNegeerProps, context: any) {
    super(props, context);
    this.state = {
      modalText: '',
      modalWarning: ''
    };
  }

  componentDidMount() {
    this.initState();
  }

  componentDidUpdate(prevProps: Readonly<IActieGevraagdAanvaardNegeerProps>, prevState: Readonly<IActieGevraagdAanvaardNegeerState>, snapshot?: any) {
    const { vraag, aanbod } = this.props;

    if (vraag.onderneming.authenticatieNiveau !== prevProps.vraag.onderneming.authenticatieNiveau
      || aanbod.onderneming.authenticatieNiveau !== prevProps.aanbod.onderneming.authenticatieNiveau) {
      this.initState();
    }
  }

  private initState() {
    const { vraag, aanbod } = this.props;

    if (vraag.onderneming.referentie === this.props.currentOndernemingReferentie) {
      this.setState({
        modalText: intl.get('symbiose.beantwoord-modal.text.aanbod'),
        modalWarning: aanbod.onderneming.authenticatieNiveau !== AuthenticatieNiveau.GEVERIFIEERD ? intl.get('symbiose.beantwoord-modal.warning.unverified') : ''
      });
    } else {
      this.setState({
        modalText: intl.get('symbiose.beantwoord-modal.text.vraag'),
        modalWarning: vraag.onderneming.authenticatieNiveau !== AuthenticatieNiveau.GEVERIFIEERD ? intl.get('symbiose.beantwoord-modal.warning.unverified') : ''
      });
    }
  }

  render(): React.ReactNode {
    return (
      <div className="detail-symbiose-actie-gevraagd">
        <div className="detail-symbiose-actie-gevraagd-wraper">
          <div className="detail-symbiose-actie-gevraagd-element">
            <Badge size="s" type="initials" className="detail-symbiose-actie-gevraagd-badge">
              <span>
                <p className="detail-symbiose-actie-gevraagd-badge-content">!</p>
              </span>
            </Badge>
          </div>
          <div>
            <Label className="detail-symbiose-actie-gevraagd-label">{intl.get('symbiose.actie-gevraagd.titel')}</Label>
          </div>
        </div>
        <div className="vl-u-spacer--small">
          <p>{intl.get('symbiose.actie-gevraagd.tekst-aanvaard-negeer')}</p>
        </div>
        <ButtonGroup>
          <Button className="detail-symbiose-button"
            onClick={this.beantwoordSymbioseOpenModal}>{intl.get('symbiose.detail.beantwoord-uitnodiging')}</Button>
          <Button className="detail-symbiose-button"
            onClick={this.negeerSymbioseOpenModal}>{intl.get('symbiose.detail.negeer-uitnodiging')}</Button>
        </ButtonGroup>
        <BeantwoordSymbioseModal text={this.state.modalText} warning={this.state.modalWarning}/>
        <NegeerSymbioseModal/>
      </div>
    );
  }

  private beantwoordSymbioseOpenModal = () => {
    this.props.showModal(BEANTWOORD_SYMBIOSE, {
      submit: () => this.handleModalSubmit(
        () => this.props.beantwoordSymbiose(extractUid('symbiose', this.props.symbiose.symbioseReferentie))
          .then(this.maakPrivateDataZichtbaar),
        BEANTWOORD_SYMBIOSE),
      cancel: () => this.props.hideModal(BEANTWOORD_SYMBIOSE),
      submitText: intl.get('symbiose.beantwoord-modal.submit-text'),
      cancelText: intl.get('detail.bericht.send.cancel.label'),
      title: intl.get('symbiose.beantwoord-modal.titel')
    });
  };

  private maakPrivateDataZichtbaar = () => {
    const vraagUid = extractUid('vraag', this.props.symbiose.vraagReferentie);
    const aanbodUid = extractUid('aanbod', this.props.symbiose.aanbodReferentie);
    this.props.getMateriaalVraag(vraagUid);
    this.props.getMateriaalAanbod(aanbodUid);
    this.props.getConversatieForAanbodAndVraag(aanbodUid, vraagUid);
  };

  private negeerSymbioseOpenModal = () => {
    this.props.showModal(NEGEER_SYMBIOSE, {
      submit: () => this.handleModalSubmit(
        () => this.props.negeerSymbiose(extractUid('symbiose', this.props.symbiose.symbioseReferentie)),
        NEGEER_SYMBIOSE),
      cancel: () => this.props.hideModal(NEGEER_SYMBIOSE),
      submitText: intl.get('symbiose.negeer-modal.submit-text'),
      cancelText: intl.get('detail.bericht.send.cancel.label'),
      title: intl.get('symbiose.negeer-modal.titel')
    });
  };

  private handleModalSubmit = (submit: Function, modal: string) => {
    submit();
    this.props.hideModal(modal);
  };
}

const mapStateToProps = storeState => ({
  vraag: storeState.vraag.details.vraag,
  aanbod: storeState.aanbod.details.aanbod
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  beantwoordSymbiose,
  negeerSymbiose,
  getMateriaalVraag,
  getMateriaalAanbod,
  getConversatieForAanbodAndVraag: getOverleg
};

const connectedActieGevraagdAanvaardNegeer =
  connect(mapStateToProps, mapDispatchToProps)(ActieGevraagdAanvaardNegeer as any as JSXElementConstructor<{ symbiose: ISymbiose; currentOndernemingReferentie: string }>);
export default connectedActieGevraagdAanvaardNegeer;
