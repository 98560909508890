import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { IConversatie } from './conversatie';

export const ACTION_TYPES = {
  FETCH_CONVERSATIES: 'conversatie/FETCH_CONVERSATIES'
};

export interface IGetConversatiesState {
  loading: boolean;
  errorMessage: string;
  conversaties: IConversatie[];
}

export const initialState: IGetConversatiesState = {
  loading: false,
  errorMessage: null,
  conversaties: []
};

export const conversatiesReducer = (state = initialState, action: AnyAction): IGetConversatiesState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_CONVERSATIES):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_CONVERSATIES):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_CONVERSATIES):
    return {
      ...state,
      loading: false,
      conversaties: action.payload.data.map(conversatie => ({
        referentie: conversatie.referentie,
        titel: conversatie.titel,
        subTitel: conversatie.subTitel,
        titelType: conversatie.titelType,
        type: conversatie.type,
        actieStatus: conversatie.actieStatus,
        ondernemingen: conversatie.ondernemingen,
        berichten: conversatie.berichten,
        aantalOngelezenBerichten: conversatie.aantalOngelezenBerichten,
        afsluitOnderwerp: conversatie.afsluitOnderwerp,
        afsluitOpmerkingen: conversatie.afsluitOpmerkingen
      }))
    };
  default:
    return state;
  }
};

// Actions
export const getConversatiesForUser: ActionCreator<any> = () => ({
  type: ACTION_TYPES.FETCH_CONVERSATIES,
  payload: axios.get('/ui/berichten/gebruiker')
});

export const getAfgeslotenConversaties: ActionCreator<any> = () => ({
  type: ACTION_TYPES.FETCH_CONVERSATIES,
  payload: axios.get('/ui/berichten/afgesloten')
});
