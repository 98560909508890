import React from 'react';
import intl from 'react-intl-universal';
import {createValidateFunction, formEdit, FormPropsFactoryBuilder, formState, formValidate, IFormActions, IFormState, initialFormState} from 'redux-form-library';
import {IModalContainerProps} from 'src/reducers/modal/modal';
import {
  Barriere,
  DOCUMENTEER_NIET_REALISATIE_FORM,
  IDocumenteerNietRealisatieForm,
  initialDocumenteerNietRealisatieFormState,
  symbioseGerealiseerd
} from 'src/reducers/symbiose/change-status';
import {getStore} from 'src/redux-store';
import {Button, ButtonGroup, Checkbox, FormComponent, InputField, Modal, ModalButtons, ModalContent, ModalTitle, Select, Textarea} from 'webuniversum-react';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {enumOptions} from 'src/modules/form-components/translatable-select-options';
import {MateriaalEenheid} from 'src/modules/materiaal/materiaal-hoeveelheid';
import {Adviesverleners} from 'src/modules/symbiose/adviesverleners';
import cloneDeep from 'lodash/cloneDeep';
import xor from 'lodash/xor';

export interface IDocumenteerNietRealisatieModalProps extends IFormActions<IDocumenteerNietRealisatieForm> {
  modalProps: IModalContainerProps;
  dispatch: Dispatch;
  form: IFormState<IDocumenteerNietRealisatieForm>;
}

export class NietRealisatieModal extends React.Component<IDocumenteerNietRealisatieModalProps> {

  createValidationRules = () => ({
    kwantiteit: 'required|numeric|min:0.001|max:9999999999999.999|maxScale:3',
    eenheid: 'required',
    barriere: 'required',
    beschrijving: 'required|max:5000'
  });

  generateErrorMessages = () => ({
    'required.kwantiteit': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.niet-gerealiseerd-modal.hoeveelheid')
    }),
    'numeric.kwantiteit': intl.get('validation.form.field.numeric.type', {
      NAME: intl.get('symbiose.niet-gerealiseerd-modal.hoeveelheid')
    }),
    'min.kwantiteit': intl.get('validation.form.field.numeric.min', {
      NAME: intl.get('symbiose.niet-gerealiseerd-modal.hoeveelheid'),
      MIN: '0.001'
    }),
    'max.kwantiteit': intl.get('validation.form.field.numeric.max', {
      NAME: intl.get('symbiose.niet-gerealiseerd-modal.hoeveelheid'),
      MAX: `1 ${intl.get('validation.form.field.numeric.values.trillion')}`
    }),
    'maxScale.kwantiteit': intl.get('validation.form.field.numeric.maxscale', {
      NAME: intl.get('symbiose.niet-gerealiseerd-modal.hoeveelheid'),
      MAXSCALE: '3'
    }),
    'required.eenheid': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.eenheid.label')
    }),
    'required.barriere': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.niet-gerealiseerd-modal.barriere.label')
    }),
    'required.beschrijving': intl.get('validation.form.field.required', {
      NAME: intl.get('symbiose.niet-gerealiseerd-modal.beschrijving')
    }),
    'max.beschrijving': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('symbiose.niet-gerealiseerd-modal.beschrijving'),
      MAX: '5000'
    })
  });

  createValidation = (errormessages, validationRules) => createValidateFunction(validationRules, errormessages);

  render(): React.ReactNode {
    const { visible, submitText, cancelText, title } = this.props.modalProps;
    const fields = this.props.form.fields;
    const errorMessages = this.generateErrorMessages();
    const formPropsFactory = new FormPropsFactoryBuilder()
      .setFieldsObject(fields)
      .setDispatch(this.props.dispatch)
      .setValidate(this.createValidation(errorMessages, this.createValidationRules()))
      .setSelector(formSelector)
      .setStore(getStore())
      .setFormName(DOCUMENTEER_NIET_REALISATIE_FORM)
      .build();

    return (
      <Modal hidden={!visible} overlayHidden={!visible} style={{ width: '90rem' }} onClose={this.cancel}>
        <ModalTitle>{title}</ModalTitle>
        <form onSubmit={formPropsFactory.createSubmitHandler(this.submit)}>
          <div className="vl-grid">
            <div className="vl-col--6-12">
              <FormComponent id="niet-realisatie-kwantiteit"
                label={intl.get('symbiose.niet-gerealiseerd-modal.hoeveelheid')}
                error={fields.kwantiteit.errors}
                required={errorMessages[ 'required.kwantiteit' ]}
                block>
                <InputField type="number" step=".001" min="0.001" max="999999999.999"
                  layout="block" {...formPropsFactory.createInputProps('kwantiteit')}/>
              </FormComponent>
            </div>
            <div className="vl-col--6-12">
              <FormComponent id="niet-realisatie-eenheid"
                label={intl.get('materiaal.edit.form.hoeveelheid.fields.eenheid.label')}
                error={fields.eenheid.errors}
                required={errorMessages[ 'required.eenheid' ]}
                block>
                <Select block {...formPropsFactory.createSelectProps('eenheid')}>
                  {enumOptions(MateriaalEenheid, 'materiaal.edit.form.hoeveelheid.fields.eenheid.values').options()}
                </Select>
              </FormComponent>
            </div>
          </div>
          <ModalContent>
            <FormComponent label={intl.get('symbiose.niet-gerealiseerd-modal.barriere.label')}
              id="niet-realisatie-barriere"
              error={fields.barriere.errors}
              block
              required={errorMessages[ 'required.barriere' ]}>
              <Select block {...formPropsFactory.createSelectProps('barriere')}>
                {enumOptions(Barriere, 'symbiose.niet-gerealiseerd-modal.barriere.enum-values').optionsWithPlaceholder('symbiose.niet-gerealiseerd-modal.barriere.placeholder')}
              </Select>
            </FormComponent>
            <FormComponent label={intl.get('symbiose.niet-gerealiseerd-modal.beschrijving')}
              id="niet-realisatie-beschrijving"
              error={fields.beschrijving.errors}
              block
              required={errorMessages[ 'required.beschrijving' ]}>
              <Textarea {...formPropsFactory.createInputProps('beschrijving')}
                style={{ width: '100%', height: '100px' }}
                placeholder={intl.get('symbiose.niet-gerealiseerd-modal.placeholder')} maxLength={5000}/>
            </FormComponent>
            <FormComponent label={intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.label')}
              id="niet-realisatie-adviesverleners"
              error={fields.adviesverleners.errors}
              block>
              <div className="vl-grid">
                <div className="vl-col--4-12">
                  <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.SYMBIOSE_TEAM)}
                    checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.SYMBIOSE_TEAM)}>
                    {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.symbiose_team')}
                  </Checkbox>
                </div>
                <div className="vl-col--4-12">
                  <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.KENNISCENTRUM)}
                    checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.KENNISCENTRUM)}>
                    {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.kenniscentrum')}
                  </Checkbox>
                </div>
                <div className="vl-col--4-12">
                  <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.MILIEUPROFESSIONAL)}
                    checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.MILIEUPROFESSIONAL)}>
                    {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.milieuprofessional')}
                  </Checkbox>
                </div>
                <div className="vl-col--4-12">
                  <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.ADVOCAAT)}
                    checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.ADVOCAAT)}>
                    {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.advocaat')}
                  </Checkbox>
                </div>
                <div className="vl-col--4-12">
                  <Checkbox onChange={this.selectAdviesverlener(Adviesverleners.ANDERE)}
                    checked={this.props.form.fields.adviesverleners.value.includes(Adviesverleners.ANDERE)}>
                    {intl.get('symbiose.niet-gerealiseerd-modal.adviesverleners.enum-values.andere')}
                  </Checkbox>
                </div>
              </div>
            </FormComponent>
          </ModalContent>
          <ModalButtons>
            <ButtonGroup>
              <Button type="submit" disabled={!this.props.form.dirty || !this.props.form.valid}>
                {submitText}
              </Button>
              <Button type="button" color="secondary" onClick={this.cancel}>
                {cancelText}
              </Button>
            </ButtonGroup>
          </ModalButtons>
        </form>
      </Modal>
    );
  }

  private selectAdviesverlener = value => () => {
    const form = cloneDeep(this.props.form);
    form.fields.adviesverleners.value = xor(form.fields.adviesverleners.value, [ value ]);
    form.fields.adviesverleners.dirty = true;
    form.dirty = true;
    this.props.formState(form);
  };

  private cancel = () => {
    this.props.formState(initialFormState(initialDocumenteerNietRealisatieFormState()));
    this.props.modalProps.cancel();
  };

  private submit = () => {
    this.props.modalProps.submit();
  };
}

const formSelector = storeState => storeState.symbiose.documenteerNietRealisatieForm;

const mapDocumenteerNietRealisatieStateToProps = storeState => ({
  form: formSelector(storeState),
  modalProps: storeState.symbiose.documenteerNietRealisatieModal
});

const mapDispatchToProps = dispatch => {
  const actions = {
    symbioseGerealiseerd,
    formState: formState(DOCUMENTEER_NIET_REALISATIE_FORM),
    formValidate: formValidate<IDocumenteerNietRealisatieForm>(DOCUMENTEER_NIET_REALISATIE_FORM),
    formEdit: formEdit(DOCUMENTEER_NIET_REALISATIE_FORM)
  };
  return { ...bindActionCreators(actions, dispatch), dispatch };
};

export const DocumenteerNietRealisatieModal = connect(mapDocumenteerNietRealisatieStateToProps, mapDispatchToProps)(NietRealisatieModal);
