import React from 'react';
import {IAdres, toArray} from 'src/reducers/adres';
import {DetailField, IGridColumnFields} from './detail-field';

export interface IDetailAdresProps extends IGridColumnFields {
  title?: string;
  label: string;
  value: IAdres;
}

export class DetailAdres extends React.Component<IDetailAdresProps> {

  render(): React.ReactNode {
    const {title, label_column_class, value_column_class, label, value} = this.props;

    return (
      <>
        {title && <h3 className="vl-h3">{title}</h3>}
        <DetailField label={label}
          label_column_class={label_column_class}
          value_column_class={value_column_class}>
          <ul>
            {toArray(value).map(line => <li key={line}>{line}</li>)}
          </ul>
        </DetailField>
      </>
    );
  }
}
