import { combineReducers } from 'redux';
import { faqsReducer } from './faqs';
import { industrieleSymbiosePaginasReducer } from './industriele-symbiose-paginas';
import { nieuwsReducer } from './nieuws';
import { quotesReducer } from './quotes';
import { workshopsReducer } from './workshops';
import { landingsPaginaReducer } from './landings-pagina';
import { instructies } from './instructies';
import { contactInformatieReducer } from './contact-informatie';
import { publicatiesReducer } from './publicaties';

export const informatieveWebsite = combineReducers({
  faqs: faqsReducer,
  industrieleSymbiosePaginas: industrieleSymbiosePaginasReducer,
  nieuws: nieuwsReducer,
  quotes: quotesReducer,
  workshops: workshopsReducer,
  landingsPagina: landingsPaginaReducer,
  contactInformatie: contactInformatieReducer,
  publicaties: publicatiesReducer,
  instructies
});
