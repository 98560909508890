import {FileUpload, IValidatedFile} from 'webuniversum-react';
import React from 'react';
import {IBestand} from 'src/reducers/bestand';
import isEqual from 'lodash/isEqual';

export interface IBijlageUploadContainerProps {
  id?: string;
  maxSizeMb: number;
  acceptFileTypes: string;
  bijlagen: IBestand[];
  uploadBijlage: Function;
  handleBijlageChange: (bijlagen: IBestand[]) => void;
  loading: boolean;
  loadingText: string;
}

export interface IBijlageUploadContainerState {
  bijlagen: IUploadBijlage[];
  invalidBijlagen: IValidatedFile[];
}

export interface IUploadBijlage {
  file: any;
  referentie: string;
}

export class BijlageUploadContainer extends React.Component<IBijlageUploadContainerProps, IBijlageUploadContainerState> {
  public static defaultProps: Partial<IBijlageUploadContainerProps> = {
    maxSizeMb: 25,
    acceptFileTypes: '.jpg, .jpeg, .png, .pdf, .xml, .doc, .docx, .xls, .xlsx, .ppt, .pptx'
  };

  constructor(props: Readonly<IBijlageUploadContainerProps>) {
    super(props);
    this.state = {
      bijlagen: [],
      invalidBijlagen: []
    };
  }

  componentDidUpdate(prevProps: Readonly<IBijlageUploadContainerProps>, prevState: Readonly<IBijlageUploadContainerState>, snapshot?: any) {
    if (!isEqual(prevProps.bijlagen, this.props.bijlagen)) {
      this.setState({bijlagen: this.bestandenToBijlagen(this.props.bijlagen)});
    }
  }

  onFileChange = (files: IValidatedFile[]) => {
    const invalid = files.filter(file => !file.valid);
    if (!isEqual(this.state.invalidBijlagen, invalid)) {
      this.setState({
        invalidBijlagen: invalid
      });
    }
    const valid = files.filter(file => file.valid);
    const added = valid.filter(file => !this.state.bijlagen.map(bijlage => bijlage.file).includes(file));
    if (added.length > 0) {
      this.props.uploadBijlage(added.map(file => file.file))
        .then(response => this.changeBijlageList(copy => copy.push(...this.bestandenToBijlagen(response.data))));
    } else {
      const removed = this.state.bijlagen.map(bijlage => bijlage.file).filter(file => !valid.includes(file));
      if (removed.length > 0) {
        this.changeBijlageList(copy => copy.splice(0, copy.length, ...copy.filter(bijlage => valid.includes(bijlage.file))));
      }
    }
  };

  private changeBijlageList = (arrayChange: (copy: IUploadBijlage[]) => void): void => {
    const copy = [
      ...this.state.bijlagen
    ];
    arrayChange(copy);
    this.props.handleBijlageChange(this.bijlagenToBestanden(copy));
  };

  render(): React.ReactNode {
    const {loading, loadingText, maxSizeMb, acceptFileTypes, id} = this.props;
    return (
      <FileUpload id={id || 'bijlage-upload'}
        maxSizeMb={maxSizeMb}
        acceptFileTypes={acceptFileTypes}
        files={this.state.bijlagen.map(bijlage => bijlage.file).concat(this.state.invalidBijlagen)}
        loading={loading}
        loadingText={loadingText}
        onFileChange={this.onFileChange}/>
    );
  }

  private bijlagenToBestanden = (bijlagen: IUploadBijlage[]): IBestand[] =>
    bijlagen.map(bijlage => ({
      referentie: bijlage.referentie,
      naam: bijlage.file.file.name,
      type: bijlage.file.file.type,
      grootte: bijlage.file.file.size
    }));

  private bestandenToBijlagen = (bestanden: IBestand[]): IUploadBijlage[] =>
    bestanden.map(bijlage => ({
      referentie: bijlage.referentie,
      file: {
        file: {
          name: bijlage.naam,
          type: `application/${bijlage.type}`,
          size: bijlage.grootte
        },
        valid: true
      }
    }));
}
