import React from 'react';
import {autoTranslationFieldInfo, IVertaling} from 'src/reducers/taal/vertalingen';

export interface IAutotranslatedProps {
  vertaling: IVertaling;
}

export class Autotranslated extends React.Component<IAutotranslatedProps> {

  render(): React.ReactNode {
    const info = autoTranslationFieldInfo(this.props.vertaling);
    return info && <p style={{fontStyle: 'italic', color: 'gray'}}>({info})</p>;
  }
}
