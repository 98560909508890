import React from 'react';
import { NavLink } from 'react-router-dom';

export interface IBreadcrumbProps {
  title: string;
  location?: string;
}

export interface IBreadcrumbsProps {
  breadcrumbs: IBreadcrumbProps[];
}

export default class Breadcrumbs extends React.Component<IBreadcrumbsProps, undefined> {
  render(): React.ReactNode {
    return (<div className="vl-link-group vl-link-group--bordered vl-u-spacer--small">
      {
        this.props.breadcrumbs.map((breadcrumb, index) => {
          if (breadcrumb.location) {
            return <NavLink key={`breadcrumb${index}`} to={breadcrumb.location}
              className="vl-link">{breadcrumb.title}</NavLink>;
          } else {
            return <h5 key={`breadcrumb${index}`} className="vl-h5 vl-link">{breadcrumb.title}</h5>;
          }
        })
      }
    </div>);
  }
}
