import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';

import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from './action-type.util';

export const ACTION_TYPES = {
  SETUP: 'application/SETUP',
  UPDATE_APPLICATIE_TAAL: 'application/UPDATE_APPLICATIE_TAAL'
};

export interface IApplicationState {
  loading: boolean;
  errorMessage: any;
  application: IApplication;
}

export interface IApplication {
  general: IGeneral;
  metrics: IMetrics;
  linkedData: ILinkedData;
  afstandRanges: number[];
  documentUrls: IDocumentUrls;
}

export interface IGeneral {
  taal: string;
  contactEmail: string;
}

export interface IMetrics {
  googleAnalyticsId: string;
}

export interface ILinkedData {
  linkedDataUri: string;
}

export interface IDocumentUrls {
  checklistVoorRegelgeving: {[key: string]: string};
}

export const initialApplicationState = (): IApplicationState => ({
  loading: false,
  errorMessage: null,
  application: {
    general: {
      taal: 'nl',
      contactEmail: ''
    },
    metrics: {
      googleAnalyticsId: ''
    },
    linkedData: {
      linkedDataUri: ''
    },
    afstandRanges: [],
    documentUrls: {
      checklistVoorRegelgeving: {}
    }
  }
});

// Reducer
export const application = (state = initialApplicationState(), action: AnyAction): IApplicationState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.SETUP):
    return {
      ...state,
      loading: true
    };
  case SUCCESS(ACTION_TYPES.SETUP):
    return {
      ...state,
      loading: false,
      application: {
        general: {
          ...state.application.general,
          contactEmail: action.payload.data.general.contactEmail
        },
        metrics: {
          googleAnalyticsId: action.payload.data.metrics.googleAnalyticsId
        },
        linkedData: {
          linkedDataUri: action.payload.data.linkedData.linkedDataUri
        },
        afstandRanges: action.payload.data.afstandRanges,
        documentUrls: {
          checklistVoorRegelgeving: {
            nl: action.payload.data.documentUrls.checklistVoorRegelgevingNl,
            en: action.payload.data.documentUrls.checklistVoorRegelgevingEn
          }
        }
      }
    };
  case FAILURE(ACTION_TYPES.SETUP):
    return {
      ...initialApplicationState(),
      errorMessage: action.payload
    };
  case ACTION_TYPES.UPDATE_APPLICATIE_TAAL: {
    const newState = cloneDeep(state);
    newState.loading = false;
    newState.application.general.taal = action.payload.data.taal;
    return newState;
  }
  default:
    return state;
  }
};

const apiUrl = '/ui/application';

export const getProperties: ActionCreator<any> = () => ({
  type: ACTION_TYPES.SETUP,
  payload: axios.get(`${apiUrl}/properties`)
});

export const updateApplicatieTaal: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.UPDATE_APPLICATIE_TAAL,
  payload: {
    data: {
      taal
    }
  }
});
