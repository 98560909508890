import React from 'react';
import intl from 'react-intl-universal';
import {AgendaItem} from 'webuniversum-react';
import {connect} from 'react-redux';
import Breadcrumbs from 'src/modules/welkomstpagina/breadcrumbs';
import {getWorkshops, IWorkshop} from 'src/reducers/informatieve_website/workshops';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IWorkshopsProps {
  history: any;
  taal: string;
  getWorkshops: Function;
  workshops: IWorkshop[];
}

export class WorkshopsPagina extends React.Component<IWorkshopsProps, undefined> {
  componentDidMount() {
    this.props.getWorkshops(this.props.taal);
  }

  render(): React.ReactNode {
    return <>
      <Breadcrumbs breadcrumbs={[
        { title: intl.get('welkomstpagina.titel'), location: '/' },
        { title: intl.get('welkomstpagina.workshops.titel') }
      ]}/>
      <h1 className="vl-h1 vl-u-spacer">{intl.get('welkomstpagina.workshops.titel')}</h1>
      <section className="vl-region">
        <div className="vl-layout vl-layout--wide">
          {this.props.workshops.map((workshop, index) =>
            <AgendaItem key={`workshop-${index}`} title={workshop.titel} tekst={`${workshop.beschrijving} (${workshop.locatie})`} date={workshop.tijdstip}
              layout="fullClickable" className="welkomstpagina-zoeken vl-u-spacer--small" onClick={() => window.open(workshop.inschrijvingslink.url, '_blank')} />
          )}
        </div>
      </section>
    </>;
  }
}

const mapStateToProps = storeState => ({
  workshops: storeState.informatieveWebsite.workshops.workshops,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  getWorkshops
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(WorkshopsPagina));
