import React from 'react';
import intl from 'react-intl-universal';
import {getGearchiveerdeVragen, IVragenList} from 'src/reducers/vraag/materiaal/list';
import {createHulpvraagForOnderneming, getHulpvraagForOnderneming, IConversatie} from 'src/reducers/bericht/conversatie';
import {connect} from 'react-redux';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {GEARCHIVEERD_VRAAG_OVERVIEW} from 'src/reducers/vraag';
import {Loading} from 'webuniversum-react';
import {isSymbioseTeam} from 'src/has-permission';
import {MateriaalTile} from 'src/modules/materiaal/materiaal-tile';
import {GearchiveerdVraagHulpvraagModal} from 'src/modules/bericht/bericht-modal';
import {extractUid} from 'src/modules/referentie-uri';
import {RouteComponentProps} from 'react-router';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IOverviewGearchiveerdMateriaalVraagProps extends RouteComponentProps {
  getGearchiveerdeVragen: Function;
  getHulpvraagForOnderneming: Function;
  createHulpvraagForOnderneming: Function;
  showModal: Function;
  hideModal: Function;
  queryResult: IVragenList;
  conversatie: IConversatie;
  changeHeader: Function;
}

export class OverviewGearchiveerdMateriaalVraag extends React.Component<IOverviewGearchiveerdMateriaalVraagProps> {
  componentDidMount(): void {
    this.props.getHulpvraagForOnderneming();
    this.props.getGearchiveerdeVragen();
    this.changeHeader();
  }

  componentDidUpdate(prevProps: Readonly<IOverviewGearchiveerdMateriaalVraagProps>, prevState: Readonly<undefined>, snapshot?: any): void {
    if (this.props.queryResult.vragen.length > 0) {
      this.changeHeader();
    }
  }

  componentWillUnmount(): void {
    this.props.hideModal(GEARCHIVEERD_VRAAG_OVERVIEW);
  }

  render(): React.ReactNode {
    return (
      <>
        <div id="buttonSection" className="vl-u-spacer vl-grid">
          <h2 className="vl-h2 vl-col--6-12">{intl.get('vraag.overview-gearchiveerd.title')}</h2>
          <div className="vl-col--6-12 vl-grid">
            {
              !isSymbioseTeam() &&
                    <div className="vl-col--12-12">
                      <VraagHulpButton handleClick={this.handleOpenModal} className="buttons-overview" />
                    </div>
            }
          </div>
        </div>
        <Loading loading={this.props.queryResult.loading && this.props.queryResult.vragen.length <= 0}>
          <div className="vl-grid vl-grid--is-stacked vl-u-spacer">
            {this.props.queryResult.vragen.map(vraag =>
              <div className="vl-col--4-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs"
                key={vraag.vraagReferentie}>
                <MateriaalTile layout="block" type="vraag-gearchiveerd" data={vraag} onClick={this.navigateToGearchiveerdeVraag}/>
              </div>)}
          </div>
        </Loading>
        <GearchiveerdVraagHulpvraagModal/>
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }

    this.props.showModal(GEARCHIVEERD_VRAAG_OVERVIEW, {
      cancel: () => this.props.hideModal(GEARCHIVEERD_VRAAG_OVERVIEW)
    });
  };

  private navigateToGearchiveerdeVraag = (vraagReferentie: string) => {
    this.props.history.push(`/vraag/gearchiveerd/${extractUid('vraag', vraagReferentie)}`);
  };

  private changeHeader() {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'vraag.overview-gearchiveerd.header.overzicht-vraag',
      '',
      [
        {
          title: 'vraag.overview-gearchiveerd.header.active',
          isActive: true
        }
      ],
      () => {
        this.props.history.push('/vraag/overzicht');
      });
  }
}

const mapStateToProps = storeState => ({
  queryResult: storeState.vraag.gearchiveerdeVragenList,
  conversatie: storeState.bericht.bericht.conversatie
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  getGearchiveerdeVragen,
  getHulpvraagForOnderneming,
  createHulpvraagForOnderneming,
  changeHeader
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(OverviewGearchiveerdMateriaalVraag));
