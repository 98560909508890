import { IFlatCategorie } from './categorie-tree-to-list';
import flatten from 'lodash/flatten';
import sortedUniqBy from 'lodash/sortedUniqBy';

export const addParents = (gefilterdeCategorieen: IFlatCategorie[], categorieen: IFlatCategorie[]) =>
  sortedUniqBy(
    flatten(gefilterdeCategorieen.map(categorie => toHierarchy(categorie, categorieen)))
      .sort((a, b) => a.code.localeCompare(b.code))
    , cat => cat.code);

const toHierarchy = (categorie: IFlatCategorie, categorieen: IFlatCategorie[]): IFlatCategorie[] => {
  if (categorie.niveau === 2) {
    const hoofdCategorie = findByCode(categorie.code.substring(0, 2), categorieen);
    const standaardCategorie = findByCode(categorie.code.substring(0, 4), categorieen);
    return [ hoofdCategorie, standaardCategorie, categorie ];
  } else if (categorie.niveau === 1) {
    const hoofdCategorie = findByCode(categorie.code.substring(0, 2), categorieen);
    return [ hoofdCategorie, categorie ];
  } else {
    return [ categorie ];
  }
};

const findByCode = (code: string, categorieen: IFlatCategorie[]): IFlatCategorie => {
  const found = categorieen.find(cat => cat.code === code);
  if (!found) throw new Error(`Categorie with code ${code} could not be found`);
  return found;
};
