import React from 'react';
import {getAanbiedingen, IAanbiedingenList} from 'src/reducers/aanbod/materiaal/list';
import {connect} from 'react-redux';
import {Button, Loading} from 'webuniversum-react';
import {Link} from 'react-router-dom';
import {extractUid} from 'src/modules/referentie-uri';
import intl from 'react-intl-universal';
import {MateriaalTile} from 'src/modules/materiaal/materiaal-tile';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {createHulpvraagForOnderneming, getHulpvraagForOnderneming, IConversatie} from 'src/reducers/bericht/conversatie';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {AANBOD_OVERVIEW} from 'src/reducers/aanbod';
import {AanbodOverviewHulpvraagModal} from 'src/modules/bericht/bericht-modal';
import {isSymbioseTeam} from 'src/has-permission';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IMateriaalAanbodOverviewProps {
  getAanbiedingen: Function;
  getHulpvraagForOnderneming: Function;
  createHulpvraagForOnderneming: Function;
  showModal: Function;
  hideModal: Function;
  queryResult: IAanbiedingenList;
  conversatie: IConversatie;
  history: any;
  changeHeader: Function;
}

export class OverviewMateriaalAanbod extends React.Component<IMateriaalAanbodOverviewProps, undefined> {

  componentDidMount() {
    this.props.getHulpvraagForOnderneming();
    this.props.getAanbiedingen();
    this.props.changeHeader(
      HeaderType.TAB_NAVIGATION
    );
  }

  componentWillUnmount(): void {
    this.props.hideModal(AANBOD_OVERVIEW);
  }

  render(): React.ReactNode {
    return (
      <>
        <div id="buttonSection" className="vl-u-spacer vl-grid">
          <h2 className="vl-h2 vl-col--6-12">{intl.get('aanbod.overview.title')}</h2>
          <div className="vl-col--6-12 vl-grid">
            <div className="vl-col--12-12">
              <Link to="/aanbod/nieuw">
                <Button type="button" className="nieuw-button buttons-overview">
                  {intl.get('aanbod.overview.buttons.nieuw-aanbod')}
                </Button>
              </Link>
            </div>
            <div className="vl-col--12-12">
              <Link to="/aanbod/gearchiveerd/overzicht">
                <Button type="button" color="secondary" className="nieuw-button buttons-overview">
                  {intl.get('aanbod.overview.buttons.mijn-gearchiveerd-aanbod')}
                </Button>
              </Link>
            </div>
            {
              !isSymbioseTeam() &&
                <div className="vl-col--12-12">
                  <VraagHulpButton handleClick={this.handleOpenModal} className="buttons-overview" />
                </div>
            }
          </div>
        </div>
        <Loading loading={this.props.queryResult.loading}>
          {this.props.queryResult.aanbiedingen.length > 0 ?
            <div className="vl-grid vl-grid--is-stacked vl-u-spacer">
              {this.props.queryResult.aanbiedingen.map(aanbieding =>
                <div className="vl-col--4-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs"
                  key={aanbieding.aanbodReferentie}>
                  <MateriaalTile layout="block" type="aanbod" data={aanbieding} onClick={this.navigateToVraagForAanbod} />
                </div>)}
            </div>
            :
            <div>
              <h3 className="vl-h3">{intl.get('aanbod.relevante-vragen.overview.geen-aanbod.titel')}</h3>
              <p
                className="vl-u-spacer--tiny">{intl.get('aanbod.relevante-vragen.overview.geen-aanbod.paragraaf-1')}</p>
              <ul>
                <li
                  className="empty-overview-opsomming">{intl.get('aanbod.relevante-vragen.overview.geen-aanbod.bullet-1')}</li>
                <li
                  className="empty-overview-opsomming">{intl.get('aanbod.relevante-vragen.overview.geen-aanbod.bullet-2')}</li>
                <li
                  className="empty-overview-opsomming">{intl.get('aanbod.relevante-vragen.overview.geen-aanbod.bullet-3')}</li>
                <li
                  className="empty-overview-opsomming">{intl.get('aanbod.relevante-vragen.overview.geen-aanbod.bullet-4')}</li>
                <li
                  className="empty-overview-opsomming">{intl.get('aanbod.relevante-vragen.overview.geen-aanbod.bullet-5')}</li>
                <li className="empty-overview-opsomming">...</li>
              </ul>
              <p
                className="vl-u-spacer--tiny">{intl.get('aanbod.relevante-vragen.overview.geen-aanbod.paragraaf-2')}</p>
              <p
                className="vl-u-spacer--tiny">{intl.getHTML('aanbod.relevante-vragen.overview.geen-aanbod.paragraaf-3', { url: '/#/aanbod/nieuw' })}</p>
              <p
                className="vl-u-spacer--tiny">{intl.get('aanbod.relevante-vragen.overview.geen-aanbod.paragraaf-4')}</p>
            </div>
          }
        </Loading>
        <AanbodOverviewHulpvraagModal/>
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }

    this.props.showModal(AANBOD_OVERVIEW, {
      cancel: () => this.props.hideModal(AANBOD_OVERVIEW)
    });
  };

  private navigateToVraagForAanbod = (aanbodReferentie: string) =>
    this.props.history.push(`/aanbod/${extractUid('aanbod', aanbodReferentie)}/vragen`);
}

const mapStateToProps = storeState => ({
  queryResult: storeState.aanbod.aanbiedingenList,
  conversatie: storeState.bericht.bericht.conversatie
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  getAanbiedingen,
  getHulpvraagForOnderneming,
  createHulpvraagForOnderneming,
  changeHeader
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(OverviewMateriaalAanbod));
