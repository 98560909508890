import axios from 'axios';
import {ActionCreator, AnyAction} from 'redux';
import {FAILURE, REQUEST, SUCCESS} from 'src/reducers/action-type.util';
import {initialSymbioseState, ISymbioseState} from 'src/reducers/symbiose/create';
import {SYMBIOSE_STATUS_ACTION_TYPES} from 'src/reducers/symbiose/change-status';

export const ACTION_TYPES = {
  GET_SYMBIOSE: 'symbiose/GET_SYMBIOSE'
};

export const getSymbioseReducer = (state = initialSymbioseState, action: AnyAction): ISymbioseState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET_SYMBIOSE):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.GET_SYMBIOSE):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET_SYMBIOSE):
  case SUCCESS(SYMBIOSE_STATUS_ACTION_TYPES.BEANTWOORD_UITNODIGING):
  case SUCCESS(SYMBIOSE_STATUS_ACTION_TYPES.NEGEER_UITNODIGING):
  case SUCCESS(SYMBIOSE_STATUS_ACTION_TYPES.SYMBIOSE_GEREALISEERD):
  case SUCCESS(SYMBIOSE_STATUS_ACTION_TYPES.DOCUMENTEER_MEERWAARDE):
  case SUCCESS(SYMBIOSE_STATUS_ACTION_TYPES.SYMBIOSE_NIET_GEREALISEERD):
  case SUCCESS(SYMBIOSE_STATUS_ACTION_TYPES.DOCUMENTEER_NIET_REALISATIE):
    return {
      ...state,
      loading: false,
      symbiose: {
        ...action.payload.data,
        datumStatus: new Date(action.payload.data.datumStatus)
      }
    };
  default:
    return state;
  }
};

export const getSymbiose: ActionCreator<any> = (symbioseUid: string) => ({
  type: ACTION_TYPES.GET_SYMBIOSE,
  payload: axios.get(`/ui/symbioses/${symbioseUid}`)
});

export const getSymbioseVoorVraagEnAanbod: ActionCreator<any> = (vraagUid: string, aanbodUid: string) => ({
  type: ACTION_TYPES.GET_SYMBIOSE,
  payload: axios.get(`/ui/symbioses/vraag/${vraagUid}/aanbod/${aanbodUid}`)
});
