import React from 'react';
import {Loading} from 'webuniversum-react';
import {connect} from 'react-redux';
import {RichText} from 'src/modules/rich-text';
import {getNieuws, IGetNieuwsState} from 'src/reducers/informatieve_website/nieuws';
import intl from 'react-intl-universal';
import Breadcrumbs from 'src/modules/welkomstpagina/breadcrumbs';
import find from 'lodash/find';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface INieuwsItemPaginaProps {
  match: any;
  taal: string;
  getNieuws: Function;
  nieuws: IGetNieuwsState;
}

export class NieuwsItemPagina extends React.Component<INieuwsItemPaginaProps, undefined> {
  componentDidMount() {
    this.props.getNieuws(this.props.taal);
  }

  render(): React.ReactNode {
    const detail = find(this.props.nieuws.nieuws, pagina => pagina.referentie === this.props.match.params.referentie);
    return <>
      {
        detail &&
        <Loading loading={this.props.nieuws.loading}>
          <Breadcrumbs breadcrumbs={[
            { title: intl.get('welkomstpagina.titel'), location: '/' },
            { title: intl.get('welkomstpagina.nieuws.titel'), location: '/welkomstpagina/nieuws' },
            { title: detail.titel }
          ]}/>
          <div className="vl-u-spacer">
            <h1 className="vl-h1 vl-u-spacer">{detail.titel}</h1>
            <p className="vl-u-spacer vl-introduction">{detail.header}</p>
            <p>
              <i className="fas fa-book-open"/>
              <span>&nbsp;&sdot;{intl.get('validation.date.format', { date: detail.datum })}&nbsp;</span>
            </p>
          </div>
          <div className="vl-u-spacer vl-u-hr"/>
          <div className="vl-u-spacer">
            <RichText content={detail.inhoud}/>
          </div>
        </Loading>
      }
    </>;
  }
}

const mapStateToProps = storeState => ({
  nieuws: storeState.informatieveWebsite.nieuws,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  getNieuws
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(NieuwsItemPagina));
