import {initialiseerApplicatieTaal} from 'src/reducers/taal/vertalingen';
import React from 'react';
import {connect} from 'react-redux';
import {HashRouter as Router} from 'react-router-dom';
import 'src/app.css';
import ErrorMessage from 'src/modules/error/error-message';
import {BrowserOndersteund} from 'src/modules/startpagina/browser-ondersteund';
import {getProperties, IApplication} from 'src/reducers/application';
import {checkAuthenticated} from 'src/reducers/authentication';
import {getCategorieen} from 'src/reducers/categorie/list';
import {getEuralCodes} from 'src/reducers/eural/list';
import {AppRoutes, HeaderRoutes} from 'src/routes';
import GoogleAnalytics from 'src/google-analytics';

export interface IAppProps {
  application: IApplication;
  checkAuthenticated: Function;
  getProperties: Function;
  getEuralCodes: Function;
  getCategorieen: Function;
}

export interface IAppState {
  initDone: boolean;
}

export class App extends React.Component<IAppProps, IAppState> {
  constructor(props) {
    super(props);
    this.state = {
      initDone: false
    };
  }

  componentDidMount(): void {
    Promise.all([
      this.props.getCategorieen(),
      this.props.getProperties().then(() => this.props.getEuralCodes(this.props.application.linkedData.linkedDataUri)),
      this.props.checkAuthenticated()
    ]).finally(() => {
      initialiseerApplicatieTaal();
      this.setState({initDone: true});
    });
  }

  render(): React.ReactNode {
    return (
      this.state.initDone &&
      <Router>
        <div>
          <HeaderRoutes/>
          <div className="vl-layout vl-layout--wide">
            <BrowserOndersteund/>
            <ErrorMessage/>
            <GoogleAnalytics id={this.props.application.metrics.googleAnalyticsId}>
              <AppRoutes/>
            </GoogleAnalytics>
          </div>
        </div>
      </Router>);
  }
}

const mapStateToProps = storeState => ({
  application: storeState.application.application
});

const mapDispatchToProps = {
  checkAuthenticated,
  getProperties,
  getEuralCodes,
  getCategorieen
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
