import {
  formEdit,
  formEditPropertyValue,
  formState,
  formValidate,
  IFormActions,
  IFormState,
  initialFormState
} from 'redux-form-library';
import { RouteComponentProps } from 'react-router';
import {
  changeHeader,
  HeaderType
} from 'src/reducers/app/header/change';
import { connect } from 'react-redux';
import {
  createTechnologie,
  IGetTechnologieState,
  uploadTechnologieBestanden
} from 'src/reducers/technologie/get';
import { technologieFormDataToRequest } from 'src/modules/technologie/technologie-factory';
import {
  initialTechnologieFormState,
  ITechnologieForm
} from 'src/reducers/technologie/technologie-form';
import React from 'react';
import intl from 'react-intl-universal';
import { TechnologieForm } from 'src/modules/technologie/technologie-form';
import {
  bindActionCreators,
  Dispatch
} from 'redux';
import { withErrorClear } from 'src/modules/error/error-clearer';
import { CREATE_TECHNOLOGIE_FORM } from 'src/reducers/technologie';

export interface ICreateTechnologieProps extends IFormActions<ITechnologieForm>, RouteComponentProps {
  dispatch: Dispatch;
  form: IFormState<ITechnologieForm>;
  technologie: IGetTechnologieState;
  createTechnologie: Function;
  formEditPropertyValue: Function;
  changeHeader: Function;
}

export class CreateTechnologie extends React.Component<ICreateTechnologieProps, undefined> {

  componentDidMount(): void {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'technologie.edit.header.breadcrumbs.overview',
      '',
      [
        {
          title: 'technologie.edit.header.title.create',
          isActive: true
        }
      ],
      this.backToOverview
    );
  }

  componentWillUnmount(): void {
    this.props.formState(initialFormState(initialTechnologieFormState()));
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  handleBijlagenUpload = (files: File[]) => uploadTechnologieBestanden(files);

  handleSave = formData => {
    this.props.createTechnologie(technologieFormDataToRequest(formData)).then(this.backToOverview);
  };

  backToOverview = () => {
    this.props.history.push('/technologie/overzicht');
  };

  render(): React.ReactNode {
    return (
      <div id="formSection">
        <div className="vl-grid">
          <h1 className="vl-h1 vl-col--6-12 vl-u-spacer--tiny">{intl.get('technologie.edit.form.title.create')}</h1>
        </div>
        <TechnologieForm pageState={this.props.technologie}
          form={this.props.form}
          formSelector={formSelector}
          formName={CREATE_TECHNOLOGIE_FORM}
          dispatch={this.props.dispatch}
          handleSave={this.handleSave}
          handleBijlagenUpload={this.handleBijlagenUpload}
          formEditPropertyValue={this.props.formEditPropertyValue}
          handleCancel={this.backToOverview}
          formEdit={this.props.formEdit}
          formState={this.props.formState}
          formValidate={this.props.formValidate}/>
      </div>
    );
  }
}

const formSelector = storeState => storeState.technologie.createTechnologieForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState),
  technologie: storeState.technologie.details
});

const mapDispatchToProps = dispatch => {
  const actions = {
    createTechnologie,
    changeHeader,
    formState: formState(CREATE_TECHNOLOGIE_FORM),
    formValidate: formValidate<ITechnologieForm>(CREATE_TECHNOLOGIE_FORM),
    formEdit: formEdit(CREATE_TECHNOLOGIE_FORM),
    formEditPropertyValue: formEditPropertyValue(CREATE_TECHNOLOGIE_FORM)
  };
  return {...bindActionCreators(actions, dispatch), dispatch};
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(CreateTechnologie));
