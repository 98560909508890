import {saveAs} from 'file-saver';
import {AxiosResponse} from 'axios';

export interface IDownload {
  loading: boolean;
  errorMessage: any;
}

export const initialState: IDownload = {
  loading: false,
  errorMessage: null
};

const asBlob = (result: AxiosResponse): Blob => new Blob([result.data], {type: result.headers['content-type']});

export const download = (result: AxiosResponse): void => {
  const fileName = /(filename=(['"])(.*?)(?=(\2)))/.exec(result.headers[ 'content-disposition' ])[ 3 ];
  saveAs(asBlob(result), fileName);
};

export const blobUrl = (result: AxiosResponse): string => URL.createObjectURL(asBlob(result));
