import { isSymbioseTeam } from 'src/has-permission';
import {
  changeHeader,
  HeaderType,
  INavigationTab
} from 'src/reducers/app/header/change';
import { IContactPersoon } from 'src/reducers/authentication';
import { createHulpvraagForVraag } from 'src/reducers/bericht/conversatie';
import {
  hideModal,
  showModal
} from 'src/reducers/modal/modal';
import {
  archiveerMateriaalVraag,
  downloadVraagBestand,
  getMateriaalVraag,
  IGetMateriaalVraagState
} from 'src/reducers/vraag/materiaal/get';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Loading
} from 'webuniversum-react';
import {
  ARCHIVEER_VRAAG,
  VRAAG_DETAIL_HULPVRAAG
} from 'src/reducers/vraag';
import { ArchiveerVraagModal } from 'src/modules/aanbod/materiaal/archiveer-modal';
import { VraagDetailHulpvraagModal } from 'src/modules/bericht/bericht-modal';
import { extractUid } from 'src/modules/referentie-uri';
import { DetailMateriaalVraag } from 'src/modules/vraag/materiaal/detail-materiaal-vraag';
import { VraagHulpButton } from 'src/modules/bericht/vraag-hulp-button';
import { RouteComponentProps } from 'react-router';
import { withErrorClear } from 'src/modules/error/error-clearer';
import cx from 'classnames';

export interface IMateriaalVraagDetailPaginaProps extends RouteComponentProps<{ vraagUid: string }> {
  getMateriaalVraag: Function;
  archiveerMateriaalVraag: Function;
  createHulpvraagForVraag: Function;
  queryResult: IGetMateriaalVraagState;
  isAuthenticated: boolean;
  currentUser: IContactPersoon;
  showModal: Function;
  hideModal: Function;
  changeHeader: Function;
}

export class MateriaalVraagDetailPagina extends React.Component<IMateriaalVraagDetailPaginaProps, undefined> {
  componentDidMount(): void {
    this.props.getMateriaalVraag(this.props.match.params.vraagUid);
    this.changeHeader();
  }

  componentDidUpdate(prevProps: Readonly<IMateriaalVraagDetailPaginaProps>, prevState: Readonly<undefined>, snapshot?: any): void {
    this.changeHeader();
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
    this.props.hideModal(VRAAG_DETAIL_HULPVRAAG);
  }

  render(): React.ReactNode {
    const { queryResult, isAuthenticated, currentUser } = this.props;
    const { loading, vraag, errorMessage } = queryResult;
    const showAanbodMakenVanuitVraagButton = vraag.onderneming.referentie !== currentUser.onderneming.referentie || isSymbioseTeam();
    return (
      <>
        {isAuthenticated &&
          <>
            <Loading loading={loading && vraag !== null}>
              <div className="vl-grid">
                <div className="vl-col--8-12">
                  <DetailMateriaalVraag vraag={vraag} downloadBestand={this.downloadBestand} />
                </div>
                <div className={cx('vl-col--4-12', 'detail-buttons', { 'detail-buttons--wide': showAanbodMakenVanuitVraagButton })}>
                  <ButtonGroup>
                    {(vraag?.onderneming?.referentie === currentUser.onderneming.referentie || isSymbioseTeam()) &&
                      <>
                        <VraagHulpButton handleClick={this.handleOpenModal} />
                        <Button type="button" color="primary" onClick={this.navigateToUpdateVraag}>
                          {intl.get('vraag.detail.vraag-bewerken')}
                        </Button>
                        <Button type="button" color="secondary" onClick={this.handleOpenArchiveerModal}>
                          {intl.get('vraag.detail.vraag-archiveren')}
                        </Button>
                        <Button type="button" color="secondary" onClick={this.handleCopyVraag}>
                          {intl.get('vraag.detail.vraag-copy')}
                        </Button>
                      </>
                    }
                    {showAanbodMakenVanuitVraagButton &&
                      <Button type="button" color="secondary" onClick={this.handleAanbodAanmakenVanuitVraag}>
                        {intl.get('vraag.detail.aanbod-maken-vanuit-vraag')}
                      </Button>
                    }
                  </ButtonGroup>
                </div>
              </div>
              <ArchiveerVraagModal error={errorMessage ? intl.get(errorMessage) : ''} />
            </Loading>
            <VraagDetailHulpvraagModal />
          </>
        }
      </>
    );
  }

  private getVraagUid = () => extractUid('vraag', this.props.queryResult.vraag.vraagReferentie);

  private handleOpenModal = () => {
    this.props.createHulpvraagForVraag(this.getVraagUid()).then(
      () => this.props.showModal(VRAAG_DETAIL_HULPVRAAG, {
        cancel: () => this.props.hideModal(VRAAG_DETAIL_HULPVRAAG)
      })
    );
  };

  private handleCopyVraag = () => {
    this.props.history.push(`${this.props.location.pathname}/copy`);
  };

  private handleAanbodAanmakenVanuitVraag = () => this.props.history.push(`/aanbod/nieuw-vanuit-vraag/${this.getVraagUid()}`);

  private changeHeader = () => {
    let tabNavigationTitles: INavigationTab[] = [{
      title: 'vraag.detail.title',
      isActive: true
    }];
    if (this.props.queryResult.vraag.onderneming.referentie === this.props.currentUser.onderneming.referentie || isSymbioseTeam()) {
      tabNavigationTitles = [
        {
          title: 'vraag.relevante-aanbiedingen.header.title',
          isActive: false,
          redirectUrl: this.getVraagUid() + '/aanbiedingen'
        },
        ...tabNavigationTitles
      ];
    }

    if (this.props.location.pathname.startsWith('/vraag/zoeken')) {
      this.props.changeHeader(HeaderType.BACK_TAB_NAVIGATION, 'vraag.relevante-aanbiedingen.header.return.zoeken', '', tabNavigationTitles, () => {
        this.props.history.push('/vraag/zoeken');
      });
    } else {
      this.props.changeHeader(HeaderType.BACK_TAB_NAVIGATION, 'vraag.relevante-aanbiedingen.header.return.mijn', '', tabNavigationTitles, () => {
        this.props.history.push('/vraag/overzicht');
      });
    }
  };

  private navigateToUpdateVraag = () => {
    this.props.history.push(this.props.location.pathname + '/update');
  };

  private downloadBestand = (uid: string) => downloadVraagBestand(this.getVraagUid(), extractUid('bestand', uid));

  private handleOpenArchiveerModal = () => {
    this.props.showModal(ARCHIVEER_VRAAG, {
      submit: () => {
        this.props.archiveerMateriaalVraag(this.getVraagUid()).then(() => {
          if (!this.props.queryResult.errorMessage) {
            this.props.history.push('/vraag/overzicht');
            this.props.hideModal(ARCHIVEER_VRAAG);
          }
        });
      },
      cancel: () => this.props.hideModal(ARCHIVEER_VRAAG),
      submitText: intl.get('vraag.archiveer-modal.submitTekst'),
      cancelText: intl.get('vraag.archiveer-modal.cancelTekst'),
      title: intl.get('vraag.archiveer-modal.title'),
      text: intl.get('vraag.archiveer-modal.text')
    });
  };
}

const mapStateToProps = storeState => ({
  queryResult: storeState.vraag.details,
  isAuthenticated: storeState.authentication.security.isAuthenticated,
  currentUser: storeState.authentication.contactPersoon
});

const mapDispatchToProps = {
  getMateriaalVraag,
  showModal,
  hideModal,
  archiveerMateriaalVraag,
  changeHeader,
  createHulpvraagForVraag
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(MateriaalVraagDetailPagina));
