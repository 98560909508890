import React from 'react';
import intl from 'react-intl-universal';
import {ZoekInfoTile} from 'src/modules/welkomstpagina/zoek-infotile';
import {RouterProps} from 'react-router';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {connect} from 'react-redux';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IZoekpaginaProps extends RouterProps {
  changeHeader: Function;
}

export class Zoekpagina extends React.Component<IZoekpaginaProps, undefined> {
  componentDidMount() {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  render(): React.ReactNode {
    return (
      <div>
        <p className="vl-u-spacer">{intl.get('applicatie.startpagina.info-text.paragraph2')}</p>
        <div className="vl-grid vl-u-spacer">
          <div className="vl-col--4-12 vl-col--4-12--m vl-col--4-12--s vl-col--1-1--xs">
            <ZoekInfoTile id="aanbod"
              title={intl.get('welkomstpagina.wat-is-symbiose.aanbod-zoeken-titel')}
              description={intl.get('welkomstpagina.wat-is-symbiose.aanbod-zoeken-tekst')}
              img="/static/images/aanbod-logo.png"
              onClick={this.handleAanbodZoeken}/>
          </div>
          <div className="vl-col--4-12 vl-col--4-12--m vl-col--4-12--s vl-col--1-1--xs">
            <ZoekInfoTile id="vraag"
              title={intl.get('welkomstpagina.wat-is-symbiose.vraag-zoeken-titel')}
              description={intl.get('welkomstpagina.wat-is-symbiose.vraag-zoeken-tekst')}
              img="/static/images/vraag-logo.png"
              onClick={this.handleVraagZoeken}/>
          </div>
          <div className="vl-col--4-12 vl-col--4-12--m vl-col--4-12--s vl-col--1-1--xs">
            <ZoekInfoTile id="technologie"
              title={intl.get('welkomstpagina.wat-is-symbiose.technologie-zoeken-titel')}
              description={intl.get('welkomstpagina.wat-is-symbiose.technologie-zoeken-tekst')}
              img="/static/images/technologie-logo.png"
              onClick={this.handleTechnologieZoeken}/>
          </div>
        </div>
      </div>
    );
  }

  handleAanbodZoeken = () => {
    this.props.history.push('/aanbod/zoeken');
  };

  handleVraagZoeken = () => {
    this.props.history.push('/vraag/zoeken');
  };

  handleTechnologieZoeken = () => {
    this.props.history.push('/technologie/zoeken');
  };
}
const mapDispatchToProps = {
  changeHeader
};

export default withErrorClear(connect(undefined, mapDispatchToProps)(Zoekpagina));
