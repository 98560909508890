import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { Document } from '@contentful/rich-text-types';

export const ACTION_TYPES = {
  GET_ALL: 'informatieve-website/industriele-symbiose-paginas/GET_ALL'
};

export interface ILink {
  beschrijving: string;
  url: string;
}

export interface IIndustrieleSymbiosePagina {
  referentie: string;
  titel: string;
  inhoud: Document;
  leesOok: ILink[];
}

export interface IGetIndustrieleSymbiosePaginasState {
  loading: boolean;
  errorMessage: any;
  industrieleSymbiosePaginas: IIndustrieleSymbiosePagina[];
}

export const initialState: IGetIndustrieleSymbiosePaginasState = {
  loading: false,
  errorMessage: null,
  industrieleSymbiosePaginas: []
};

export const industrieleSymbiosePaginasReducer = (state = initialState, action: AnyAction): IGetIndustrieleSymbiosePaginasState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      industrieleSymbiosePaginas: action.payload.data.map((pagina, index) => ({
        referentie: String(index),
        titel: pagina.titel,
        inhoud: pagina.inhoud,
        leesOok: pagina.leesOok
      }))
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite';

export const getIndustrieleSymbiosePaginas: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET_ALL,
  payload: axios.get(`${apiUrl}/industriele-symbiose-paginas/${taal}`)
});
