import React from 'react';
import {autoTranslationWarning, IVertaling} from 'src/reducers/taal/vertalingen';
import {Alert} from 'webuniversum-react';

export interface IAutotranslationWarningProps {
  vertaling: IVertaling;
}

export class AutotranslationWarning extends React.Component<IAutotranslationWarningProps> {

  render(): React.ReactNode {
    const warning = autoTranslationWarning(this.props.vertaling);
    return warning && <Alert type="warning">{warning}</Alert>;
  }
}
