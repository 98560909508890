import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import intl from 'react-intl-universal';

import {FormFields, SubFormPropsFactory} from 'redux-form-library';
import {Alert, FormComponent, InputField} from 'webuniversum-react';
import {DetailEuralTree} from 'src/modules/detail-components/detail-eural-tree';
import {UitlegRelevantieBerekening} from 'src/modules/materiaal/uitleg-relevantie-berekening';
import {isEuralDataBeschikbaar} from 'src/modules/eural/eural-filter';
import {ExternalLink} from 'src/modules/detail-components/external-link';

export interface IEuralForm {
  eural: string;
}

export const initialEuralFormState = (): IEuralForm => ({
  eural: ''
});

interface IEuralProps {
  fields: FormFields<IEuralForm>;
  formPropsFactory: SubFormPropsFactory<IEuralForm>;
  required: boolean;
}

export class Eural extends React.Component<IEuralProps> {
  generateTooltipMessages = () => ({
    'required.eural': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.classificatie.fields.eural.label')
    })
  });

  formatEural = eural => {
    eural = eural.replace(/\s+/g, '');
    if (eural.length > 2 && eural.slice(2, 3) !== ' ') {
      eural = eural.slice(0, 2) + ' ' + eural.slice(2);
    }
    if (eural.length > 5 && eural.slice(5, 6) !== ' ') {
      eural = eural.slice(0, 5) + ' ' + eural.slice(5);
    }
    return eural;
  };

  onChange = onChange => event => {
    const newEvent = cloneDeep(event);
    newEvent.target.value = this.formatEural(newEvent.target.value);
    onChange(newEvent);
  };

  render(): React.ReactNode {
    const tooltipMessages = this.generateTooltipMessages();
    return (
      <>
        {this.props.required ?
          <FormComponent label={intl.get('materiaal.edit.form.classificatie.fields.eural.label')}
            id="eural"
            block
            required={tooltipMessages[ 'required.eural' ]}
            error={this.props.fields.eural.errors}>
            {this.content()}
          </FormComponent> :
          <FormComponent label={intl.get('materiaal.edit.form.classificatie.fields.eural.label')}
            id="eural"
            block
            error={this.props.fields.eural.errors}>
            {this.content()}
          </FormComponent>
        }

        {!isEuralDataBeschikbaar() && <Alert type={'error'} title={intl.get('error.eural.niet_beschikbaar.titel')}>
          {intl.get('error.eural.niet_beschikbaar.text')}
        </Alert>}
        {this.props.fields.eural.value && <DetailEuralTree eural={this.props.fields.eural.value}/>}
      </>
    );
  }

  private content() {
    const { formPropsFactory } = this.props;
    const inputProps = formPropsFactory.createInputProps('eural');
    return (
      <>
        <UitlegRelevantieBerekening
          tekstKey="materiaal.edit.form.classificatie.fields.eural.uitleg-relevantie-berekening"/>
        <InputField name={inputProps.name}
          maxLength={8}
          onChange={this.onChange(inputProps.onChange)}
          onBlur={inputProps.onBlur}
          disabled={!isEuralDataBeschikbaar()}
          value={inputProps.value ? inputProps.value : ''}
          error={this.props.fields.eural.errors.length > 0}/>
        <ExternalLink className="right" href={intl.get('materiaal.edit.form.classificatie.fields.eural.wizard.url')}>
          {intl.get('materiaal.edit.form.classificatie.fields.eural.wizard.title')}
        </ExternalLink>
      </>
    );
  }
}
