import React from 'react';
import { IFlatCategorie } from './categorie-tree-to-list';

export interface ICategorieProps {
  categorie: IFlatCategorie;
  handleClick: Function;
}

export interface ICategorieState {
  isHovered: boolean;
}

export class Categorie extends React.Component<ICategorieProps, ICategorieState> {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false
    };
  }

  render(): React.ReactNode {
    const { categorie } = this.props;
    if (categorie.niveau === 0) {
      return (
        <>
          <div className="vl-u-spacer"/>
          <div className="vl-u-hr vl-col--12-12 vl-u-spacer"/>
          <div className={(this.state.isHovered ? 'categorie-hover ' : '') + 'clickable categorie-parent vl-grid vl-u-spacer--tiny'}
            onClick={() => this.props.handleClick(categorie)} onMouseEnter={this.handleHover}
            onMouseLeave={this.handleHover}>
            <div className="vl-col--1-12">
              {categorie.code}
            </div>
            <div className="vl-col--11-12">
              {categorie.beschijving}
            </div>
          </div>
        </>
      );
    }

    if (categorie.niveau === 1) {
      return (
        <div className={(this.state.isHovered ? 'categorie-hover ' : '') + 'clickable categorie-parent vl-grid'}
          onClick={() => this.props.handleClick(categorie)} onMouseEnter={this.handleHover}
          onMouseLeave={this.handleHover}>
          <div className="vl-col--1-12">
            {categorie.code}
          </div>
          <div className="vl-col--11-12 standaardcategorie">
            {categorie.beschijving}
          </div>
        </div>
      );
    }

    if (categorie.niveau === 2) {
      return (
        <div className={(this.state.isHovered ? 'categorie-hover ' : '') + 'clickable vl-grid'}
          onClick={() => this.props.handleClick(categorie)}
          onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}>
          <div className="vl-col--1-12">
            {categorie.code.slice(0, 4)} {categorie.code.slice(-2)}
          </div>
          <div className="vl-col--11-12 subcategorie">
            {categorie.beschijving}
          </div>
        </div>
      );
    }
  }

  private readonly handleHover = () => {
    this.setState(prevState => ({
      isHovered: !prevState.isHovered
    }));
  };
}

export default Categorie;
