import {IGraph, ILanguageLabelMap, ISkosConcept} from 'src/linked-data';
import axios from 'axios';

export interface IEural extends ISkosConcept {
  identifier: string;
  notation: string;
  inScheme: string;
  hazardous?: boolean;
  prefLabel: ILanguageLabelMap;
  altLabel: ILanguageLabelMap;
  broader?: string;
  narrower?: string[];
}

export interface IEuralCodeMap {
  [ code: string ]: IEural;
}

export const extractEuralCodes = (data: IGraph): IEural[] => (data[ '@graph' ]
  .filter((concept: ISkosConcept) => concept.inScheme === 'https://data.ovam.be/id/euralcode') as IEural[]);

// Nodig om te voorkomen dat authenticatie tokens naar de eural service worden gestuurd.
export const euralCodeClient = (baseUrl: string) => axios.create({ baseURL: baseUrl });
