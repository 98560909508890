import React from 'react';
import intl from 'react-intl-universal';
import {DetailField, IGridColumnFields} from './detail-field';
import {formatNumber} from 'src/translations/i18n-utils';
import {MateriaalFrequentie} from '../materiaal/materiaal-hoeveelheid';

export interface IDetailHoeveelheidProps extends IGridColumnFields {
  title: string;
  frequentie: string;
  kwantiteit: number;
  eenheid: string;
}

export class DetailHoeveelheid extends React.Component<IDetailHoeveelheidProps> {

  render(): React.ReactNode {
    const { label_column_class, value_column_class, title, frequentie, kwantiteit, eenheid } = this.props;
    const opJaarbasis = (frequentie !== MateriaalFrequentie.EENMALIG.toLowerCase() && frequentie !== '') ?
      intl.get('materiaal.edit.form.hoeveelheid.fields.kwantiteit.op-jaarbasis') : '';

    return (
      <section id={title.toLocaleLowerCase().replace(' ', '-')}>
        <h3 className="vl-h3">{title}</h3>

        <DetailField label={intl.get('detail.materiaal-info.hoeveelheid.frequentie.label') + opJaarbasis}
          value={frequentie}
          label_column_class={label_column_class}
          value_column_class={value_column_class}/>

        <div className="vl-u-spacer--tiny"/>

        <DetailField
          label={intl.get('detail.materiaal-info.hoeveelheid.kwantiteit.label')}
          value={`${formatNumber(kwantiteit)} ${eenheid}`}
          label_column_class={label_column_class}
          value_column_class={value_column_class}/>

      </section>
    );
  }
}
