import React, { PropsWithChildren } from 'react';
import {
  BLOCKS,
  Document,
  MARKS
} from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { DocumentMiniature } from 'webuniversum-react';
import flatMap from 'lodash/flatMap';

export interface IRichTextProps {
  content: Document;
}

const Paragraph = ({ children }: PropsWithChildren<any>) => <p className="vl-u-spacer--small">{children}</p>;
const H1 = ({ children }: PropsWithChildren<any>) => <h1 className="vl-h1">{children}</h1>;
const H2 = ({ children }: PropsWithChildren<any>) => <h2 className="vl-h2">{children}</h2>;
const H3 = ({ children }: PropsWithChildren<any>) => <h3 className="vl-h3">{children}</h3>;
const H4 = ({ children }: PropsWithChildren<any>) => <h4 className="vl-h4">{children}</h4>;
const H5 = ({ children }: PropsWithChildren<any>) => <h5 className="vl-h5">{children}</h5>;
const H6 = ({ children }: PropsWithChildren<any>) => <h6 className="vl-h6">{children}</h6>;
const OL = ({ children }: PropsWithChildren<any>) => <ol>{children}</ol>;
const UL = ({ children }: PropsWithChildren<any>) => <ul>{children}</ul>;
const LI = ({ children }: PropsWithChildren<any>) => <li className="welkompagina-bullet-points">{children}</li>;
const QUOTE = ({ children }: PropsWithChildren<any>) => <blockquote>{children}</blockquote>;
const IMG = ({ children }: PropsWithChildren<any>) => <img alt={children.title} title={children.title} src={children.file.url} />;
const DOC = ({ children }: PropsWithChildren<any>) => (
  <DocumentMiniature className="clickable" docType={children.file.contentType} bytes={children.file.details.size} href={children.file.url}>
    {children.title}</DocumentMiniature>
);
const Bold = ({ children }: PropsWithChildren<any>) => <span className="bold">{children}</span>;
const Italic = ({ children }: PropsWithChildren<any>) => <span className="italic">{children}</span>;
const Underline = ({ children }: PropsWithChildren<any>) => <span className="underline">{children}</span>;
const Code = ({ children }: PropsWithChildren<any>) => <pre><code>{children}</code></pre>;
const renderOptions = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
    [MARKS.CODE]: text => <Code>{text}</Code>
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>,
    [BLOCKS.OL_LIST]: (node, children) => <OL>{children}</OL>,
    [BLOCKS.UL_LIST]: (node, children) => <UL>{children}</UL>,
    [BLOCKS.LIST_ITEM]: (node, children) => <LI>{children[0].props.children}</LI>,
    [BLOCKS.QUOTE]: (node, children) => <QUOTE>{children}</QUOTE>,
    [BLOCKS.EMBEDDED_ASSET]: node => node.data.file.contentType.startsWith('image/') ? <IMG>{node.data}</IMG> : <DOC>{node.data}</DOC>
  },
  renderText: text => flatMap(text.split('\n').map((line, i) => [i > 0 && <br key={i} />, line]))
};

export class RichText extends React.PureComponent<IRichTextProps, undefined> {
  render(): React.ReactNode {
    return <>
      {documentToReactComponents(this.props.content, renderOptions)}
    </>;
  }
}
