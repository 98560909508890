import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';

export const ACTION_TYPES = {
  GET_HIGHLIGHTED: 'informatieve-website/publicaties/GET_HIGHLIGHTED',
  GET_ALL: 'informatieve-website/publicaties/GET_ALL'
};

export interface IPublicaties {
  titel: string;
  bestanden: IMedia[];
}

export interface IMedia {
  title: string;
  url: string;
  mimeType: string;
  fileName: string;
  extension: string;
  size: number;
}

export interface IGetPublicatiesState {
  loading: boolean;
  errorMessage: any;
  publicaties: IPublicaties;
}

export const initialState: IGetPublicatiesState = {
  loading: false,
  errorMessage: null,
  publicaties: null
};

export const publicatiesReducer = (state = initialState, action: AnyAction): IGetPublicatiesState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET_HIGHLIGHTED):
  case REQUEST(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.GET_HIGHLIGHTED):
  case FAILURE(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET_HIGHLIGHTED):
  case SUCCESS(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      publicaties: action.payload.data
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite';

export const getHighlightedPublicaties: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET_HIGHLIGHTED,
  payload: axios.get(`${apiUrl}/publicaties/highlighted/${taal}`)
});

export const getAllePublicaties: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET_ALL,
  payload: axios.get(`${apiUrl}/publicaties/${taal}`)
});
