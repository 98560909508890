import {Button, Pill} from 'webuniversum-react';
import intl from 'react-intl-universal';
import React from 'react';
import {IConversatie} from 'src/reducers/bericht/conversatie';

interface IToonConversatieButtonProps {
  conversatie: IConversatie;
  handleClick: () => void;
}

export const ToonConversatieButton = (props: IToonConversatieButtonProps) => (
  <Button className="bericht-button" type="button" color="primary" onClick={props.handleClick}>
    {intl.get(props.conversatie.berichten.length > 0 ? 'detail.bericht.send.messages.label' : 'detail.bericht.send.send-message.label')}
    {props.conversatie.aantalOngelezenBerichten > 0 &&
    <Pill type="pill" className="button-bericht-pill">
      {props.conversatie.aantalOngelezenBerichten}
    </Pill>}
  </Button>
);
