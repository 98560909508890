import React from 'react';
import intl from 'react-intl-universal';
import './text-tile.css';

export interface ITextTileProps {
  title: string;
  titleClass?: string;
  content: string;
  timestamp: string;
  className: string;
}

export class TextTile extends React.Component<ITextTileProps> {
  render(): React.ReactNode {
    const { title, titleClass, content, timestamp, className } = this.props;

    return (
      <div className={'text-tile ' + className}>
        <h1 className={titleClass}>{title}</h1>
        <pre>{content}</pre>
        <p>{intl.get('validation.time-short.format', { time: new Date(timestamp) })}</p>
      </div>
    );
  }
}
