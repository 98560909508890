export const relevantieTekst = (queryResult: any) => {
  if (!queryResult.loading && queryResult.topRelevanties.length === 0) {
    if (queryResult.interessanteRelevanties.length > 0) {
      return queryResult.andereRelevanties.length > 0 ? 'interessante-true-andere-true' : 'interessante-true-andere-false';
    } else {
      return queryResult.andereRelevanties.length > 0 ? 'interessante-false-andere-true' : 'interessante-false-andere-false';
    }
  } else {
    return 'default';
  }
};
