import intl from 'react-intl-universal';
import React from 'react';

interface ICoverPhotoProps {
  urlFetcher: () => Promise<string>;
  label: string;
}

interface ICoverPhotoState {
  url: string;
  alt: string;
}

export class CoverPhoto extends React.Component<ICoverPhotoProps, ICoverPhotoState> {
  _isMounted = false;

  constructor(props: Readonly<ICoverPhotoProps>) {
    super(props);
    this.state = {
      url: '/static/images/missing-foto.png',
      alt: 'detail.overzicht-tegel.cover-foto.alt-missing'
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.urlFetcher()
      .then(url => {
        if (this._isMounted) {
          this.setState({
            url,
            alt: 'detail.overzicht-tegel.cover-foto.alt'
          });
        }
      })
      .catch(() => null);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {label} = this.props;
    const {url, alt} = this.state;
    return (
      <img src={url} className="cover-foto" alt={intl.get(alt, {title: label})} title={label}/>
    );
  }
}
