export interface IModalContainerProps {
  visible: boolean;
  submit: () => void;
  submitText: string;
  cancel: () => void;
  cancelText: string;
  title: string;
  text: string;
}

/**
 * Hulp methode zoals de REQUEST/FAILURE/SUCCESS methodes van Jhipster, om de naam van een modal voor het actie type te plaatsen.
 * @param {string} name de naam van de modal.
 * @param {string} actionType het type van de actie.
 * @returns {string} name/actionType
 */
export const modal = (name: string, actionType: string): string => `${name}/${actionType}`;

export const showModal = (name, modalProps: Partial<IModalContainerProps>) => ({
  type: modal(name, ACTION_TYPES.SHOW_MODAL),
  payload: modalProps
});

export const hideModal = name => ({
  type: modal(name, ACTION_TYPES.HIDE_MODAL)
}
);

export const ACTION_TYPES = {
  SHOW_MODAL: 'modal/show',
  HIDE_MODAL: 'modal/hide'
};

export const initialModalState = (): IModalContainerProps => ({
  visible: false,
  submitText: '',
  submit: (): void => {},
  cancelText: '',
  cancel: (): void => {},
  title: '',
  text: ''
});

export const modalReducer = (name, initialState: IModalContainerProps) => (state = initialState, action) => {
  switch (action.type) {
  case modal(name, ACTION_TYPES.SHOW_MODAL):
    return {
      ...state,
      ...action.payload,
      visible: true
    };
  case modal(name, ACTION_TYPES.HIDE_MODAL):
    return {
      ...initialModalState(),
      visible: false
    };
  default:
    return state;
  }
};
