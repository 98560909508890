import React from 'react';
import intl from 'react-intl-universal';
import {
  createValidateFunction,
  FormFields,
  formFieldsToObject,
  FormPropsFactory,
  FormPropsFactoryBuilder,
  IFormActions,
  IFormState,
  initialFormState,
  ValidationMethod
} from 'redux-form-library';
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormComponent,
  InputField,
  Label,
  Select,
  Textarea
} from 'webuniversum-react';
import { getStore } from 'src/redux-store';
import { componentOffset } from 'src/modules/form-components/componentOffset';
import { enumOptions } from 'src/modules/form-components/translatable-select-options';
import {
  initialTechnologieFormState,
  ITechnologieForm
} from 'src/reducers/technologie/technologie-form';
import { Ontwikkelingsstadium } from 'src/modules/technologie/ontwikkelingsstadium';
import { AdresForm } from 'src/modules/adres/adres-form';
import { IPageState } from 'src/reducers/reset-state-action';
import { IBestand } from 'src/reducers/bestand';
import { BijlageUploadContainer } from 'src/modules/bijlage/bijlage-upload-container';
import { Dispatch } from 'redux';

export interface ITechnologieFormProps extends IFormActions<ITechnologieForm> {
  pageState: IPageState;
  form: IFormState<ITechnologieForm>;
  formSelector: Function;
  formName: string;
  dispatch: Dispatch;
  formEditPropertyValue: Function;
  handleSave: Function;
  handleBijlagenUpload: (files: File[]) => Promise<any>;
  handleCancel: Function;
}

export class TechnologieForm extends React.Component<ITechnologieFormProps, undefined> {
  createValidationRules = () => ({
    titel: 'string|required|max:100',
    beschrijving: 'string|required|max:4000',
    ontwikkelingsstadium: 'required',
    beschrijvingTeVerwerkenMateriaal: 'string|required|max:4000',
    oorsprongTeVerwerkenMateriaal: 'string|max:250',
    beschrijvingVerwerktMateriaal: 'string|required|max:4000',
    mogelijkeBestemmingVerwerktMateriaal: 'string|max:250',
    adres: {
      straatnaam: 'string|required|max:255',
      huisnummer: 'string|required|max:50',
      adresLijn2: 'string|max:255',
      postcode: 'string|required|max:20',
      gemeente: 'string|required|max:255',
      land: 'required'
    },
    ondernemingsNaam: 'string|required|max:255',
    voornaamContactPersoon: 'string|required|max:255',
    familienaamContactPersoon: 'string|required|max:255',
    email: 'string|required|max:254|email',
    telefoon: 'string|max:50|phone',
    website: 'string|max:2000|url'
  });

  generateErrorMessages = () => ({
    'required.titel': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.algemeen.fields.titel.label')
    }),
    'max.titel': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.algemeen.fields.titel.label'),
      MAX: '100'
    }),
    'required.beschrijving': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.algemeen.fields.beschrijving.label')
    }),
    'max.beschrijving': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.algemeen.fields.beschrijving.label'),
      MAX: '4000'
    }),
    'required.ontwikkelingsstadium': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.algemeen.fields.ontwikkelingsstadium.label')
    }),
    'required.beschrijvingTeVerwerkenMateriaal': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.te-verwerken-materiaal.fields.beschrijving.label')
    }),
    'max.beschrijvingTeVerwerkenMateriaal': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.te-verwerken-materiaal.fields.beschrijving.label'),
      MAX: '4000'
    }),
    'max.oorsprongTeVerwerkenMateriaal': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.te-verwerken-materiaal.fields.oorsprong.label'),
      MAX: '250'
    }),
    'required.beschrijvingVerwerktMateriaal': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.verwerkt-materiaal.fields.beschrijving.label')
    }),
    'max.beschrijvingVerwerktMateriaal': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.verwerkt-materiaal.fields.beschrijving.label'),
      MAX: '4000'
    }),
    'max.mogelijkeBestemmingVerwerktMateriaal': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.verwerkt-materiaal.fields.bestemming.label'),
      MAX: '250'
    }),
    'required.adres.straatnaam': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.locatie.fields.straatnaam.label')
    }),
    'max.adres.straatnaam': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.locatie.fields.straatnaam.label'),
      MAX: '255'
    }),
    'required.adres.huisnummer': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.locatie.fields.huisnummer.label')
    }),
    'max.adres.huisnummer': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.locatie.fields.huisnummer.label'),
      MAX: '50'
    }),
    'max.adres.adresLijn2': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.locatie.fields.adreslijn2.label'),
      MAX: '255'
    }),
    'required.adres.postcode': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.locatie.fields.postcode.label')
    }),
    'max.adres.postcode': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.locatie.fields.postcode.label'),
      MAX: '20'
    }),
    'required.adres.gemeente': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.locatie.fields.gemeente.label')
    }),
    'max.adres.gemeente': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.locatie.fields.gemeente.label'),
      MAX: '255'
    }),
    'required.adres.land': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.locatie.fields.land.label')
    }),
    'required.ondernemingsNaam': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.onderneming.label')
    }),
    'max.ondernemingsNaam': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.onderneming.label'),
      MAX: '255'
    }),
    'required.voornaamContactPersoon': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.contactpersoon.voornaam.label')
    }),
    'max.voornaamContactPersoon': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.contactpersoon.voornaam.label'),
      MAX: '255'
    }),
    'required.familienaamContactPersoon': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.contactpersoon.familienaam.label')
    }),
    'max.familienaamContactPersoon': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.contactpersoon.familienaam.label'),
      MAX: '255'
    }),
    'required.email': intl.get('validation.form.field.required', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.email.label')
    }),
    'max.email': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.email.label'),
      MAX: '254'
    }),
    'email.email': intl.get('validation.form.field.text.email', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.email.label')
    }),
    'max.telefoon': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.telefoon.label'),
      MAX: '254'
    }),
    'phone.telefoon': intl.get('validation.form.field.text.phone', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.telefoon.label')
    }),
    'max.website': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.website.label'),
      MAX: '2000'
    }),
    'url.website': intl.get('validation.form.field.text.url', {
      NAME: intl.get('technologie.edit.form.contactinformatie.fields.website.label')
    })
  });

  createValidation = (errormessages, validationRules) => createValidateFunction(validationRules, errormessages);

  createValidationFunction = () => this.createValidation(this.generateErrorMessages(), this.createValidationRules());

  private readonly myrefs: {
    titel: any;
    beschrijving: any;
    ontwikkelingsstadium: any;
    beschrijvingTeVerwerkenMateriaal: any;
    oorsprongTeVerwerkenMateriaal: any;
    beschrijvingVerwerktMateriaal: any;
    mogelijkeBestemmingVerwerktMateriaal: any;
    adres: any;
    ondernemingsNaam: any;
    voornaamContactPersoon: any;
    familienaamContactPersoon: any;
    email: any;
    telefoon: any;
    website: any;
  };

  constructor(props: ITechnologieFormProps) {
    super(props);
    this.myrefs = {
      titel: React.createRef(),
      beschrijving: React.createRef(),
      ontwikkelingsstadium: React.createRef(),
      beschrijvingTeVerwerkenMateriaal: React.createRef(),
      oorsprongTeVerwerkenMateriaal: React.createRef(),
      beschrijvingVerwerktMateriaal: React.createRef(),
      mogelijkeBestemmingVerwerktMateriaal: React.createRef(),
      adres: React.createRef(),
      ondernemingsNaam: React.createRef(),
      voornaamContactPersoon: React.createRef(),
      familienaamContactPersoon: React.createRef(),
      email: React.createRef(),
      telefoon: React.createRef(),
      website: React.createRef()
    };
  }

  render(): React.ReactNode {
    const fields = this.props.form.fields;
    const errorMessages = this.generateErrorMessages();
    const formPropsFactory: FormPropsFactory<ITechnologieForm> = new FormPropsFactoryBuilder()
      .setFieldsObject(fields)
      .setDispatch(this.props.dispatch)
      .setValidate(this.createValidation(errorMessages, this.createValidationRules()))
      .setSelector(this.props.formSelector)
      .setStore(getStore())
      .setFormName(this.props.formName)
      .build();
    const loading = this.props.pageState.loading;

    return (
      <>
        <form className="technologie-form" onSubmit={this.handleSubmit}>
          <p className="vl-h3 private-text vl-u-spacer--tiny">{intl.get('technologie.edit.form.algemeen.text')}</p>
          <div className="vl-u-spacer--tiny" ref={this.myrefs.titel}>
            <FormComponent id="titel"
              label={intl.get('technologie.edit.form.algemeen.fields.titel.label')}
              required={errorMessages['required.titel']}
              error={fields.titel.errors} block>
              <InputField {...formPropsFactory.createInputProps('titel')} error={fields.titel.errors.length > 0}
                layout="block"/>
            </FormComponent>
          </div>
          <div ref={this.myrefs.beschrijving}>
            <FormComponent id="beschrijving"
              label={intl.get('technologie.edit.form.algemeen.fields.beschrijving.label')}
              required={errorMessages['required.beschrijving']}
              error={fields.beschrijving.errors}
              block>
              <Textarea {...formPropsFactory.createInputProps('beschrijving')}
                placeholder={intl.get('technologie.edit.form.algemeen.fields.beschrijving.placeholder')}
                error={fields.beschrijving.errors.length > 0} block
                rows={10}/>
            </FormComponent>
          </div>
          <div ref={this.myrefs.ontwikkelingsstadium}>
            <FormComponent id="ontwikkelingsstadium"
              label={intl.get('technologie.edit.form.algemeen.fields.ontwikkelingsstadium.label')}
              required={errorMessages['required.ontwikkelingsstadium']}
              error={fields.ontwikkelingsstadium.errors}
              block>
              <Select error={fields.ontwikkelingsstadium.errors.length > 0} block {...formPropsFactory.createSelectProps('ontwikkelingsstadium')}>
                {enumOptions(Ontwikkelingsstadium, 'technologie.edit.form.algemeen.fields.ontwikkelingsstadium.values')
                  .optionsWithPlaceholder('technologie.edit.form.algemeen.fields.ontwikkelingsstadium.placeholder')}
              </Select>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--small"/>
          <h3 className="vl-h3">{intl.get('technologie.edit.form.te-verwerken-materiaal.title')}</h3>
          <div ref={this.myrefs.beschrijvingTeVerwerkenMateriaal}>
            <FormComponent id="beschrijvingTeVerwerkenMateriaal"
              label={intl.get('technologie.edit.form.te-verwerken-materiaal.fields.beschrijving.label')}
              required={errorMessages['required.beschrijvingTeVerwerkenMateriaal']}
              error={fields.beschrijvingTeVerwerkenMateriaal.errors}
              block>
              <Textarea {...formPropsFactory.createInputProps('beschrijvingTeVerwerkenMateriaal')}
                placeholder={intl.get('technologie.edit.form.te-verwerken-materiaal.fields.beschrijving.placeholder')}
                block
                rows={10}/>
            </FormComponent>
          </div>
          <div ref={this.myrefs.oorsprongTeVerwerkenMateriaal}>
            <FormComponent id="oorsprongTeVerwerkenMateriaal"
              label={intl.get('technologie.edit.form.te-verwerken-materiaal.fields.oorsprong.label')}
              error={fields.oorsprongTeVerwerkenMateriaal.errors}
              block>
              <InputField {...formPropsFactory.createInputProps('oorsprongTeVerwerkenMateriaal')}
                placeholder={intl.get('technologie.edit.form.te-verwerken-materiaal.fields.oorsprong.placeholder')}
                error={fields.oorsprongTeVerwerkenMateriaal.errors.length > 0} layout="block"/>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--small"/>
          <h3 className="vl-h3">{intl.get('technologie.edit.form.verwerkt-materiaal.title')}</h3>
          <div ref={this.myrefs.beschrijvingVerwerktMateriaal}>
            <FormComponent id="beschrijvingVerwerktMateriaal"
              label={intl.get('technologie.edit.form.verwerkt-materiaal.fields.beschrijving.label')}
              required={errorMessages['required.beschrijvingVerwerktMateriaal']}
              error={fields.beschrijvingVerwerktMateriaal.errors}
              block>
              <Textarea {...formPropsFactory.createInputProps('beschrijvingVerwerktMateriaal')}
                placeholder={intl.get('technologie.edit.form.verwerkt-materiaal.fields.beschrijving.placeholder')}
                block
                rows={10}/>
            </FormComponent>
          </div>
          <div ref={this.myrefs.mogelijkeBestemmingVerwerktMateriaal}>
            <FormComponent id="mogelijkeBestemmingVerwerktMateriaal"
              label={intl.get('technologie.edit.form.verwerkt-materiaal.fields.bestemming.label')}
              error={fields.mogelijkeBestemmingVerwerktMateriaal.errors}
              block>
              <InputField {...formPropsFactory.createInputProps('mogelijkeBestemmingVerwerktMateriaal')}
                placeholder={intl.get('technologie.edit.form.verwerkt-materiaal.fields.bestemming.placeholder')}
                error={fields.mogelijkeBestemmingVerwerktMateriaal.errors.length > 0} layout="block"/>
            </FormComponent>
          </div>

          <div className="vl-u-spacer--small"/>
          <h3 className="vl-h3">{intl.get('technologie.edit.form.locatie.title')}</h3>
          <div className="vl-grid">
            <div className="vl-col--4-12">
              <FormComponent id="terPlaatse"
                label={intl.get('technologie.edit.form.locatie.fields.ter-plaatse.label')}
                error={fields.terPlaatse.errors}>
                <Checkbox {...formPropsFactory.createCheckboxProps('terPlaatse')}/>
              </FormComponent>
            </div>
            <div className="vl-col--4-12">
              <FormComponent id="verplaatsbaar"
                label={intl.get('technologie.edit.form.locatie.fields.verplaatsbaar.label')}
                error={fields.verplaatsbaar.errors}>
                <Checkbox {...formPropsFactory.createCheckboxProps('verplaatsbaar')}/>
              </FormComponent>
            </div>
          </div>
          <div ref={this.myrefs.adres}>
            <AdresForm fields={fields.adres} formPropsFactory={formPropsFactory.createSubFormPropsFactory('adres')}/>
          </div>

          <div className="vl-u-spacer--small"/>
          <h3 className="vl-h3">{intl.get('technologie.edit.form.meer-informatie.title')}</h3>
          <div className="vl-u-spacer--tiny">
            <div>
              <Label>{intl.get('technologie.edit.form.meer-informatie.fields.bijlagen.label')}</Label>
              <BijlageUploadContainer
                bijlagen={fields.bijlagen.value}
                uploadBijlage={this.props.handleBijlagenUpload}
                handleBijlageChange={this.handleBijlageChange}
                loading={loading}
                loadingText={intl.get('technologie.edit.form.meer-informatie.fields.bijlagen.loading')}/>
            </div>
          </div>

          <div className="vl-u-spacer--small"/>
          <h3 className="vl-h3">{intl.get('technologie.edit.form.contactinformatie.title')}</h3>
          <div className="vl-u-spacer--tiny" ref={this.myrefs.ondernemingsNaam}>
            <FormComponent id="ondernemingsNaam"
              label={intl.get('technologie.edit.form.contactinformatie.fields.onderneming.label')}
              required={errorMessages['required.ondernemingsNaam']}
              error={fields.ondernemingsNaam.errors} block>
              <InputField {...formPropsFactory.createInputProps('ondernemingsNaam')} error={fields.ondernemingsNaam.errors.length > 0}
                layout="block"/>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--tiny" ref={this.myrefs.voornaamContactPersoon}>
            <FormComponent id="voornaamContactPersoon"
              label={intl.get('technologie.edit.form.contactinformatie.fields.contactpersoon.voornaam.label')}
              required={errorMessages['required.voornaamContactPersoon']}
              error={fields.voornaamContactPersoon.errors} block>
              <InputField {...formPropsFactory.createInputProps('voornaamContactPersoon')} error={fields.voornaamContactPersoon.errors.length > 0}
                layout="block"/>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--tiny" ref={this.myrefs.familienaamContactPersoon}>
            <FormComponent id="familienaamContactPersoon"
              label={intl.get('technologie.edit.form.contactinformatie.fields.contactpersoon.familienaam.label')}
              required={errorMessages['required.familienaamContactPersoon']}
              error={fields.familienaamContactPersoon.errors} block>
              <InputField {...formPropsFactory.createInputProps('familienaamContactPersoon')} error={fields.familienaamContactPersoon.errors.length > 0}
                layout="block"/>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--tiny" ref={this.myrefs.email}>
            <FormComponent id="email"
              label={intl.get('technologie.edit.form.contactinformatie.fields.email.label')}
              required={errorMessages['required.email']}
              error={fields.email.errors} block>
              <InputField {...formPropsFactory.createInputProps('email')} error={fields.email.errors.length > 0}
                placeholder={intl.get('technologie.edit.form.contactinformatie.fields.email.placeholder')}
                layout="block"/>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--tiny" ref={this.myrefs.telefoon}>
            <FormComponent id="telefoon"
              label={intl.get('technologie.edit.form.contactinformatie.fields.telefoon.label')}
              error={fields.telefoon.errors} block>
              <InputField {...formPropsFactory.createInputProps('telefoon')} error={fields.telefoon.errors.length > 0}
                placeholder={intl.get('technologie.edit.form.contactinformatie.fields.telefoon.placeholder')}
                layout="block"/>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--small" ref={this.myrefs.website}>
            <FormComponent id="website"
              label={intl.get('technologie.edit.form.contactinformatie.fields.website.label')}
              error={fields.website.errors} block>
              <InputField {...formPropsFactory.createInputProps('website')} error={fields.website.errors.length > 0}
                placeholder={intl.get('technologie.edit.form.contactinformatie.fields.website.placeholder')}
                layout="block"/>
            </FormComponent>
          </div>

          <ButtonGroup>
            <Button type="submit" loading={loading} disabled={!this.props.form.dirty}>
              {intl.get('materiaal.edit.form.button.save')}
            </Button>
            <Button type="button" color="secondary" loading={loading} onClick={this.handleCancel}>
              {intl.get('materiaal.edit.form.button.cancel')}
            </Button>
          </ButtonGroup>
        </form>
        <div className="vl-u-spacer--tiny"/>
      </>
    );
  }

  handleBijlageChange = (bijlagen: IBestand[]) => {
    this.props.formEditPropertyValue('bijlagen', bijlagen);
  };

  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.formValidate(this.createValidationFunction(), ValidationMethod.ValidateAll, this.props.formSelector(getStore().getState()));
    const state: IFormState<ITechnologieForm> = this.props.formSelector(getStore().getState());
    const formFields: FormFields<ITechnologieForm> = state.fields;
    if (state.valid) {
      const formData = formFieldsToObject(formFields);
      this.props.handleSave(formData);
      if (this.props.pageState.errorMessage === null) {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, componentOffset(formFields, this.myrefs, false));
    }
  };

  handleCancel = () => {
    this.props.formState(initialFormState(initialTechnologieFormState()));
    this.props.handleCancel();
  };
}
