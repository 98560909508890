import React from 'react';
import intl from 'react-intl-universal';
import { getPropertyFromObject, ICellGenerator } from 'webuniversum-react';

export class TitelSubtitelCellGenerator implements ICellGenerator<any> {

  constructor() {
  }

  generateCell(row: any): string | React.ReactElement<HTMLElement> | null {
    const titel = getPropertyFromObject('titel', row);
    const type = getPropertyFromObject('type', row);
    const subTitel = getPropertyFromObject('subTitel', row);
    const titelType = getPropertyFromObject('titelType', row);

    return (
      <>
        {
          (type === 'OVERLEG' || type === 'BEMIDDELING_HULPVRAAG') &&
            <div key={row + 'overleg'}>
              <p className="bold">{subTitel}</p>
              <p className="normal">{`${titelType === 'AANBOD_ONDERWERP' ?
                intl.get('bericht.send.header.label.aanbod') :
                intl.get('bericht.send.header.label.vraag')}: ${titel}`}
              </p>
            </div>
        }
        {
          (type === 'OPEN_HULPVRAAG') &&
          <p key={row + 'open'}><strong>{intl.get('bericht.open-vraag.label')}</strong></p>
        }
        {
          (type === 'AANBOD_HULPVRAAG') &&
          <div key={row + 'aanbod_hulpvraag'}>
            <p className="bold">{intl.get('bericht.open-vraag.betreffend.label')}</p>
            <p className="normal">{`${intl.get('bericht.send.header.label.aanbod')}: ${titel}`}</p>
          </div>
        }
        {
          (type === 'VRAAG_HULPVRAAG') &&
          <div key={row + 'vraag_hulpvraag'}>
            <p className="bold">{intl.get('bericht.open-vraag.betreffend.label')}</p>
            <p className="normal">{`${intl.get('bericht.send.header.label.vraag')}: ${titel}`}</p>
          </div>
        }
        {
          (type === 'RELEVANT_AANBOD_HULPVRAAG' || type === 'RELEVANTE_VRAAG_HULPVRAAG') &&
          <div key={row + 'relevant'}>
            <p className="bold">{subTitel}</p>
            <p className="normal">{`${titelType === 'AANBOD_ONDERWERP' ?
              intl.get('bericht.send.header.label.aanbod') :
              intl.get('bericht.send.header.label.vraag')}: ${titel}`}
            </p>
          </div>
        }
      </>
    );
  }
}
