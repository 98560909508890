import { combineReducers } from 'redux';
import { createSymbioseReducer } from './create';
import { mijnSymbiosesReducer } from './mijn-symbioses';
import { getSymbioseReducer } from './get';
import { initialModalState, modalReducer } from '../modal/modal';
import { changeStatusReducer, documenteerMeerwaardeForm, documenteerNietRealisatieForm } from './change-status';

export const SYMBIOSE_OVERVIEW = 'symbiose-overzicht/conversatie/hulpvraag';
export const SYMBIOSE_SEND_BERICHT = 'symbiose/conversatie/send';
export const SYMBIOSE_HULPVRAAG = 'symbiose/conversatie/hulpvraag';
export const BEANTWOORD_SYMBIOSE = 'symbiose/beantwoord';
export const NEGEER_SYMBIOSE = 'symbiose/negeer';
export const REALISEER_SYMBIOSE = 'symbiose/realiseer';
export const DOCUMENTEER_MEERWAARDE = 'symbiose/documenteer-meerwaarde';
export const SYMBIOSE_NIET_REALISATIE = 'symbiose/niet-realisatie';
export const DOCUMENTEER_NIET_REALISATIE = 'symbiose/documenteer-niet-realisatie';

export const symbiose = combineReducers({
  create: createSymbioseReducer,
  mijnSymbiosesList: mijnSymbiosesReducer,
  get: getSymbioseReducer,
  symbioseBerichtModal: modalReducer(SYMBIOSE_SEND_BERICHT, initialModalState()),
  symbioseHulpvraagModal: modalReducer(SYMBIOSE_HULPVRAAG, initialModalState()),
  changeStatus: changeStatusReducer,
  beantwoordSymbioseModal: modalReducer(BEANTWOORD_SYMBIOSE, initialModalState()),
  negeerSymbioseModal: modalReducer(NEGEER_SYMBIOSE, initialModalState()),
  gerealiseerdSymbioseModal: modalReducer(REALISEER_SYMBIOSE, initialModalState()),
  documenteerMeerwaardeModal: modalReducer(DOCUMENTEER_MEERWAARDE, initialModalState()),
  nietGerealiseerdModal: modalReducer(SYMBIOSE_NIET_REALISATIE, initialModalState()),
  documenteerNietRealisatieModal: modalReducer(DOCUMENTEER_NIET_REALISATIE, initialModalState()),
  symbioseOverviewModal: modalReducer(SYMBIOSE_OVERVIEW, initialModalState()),
  documenteerMeerwaardeForm,
  documenteerNietRealisatieForm
});
