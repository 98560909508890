import React from 'react';
import intl from 'react-intl-universal';
import { ModalContent } from 'webuniversum-react';
import { IBericht, IConversatie } from 'src/reducers/bericht/conversatie';
import { TextTile } from './text-tile';
import './bericht-modal.css';
import { IContactPersoon } from 'src/reducers/authentication';
import { ActieStatusPill } from './actie-status-pill';
import { SysteemTextTile } from './systeem-text-tile';
import { animateScroll } from 'react-scroll';
import groupBy from 'lodash/groupBy';

export interface IBerichtModalContentBerichtenProps {
  conversatie: IConversatie;
  contactPersoon: IContactPersoon;
  toggleAfsluiten: Function;
}

export class BerichtModalContentBerichten extends React.Component<IBerichtModalContentBerichtenProps, undefined> {
  componentDidMount(): void {
    this.scroll();
  }

  componentDidUpdate(prevProps: Readonly<IBerichtModalContentBerichtenProps>, prevState: Readonly<undefined>, snapshot?: any): void {
    this.scroll();
  }

  render(): React.ReactNode {
    const { conversatie, contactPersoon } = this.props;

    return (
      <div id="content" className={`send-bericht-modal-dialog__content ${conversatie.actieStatus && contactPersoon.onderneming.naam === 'Symbioseteam' ? '' : 'full'}`}>
        <ModalContent>
          {
            conversatie &&
            <>
              {
                conversatie.welkomstTekst &&
                <p className="welkomstTekst">{intl.getHTML(conversatie.welkomstTekst)}</p>
              }
              {
                Object.entries(groupBy(conversatie.berichten, bericht => new Date(bericht.timestamp).toDateString()))
                  .map(([ key, value ]) =>
                    <div key={key}>
                      <p className="message-separating-date-stamp">{intl.get('validation.date.format', { date: new Date(key) })}</p>
                      {
                        value.map((bericht, i) =>
                          bericht.type === 'GEBRUIKERS_BERICHT' ?
                            <div key={i}>
                              <TextTile title={BerichtModalContentBerichten.getTextTileTitle(bericht, contactPersoon)}
                                titleClass={BerichtModalContentBerichten.getTextTileTitleClass(bericht)}
                                content={bericht.inhoud}
                                timestamp={bericht.timestamp}
                                className={BerichtModalContentBerichten.getClassName(bericht.verzender, contactPersoon)}/>
                            </div> :
                            <div key={i}>
                              <SysteemTextTile content={bericht.inhoud}/>
                            </div>)
                      }
                    </div>
                  )
              }
              {
                (conversatie.actieStatus === 'OPEN' && contactPersoon.onderneming.naam === 'Symbioseteam') &&
                <div className="symbioseteam-hulpvraag-footer">
                  <ActieStatusPill actieStatus={conversatie.actieStatus}/>
                  <a className="close-hulpvraag" onClick={() => this.props.toggleAfsluiten()}>
                    <i className="vl-link--icon--close"/>
                    <span>{intl.get('bericht.sluit-af.afsluiten')}</span>
                  </a>
                </div>
              }
            </>
          }
        </ModalContent>
      </div>
    );
  }

  private scroll = () => {
    animateScroll.scrollToBottom({
      containerId: 'content'
    });
  };

  private static getTextTileTitle(bericht: IBericht, gebruiker: IContactPersoon) {
    return bericht.verzender.referentie === gebruiker.referentie ? '' :
      bericht.verzender.onderneming.naam ? bericht.verzender.voornaam + ' ' + bericht.verzender.familienaam + ' (' + bericht.verzender.onderneming.naam + ')' :
        intl.get('bericht.send.bericht.sender.anonymous.label');
  }

  private static getTextTileTitleClass(bericht: IBericht) {
    return bericht.verzender.onderneming.naam ? '' : 'niet-vrijgegeven';
  }

  private static getClassName(verzender: IContactPersoon, gebruiker: IContactPersoon) {
    return verzender.onderneming.referentie === gebruiker.onderneming.referentie ? 'sent-text-tile' :
      verzender.onderneming.naam === 'Symbioseteam' ? 'symbiose-text-tile' : 'received-text-tile';
  }
}
