import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { SEND_BERICHT_ACTION_TYPES } from './send';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { BERICHTEN_GELEZEN_ACTION_TYPES } from './berichten-gelezen';
import { IContactPersoon, IOnderneming } from '../authentication';
import { isSymbioseTeam } from 'src/has-permission';

export const ACTION_TYPES = {
  FETCH_CONVERSATIE: 'conversatie/FETCH_CONVERSATIE',
  CREATE_CONVERSATIE: 'conversatie/CREATE_CONVERSATIE'
};

export interface IConversatie {
  referentie: string;
  type: string;
  titel: string;
  subTitel: string;
  titelType: string;
  actieStatus: string;
  welkomstTekst: string;
  ondernemingen: IOnderneming[];
  berichten: IBericht[];
  aantalOngelezenBerichten: number;
  afsluitOnderwerp: string;
  afsluitOpmerkingen: string;
}

export interface IBericht {
  verzender: IContactPersoon;
  inhoud: string;
  type: string;
  timestamp: any;
}

export interface IGetBerichtenState {
  loading: boolean;
  errorMessage: string;
  conversatie: IConversatie;
}

export const initialState: IGetBerichtenState = {
  loading: false,
  errorMessage: null,
  conversatie: {
    referentie: '',
    type: '',
    titel: '',
    subTitel: '',
    titelType: '',
    actieStatus: '',
    welkomstTekst: '',
    berichten: [],
    ondernemingen: [],
    aantalOngelezenBerichten: 0,
    afsluitOnderwerp: '',
    afsluitOpmerkingen: ''
  }
};

export const conversatieReducer = (state = initialState, action: AnyAction): IGetBerichtenState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_CONVERSATIE):
  case REQUEST(ACTION_TYPES.CREATE_CONVERSATIE):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_CONVERSATIE):
  case FAILURE(ACTION_TYPES.CREATE_CONVERSATIE):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_CONVERSATIE):
  case SUCCESS(ACTION_TYPES.CREATE_CONVERSATIE):
    return {
      ...state,
      loading: false,
      conversatie: {
        referentie: action.payload.data.referentie,
        type: action.payload.data.type,
        titel: action.payload.data.titel,
        subTitel: action.payload.data.subTitel,
        titelType: action.payload.data.titelType,
        actieStatus: action.payload.data.actieStatus,
        welkomstTekst: action.payload.data.welkomstTekst,
        ondernemingen: action.payload.data.ondernemingen,
        berichten: action.payload.data.berichten,
        aantalOngelezenBerichten: action.payload.data.aantalOngelezenBerichten,
        afsluitOpmerkingen: action.payload.data.afsluitOpmerkingen,
        afsluitOnderwerp: action.payload.data.afsluitOnderwerp
      }
    };
  case SUCCESS(SEND_BERICHT_ACTION_TYPES.SEND_BERICHT):
    return {
      ...state,
      loading: false,
      conversatie: {
        referentie: state.conversatie.referentie,
        type: state.conversatie.type,
        titel: state.conversatie.titel,
        subTitel: state.conversatie.subTitel,
        titelType: state.conversatie.titelType,
        actieStatus: state.conversatie.actieStatus,
        welkomstTekst: state.conversatie.welkomstTekst,
        ondernemingen: action.payload.data.ondernemingen,
        berichten: action.payload.data.berichten,
        aantalOngelezenBerichten: state.conversatie.aantalOngelezenBerichten,
        afsluitOpmerkingen: action.payload.data.afsluitOpmerkingen,
        afsluitOnderwerp: action.payload.data.afsluitOnderwerp
      }
    };
  case SUCCESS(BERICHTEN_GELEZEN_ACTION_TYPES.UPDATE_BERICHTEN_GELEZEN):
    return {
      ...state,
      loading: false,
      conversatie: {
        referentie: state.conversatie.referentie,
        type: state.conversatie.type,
        titel: state.conversatie.titel,
        subTitel: state.conversatie.subTitel,
        titelType: state.conversatie.titelType,
        actieStatus: state.conversatie.actieStatus,
        welkomstTekst: state.conversatie.welkomstTekst,
        ondernemingen: [ ...state.conversatie.ondernemingen ],
        berichten: [ ...state.conversatie.berichten ],
        aantalOngelezenBerichten: action.payload.data.aantalOngelezenBerichten,
        afsluitOnderwerp: state.conversatie.afsluitOnderwerp,
        afsluitOpmerkingen: state.conversatie.afsluitOpmerkingen
      }
    };
  default:
    return state;
  }
};

// Actions
export const getConversatie: ActionCreator<any> = (conversatieUid: string) => ({
  type: ACTION_TYPES.FETCH_CONVERSATIE,
  payload: axios.get(`/ui/berichten/conversatie/${conversatieUid}`)
});

export const getOverleg: ActionCreator<any> = (aanbodUid: string, vraagUid: string) => ({
  type: ACTION_TYPES.FETCH_CONVERSATIE,
  payload: axios.get(`/ui/berichten/conversatie/aanbod/${aanbodUid}/vraag/${vraagUid}`)
});

export const createConversatie: ActionCreator<any> = (aanbodUid: string, vraagUid: string) => ({
  type: ACTION_TYPES.CREATE_CONVERSATIE,
  payload: axios.post(`/ui/berichten/conversatie/aanbod/${aanbodUid}/vraag/${vraagUid}`)
});

export const getHulpvraagForOnderneming: ActionCreator<any> = () => dispatch => {
  if (!isSymbioseTeam()) {
    return axios.get('/ui/berichten/conversatie/hulpvraag').then(
      response => dispatch({
        type: SUCCESS(ACTION_TYPES.FETCH_CONVERSATIE),
        payload: response
      }),
      error => dispatch({
        type: FAILURE(ACTION_TYPES.FETCH_CONVERSATIE),
        payload: error
      })
    );
  }
};

export const getHulpvraagForAanbod: ActionCreator<any> = (aanbodUid: string) => ({
  type: ACTION_TYPES.FETCH_CONVERSATIE,
  payload: axios.get(`/ui/berichten/conversatie/aanbod/${aanbodUid}/hulpvraag`)
});

export const getHulpvraagForVraag: ActionCreator<any> = (vraagUid: string) => ({
  type: ACTION_TYPES.FETCH_CONVERSATIE,
  payload: axios.get(`/ui/berichten/conversatie/vraag/${vraagUid}/hulpvraag`)
});

export const getHulpvraagForRelevanteVraag: ActionCreator<any> = (aanbodUid: string, vraagUid: string) => ({
  type: ACTION_TYPES.FETCH_CONVERSATIE,
  payload: axios.get(`/ui/berichten/conversatie/aanbod/${aanbodUid}/${vraagUid}/hulpvraag`)
});

export const getHulpvraagForRelevantAanbod: ActionCreator<any> = (vraagUid: string, aanbodUid: string) => ({
  type: ACTION_TYPES.FETCH_CONVERSATIE,
  payload: axios.get(`/ui/berichten/conversatie/vraag/${vraagUid}/${aanbodUid}/hulpvraag`)
});

export const getHulpvraagForSymbiose: ActionCreator<any> = (symbioseUid: string) => ({
  type: ACTION_TYPES.FETCH_CONVERSATIE,
  payload: axios.get(`/ui/berichten/conversatie/symbiose/${symbioseUid}/hulpvraag`)
});

export const createHulpvraagForOnderneming: ActionCreator<any> = () => ({
  type: ACTION_TYPES.CREATE_CONVERSATIE,
  payload: axios.post('/ui/berichten/conversatie/hulpvraag')
});

export const createHulpvraagForAanbod: ActionCreator<any> = (aanbodUid: string) => ({
  type: ACTION_TYPES.CREATE_CONVERSATIE,
  payload: axios.post(`/ui/berichten/conversatie/aanbod/${aanbodUid}/hulpvraag`)
});

export const createHulpvraagForVraag: ActionCreator<any> = (vraagUid: string) => ({
  type: ACTION_TYPES.CREATE_CONVERSATIE,
  payload: axios.post(`/ui/berichten/conversatie/vraag/${vraagUid}/hulpvraag`)
});

export const createHulpvraagForRelevanteVraag: ActionCreator<any> = (aanbodUid: string, vraagUid: string) => ({
  type: ACTION_TYPES.CREATE_CONVERSATIE,
  payload: axios.post(`/ui/berichten/conversatie/aanbod/${aanbodUid}/${vraagUid}/hulpvraag`)
});

export const createHulpvraagForRelevantAanbod: ActionCreator<any> = (vraagUid: string, aanbodUid: string) => ({
  type: ACTION_TYPES.CREATE_CONVERSATIE,
  payload: axios.post(`/ui/berichten/conversatie/vraag/${vraagUid}/${aanbodUid}/hulpvraag`)
});

export const createHulpvraagForBemiddeling: ActionCreator<any> = (conversatieUid: string) => ({
  type: ACTION_TYPES.CREATE_CONVERSATIE,
  payload: axios.post(`/ui/berichten/conversatie/bemiddeling/${conversatieUid}/hulpvraag`)
});

export const createHulpvraagForSymbiose: ActionCreator<any> = (symbioseUid: string) => ({
  type: ACTION_TYPES.CREATE_CONVERSATIE,
  payload: axios.post(`/ui/berichten/conversatie/symbiose/${symbioseUid}/hulpvraag`)
});
