import axios from 'axios';
import {ActionCreator, AnyAction} from 'redux';
import {FAILURE, REQUEST, SUCCESS} from 'src/reducers/action-type.util';

export const ACTION_TYPES = {
  LIST_MIJN_TECHNOLOGIE: 'technologie/LIST_MIJN_TECHNOLOGIE'
};

export interface ITechnologieList {
  loading: boolean;
  errorMessage: any;
  results: any[];
}

const initialState: ITechnologieList = {
  loading: false,
  errorMessage: null,
  results: []
};

export const listMijnTechnologieReducer = (state = initialState, action: AnyAction): ITechnologieList => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.LIST_MIJN_TECHNOLOGIE):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.LIST_MIJN_TECHNOLOGIE):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.LIST_MIJN_TECHNOLOGIE):
    return {
      ...state,
      loading: false,
      results: action.payload.data
    };
  default:
    return state;
  }
};

export const getMijnTechnologie: ActionCreator<any> = () => ({
  type: ACTION_TYPES.LIST_MIJN_TECHNOLOGIE,
  payload: axios.get('/ui/technologie/mijn-technologie')
});
