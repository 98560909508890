import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  bindActionCreators,
  Dispatch
} from 'redux';
import {
  formEdit,
  formEditPropertyValue,
  formState,
  formValidate,
  IFormActions,
  IFormState,
  initialFormState
} from 'redux-form-library';
import { isSymbioseTeam } from 'src/has-permission';
import {
  AANBOD_MAKEN_UIT_VRAAG_ONDERNEMING_HULPVRAAG,
  CREATE_MATERIAAL_AANBOD_FROM_VRAAG_FORM
} from 'src/reducers/aanbod';
import {
  createMateriaalAanbod,
  IGetMateriaalAanbodState,
  uploadMateriaalAanbodBestanden
} from 'src/reducers/aanbod/materiaal/get';
import {
  IMateriaalAanbodForm,
  initialMateriaalAanbodFormState
} from 'src/reducers/aanbod/materiaal/materiaal-aanbod-form';
import {
  changeHeader,
  HeaderType
} from 'src/reducers/app/header/change';
import {
  createHulpvraagForOnderneming,
  getHulpvraagForOnderneming,
  IConversatie
} from 'src/reducers/bericht/conversatie';
import {
  ICategorieenMap,
  resetCategorieen,
  updateHoofdCategorie,
  updateStandaardCategorie
} from 'src/reducers/categorie/list';
import {
  hideModal,
  showModal
} from 'src/reducers/modal/modal';
import { AanbodMakenUitVraagOndernemingHulpvraagModal } from 'src/modules/bericht/bericht-modal';
import { extractUid } from 'src/modules/referentie-uri';
import {
  materiaalAanbodFormDataToRequest,
  materiaalAanbodFromMateriaalVraagResponse
} from './materiaal-aanbod-factory';
import { MateriaalAanbodForm } from 'src/modules/aanbod/materiaal/materiaal-aanbod-form';
import { IBestand } from 'src/reducers/bestand';
import { VraagHulpButton } from 'src/modules/bericht/vraag-hulp-button';
import { withErrorClear } from 'src/modules/error/error-clearer';
import { Bestandstype } from '../../bestand/bestandstype';
import { getMateriaalVraag } from '../../../reducers/vraag/materiaal/get';

export interface ICreateAanbodFromVraagProps extends IFormActions<IMateriaalAanbodForm>, RouteComponentProps<{ vraagUid: string }> {
  materiaalAanbod: IGetMateriaalAanbodState;
  showModal: Function;
  hideModal: Function;
  conversatie: IConversatie;
  createHulpvraagForOnderneming: Function;
  getHulpvraagForOnderneming: Function;
  updateHoofdCategorie: Function;
  updateStandaardCategorie: Function;
  categorieQueryResult: ICategorieenMap;
  form: IFormState<IMateriaalAanbodForm>;
  dispatch: Dispatch;
  formEditPropertyValue: Function;
  resetCategorieen: Function;
  changeHeader: Function;
  getMateriaalVraag: Function;
  createMateriaalAanbod: Function;
}

export class CreateAanbodFromVraag extends React.Component<ICreateAanbodFromVraagProps> {

  componentDidMount(): void {
    this.changeHeader('/aanbod/overzicht', 'materiaal.edit.header.return.aanbod.update.mijn');
    this.props.getHulpvraagForOnderneming();
    this.props.getMateriaalVraag(this.props.match.params.vraagUid)
      .then(response => this.props.formState(materiaalAanbodFromMateriaalVraagResponse(response)));
  }

  componentWillUnmount(): void {
    this.props.formState(initialFormState(initialMateriaalAanbodFormState()));
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  render(): React.ReactNode {
    return (
      <>
        <div id="formSection">
          <div className="vl-grid">
            <h1
              className="vl-h1 vl-col--6-12 vl-u-spacer--tiny">{intl.get('materiaal.edit.form.title.create.aanbod')}</h1>
            {
              !isSymbioseTeam() &&
              <div className="vl-col--6-12">
                <VraagHulpButton handleClick={this.handleOpenModal} />
              </div>
            }
          </div>
          <fieldset>
            <MateriaalAanbodForm
              categorieQueryResult={this.props.categorieQueryResult}
              materiaalAanbod={this.props.materiaalAanbod}
              form={this.props.form}
              dispatch={this.props.dispatch}
              handleSave={this.handleSave}
              handleUpload={this.handleUpload}
              handleDownload={this.handleFotoDownload}
              formEditPropertyValue={this.props.formEditPropertyValue}
              handleCancel={this.handleCancel}
              formEdit={this.props.formEdit}
              formState={this.props.formState}
              formValidate={this.props.formValidate}
              resetCategorieen={this.props.resetCategorieen}
              updateHoofdCategorie={this.props.updateHoofdCategorie}
              updateStandaardCategorie={this.props.updateStandaardCategorie}
              formSelector={formSelector}
              formName={CREATE_MATERIAAL_AANBOD_FROM_VRAAG_FORM}
            />
          </fieldset>
        </div>
        <AanbodMakenUitVraagOndernemingHulpvraagModal />
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }
    this.props.showModal(AANBOD_MAKEN_UIT_VRAAG_ONDERNEMING_HULPVRAAG, {
      cancel: () => this.props.hideModal(AANBOD_MAKEN_UIT_VRAAG_ONDERNEMING_HULPVRAAG)
    });
  };

  handleSave = formData => {
    this.props.createMateriaalAanbod(materiaalAanbodFormDataToRequest(formData))
      .then(response => {
        const aanbodUid = extractUid('aanbod', response.value.data.aanbodReferentie);
        this.resetFormAndRedirect(`/aanbod/${aanbodUid}/vragen`);
      });
  };

  handleUpload = (files: File[], bestandstype: Bestandstype) => uploadMateriaalAanbodBestanden(files, bestandstype);

  handleFotoDownload = (foto: IBestand): Promise<string> => Promise.resolve(foto.url);

  handleCancel = () => this.resetFormAndRedirect('/aanbod/overzicht');

  resetFormAndRedirect = (redirectUrl: string) => {
    this.props.formState(initialFormState(initialMateriaalAanbodFormState()));
    this.props.resetCategorieen();
    this.props.history.push(redirectUrl);
  };

  private changeHeader(backUrl: string, backTranslation) {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      backTranslation,
      '',
      [
        {
          title: 'materiaal.edit.header.title.aanbod.create',
          isActive: true
        }
      ],
      () => {
        this.resetFormAndRedirect(backUrl);
      });
  }
}

const formSelector = storeState => storeState.aanbod.createMateriaalAanbodFromVraagForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState),
  materiaalAanbod: storeState.aanbod.details,
  conversatie: storeState.bericht.bericht.conversatie,
  categorieQueryResult: storeState.categorie.categorieenList
});

const mapDispatchToProps = dispatch => {
  const actions = {
    showModal,
    hideModal,
    createHulpvraagForOnderneming,
    getHulpvraagForOnderneming,
    updateHoofdCategorie,
    updateStandaardCategorie,
    resetCategorieen,
    changeHeader,
    getMateriaalVraag,
    createMateriaalAanbod,
    formState: formState(CREATE_MATERIAAL_AANBOD_FROM_VRAAG_FORM),
    formValidate: formValidate<IMateriaalAanbodForm>(CREATE_MATERIAAL_AANBOD_FROM_VRAAG_FORM),
    formEdit: formEdit(CREATE_MATERIAAL_AANBOD_FROM_VRAAG_FORM),
    formEditPropertyValue: formEditPropertyValue(CREATE_MATERIAAL_AANBOD_FROM_VRAAG_FORM)
  };
  return { ...bindActionCreators(actions, dispatch), dispatch };
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(CreateAanbodFromVraag));
