import React from 'react';
import intl from 'react-intl-universal';
import {getRelevanteAanbiedingen, IRelevanteAanbiedingenList} from 'src/reducers/vraag/materiaal/relevante-aanbiedingen';
import {connect} from 'react-redux';
import {Loading} from 'webuniversum-react';
import {extractUid} from 'src/modules/referentie-uri';
import {MateriaalTile} from 'src/modules/materiaal/materiaal-tile';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {IContactPersoon} from 'src/reducers/authentication';
import {createHulpvraagForVraag, getHulpvraagForVraag, IConversatie} from 'src/reducers/bericht/conversatie';
import {RelevanteAanbiedingenOverviewHulpvraagModal} from 'src/modules/bericht/bericht-modal';
import {RELEVANTE_AANBIEDINGEN_OVERVIEW} from 'src/reducers/vraag';
import {relevantieTekst} from 'src/modules/table-components/relevantie-begeleidende-tekst';
import {RelevanteAanbiedingenAccordion} from 'src/modules/vraag/materiaal/relevante-aanbiedingen-accordion';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IMateriaalAanbodOverviewProps {
  getRelevanteAanbiedingen: Function;
  conversatie: IConversatie;
  createHulpvraagForVraag: Function;
  getHulpvraagForVraag: Function;
  showModal: Function;
  hideModal: Function;
  queryResult: IRelevanteAanbiedingenList;
  history: any;
  location: any;
  match: any;
  isAuthenticated: boolean;
  currentUser: IContactPersoon;
  changeHeader: Function;
}

export class RelevanteAanbiedingenMateriaalVraag extends React.Component<IMateriaalAanbodOverviewProps, undefined> {

  componentDidMount() {
    this.props.getHulpvraagForVraag(this.props.match.params.vraagUid);
    this.props.getRelevanteAanbiedingen(this.props.match.params.vraagUid);
    if (this.props.location.pathname.startsWith('/vraag/zoeken')) {
      this.changeHeader('/vraag/zoeken', 'vraag.relevante-aanbiedingen.header.return.zoeken');
    } else {
      this.changeHeader('/vraag/overzicht', 'vraag.relevante-aanbiedingen.header.return.mijn');
    }
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
    this.props.hideModal(RELEVANTE_AANBIEDINGEN_OVERVIEW);
  }

  private changeHeader(backUrl: string, backTranslation) {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      backTranslation,
      '',
      [
        {
          title: 'vraag.relevante-aanbiedingen.header.title',
          isActive: true
        },
        {
          title: 'vraag.detail.title',
          isActive: false,
          redirectUrl: '/vraag/' + this.props.match.params.vraagUid
        }
      ],
      () => {
        this.props.history.push(backUrl);
      });
  }

  render(): React.ReactNode {
    return (
      <>
        {this.props.isAuthenticated &&
        <div>
          <div id="buttonSection" className="vl-u-spacer vl-grid" style={{ marginBottom: '0' }}>
            <h2 className="vl-h2 vl-col--6-12">{intl.get('vraag.relevante-aanbiedingen.overview.title')}</h2>
            <div className="vl-col--6-12">
              <VraagHulpButton handleClick={this.handleOpenModal} />
            </div>
          </div>
          <Loading loading={this.props.queryResult.loading}>
            <MateriaalTile layout="bar" type="vraag" data={this.props.queryResult} onClick={this.navigateToMijnMateriaalVraag}/>
            <div className="vl-u-spacer"/>
            <p>{intl.get(`vraag.relevante-aanbiedingen.relevantie-tekst.${relevantieTekst(this.props.queryResult)}`)}</p>

            <RelevanteAanbiedingenAccordion title={intl.get('vraag.relevante-aanbiedingen.title.top-relevantie')}
              defaultOpen={this.props.queryResult.topRelevanties.length > 0}
              aanbiedingen={this.props.queryResult.topRelevanties}
              navigateToAanbieding={this.navigateToRelevanteAanbieding} />

            <RelevanteAanbiedingenAccordion title={intl.get('vraag.relevante-aanbiedingen.title.interessante-relevantie')}
              defaultOpen={this.props.queryResult.interessanteRelevanties.length > 0}
              aanbiedingen={this.props.queryResult.interessanteRelevanties}
              navigateToAanbieding={this.navigateToRelevanteAanbieding} />

            <RelevanteAanbiedingenAccordion title={intl.get('vraag.relevante-aanbiedingen.title.andere-relevantie')}
              aanbiedingen={this.props.queryResult.andereRelevanties}
              navigateToAanbieding={this.navigateToRelevanteAanbieding} />
          </Loading>

          <RelevanteAanbiedingenOverviewHulpvraagModal/>
        </div>
        }
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForVraag(this.props.match.params.vraagUid);
    } else {
      this.props.getHulpvraagForVraag(this.props.match.params.vraagUid);
    }

    this.props.showModal(RELEVANTE_AANBIEDINGEN_OVERVIEW, {
      cancel: () => this.props.hideModal(RELEVANTE_AANBIEDINGEN_OVERVIEW)
    });
  };

  private navigateToMijnMateriaalVraag = (vraagReferentie: string) => {
    this.props.history.push(`/vraag/${extractUid('vraag', vraagReferentie)}`);
  };

  private navigateToRelevanteAanbieding = data => {
    this.props.history.push(`relevant-aanbod/${extractUid('aanbod', data)}`);
  };
}

const mapStateToProps = storeState => ({
  queryResult: storeState.vraag.relevanteAanbiedingenList,
  conversatie: storeState.bericht.bericht.conversatie,
  isAuthenticated: storeState.authentication.security.isAuthenticated,
  currentUser: storeState.authentication.contactPersoon
});

const mapDispatchToProps = {
  getRelevanteAanbiedingen,
  createHulpvraagForVraag,
  getHulpvraagForVraag,
  showModal,
  hideModal,
  changeHeader
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(RelevanteAanbiedingenMateriaalVraag));
