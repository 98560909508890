import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { ILink } from './industriele-symbiose-paginas';

export const ACTION_TYPES = {
  GET_ALL: 'informatieve-website/workshops/GET_ALL'
};

export interface IWorkshop {
  tijdstip: Date;
  titel: string;
  locatie: string;
  beschrijving: string;
  inschrijvingslink: ILink;
}

export interface IGetWorkshopsState {
  loading: boolean;
  errorMessage: any;
  workshops: IWorkshop[];
}

export const initialState: IGetWorkshopsState = {
  loading: false,
  errorMessage: null,
  workshops: []
};

export const workshopsReducer = (state = initialState, action: AnyAction): IGetWorkshopsState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      workshops: action.payload.data.map(workshop => ({
        ...workshop,
        tijdstip: workshop.tijdstip && new Date(workshop.tijdstip),
        inschrijvingslink: workshop.inschrijvingslink && {
          url: workshop.inschrijvingslink.url,
          beschrijving: workshop.inschrijvingslink.beschrijving
        }
      }))
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite';

export const getWorkshops: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET_ALL,
  payload: axios.get(`${apiUrl}/workshops/${taal}`)
});
