import {combineReducers} from 'redux';
import {listMijnTechnologieReducer} from 'src/reducers/technologie/list';
import {getTechnologieReducer} from 'src/reducers/technologie/get';
import {searchTechnologieForm, searchTechnologieReducer} from 'src/reducers/technologie/search';
import {initialModalState, modalReducer} from 'src/reducers/modal/modal';
import {formReducer, initialFormState} from 'redux-form-library';
import {initialTechnologieFormState} from 'src/reducers/technologie/technologie-form';

export const CREATE_TECHNOLOGIE_FORM = 'CREATE_TECHNOLOGIE_FORM';
export const UPDATE_TECHNOLOGIE_FORM = 'UPDATE_TECHNOLOGIE_FORM';

export const DELETE_TECHNOLOGIE_MODAL = 'modal/technologie/DELETE_TECHNOLOGIE';

export const technologie = combineReducers({
  details: getTechnologieReducer,
  createTechnologieForm: formReducer(CREATE_TECHNOLOGIE_FORM, initialFormState(initialTechnologieFormState())),
  updateTechnologieForm: formReducer(UPDATE_TECHNOLOGIE_FORM, initialFormState(initialTechnologieFormState())),
  mijnTechnologie: listMijnTechnologieReducer,
  searchTechnologieForm,
  searchTechnologieReducer,
  deleteTechnologieModal: modalReducer(DELETE_TECHNOLOGIE_MODAL, initialModalState())
});
