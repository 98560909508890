import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {getStore} from 'src/redux-store';
import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {
  formEdit,
  formEditPropertyValue,
  formFieldsToObject,
  FormPropsFactory,
  FormPropsFactoryBuilder,
  formState,
  IFormActions,
  IFormState,
  initialFormState
} from 'redux-form-library';
import {CellGenerator, DataTable, FormComponent, InputField, Loading} from 'webuniversum-react';
import {SearchFilterMetResetButton} from 'src/modules/materiaal/search-filter-met-reset-button';
import {extractUid} from 'src/modules/referentie-uri';
import {RegistratieHint} from 'src/modules/welkomstpagina/registratie-hint';
import {
  clearSearchResults,
  initialSearchTechnologieFormState,
  ISearchTechnologieForm,
  ITechnologieSearch,
  SEARCH_TECHNOLOGIE_FORM,
  zoekenTechnologie
} from 'src/reducers/technologie/search';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface ISearchTechnologieProps extends IFormActions<ISearchTechnologieForm> {
  dispatch: Dispatch;
  form: IFormState<ISearchTechnologieForm>;
  queryResult: ITechnologieSearch;
  isAuthenticated: boolean;
  taal: string;
  zoekenTechnologie: Function;
  changeHeader: Function;
  history: any;
  clearSearchResults: Function;
}

export class SearchTechnologie extends React.Component<ISearchTechnologieProps, undefined> {
  componentDidMount(): void {
    if (this.props.queryResult.searched) {
      this.handleSubmit(formFieldsToObject(this.props.form.fields));
    } else {
      this.resetSearchComponent();
    }

    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      this.props.isAuthenticated ? 'applicatie.zoekpagina.terug' : 'welkomstpagina.terug',
      '',
      [
        {
          title: 'applicatie.tablink.symbioseteam.aanbod',
          isActive: false,
          redirectUrl: '/aanbod/zoeken'
        },
        {
          title: 'applicatie.tablink.symbioseteam.vraag',
          isActive: false,
          redirectUrl: '/vraag/zoeken'
        },
        {
          title: 'applicatie.tablink.symbioseteam.technologie',
          isActive: true
        }
      ], () => {
        this.props.history.push(this.props.isAuthenticated ? '/zoekpagina' : '/');
      }
    );
  }

  handleSubmit = formData => this.props.zoekenTechnologie(this.props.taal, formData);

  generateColumnDefinition = (titel, beschrijving, beschrijvingTeVerwerkenMateriaal, beschrijvingVerwerktMateriaal) => (
    [
      {
        cellObject: new CellGenerator('titel'),
        header: {sortable: false, title: titel}
      },
      {
        cellObject: new CellGenerator('beschrijving'),
        header: {sortable: false, title: beschrijving}
      },
      {
        cellObject: new CellGenerator('beschrijvingTeVerwerkenMateriaal'),
        header: {sortable: false, title: beschrijvingTeVerwerkenMateriaal}
      },
      {
        cellObject: new CellGenerator('beschrijvingVerwerktMateriaal'),
        header: {sortable: false, title: beschrijvingVerwerktMateriaal}
      }
    ]
  );

  private navigateToTechnologie = data => {
    this.props.history.push(`/technologie/zoeken/${extractUid('technologie', data)}/${this.props.taal}`);
  };

  resetSearchComponent = () => {
    this.props.formState(initialFormState(initialSearchTechnologieFormState()));
    this.props.clearSearchResults();
  };

  render(): React.ReactNode {
    const fields = this.props.form.fields;
    const formPropsFactory: FormPropsFactory<ISearchTechnologieForm> = new FormPropsFactoryBuilder()
      .setFieldsObject(fields)
      .setDispatch(this.props.dispatch)
      .setValidate(field => field)
      .setSelector(formSelector)
      .setStore(getStore())
      .setFormName(SEARCH_TECHNOLOGIE_FORM)
      .build();

    return (
      <>
        <h1 className="vl-h1 vl-u-spacer--tiny">{intl.get('technologie.search.form.title')}</h1>
        {!this.props.isAuthenticated && <RegistratieHint/>}

        <SearchFilterMetResetButton onSubmit={formPropsFactory.createSubmitHandler(this.handleSubmit)}
          searchButtonText={intl.get('technologie.search.form.button.search')}
          onReset={this.resetSearchComponent}
          resetButtonText={intl.get('technologie.search.form.button.reset')}>
          <div className="vl-u-spacer--tiny">
            <FormComponent id="titel"
              label={intl.get('technologie.search.form.criteria.titel.label')}
              block>
              <InputField {...formPropsFactory.createInputProps('titel')} layout="block"/>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--tiny">
            <FormComponent id="beschrijvingTeVerwerkenMateriaal"
              label={intl.get('technologie.search.form.criteria.beschrijving-te-verwerken-materiaal.label')}
              block>
              <InputField {...formPropsFactory.createInputProps('beschrijvingTeVerwerkenMateriaal')} layout="block"/>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--tiny">
            <FormComponent id="beschrijvingVerwerktMateriaal"
              label={intl.get('technologie.search.form.criteria.beschrijving-verwerkt-materiaal.label')}
              block>
              <InputField {...formPropsFactory.createInputProps('beschrijvingVerwerktMateriaal')} layout="block"/>
            </FormComponent>
          </div>
        </SearchFilterMetResetButton>
        <div className="vl-u-spacer"/>
        {this.props.queryResult.results.length === 0 && this.props.queryResult.searched && <div>
          <p><strong>{intl.get('technologie.search.results.no-results.message')}</strong></p>
          <ul style={{listStyleType: 'disc', listStylePosition: 'inside'}}>
            <li>{intl.get('technologie.search.results.no-results.suggestie1')}</li>
            <li>{intl.get('technologie.search.results.no-results.suggestie2')}</li>
            <li>{intl.get('technologie.search.results.no-results.suggestie3')}</li>
            <li>{intl.get('technologie.search.results.no-results.suggestie4')}</li>
          </ul>
        </div>}

        {this.props.queryResult.results.length > 0 &&
        <Loading loading={this.props.queryResult.loading}>
          <DataTable data={this.props.queryResult.results}
            columns={this.generateColumnDefinition(
              intl.get('technologie.search.results.table.header.titel'),
              intl.get('technologie.search.results.table.header.beschrijving'),
              intl.get('technologie.search.results.table.header.beschrijving-te-verwerken-materiaal'),
              intl.get('technologie.search.results.table.header.beschrijving-verwerkt-materiaal')
            )}
            rowIdAccessor="technologieReferentie"
            onRowClick={this.navigateToTechnologie}
          />
        </Loading>
        }
      </>
    );
  }
}

const formSelector = storeState => storeState.technologie.searchTechnologieForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState),
  queryResult: storeState.technologie.searchTechnologieReducer,
  isAuthenticated: storeState.authentication.security.isAuthenticated,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = dispatch => {
  const actions = {
    zoekenTechnologie,
    changeHeader,
    formState: formState(SEARCH_TECHNOLOGIE_FORM),
    formEdit: formEdit(SEARCH_TECHNOLOGIE_FORM),
    formEditPropertyValue: formEditPropertyValue(SEARCH_TECHNOLOGIE_FORM),
    clearSearchResults
  };
  return {...bindActionCreators(actions, dispatch), dispatch};
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(SearchTechnologie));
