import React from 'react';
import {InfoTile} from 'webuniversum-react';
import {Afstand, Onderneming, RelevantieScore, Verwerkingswijze} from 'src/modules/detail-components/icon-value';
import {IBegrensdeWaarde} from 'src/reducers/relevantie';
import {CoverPhoto} from 'src/modules/materiaal/cover-photo';
import {IMateriaalBeschikbaarheid} from '../beschikbaarheid/materiaal-beschikbaarheid';
import intl from 'react-intl-universal';
import './overzicht-tegel.css';
import {changeDateFormat, DATEPICKER_DATE, ISO_LOCAL_DATE} from '../../dates';

interface IOverzichtTegelProps {
  coverFotoUrlFetcher: () => Promise<string>;
  titel: string;
  beschrijving: string;
  relevantieScore?: IBegrensdeWaarde;
  afstand?: IBegrensdeWaarde;
  verwerkingswijze: string;
  onderneming: string;
  onClick?: () => void;
  beschikbaarheid: IMateriaalBeschikbaarheid;
}

const mapToText = (beschikbaarheid: IMateriaalBeschikbaarheid) => {
  if (beschikbaarheid.van && beschikbaarheid.tot) {
    return intl.get('beschikbaarheid.overzicht_tegel.all', {
      van: changeDateFormat(beschikbaarheid.van, ISO_LOCAL_DATE, DATEPICKER_DATE),
      tot: changeDateFormat(beschikbaarheid.tot, ISO_LOCAL_DATE, DATEPICKER_DATE)
    });
  } else {
    if (beschikbaarheid.van) {
      return intl.get('beschikbaarheid.overzicht_tegel.van', {
        van: changeDateFormat(beschikbaarheid.van, ISO_LOCAL_DATE, DATEPICKER_DATE)
      });
    }
    if (beschikbaarheid.tot) {
      return intl.get('beschikbaarheid.overzicht_tegel.tot', {
        tot: changeDateFormat(beschikbaarheid.tot, ISO_LOCAL_DATE, DATEPICKER_DATE)
      });
    }
  }
};

export class OverzichtTegel extends React.Component<IOverzichtTegelProps> {
  render(): React.ReactNode {
    const {coverFotoUrlFetcher, titel, beschrijving, relevantieScore, afstand, verwerkingswijze, onderneming, onClick, beschikbaarheid} = this.props;

    return <>
      <InfoTile onClick={onClick} className="vl-u-spacer--tiny">
        <div className="vl-grid">
          <div className="vl-col--2-12 vl-col--3-12--m vl-col--4-12--s vl-col--12-12--xs cover-foto">
            <span className="vertical-align-center"/>
            <CoverPhoto urlFetcher={coverFotoUrlFetcher} label={titel}/>
          </div>
          <div className="overzicht-tegel-content vl-col--8-12 vl-col--6-12--m vl-col--12-12--s vl-col--12-12--xs">
            <h3>{titel}</h3>
            <span className="line-clamp">{beschrijving}</span>
            {beschikbaarheid && <div className="beschikbaarheid">{mapToText(beschikbaarheid)}</div>}
          </div>
          <div className="vl-col--2-12 vl-col--3-12--m vl-col--4-12--s vl-col--12-12--xs overzicht-tegel-right">
            <ul>
              <li>{relevantieScore?.value && <RelevantieScore value={relevantieScore?.value}/>}</li>
              <li>{afstand && <Afstand value={afstand}/>}</li>
              <li><Verwerkingswijze value={verwerkingswijze}/></li>
              <li>{onderneming && <Onderneming naam={onderneming}/>}</li>
            </ul>
          </div>
        </div>
      </InfoTile>
    </>;
  }
}
