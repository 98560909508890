import React from 'react';
import intl from 'react-intl-universal';
import {
  createValidateFunction,
  FormFields,
  formFieldsToObject,
  FormPropsFactory,
  FormPropsFactoryBuilder,
  IFormActions,
  IFormState,
  initialFormState,
  ValidationMethod
} from 'redux-form-library';
import {
  Button,
  ButtonGroup,
  FormComponent,
  InputField,
  Label,
  Select,
  StatelessAccordion,
  Textarea
} from 'webuniversum-react';
import { ICategorieenMap } from 'src/reducers/categorie/list';
import {
  IMateriaalVraagForm,
  initialMateriaalVraagFormState
} from 'src/reducers/vraag/materiaal/materiaal-vraag-form';
import { getStore } from 'src/redux-store';
import CategorieForm from 'src/modules/categorie/categorie-form';
import CategorieModal from 'src/modules/categorie/categorie-modal';
import { DetailCategorie } from 'src/modules/detail-components/detail-categorie';
import { Eural } from 'src/modules/eural/eural';
import { componentOffset } from 'src/modules/form-components/componentOffset';
import { enumOptions } from 'src/modules/form-components/translatable-select-options';
import { GevaarlijkeStoffen } from 'src/modules/materiaal/gevaarlijke-stoffen';
import { GevaarlijkeStoffenEnum } from 'src/modules/materiaal/gevaarlijke-stoffen-enum';
import { MateriaalHoeveelheid } from 'src/modules/materiaal/materiaal-hoeveelheid';
import { MateriaalToestand } from 'src/modules/materiaal/materiaal-toestand-enum';
import { PrivaatPubliekSwitch } from 'src/modules/materiaal/privaat-publiek-switch';
import { UitlegRelevantieBerekening } from 'src/modules/materiaal/uitleg-relevantie-berekening';
import { BeoogdeVerwerkingswijzeVraag } from 'src/modules/vraag/materiaal/beoogde-verwerkingswijze-vraag-enum';
import { AdresForm } from 'src/modules/adres/adres-form';
import { IBestand } from 'src/reducers/bestand';
import FotoUploadContainer from 'src/modules/foto/foto-upload-container';
import { BijlageUploadContainer } from 'src/modules/bijlage/bijlage-upload-container';
import { IGetMateriaalVraagState } from 'src/reducers/vraag/materiaal/get';
import { Dispatch } from 'redux';
import { Bestandstype } from '../../bestand/bestandstype';
import MateriaalBeschikbaarheid from '../../beschikbaarheid/materiaal-beschikbaarheid';
import capitalize from 'lodash/capitalize';

export interface IMateriaalVraagFormProps extends IFormActions<IMateriaalVraagForm> {
  categorieQueryResult: ICategorieenMap;
  materiaalVraag: IGetMateriaalVraagState;
  form: IFormState<IMateriaalVraagForm>;
  dispatch: Dispatch;
  handleSave: Function;
  handleUpload: (files: File[], bestandstype: Bestandstype) => Promise<any>;
  handleDownload: (bestand: IBestand) => Promise<string>;
  updateHoofdCategorie: Function;
  updateStandaardCategorie: Function;
  resetCategorieen: Function;
  formEditPropertyValue: Function;
  handleCancel: Function;
  formSelector: Function;
  formName: string;
}

export interface IMateriaalVraagFormState {
  categorieFormVisible: boolean;
}

export class MateriaalVraagForm extends React.Component<IMateriaalVraagFormProps, IMateriaalVraagFormState> {
  createValidationRules = () => {
    const beschikbaarheid = this.props.form.fields.beschikbaarheid;
    return ({
      titel: 'string|required|max:100',
      beschrijving: 'string|required|max:4000',
      hoeveelheid: {
        frequentie: 'required',
        kwantiteit: 'required|numeric|min:0.001|max:9999999999999.999|maxScale:3',
        eenheid: 'required'
      },
      beoogdeVerwerkingswijze: 'required',
      categorieen: 'array|required',
      eural: {
        eural: 'string|is_eural_code|max:8|min:8'
      },
      gevaarlijkeStoffen: {
        beschrijvingGevaarlijkeStoffen:
          this.props.form.fields.gevaarlijkeStoffen.gevaarlijkeStoffen.value === GevaarlijkeStoffenEnum.MET_GEVAARLIJKE_STOFFEN ? 'max:150|required' : ''
      },
      adres: {
        straatnaam: 'required|max:255',
        huisnummer: 'required|max:50',
        adresLijn2: 'max:255',
        postcode: 'required|max:20',
        gemeente: 'required|max:255',
        land: 'required'
      },
      materiaalToestand: 'required',
      kleur: 'max:50',
      bijkomendeInfo: 'max:4000',
      materiaalSamenstelling: 'required|max:250',
      productieProces: 'max:4000',
      idealeOorsprong: 'max:250',
      beschikbaarheid: {
        tot: beschikbaarheid.van.value && beschikbaarheid.tot.value ? `is_after:${beschikbaarheid.van.value}` : 'string'
      }
    });
  };

  createCategorieValidationRules = (standaardCategorieen, subCategorieen) => ({
    categorie: {
      hoofdCategorie: 'required',
      standaardCategorie: standaardCategorieen.length > 0 ? 'required' : 'string',
      subCategorie: subCategorieen.length > 0 ? 'required' : 'string'
    }
  });

  generateErrorMessages = () => ({
    'required.titel': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.titel.fields.titel.label')
    }),
    'max.titel': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.titel.fields.titel.label'),
      MAX: '100'
    }),
    'required.beschrijving': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.beschrijving-vraag.fields.beschrijving.label')
    }),
    'max.beschrijving': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.beschrijving-vraag.fields.beschrijving.label'),
      MAX: '4000'
    }),
    'required.categorieen': intl.get('materiaal.edit.form.classificatie.fields.categorieen.required'),
    'required.categorie.hoofdCategorie': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.classificatie.fields.categorie.label.required')
    }),
    'required.categorie.standaardCategorie': intl.get('validation.form.field.incomplete', {
      NAME: intl.get('materiaal.edit.form.classificatie.fields.categorie.label.incomplete')
    }),
    'required.categorie.subCategorie': intl.get('validation.form.field.incomplete', {
      NAME: intl.get('materiaal.edit.form.classificatie.fields.categorie.label.incomplete')
    }),
    'is_eural_code.eural.eural': intl.get('materiaal.edit.form.classificatie.fields.eural.invalid'),
    'min.eural.eural': intl.get('materiaal.edit.form.classificatie.fields.eural.full-length'),
    'max.eural.eural': intl.get('materiaal.edit.form.classificatie.fields.eural.full-length'),
    'required.hoeveelheid.frequentie': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.frequentie.label')
    }),
    'required.materiaalSamenstelling': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.samenstelling-vraag.fields.samenstelling.label')
    }),
    'max.materiaalSamenstelling': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.samenstelling-vraag.fields.samenstelling.label'),
      MAX: '250'
    }),
    'max.productieProces': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.productie-proces-vraag.fields.productie-proces.label'),
      MAX: '4000'
    }),
    'max.idealeOorsprong': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.ideale-oorsprong.fields.ideale-oorsprong.label'),
      MAX: '250'
    }),
    'required.hoeveelheid.kwantiteit': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.kwantiteit.label')
    }),
    'numeric.hoeveelheid.kwantiteit': intl.get('validation.form.field.numeric.type', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.kwantiteit.label')
    }),
    'min.hoeveelheid.kwantiteit': intl.get('validation.form.field.numeric.min', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.kwantiteit.label'),
      MIN: '0.001'
    }),
    'max.hoeveelheid.kwantiteit': intl.get('validation.form.field.numeric.max', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.kwantiteit.label'),
      MAX: `1 ${intl.get('validation.form.field.numeric.values.trillion')}`
    }),
    'maxScale.hoeveelheid.kwantiteit': intl.get('validation.form.field.numeric.maxscale', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.kwantiteit.label'),
      MAXSCALE: '3'
    }),
    'required.hoeveelheid.eenheid': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.hoeveelheid.fields.eenheid.label')
    }),
    'required.adres.straatnaam': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.straatnaam.label')
    }),
    'max.adres.straatnaam': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.adres.fields.straatnaam.label'),
      MAX: '255'
    }),
    'required.adres.huisnummer': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.huisnummer.label')
    }),
    'max.adres.huisnummer': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.adres.fields.huisnummer.label'),
      MAX: '50'
    }),
    'max.adres.adresLijn2': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.adres.fields.adreslijn2.label'),
      MAX: '255'
    }),
    'required.adres.postcode': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.postcode.label')
    }),
    'max.adres.postcode': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.adres.fields.postcode.label'),
      MAX: '20'
    }),
    'required.adres.gemeente': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.gemeente.label')
    }),
    'max.adres.gemeente': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.adres.fields.gemeente.label'),
      MAX: '255'
    }),
    'required.adres.land': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.land.label')
    }),
    'required.materiaalToestand': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.meer-informatie.fields.materiaaltoestand.label')
    }),
    'max.kleur': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.meer-informatie.fields.kleur.fields.label'),
      MAX: '50'
    }),
    'required.beoogdeVerwerkingswijze': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.meer-informatie.fields.beoogde-verwerkingswijze.label')
    }),
    'max.bijkomendeInfo': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.meer-informatie.fields.bijkomendeinfo.label'),
      MAX: '4000'
    }),
    'required.gevaarlijkeStoffen.beschrijvingGevaarlijkeStoffen': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.beschrijving.name')
    }),
    'max.gevaarlijkeStoffen.beschrijvingGevaarlijkeStoffen': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('materiaal.edit.form.gevaarlijke-stoffen.fields.beschrijving.name'),
      MAX: '150'
    }),
    'is_after.beschikbaarheid.tot': intl.get('validation.form.field.date.is_after', {
      NAME: capitalize(intl.get('materiaal.edit.form.beschikbaarheid.fields.tot.name')),
      OTHER: intl.get('materiaal.edit.form.beschikbaarheid.fields.van.name').toLowerCase()
    })
  });

  createValidation = (errormessages, validationRules) => createValidateFunction(validationRules, errormessages);

  createValidationFunction = () => this.createValidation(this.generateErrorMessages(), this.createValidationRules());

  createCategorieValidationFunction = () => {
    const { categorieQueryResult } = this.props;
    const { standaardCategorieen, subCategorieen } = categorieQueryResult;
    const errorMessages = this.generateErrorMessages();
    const validationRules = this.createCategorieValidationRules(standaardCategorieen, subCategorieen);

    return this.createValidation(errorMessages, validationRules);
  };

  private readonly myrefs: {
    titel: any;
    beschrijving: any;
    categorie: any;
    eural: any;
    materiaalSamenstelling: any;
    materiaalToestand: any;
    kleur: any;
    beoogdeVerwerkingswijze: any;
    productieProces: any;
    idealeOorsprong: any;
    hoeveelheid: any;
    adres: any;
    gevaarlijkeStoffen: any;
    bijkomendeInfo: any;
    beschikbaarheid: any;
  };

  constructor(props: IMateriaalVraagFormProps) {
    super(props);
    this.state = { categorieFormVisible: true };
    this.myrefs = {
      titel: React.createRef(),
      beschrijving: React.createRef(),
      categorie: React.createRef(),
      eural: React.createRef(),
      materiaalSamenstelling: React.createRef(),
      materiaalToestand: React.createRef(),
      kleur: React.createRef(),
      beoogdeVerwerkingswijze: React.createRef(),
      productieProces: React.createRef(),
      idealeOorsprong: React.createRef(),
      hoeveelheid: React.createRef(),
      adres: React.createRef(),
      gevaarlijkeStoffen: React.createRef(),
      bijkomendeInfo: React.createRef(),
      beschikbaarheid: React.createRef()
    };
  }

  componentDidUpdate(prevProps: Readonly<IMateriaalVraagFormProps>, prevState: Readonly<IMateriaalVraagFormState>, snapshot?: any): void {
    if (prevProps.form.fields.categorieen.value.length !== this.props.form.fields.categorieen.value.length) {
      this.setState({ categorieFormVisible: this.props.form.fields.categorieen.value.length === 0 });
    }
  }

  render(): React.ReactNode {
    const { categorieQueryResult } = this.props;
    const { hoofdCategorieen, standaardCategorieen, subCategorieen } = categorieQueryResult;

    const fields = this.props.form.fields;
    const errorMessages = this.generateErrorMessages();
    const formPropsFactory: FormPropsFactory<IMateriaalVraagForm> = new FormPropsFactoryBuilder()
      .setFieldsObject(fields)
      .setDispatch(this.props.dispatch)
      .setValidate(this.createValidation(errorMessages, this.createValidationRules()))
      .setSelector(this.props.formSelector)
      .setStore(getStore())
      .setFormName(this.props.formName)
      .build();

    const dirty = this.props.form.dirty;
    const loading = this.props.materiaalVraag.loading;

    return (
      <>
        <h2 className="vl-h2 public-title">{intl.get('materiaal.edit.form.section.public.title')}</h2>
        <p className="vl-h3 public-text">{intl.get('materiaal.edit.form.section.public.text')}</p>
        <form className="vraag-form" onSubmit={this.handleSubmit}>
          <div className="vl-u-spacer--tiny" ref={this.myrefs.titel}>
            <FormComponent
              id="titel"
              label={intl.get('materiaal.edit.form.titel.fields.titel.label')}
              required={errorMessages['required.titel']}
              error={fields.titel.errors} block
            >
              <UitlegRelevantieBerekening
                tekstKey="materiaal.edit.form.titel.fields.titel.uitleg-relevantie-berekening.vraag" />
              <InputField
                {...formPropsFactory.createInputProps('titel')} error={fields.titel.errors.length > 0}
                layout="block"
              />
            </FormComponent>
          </div>
          <div ref={this.myrefs.beschrijving}>
            <FormComponent
              id="beschrijving"
              label={intl.get('materiaal.edit.form.beschrijving-vraag.fields.beschrijving.label')}
              required={errorMessages['required.beschrijving']}
              error={fields.beschrijving.errors}
              block
            >
              <UitlegRelevantieBerekening
                tekstKey="materiaal.edit.form.beschrijving-vraag.fields.beschrijving.uitleg-relevantie-berekening" />
              <Textarea
                {...formPropsFactory.createInputProps('beschrijving')}
                placeholder={intl.get('materiaal.edit.form.beschrijving-vraag.fields.beschrijving.placeholder')}
                error={fields.beschrijving.errors.length > 0} block
                rows={10}
              />
            </FormComponent>
          </div>
          <div className="vl-u-spacer--tiny" ref={this.myrefs.categorie}>
            <FormComponent
              id="categorieen"
              label={intl.get('materiaal.edit.form.classificatie.title')}
              required={errorMessages['required.categorieen']}
              error={fields.categorieen.errors} block
            >
              <UitlegRelevantieBerekening
                tekstKey="materiaal.edit.form.classificatie.fields.categorie.uitleg-relevantie-berekening" />
              <div className="vl-u-spacer--tiny" />
              {fields.categorieen.value.sort((a, b) => a.code.localeCompare(b.code))
                .map((cat, index) => (<div key={`${String(index).padStart(3, '0')}-${cat.code}`}>
                  <a onClick={this.verwijderCategorie(index)} className="vl-link--icon--close right">
                    <span className="vl-u-visually-hidden">
                      {intl.get('materiaal.edit.form.classificatie.fields.categorieen.verwijderen.submit')}
                    </span>
                  </a>
                  <DetailCategorie categorie={cat} />
                </div>))}
            </FormComponent>

            <StatelessAccordion
              layout="toggle" className="relevantie-accordion"
              title={intl.get('materiaal.edit.form.classificatie.fields.categorieen.toevoegen.title')}
              onClick={this.toggleCategorieToevoegen}
              open={this.state.categorieFormVisible}
            >
              <div className="vl-filter__form">
                <CategorieForm
                  fields={fields.categorie}
                  formPropsFactory={formPropsFactory.createSubFormPropsFactory('categorie')}
                  required
                  hoofdCategorieen={hoofdCategorieen}
                  standaardCategorieen={standaardCategorieen}
                  subCategorieen={subCategorieen}
                  updateHoofdCategorie={this.props.updateHoofdCategorie}
                  updateStandaardCategorie={this.props.updateStandaardCategorie}
                  resetField={this.resetField}
                />
                <ButtonGroup>
                  <Button type="button" color="primary" onClick={this.voegCategorieToe}>
                    {intl.get('materiaal.edit.form.classificatie.fields.categorieen.toevoegen.submit')}
                  </Button>
                  <Button type="button" color="secondary" onClick={this.toggleCategorieToevoegen}>
                    {intl.get('materiaal.edit.form.classificatie.fields.categorieen.toevoegen.cancel')}
                  </Button>
                </ButtonGroup>
              </div>
            </StatelessAccordion>
          </div>
          <div ref={this.myrefs.eural}>
            <Eural
              fields={fields.eural} formPropsFactory={formPropsFactory.createSubFormPropsFactory('eural')}
              required={false}
            />
          </div>
          <div className="vl-u-spacer--small" />
          <div ref={this.myrefs.materiaalSamenstelling}>
            <FormComponent
              id="materiaalSamenstelling"
              label={intl.get('materiaal.edit.form.samenstelling-vraag.fields.samenstelling.label')}
              required={errorMessages['required.materiaalSamenstelling']}
              error={fields.materiaalSamenstelling.errors}
              block
            >
              <UitlegRelevantieBerekening
                tekstKey="materiaal.edit.form.samenstelling-aanbod.fields.samenstelling.uitleg-relevantie-berekening" />
              <Textarea
                {...formPropsFactory.createInputProps('materiaalSamenstelling')}
                placeholder={intl.get('materiaal.edit.form.samenstelling-vraag.fields.samenstelling.placeholder')}
                error={fields.materiaalSamenstelling.errors.length > 0} block
                rows={2}
              />
            </FormComponent>
          </div>
          <div ref={this.myrefs.materiaalToestand}>
            <FormComponent
              id="toestand"
              label={intl.get('materiaal.edit.form.meer-informatie.fields.materiaaltoestand.label')}
              required={errorMessages['required.materiaalToestand']}
              error={fields.materiaalToestand.errors}
              block
            >
              <UitlegRelevantieBerekening
                tekstKey="materiaal.edit.form.meer-informatie.fields.materiaaltoestand.uitleg-relevantie-berekening" />
              <Select error={fields.materiaalToestand.errors.length > 0} block {...formPropsFactory.createSelectProps('materiaalToestand')}>
                {enumOptions(MateriaalToestand, 'materiaal.edit.form.meer-informatie.fields.materiaaltoestand.values')
                  .optionsWithPlaceholder('materiaal.edit.form.meer-informatie.fields.materiaaltoestand.placeholder')}
              </Select>
            </FormComponent>
          </div>
          <div className="vl-u-spacer--tiny" ref={this.myrefs.kleur}>
            <FormComponent
              id="kleur"
              label={intl.get('materiaal.edit.form.meer-informatie.fields.kleur.fields.label')}
              error={fields.kleur.errors} block
            >
              <InputField {...formPropsFactory.createInputProps('kleur')} layout="block" />
            </FormComponent>
          </div>

          <div className="vl-u-spacer--small" />

          <div ref={this.myrefs.beoogdeVerwerkingswijze}>
            <FormComponent
              label={intl.get('materiaal.edit.form.meer-informatie.fields.beoogde-verwerkingswijze.label')}
              id="beoogdeVerwerkingswijze"
              required={errorMessages['required.beoogdeVerwerkingswijze']}
              error={fields.beoogdeVerwerkingswijze.errors} block>
              <UitlegRelevantieBerekening
                tekstKey="materiaal.edit.form.meer-informatie.fields.verwerkingswijze.uitleg-relevantie-berekening" />
              <Select
                {...formPropsFactory.createInputProps('beoogdeVerwerkingswijze')}
                error={fields.beoogdeVerwerkingswijze.errors.length > 0} block
              >
                {enumOptions(BeoogdeVerwerkingswijzeVraag, 'materiaal.edit.form.meer-informatie.fields.beoogde-verwerkingswijze.values')
                  .optionsWithPlaceholder('materiaal.edit.form.meer-informatie.fields.beoogde-verwerkingswijze.placeholder')}
              </Select>
            </FormComponent>
          </div>
          <div ref={this.myrefs.productieProces}>
            <FormComponent
              id="productieProces"
              label={intl.get('materiaal.edit.form.productie-proces-vraag.fields.productie-proces.label')}
              error={fields.productieProces.errors}
              block
            >
              <Textarea
                {...formPropsFactory.createInputProps('productieProces')}
                placeholder={intl.get('materiaal.edit.form.productie-proces-vraag.fields.productie-proces.placeholder')}
                block
                rows={10}
              />
            </FormComponent>
          </div>
          <div ref={this.myrefs.idealeOorsprong}>
            <FormComponent
              id="idealeOorsprong"
              label={intl.get('materiaal.edit.form.ideale-oorsprong.fields.ideale-oorsprong.label')}
              error={fields.idealeOorsprong.errors}
              block
            >
              <InputField
                {...formPropsFactory.createInputProps('idealeOorsprong')}
                placeholder={intl.get('materiaal.edit.form.ideale-oorsprong.fields.ideale-oorsprong.placeholder')}
                layout="block"
              />
            </FormComponent>
          </div>

          <div className="vl-u-spacer--small" />
          <h3 className="vl-h3">{intl.get('materiaal.edit.form.fotos.title')}</h3>
          <div>
            <Label>{intl.get('materiaal.edit.form.fotos.fields.fotos.label')}</Label>
            <div className="vl-u-spacer--tiny" />
            <FotoUploadContainer
              fotos={fields.fotos.value}
              uploadFoto={files => this.props.handleUpload(files, Bestandstype.FOTO)}
              downloadFoto={this.props.handleDownload}
              handleFotoChange={this.handleFotoChange}
            />
          </div>

          <div ref={this.myrefs.beschikbaarheid}>
            <MateriaalBeschikbaarheid
              title={'materiaal.edit.form.beschikbaarheid.title'}
              subtitle={'materiaal.edit.form.beschikbaarheid.subtitle'}
              fields={fields.beschikbaarheid}
              formEditPropertyValue={this.props.formEditPropertyValue}
              formPropsFactory={formPropsFactory.createSubFormPropsFactory('beschikbaarheid')}
            />
          </div>

          <h3 className="vl-h3">{intl.get('materiaal.edit.form.publieke-bijlagen.title')}</h3>
          <div className="vl-u-spacer--tiny">
            <BijlageUploadContainer
              id="publieke-bijlagen-upload"
              bijlagen={fields.publiekeBijlagen.value}
              uploadBijlage={files => this.props.handleUpload(files, Bestandstype.PUBLIEKE_BIJLAGE)}
              handleBijlageChange={this.handlePubliekeBijlageChange}
              loading={loading}
              loadingText={intl.get('materiaal.edit.form.bijlagen-loading')}
            />
          </div>

          <h2 className="vl-h2 private-title">{intl.get('materiaal.edit.form.section.instellen.title')}</h2>
          <p className="vl-h3 private-text">{intl.get('materiaal.edit.form.section.instellen.text')}</p>

          <div className="vl-cta-title" ref={this.myrefs.hoeveelheid}>
            <div className="vl-cta-title__cta private-action right">
              <PrivaatPubliekSwitch initialValue={fields.toegangHoeveelheid.value} onChange={this.toggleToegangHoeveelheid} />
            </div>
            <MateriaalHoeveelheid
              title="materiaal.edit.form.hoeveelheid.title.vraag"
              formPropsFactory={formPropsFactory.createSubFormPropsFactory('hoeveelheid')}
              fields={fields.hoeveelheid}
            />
          </div>
          <div className="vl-cta-title" ref={this.myrefs.adres}>
            <div className="vl-cta-title__cta private-action right">
              <PrivaatPubliekSwitch initialValue={fields.toegangLocatie.value} onChange={this.toggleToegangLocatie} />
            </div>
            <h3 className="vl-h3">{intl.get('materiaal.edit.form.adres.title.vraag')}</h3>
            <UitlegRelevantieBerekening tekstKey="materiaal.edit.form.adres.uitleg-relevantie-berekening" />
            <div>
              <FormComponent id="locatienaam" label={intl.get('detail.contact-info.onderneming.label')} block>
                <span className="relevantie-icon block">{fields.locatienaam.value}</span>
              </FormComponent>
            </div>
            <AdresForm fields={fields.adres} formPropsFactory={formPropsFactory.createSubFormPropsFactory('adres')} />
          </div>

          <h2 className="vl-h2 private-title">{intl.get('materiaal.edit.form.section.private.title')}</h2>
          <p className="vl-h3 private-text">{intl.get('materiaal.edit.form.section.private.text')}</p>

          <h3 className="vl-h3">{intl.get('materiaal.edit.form.meer-informatie.title')}</h3>
          <div className="vl-u-spacer--tiny">
            <div ref={this.myrefs.gevaarlijkeStoffen}>
              <GevaarlijkeStoffen
                fields={fields.gevaarlijkeStoffen}
                formPropsFactory={formPropsFactory.createSubFormPropsFactory('gevaarlijkeStoffen')}
                euralCode={this.props.form.fields.eural.eural.value}
                formEditPropertyValue={this.props.formEditPropertyValue}
                isVraag
              />
            </div>
            <FormComponent
              id="bijkomendeInfo"
              label={intl.get('materiaal.edit.form.meer-informatie.fields.bijkomendeinfo.label')}
              error={fields.bijkomendeInfo.errors} block
            >
              <UitlegRelevantieBerekening
                tekstKey="materiaal.edit.form.meer-informatie.fields.bijkomendeinfo.uitleg-relevantie-berekening" />
              <Textarea
                {...formPropsFactory.createInputProps('bijkomendeInfo')}
                block
                rows={10}
                placeholder={intl.get('materiaal.edit.form.meer-informatie.fields.bijkomendeinfo.placeholder')}
              />
            </FormComponent>
          </div>

          <h3 className="vl-h3">{intl.get('materiaal.edit.form.private-bijlagen.title')}</h3>
          <div className="vl-u-spacer--tiny">
            <BijlageUploadContainer
              id="private-bijlagen-upload"
              bijlagen={fields.privateBijlagen.value}
              uploadBijlage={files => this.props.handleUpload(files, Bestandstype.PRIVATE_BIJLAGE)}
              handleBijlageChange={this.handlePrivateBijlageChange}
              loading={loading}
              loadingText={intl.get('materiaal.edit.form.bijlagen-loading')}
            />
          </div>

          <ButtonGroup>
            <Button type="submit" loading={loading} disabled={!dirty}>
              {intl.get('materiaal.edit.form.button.save')}
            </Button>
            <Button type="button" color="secondary" loading={loading} onClick={this.handleCancel}>
              {intl.get('materiaal.edit.form.button.cancel')}
            </Button>
          </ButtonGroup>
        </form>
        <div className="vl-u-spacer--tiny" />
        <CategorieModal formEditPropertyValue={this.props.formEditPropertyValue} />
      </>
    );
  }

  voegCategorieToe = () => {
    this.props.formValidate(this.createCategorieValidationFunction(), ValidationMethod.ValidateAll, this.props.formSelector(getStore().getState()));
    const state: IFormState<IMateriaalVraagForm> = this.props.formSelector(getStore().getState());
    const formFields: FormFields<IMateriaalVraagForm> = state.fields;
    if (state.valid) {
      const { categorieQueryResult } = this.props;
      const { hoofdCategorieen, standaardCategorieen, subCategorieen } = categorieQueryResult;
      const selectedCategorie = subCategorieen.find(cat => cat.referentie === formFields.categorie.subCategorie.value)
        || standaardCategorieen.find(cat => cat.referentie === formFields.categorie.standaardCategorie.value)
        || hoofdCategorieen.find(cat => cat.referentie === formFields.categorie.hoofdCategorie.value);
      this.props.formEditPropertyValue('categorieen', [...formFields.categorieen.value, {
        code: selectedCategorie.code,
        hoofdCategorie: formFields.categorie.hoofdCategorie.value,
        standaardCategorie: formFields.categorie.standaardCategorie.value,
        subCategorie: formFields.categorie.subCategorie.value
      }]);
      this.toggleCategorieToevoegen();
    }
  };

  toggleCategorieToevoegen = () => {
    this.setState({ categorieFormVisible: !this.state.categorieFormVisible });

    if (!this.state.categorieFormVisible) {
      this.resetField('hoofdCategorie');
      this.resetField('standaardCategorie');
      this.resetField('subCategorie');
      this.props.resetCategorieen();
    }
  };

  verwijderCategorie = (index: number) => () => {
    const formFields: FormFields<IMateriaalVraagForm> = this.props.formSelector(getStore().getState()).fields;
    const copy = [...formFields.categorieen.value];
    copy.splice(index, 1);
    this.props.formEditPropertyValue('categorieen', copy);
  };

  handleFotoChange = (fotos: IBestand[]) => {
    this.props.formEditPropertyValue('fotos', fotos);
  };

  handlePrivateBijlageChange = (bijlagen: IBestand[]) => {
    this.props.formEditPropertyValue('privateBijlagen', bijlagen);
  };

  handlePubliekeBijlageChange = (bijlagen: IBestand[]) => {
    this.props.formEditPropertyValue('publiekeBijlagen', bijlagen);
  };

  toggleToegangHoeveelheid = (value: string) => {
    this.props.formEditPropertyValue('toegangHoeveelheid', value);
  };

  toggleToegangLocatie = (value: string) => {
    this.props.formEditPropertyValue('toegangLocatie', value);
  };

  handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.formValidate(this.createValidationFunction(), ValidationMethod.ValidateAll, this.props.formSelector(getStore().getState()));
    const state: IFormState<IMateriaalVraagForm> = this.props.formSelector(getStore().getState());
    const formFields: FormFields<IMateriaalVraagForm> = state.fields;
    if (state.valid) {
      const formData = formFieldsToObject(formFields);
      this.props.handleSave(formData);
      if (this.props.materiaalVraag.errorMessage === null) {
        window.scrollTo(0, 0);
      }
    } else {
      window.scrollTo(0, componentOffset(formFields, this.myrefs, false));
    }
  };

  handleCancel = () => {
    this.props.formState(initialFormState(initialMateriaalVraagFormState()));
    this.props.resetCategorieen();
    this.props.handleCancel();
  };

  resetField = fieldName => {
    this.props.formEditPropertyValue('categorie.' + fieldName, '');
  };
}
