import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {Badge, Loading} from 'webuniversum-react';
import {isSymbioseTeam} from 'src/has-permission';
import {createHulpvraagForOnderneming, getHulpvraagForOnderneming, IConversatie} from 'src/reducers/bericht/conversatie';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {SYMBIOSE_OVERVIEW} from 'src/reducers/symbiose';
import {getMijnSymbioses, IMijnSymbioseState} from 'src/reducers/symbiose/mijn-symbioses';
import {SymbioseOverviewHulpvraagModal} from 'src/modules/bericht/bericht-modal';
import {extractUid} from 'src/modules/referentie-uri';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IMijnSymbiosesProps {
  getMijnSymbioses: Function;
  queryResult: IMijnSymbioseState;
  getHulpvraagForOnderneming: Function;
  createHulpvraagForOnderneming: Function;
  showModal: Function;
  hideModal: Function;
  conversatie: IConversatie;
  currentOndernemingsReferentie: string;
  history: any;
  isAuthenticated: boolean;
}

export class MijnSymbioses extends React.Component<IMijnSymbiosesProps, undefined> {

  componentDidMount() {
    this.props.getHulpvraagForOnderneming();
    this.props.getMijnSymbioses();
  }

  componentWillUnmount(): void {
    this.props.hideModal(SYMBIOSE_OVERVIEW);
  }

  render(): React.ReactNode {
    return (
      <>
        {this.props.isAuthenticated && <div>
          <div id="buttonSection" className="vl-u-spacer vl-grid">
            <h2 className="vl-h2 vl-col--6-12">{intl.get('symbiose.overview.title')}</h2>
            <div className="vl-col--6-12">
              {!isSymbioseTeam() && <VraagHulpButton handleClick={this.handleOpenModal} />}
            </div>
          </div>
          <Loading loading={this.props.queryResult.loading}>
            {this.props.queryResult.symbioses.length > 0 ?
              this.props.queryResult.symbioses.map(symbiose =>
                this.props.currentOndernemingsReferentie === symbiose.vraag.ondernemingsReferentie ?
                  <div onClick={e => this.navigateToSymbioseDetail(e, symbiose.symbioseReferentie)}
                    key={symbiose.symbioseReferentie}
                    className="vl-grid vl-u-hr symbiose-overzicht-click vl-layout vl-layout--wide">
                    <div className="vl-col--1-12" style={{ paddingLeft: '1rem' }}>
                      <Badge size="l">
                        <img className="vl-badge__img" src="/static/images/vraag-logo.png" alt="vraag"/>
                      </Badge>
                    </div>
                    <div className="vl-col--4-12" style={{ paddingLeft: '1rem' }}>
                      <p className="symbiose-overzicht-onderwerp-tekst">{symbiose.vraag.naam}</p>
                      <p className="symbiose-overzicht-onderneming-naam">{intl.get('symbiose.overview.mijn-vraag')}</p>
                    </div>
                    <div className="vl-col--1-12" style={{ paddingLeft: '1rem' }}>
                      <Badge size="l">
                        <img className="vl-badge__img" src="/static/images/aanbod-logo.png" alt="aanbod"/>
                      </Badge>
                    </div>
                    <div className="vl-col--4-12" style={{ paddingLeft: '1rem' }}>
                      <p className="symbiose-overzicht-onderwerp-tekst">{symbiose.aanbod.naam}</p>
                      <p className="symbiose-overzicht-onderneming-naam">{symbiose.aanbod.ondernemingsNaam}</p>
                    </div>
                    <div className="vl-col--2-12">
                      <p className="symbiose-overzicht-onderneming-naam">{symbiose.status ? intl.get('symbiose.status.values.' + symbiose.status) : ''}</p>
                      <p className="symbiose-overzicht-onderneming-naam">{intl.get('validation.date.format', { date: new Date(symbiose.datumStatus) })}</p>
                    </div>
                  </div> :
                  <div onClick={e => this.navigateToSymbioseDetail(e, symbiose.symbioseReferentie)}
                    key={symbiose.symbioseReferentie}
                    className="vl-grid vl-u-hr vl-u-spacer--tiny symbiose-overzicht-click vl-layout vl-layout--wide">
                    <div className="vl-col--1-12" style={{ paddingLeft: '1rem' }}>
                      <Badge size="l">
                        <img className="vl-badge__img" src="/static/images/aanbod-logo.png" alt="aanbod"/>
                      </Badge>
                    </div>
                    <div className="vl-col--4-12" style={{ paddingLeft: '1rem' }}>
                      <p className="symbiose-overzicht-onderwerp-tekst">{symbiose.aanbod.naam}</p>
                      <p className="symbiose-overzicht-onderneming-naam">{intl.get('symbiose.overview.mijn-aanbod')}</p>
                    </div>
                    <div className="vl-col--1-12" style={{ paddingLeft: '1rem' }}>
                      <Badge size="l">
                        <img className="vl-badge__img" src="/static/images/vraag-logo.png" alt="vraag"/>
                      </Badge>
                    </div>
                    <div className="vl-col--4-12" style={{ paddingLeft: '1rem' }}>
                      <p className="symbiose-overzicht-onderwerp-tekst">{symbiose.vraag.naam}</p>
                      <p className="symbiose-overzicht-onderneming-naam">{symbiose.vraag.ondernemingsNaam}</p>
                    </div>
                    <div className="vl-col--2-12">
                      <p className="symbiose-overzicht-onderneming-naam">{symbiose.status ? intl.get('symbiose.status.values.' + symbiose.status) : ''}</p>
                      <p className="symbiose-overzicht-onderneming-naam">{intl.get('validation.date.format', { date: new Date(symbiose.datumStatus) })}</p>
                    </div>
                  </div>)
              :
              <div>
                <h3 className="vl-h3">{intl.get('symbiose.overview.geen-symbioses.titel')}</h3>
                <p className="vl-u-spacer--tiny">{intl.get('symbiose.overview.geen-symbioses.paragraaf-1')}</p>
                <p className="vl-u-spacer--tiny">{intl.get('symbiose.overview.geen-symbioses.paragraaf-2')}</p>
                <p className="vl-u-spacer--tiny">{intl.get('symbiose.overview.geen-symbioses.paragraaf-3')}</p>
                <p className="vl-u-spacer--tiny">{intl.get('symbiose.overview.geen-symbioses.paragraaf-4')}</p>
                <p className="vl-u-spacer--tiny">
                  {intl.getHTML('symbiose.overview.geen-symbioses.paragraaf-5', {url: 'https://ovam.vlaanderen.be/symbiose-veelgestelde-vragen'})}
                </p>
              </div>
            }
          </Loading>
          <SymbioseOverviewHulpvraagModal/>
        </div>}
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }

    this.props.showModal(SYMBIOSE_OVERVIEW, {
      cancel: () => this.props.hideModal(SYMBIOSE_OVERVIEW)
    });
  };

  private navigateToSymbioseDetail = (event, ref) => {
    this.props.history.push(`/symbiose/${extractUid('symbiose', ref)}`);
  };
}

const mapStateToProps = storeState => ({
  queryResult: storeState.symbiose.mijnSymbiosesList,
  conversatie: storeState.bericht.bericht.conversatie,
  currentOndernemingsReferentie: storeState.authentication.contactPersoon.onderneming.referentie,
  isAuthenticated: storeState.authentication.security.isAuthenticated
});

const mapDispatchToProps = {
  getMijnSymbioses,
  showModal,
  hideModal,
  getHulpvraagForOnderneming,
  createHulpvraagForOnderneming
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(MijnSymbioses));
