import axios, { AxiosPromise } from 'axios';
import {
  ActionCreator,
  AnyAction
} from 'redux';
import { Land } from 'src/modules/adres/adres-form';
import { EnumWrapper } from 'src/modules/form-components/enum-wrapper';
import { IGevaarlijkeStoffenForm } from 'src/modules/materiaal/gevaarlijke-stoffen';
import { GevaarlijkeStoffenEnum } from 'src/modules/materiaal/gevaarlijke-stoffen-enum';
import { ICategorie } from 'src/modules/materiaal/materiaal-categorie';
import {
  IMateriaalHoeveelheid,
  MateriaalEenheid,
  MateriaalFrequentie
} from 'src/modules/materiaal/materiaal-hoeveelheid';
import { MateriaalToestand } from 'src/modules/materiaal/materiaal-toestand-enum';
import { Vertrouwelijkheid } from 'src/modules/materiaal/privaat-publiek-switch';
import { BeoogdeVerwerkingswijzeVraag } from 'src/modules/vraag/materiaal/beoogde-verwerkingswijze-vraag-enum';
import {
  FAILURE,
  REQUEST,
  SUCCESS
} from 'src/reducers/action-type.util';
import { IAdres } from 'src/reducers/adres';
import {
  initialOnderneming,
  IOnderneming
} from 'src/reducers/authentication';
import {
  filesToBestandenFormData,
  IBestand
} from 'src/reducers/bestand';
import { IVertaling } from 'src/reducers/taal/vertalingen';
import { Bestandstype } from '../../../modules/bestand/bestandstype';
import {IMateriaalBeschikbaarheidForm} from '../../../modules/beschikbaarheid/materiaal-beschikbaarheid';

export const ACTION_TYPES = {
  FETCH_MATERIAAL_VRAAG: 'vraag/FETCH_MATERIAAL_VRAAG',
  CREATE_MATERIAAL_VRAAG: 'vraag/CREATE_MATERIAAL_VRAAG',
  UPDATE_MATERIAAL_VRAAG: 'vraag/UPDATE_MATERIAAL_VRAAG',
  ARCHIVEER_MATERIAAL_VRAAG: 'vraag/ARCHIVEER_MATERIAAL_VRAAG',
  COPY_MATERIAAL_VRAAG: 'vraag/COPY_MATERIAAL_VRAAG'
};

export interface IMateriaalVraag extends IVertaling {
  vraagReferentie: string;
  titel: string;
  beschrijving: string;
  categorieen: ICategorie[];
  euralCode: string;
  materiaalToestand: string;
  kleur: string;
  beoogdeVerwerkingswijze: string;
  materiaalSamenstelling: string;
  productieProces: string;
  idealeOorsprong: string;
  fotos: IBestand[];
  toegangHoeveelheid: string;
  hoeveelheid: IMateriaalHoeveelheid;
  toegangLocatie: string;
  adres: IAdres;
  gevaarlijkeStoffen: IGevaarlijkeStoffenForm;
  bijkomendeInfo: string;
  privateBijlagen: IBestand[];
  publiekeBijlagen: IBestand[];
  contactPersoon: string;
  onderneming: IOnderneming;
  beschikbaarheid: IMateriaalBeschikbaarheidForm;
}

export interface IGetMateriaalVraagState {
  loading: boolean;
  errorMessage: string;
  vraag: IMateriaalVraag;
}

export const initialState: IGetMateriaalVraagState = {
  loading: false,
  errorMessage: null,
  vraag: {
    vraagReferentie: '',
    contactPersoon: '',
    onderneming: initialOnderneming(),
    taal: '',
    bronTaal: '',
    vertalingStatus: '',
    titel: '',
    beschrijving: '',
    toegangHoeveelheid: '',
    hoeveelheid: {
      frequentie: '',
      kwantiteit: null,
      eenheid: MateriaalEenheid.TON
    },
    beoogdeVerwerkingswijze: '',
    toegangLocatie: '',
    adres: {
      locatienaam: '',
      straatnaam: '',
      huisnummer: '',
      adresLijn2: '',
      postcode: '',
      gemeente: '',
      land: ''
    },
    categorieen: [
      {
        code: '',
        hoofdCategorie: '',
        standaardCategorie: '',
        subCategorie: ''
      }
    ],
    euralCode: '',
    materiaalToestand: '',
    kleur: '',
    gevaarlijkeStoffen: {
      gevaarlijkeStoffen: GevaarlijkeStoffenEnum.ZONDER_GEVAARLIJKE_STOFFEN,
      beschrijvingGevaarlijkeStoffen: ''
    },
    bijkomendeInfo: '',
    privateBijlagen: [],
    publiekeBijlagen: [],
    fotos: [],
    materiaalSamenstelling: '',
    productieProces: '',
    idealeOorsprong: '',
    beschikbaarheid:
      {
        van: null,
        tot: null
      }
  }
};

export const getMateriaalVraagReducer = (state = initialState, action: AnyAction): IGetMateriaalVraagState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_MATERIAAL_VRAAG):
  case REQUEST(ACTION_TYPES.CREATE_MATERIAAL_VRAAG):
  case REQUEST(ACTION_TYPES.UPDATE_MATERIAAL_VRAAG):
  case REQUEST(ACTION_TYPES.ARCHIVEER_MATERIAAL_VRAAG):
  case REQUEST(ACTION_TYPES.COPY_MATERIAAL_VRAAG):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_MATERIAAL_VRAAG):
  case FAILURE(ACTION_TYPES.CREATE_MATERIAAL_VRAAG):
  case FAILURE(ACTION_TYPES.UPDATE_MATERIAAL_VRAAG):
  case FAILURE(ACTION_TYPES.COPY_MATERIAAL_VRAAG):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case FAILURE(ACTION_TYPES.ARCHIVEER_MATERIAAL_VRAAG):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload?.response?.data?.message || 'error.general.subtitle.without-message'
    };
  case SUCCESS(ACTION_TYPES.FETCH_MATERIAAL_VRAAG):
  case SUCCESS(ACTION_TYPES.CREATE_MATERIAAL_VRAAG):
  case SUCCESS(ACTION_TYPES.UPDATE_MATERIAAL_VRAAG):
  case SUCCESS(ACTION_TYPES.ARCHIVEER_MATERIAAL_VRAAG):
  case SUCCESS(ACTION_TYPES.COPY_MATERIAAL_VRAAG):
    return {
      ...state,
      loading: false,
      vraag: {
        vraagReferentie: action.payload.data.vraagReferentie,
        contactPersoon: action.payload.data.contactPersoon,
        onderneming: action.payload.data.onderneming,
        taal: action.payload.data.taal,
        bronTaal: action.payload.data.bronTaal,
        vertalingStatus: action.payload.data.vertalingStatus,
        titel: action.payload.data.titel,
        beschrijving: action.payload.data.beschrijving,
        toegangHoeveelheid: action.payload.data.toegangHoeveelheid ? new EnumWrapper(Vertrouwelijkheid, 'Vertrouwelijkheid').parse(action.payload.data.toegangHoeveelheid) : null,
        hoeveelheid: {
          frequentie: action.payload.data.frequentie ? new EnumWrapper(MateriaalFrequentie, 'MateriaalFrequentie').parse(action.payload.data.frequentie) : null,
          kwantiteit: action.payload.data.kwantiteit,
          eenheid: action.payload.data.eenheid ? new EnumWrapper(MateriaalEenheid, 'MateriaalEenheid').parse(action.payload.data.eenheid) : null
        },
        beoogdeVerwerkingswijze: action.payload.data.beoogdeVerwerkingswijze ?
          new EnumWrapper(BeoogdeVerwerkingswijzeVraag, 'BeoogdeVerwerkingswijzeVraag').parse(action.payload.data.beoogdeVerwerkingswijze) : null,
        toegangLocatie: action.payload.data.toegangLocatie ? new EnumWrapper(Vertrouwelijkheid, 'Vertrouwelijkheid').parse(action.payload.data.toegangLocatie) : null,
        adres: {
          locatienaam: action.payload.data.locatienaam,
          straatnaam: action.payload.data.straatnaam,
          huisnummer: action.payload.data.huisnummer,
          adresLijn2: action.payload.data.adresLijn2,
          postcode: action.payload.data.postcode,
          gemeente: action.payload.data.gemeente,
          land: action.payload.data.land ? new EnumWrapper(Land, 'Land').parse(action.payload.data.land) : null
        },
        categorieen: action.payload.data.categorieen.map(cat => (
          {
            code: cat.code,
            hoofdCategorie: cat.hoofdCategorie,
            standaardCategorie: cat.standaardCategorie,
            subCategorie: cat.subCategorie
          })),
        euralCode: action.payload.data.euralCode,
        materiaalToestand: action.payload.data.toestand ? new EnumWrapper(MateriaalToestand, 'MateriaalToestand').parse(action.payload.data.toestand) : null,
        kleur: action.payload.data.kleur,
        gevaarlijkeStoffen: {
          gevaarlijkeStoffen: action.payload.data.gevaarlijkeStoffen,
          beschrijvingGevaarlijkeStoffen: action.payload.data.beschrijvingGevaarlijkeStoffen
        },
        bijkomendeInfo: action.payload.data.bijkomendeInfo,
        privateBijlagen: action.payload.data.privateBijlagen,
        publiekeBijlagen: action.payload.data.publiekeBijlagen,
        fotos: action.payload.data.fotos,
        materiaalSamenstelling: action.payload.data.materiaalSamenstelling,
        productieProces: action.payload.data.productieProces,
        idealeOorsprong: action.payload.data.idealeOorsprong,
        beschikbaarheid: action.payload.data.beschikbaarheid
      }
    };
  default:
    return state;
  }
};

export const getMateriaalVraag: ActionCreator<any> = (vraagUid: string) => ({
  type: ACTION_TYPES.FETCH_MATERIAAL_VRAAG,
  payload: axios.get(`/ui/vragen/${vraagUid}`)
});

export const createMateriaalVraag: ActionCreator<any> = (vraag: any) => ({
  type: ACTION_TYPES.CREATE_MATERIAAL_VRAAG,
  payload: axios.post('/ui/vragen/materiaal', vraag)
});

export const updateMateriaalVraag: ActionCreator<any> = (vraagUid: string, vraag: any) => ({
  type: ACTION_TYPES.UPDATE_MATERIAAL_VRAAG,
  payload: axios.put(`/ui/vragen/${vraagUid}`, vraag)
});

export const archiveerMateriaalVraag: ActionCreator<any> = (vraagUid: string) => ({
  type: ACTION_TYPES.ARCHIVEER_MATERIAAL_VRAAG,
  payload: axios.put(`/ui/vragen/${vraagUid}/archiveer`)
});

export const copyMateriaalVraag: ActionCreator<any> = (bronVraagUid: string, vraag: any) => ({
  type: ACTION_TYPES.COPY_MATERIAAL_VRAAG,
  payload: axios.post(`/ui/vragen/${bronVraagUid}/kopieer`, vraag)
});

export const downloadVraagBestand = (vraagUid: string, bestandUid: string): AxiosPromise =>
  axios.get(`/ui/vragen/${vraagUid}/bestanden/${bestandUid}/download`, { responseType: 'blob' });

export const uploadMateriaalVraagBestanden = (files: File[], bestandstype: Bestandstype, vraagUid = 'materiaal'): AxiosPromise =>
  axios.post(`/ui/vragen/${vraagUid}/bestanden`, filesToBestandenFormData(files), {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    params: {
      bestandstype
    }
  });
