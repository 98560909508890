export const chunkPreservingWords = (text: string, maxCharactersLine: number) => {
  if (text.length < maxCharactersLine) {
    return { line1: text, line2: '' };
  }

  let line1 = text.substr(0, maxCharactersLine);
  if (text.length > maxCharactersLine && text.substr(maxCharactersLine, 1) !== ' ') {
    line1 = line1.substr(0, Math.min(line1.length, line1.lastIndexOf(' ')));
  }

  let line2 = text.substr(line1.length, maxCharactersLine).trim();
  if (text.length > line1.length + maxCharactersLine) {
    line2 = line2.substr(0, Math.min(line2.length, line2.lastIndexOf(' ')));
    line2 = line2 + ' ...';
  }

  return { line1, line2 };
};
