import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { Document } from '@contentful/rich-text-types';

export const ACTION_TYPES = {
  GET_ALL: 'informatieve-website/nieuws/GET_ALL'
};

export interface INieuws {
  referentie: string;
  datum: Date;
  titel: string;
  header: string;
  inhoud: Document;
}

export interface IGetNieuwsState {
  loading: boolean;
  errorMessage: any;
  nieuws: INieuws[];
}

export const initialState: IGetNieuwsState = {
  loading: false,
  errorMessage: null,
  nieuws: []
};

export const nieuwsReducer = (state = initialState, action: AnyAction): IGetNieuwsState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET_ALL):
    return {
      ...state,
      loading: false,
      nieuws: action.payload.data.map(nieuws => ({
        referentie: nieuws.id,
        titel: nieuws.titel,
        inhoud: nieuws.inhoud,
        header: nieuws.header,
        datum: nieuws.datum && new Date(nieuws.datum)
      }))
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite';

export const getNieuws: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET_ALL,
  payload: axios.get(`${apiUrl}/nieuws/${taal}`)
});
