import {
  IAdresForm,
  initialAdresFormState
} from 'src/modules/adres/adres-form';
import {
  ICategorieForm,
  initialCategorieFormState
} from 'src/modules/categorie/categorie-form';
import {
  IEuralForm,
  initialEuralFormState
} from 'src/modules/eural/eural';
import {
  IGevaarlijkeStoffenForm,
  initialGevaarlijkeStoffenFormState
} from 'src/modules/materiaal/gevaarlijke-stoffen';
import {
  IMateriaalHoeveelheidForm,
  initialMateriaalHoeveelheidFormState
} from 'src/modules/materiaal/materiaal-hoeveelheid';
import { MateriaalToestand } from 'src/modules/materiaal/materiaal-toestand-enum';
import { Vertrouwelijkheid } from 'src/modules/materiaal/privaat-publiek-switch';
import { BeoogdeVerwerkingswijzeVraag } from 'src/modules/vraag/materiaal/beoogde-verwerkingswijze-vraag-enum';
import { IBestand } from 'src/reducers/bestand';
import {IMateriaalBeschikbaarheidForm, initialMateriaalBeschikbaarheidFormState} from '../../../modules/beschikbaarheid/materiaal-beschikbaarheid';

export interface IMateriaalVraagForm {
  taal: string;
  bronTaal: string;
  titel: string;
  beschrijving: string;
  categorieen: ICategorieForm[];
  categorie: ICategorieForm;
  eural: IEuralForm;
  materiaalSamenstelling: string;
  materiaalToestand: MateriaalToestand | '';
  kleur: string;
  beoogdeVerwerkingswijze: BeoogdeVerwerkingswijzeVraag | '';
  productieProces: string;
  idealeOorsprong: string;
  toegangHoeveelheid: Vertrouwelijkheid;
  hoeveelheid: IMateriaalHoeveelheidForm;
  toegangLocatie: Vertrouwelijkheid;
  locatienaam: string;
  adres: IAdresForm;
  gevaarlijkeStoffen: IGevaarlijkeStoffenForm;
  bijkomendeInfo: string;
  privateBijlagen: IBestand[];
  publiekeBijlagen: IBestand[];
  fotos: IBestand[];
  beschikbaarheid: IMateriaalBeschikbaarheidForm;
}

export const initialMateriaalVraagFormState = (): IMateriaalVraagForm => ({
  taal: '',
  bronTaal: '',
  titel: '',
  beschrijving: '',
  categorieen: [],
  categorie: initialCategorieFormState(),
  eural: initialEuralFormState(),
  materiaalSamenstelling: '',
  materiaalToestand: '',
  kleur: '',
  beoogdeVerwerkingswijze: '',
  productieProces: '',
  idealeOorsprong: '',
  toegangHoeveelheid: Vertrouwelijkheid.PUBLIEK,
  hoeveelheid: initialMateriaalHoeveelheidFormState(),
  toegangLocatie: Vertrouwelijkheid.PUBLIEK,
  locatienaam: '',
  adres: initialAdresFormState(),
  gevaarlijkeStoffen: initialGevaarlijkeStoffenFormState(),
  bijkomendeInfo: '',
  privateBijlagen: [],
  publiekeBijlagen: [],
  fotos: [],
  beschikbaarheid: initialMateriaalBeschikbaarheidFormState()
});
