import {combineReducers} from 'redux';
import {initialModalState, modalReducer} from 'src/reducers/modal/modal';

export interface IBestand {
  referentie: string;
  type: string;
  grootte: number;
  naam: string;
  uid?: string;
  url?: string;
}

export const filesToBestandenFormData = (files: File[]): FormData => {
  const data = new FormData();
  files.forEach(file => data.append('bestanden', file));
  return data;
};

export const FOTO_PREVIEW_MODAL = 'foto/preview';

export const bestand = combineReducers({
  fotoPreviewModal: modalReducer(FOTO_PREVIEW_MODAL, initialModalState())
});
