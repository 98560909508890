import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {FunctionalHeader, FunctionalHeaderBackTabNavigation, FunctionalHeaderNavigation, FunctionalHeaderTabNavigation, TabLink} from 'webuniversum-react';
import {hasPermission, PermissiesEnum} from './has-permission';
import {generateUsername} from './modules/welkomstpagina/generate-username';
import {HeaderType, INavigationTab} from './reducers/app/header/change';
import {IAuthenticationState, login, logout} from './reducers/authentication';
import {getAantalOngelezenBerichten} from './reducers/bericht/ongelezen-berichten';
import {hideModal, showModal} from './reducers/modal/modal';

export interface IHeaderProps {
  headerType: HeaderType;
  backArrowTitle: string;
  subTitle: string;
  tabNavigationTitles: INavigationTab[];
  aantalOngelezenBerichten: number;
  onBackClick: (e: any) => void;

  location: any;
  history: any;

  authentication: IAuthenticationState;
  login: Function;
  logout: Function;
  getAantalOngelezenBerichten: Function;
  showModal: Function;
  hideModal: Function;
}

export interface IHeaderState {
  intervalId: number;
}

export class Header extends React.Component<IHeaderProps, IHeaderState> {
  constructor(props) {
    super(props);
    this.state = {
      intervalId: 0
    };
  }

  componentDidUpdate(prevProps: Readonly<IHeaderProps>, prevState: Readonly<IHeaderState>, snapshot?: any) {
    if (hasPermission(PermissiesEnum.READ_BERICHTEN) && !hasPermission(PermissiesEnum.READ_BERICHTEN, prevProps.authentication.security)) {
      this.props.getAantalOngelezenBerichten();
      const intervalId = window.setInterval(() => {
        this.props.getAantalOngelezenBerichten();
      }, 60000);
      this.setState({ intervalId });
    } else if (!hasPermission(PermissiesEnum.READ_BERICHTEN) && hasPermission(PermissiesEnum.READ_BERICHTEN, prevProps.authentication.security)) {
      window.clearInterval(this.state.intervalId);
      this.setState({ intervalId: 0 });
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.state.intervalId);
  }

  render(): React.ReactNode {
    const { headerType, backArrowTitle, subTitle, tabNavigationTitles, authentication } = this.props;
    let tabs = null;
    if (headerType === HeaderType.TAB_NAVIGATION) {
      tabs = this.generateTabNavigation();
    } else if (headerType === HeaderType.BACK_NAVIGATION) {
      tabs = this.generateBackNavigation(backArrowTitle, subTitle);
    } else if (headerType === HeaderType.BACK_TAB_NAVIGATION) {
      tabs = this.generateBackTabNavigation(backArrowTitle, tabNavigationTitles);
    }
    return (
      <>
        <FunctionalHeader login
          title={intl.get('applicatie.title')}
          loginUserName={generateUsername(authentication)}
          loginTitle={this.generateLoginTitle()}
          loginOnClick={() => this.getLoginAction()()}
          titleOnClick={() => this.props.history.push('/')}>
          <Link to="/home">
            <img className="symbiose-logo" src={'/static/images/symbiose-logo.png'} alt={intl.get('applicatie.title')}/>
          </Link>
          {tabs}
        </FunctionalHeader>
      </>
    );
  }

  private generateBackNavigation(backArrowTitle: string, subTitle: string) {
    return (
      <FunctionalHeaderNavigation
        backArrowTitle={backArrowTitle}
        subtitle={subTitle}
        onClickBack={this.props.onBackClick}/>
    );
  }

  private generateBackTabNavigation(backArrowTitle: string, tabNavigationTitles: INavigationTab[]) {
    return (
      <FunctionalHeaderBackTabNavigation
        backArrowTitle={intl.get(backArrowTitle)}
        onClickBack={this.props.onBackClick}>
        {
          tabNavigationTitles.map((tab, i) =>
            <TabLink
              key={i}
              index={i}
              title={intl.get(tab.title)}
              active={tab.isActive}
              clickHandler={tab.redirectUrl ? () => this.clickHandler(tab.redirectUrl) : () => null}/>
          )
        }
      </FunctionalHeaderBackTabNavigation>
    );
  }

  private generateTabNavigation() {
    return (
      <>
        <FunctionalHeaderTabNavigation>
          <TabLink
            index={0}
            title={intl.get('applicatie.tablink.startpagina')}
            active={this.props.location.pathname.startsWith('/startpagina')}
            clickHandler={() => this.clickHandler('/startpagina')}/>
          {hasPermission(PermissiesEnum.READ_MIJN_AANBOD) && <TabLink
            index={1}
            title={intl.get('applicatie.tablink.aanbod')}
            active={this.props.location.pathname.match('/aanbod/(?!zoeken)')}
            clickHandler={() => this.clickHandler('/aanbod/overzicht')}/>}
          {hasPermission(PermissiesEnum.READ_MIJN_VRAAG) && <TabLink
            index={2}
            title={intl.get('applicatie.tablink.vraag')}
            active={this.props.location.pathname.match('/vraag/(?!zoeken)')}
            clickHandler={() => this.clickHandler('/vraag/overzicht')}/>}
          {hasPermission(PermissiesEnum.READ_MIJN_TECHNOLOGIE) && <TabLink
            index={3}
            title={intl.get('applicatie.tablink.technologie')}
            active={this.props.location.pathname.match('/technologie/(?!zoeken)')}
            clickHandler={() => this.clickHandler('/technologie/overzicht')}/>}
          {hasPermission(PermissiesEnum.READ_MIJN_SYMBIOSES) && <TabLink
            index={4}
            title={intl.get('applicatie.tablink.symbiose')}
            active={this.props.location.pathname.startsWith('/symbiose')}
            clickHandler={() => this.clickHandler('/symbiose/overzicht')}/>}
          <TabLink
            index={5}
            title={intl.get('applicatie.tablink.zoekpagina')}
            active={this.props.location.pathname.match('/zoekpagina')}
            clickHandler={() => this.clickHandler('/zoekpagina')}/>
          {hasPermission(PermissiesEnum.READ_BERICHTEN) && <TabLink
            index={6}
            title={`${intl.get('applicatie.tablink.berichten')} ${this.props.aantalOngelezenBerichten ? ' (' + this.props.aantalOngelezenBerichten + ')' : ''}`}
            active={this.props.location.pathname.startsWith('/berichten')}
            clickHandler={() => this.clickHandler('/berichten/overzicht')}/>}
        </FunctionalHeaderTabNavigation>
      </>
    );
  }

  private clickHandler(route: string) {
    this.props.history.push(route);
  }

  generateLoginTitle() {
    return this.props.authentication.security.isAuthenticated ? intl.get('applicatie.header.afmelden') : intl.get('applicatie.header.aanmelden');
  }

  getLoginAction() {
    return this.props.authentication.security.isAuthenticated ? this.props.logout : this.props.login;
  }
}

const mapStateToProps = storeState => ({
  headerType: storeState.app.header.headerType,
  backArrowTitle: storeState.app.header.backArrowTitle,
  subTitle: storeState.app.header.subTitle,
  tabNavigationTitles: storeState.app.header.tabNavigationTitles,
  onBackClick: storeState.app.header.onBackClick,
  authentication: storeState.authentication,
  aantalOngelezenBerichten: storeState.bericht.aantalOngelezenBerichten.aantalOngelezenBerichten
});

const mapDispatchToProps = {
  login,
  logout,
  getAantalOngelezenBerichten,
  showModal,
  hideModal
};

const ConnectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);

export default withRouter(props =>
  <ConnectedHeader history={props.history} location={props.location}/>
);
