import {IGeneral} from 'src/reducers/application';
import React from 'react';
import {
  Accordion,
  AgendaItem,
  Button,
  Doormat,
  InfoBlock,
  Loading,
  NewsTeaser,
  NewsTeaserLabel,
  NewsTeaserText,
  NewsTeaserTitle,
  Quote,
  Slider,
  SpotlightDocument
} from 'webuniversum-react';
import intl from 'react-intl-universal';
import {IAuthenticationState} from 'src/reducers/authentication';
import {connect} from 'react-redux';
import {getHighlightedFaqs, IFaq} from 'src/reducers/informatieve_website/faqs';
import {Link} from 'react-router-dom';
import {RichText} from 'src/modules/rich-text';
import {getIndustrieleSymbiosePaginas, IIndustrieleSymbiosePagina} from 'src/reducers/informatieve_website/industriele-symbiose-paginas';
import {getNieuws, INieuws} from 'src/reducers/informatieve_website/nieuws';
import {ZoekInfoTile} from 'src/modules/welkomstpagina/zoek-infotile';
import {ILandingsPagina} from 'src/reducers/informatieve_website/landings-pagina';
import {getWorkshops, IWorkshop} from 'src/reducers/informatieve_website/workshops';
import {getQuotes, IQuote} from 'src/reducers/informatieve_website/quotes';
import ContactInformatieBlock from 'src/modules/welkomstpagina/contact-informatie';
import {getHighlightedPublicaties, IPublicaties} from 'src/reducers/informatieve_website/publicaties';
import {isFullyAuthenticated} from 'src/has-permission';
import {ActieBlock} from 'src/modules/welkomstpagina/actie-block';
import AuthenticatieBlock from 'src/modules/welkomstpagina/authenticatie-block';
import {RouterProps} from 'react-router';
import {ErrorMessage} from 'src/modules/error/error-message';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IWelkomstpaginaProps extends RouterProps {
  getHighlightedFaqs: Function;
  faqs: IFaq[];
  getIndustrieleSymbiosePaginas: Function;
  industrieleSymbiosePaginas: IIndustrieleSymbiosePagina[];
  getHighlightedPublicaties: Function;
  publicaties: IPublicaties;
  getNieuws: Function;
  nieuws: INieuws[];
  getQuotes: Function;
  quotes: IQuote[];
  getWorkshops: Function;
  workshops: IWorkshop[];
  landingsPagina: ILandingsPagina;
  generalSettings: IGeneral;
  contactEmail: string;
  authentication: IAuthenticationState;
}

export class Welkomstpagina extends React.Component<IWelkomstpaginaProps, undefined> {
  componentDidMount() {
    const language = this.props.generalSettings.taal;
    this.props.getHighlightedFaqs(language);
    this.props.getIndustrieleSymbiosePaginas(language);
    this.props.getHighlightedPublicaties(language);
    this.props.getNieuws(language);
    this.props.getQuotes(language);
    this.props.getWorkshops(language);
  }

  render(): React.ReactNode {
    return (
      <div>
        <div className="vl-grid vl-u-spacer">
          <div className="vl-col--9-12 vl-col--1-1--xs">
            {this.props.landingsPagina && <RichText content={this.props.landingsPagina.inhoud}/>}
            {this.props.landingsPagina && !this.props.landingsPagina.inhoud &&
            <ErrorMessage error={{
              uuid: '',
              code: '500',
              title: 'error.general.title',
              subtitle: 'error.general.subtitle.without-message',
              message: intl.getHTML('error.general.message.sendmail', { email: this.props.contactEmail })}}/>}
          </div>
          <div className="vl-col--3-12">
            <Loading loading={this.props.authentication.loading}>
              {isFullyAuthenticated(this.props.authentication.security) &&
                <ActieBlock title={intl.get('welkomstpagina.platform.titel')}>
                  <Link to="/startpagina">
                    <Button color="primary" style={{ width: '100%' }}>{intl.get('welkomstpagina.buttons.naar-symbioses')}</Button>
                  </Link>
                </ActieBlock>
              }
            </Loading>
            <AuthenticatieBlock />
          </div>
        </div>

        <div className="vl-grid vl-u-spacer">
          <div className="vl-col--4-12 vl-col--4-12--m vl-col--4-12--s vl-col--1-1--xs">
            <ZoekInfoTile id="aanbod"
              title={intl.get('welkomstpagina.wat-is-symbiose.aanbod-zoeken-titel')}
              description={intl.get('welkomstpagina.wat-is-symbiose.aanbod-zoeken-tekst')}
              img="/static/images/aanbod-logo.png"
              onClick={this.handleAanbodZoeken}/>
          </div>
          <div className="vl-col--4-12 vl-col--4-12--m vl-col--4-12--s vl-col--1-1--xs">
            <ZoekInfoTile id="vraag"
              title={intl.get('welkomstpagina.wat-is-symbiose.vraag-zoeken-titel')}
              description={intl.get('welkomstpagina.wat-is-symbiose.vraag-zoeken-tekst')}
              img="/static/images/vraag-logo.png"
              onClick={this.handleVraagZoeken}/>
          </div>
          <div className="vl-col--4-12 vl-col--4-12--m vl-col--4-12--s vl-col--1-1--xs">
            <ZoekInfoTile id="technologie"
              title={intl.get('welkomstpagina.wat-is-symbiose.technologie-zoeken-titel')}
              description={intl.get('welkomstpagina.wat-is-symbiose.technologie-zoeken-tekst')}
              img="/static/images/technologie-logo.png"
              onClick={this.handleTechnologieZoeken}/>
          </div>
        </div>

        {this.props.quotes.length > 0 && <section className="vl-region vl-region--alt">
          <div className="vl-layout vl-layout--wide">
            <Slider autoplay autoplaySpeed={10000} dots={false}>
              {this.props.quotes.map((quote, index) => {
                if (quote.beeld && quote.beeld.url) {
                  return (<Quote key={`quote-${index}`} text={quote.tekst} image={quote.beeld.url}
                    authorInfo={`${quote.auteur}, ${quote.functie}, ${quote.organisatie}`}/>);
                } else {
                  return (<Quote key={`quote-${index}`} text={quote.tekst}
                    authorInfo={`${quote.auteur}, ${quote.functie}, ${quote.organisatie}`}/>);
                }
              })}
            </Slider>
          </div>
        </section>}

        <div className="vl-u-spacer"/>
        <div className="vl-grid vl-u-spacer--small">
          {this.props.nieuws.length > 0 && <div className="vl-col--6-12">
            <h2 className="vl-h2 vl-u-spacer">{intl.get('welkomstpagina.nieuws.titel')}</h2>
            <div className="vl-grid">
              {this.props.nieuws.slice(0, 2).map(nieuws =>
                <div className="vl-col--6-12" key={`nieuws-${nieuws.referentie}`}>
                  <NewsTeaser onClick={() => this.handleOpenNieuws(nieuws.referentie)}>
                    <NewsTeaserLabel>{intl.get('validation.date.format', { date: nieuws.datum })}</NewsTeaserLabel>
                    <NewsTeaserTitle>{nieuws.titel}</NewsTeaserTitle>
                    <NewsTeaserText>{nieuws.header}</NewsTeaserText>
                  </NewsTeaser>
                </div>
              )}
            </div>
          </div>}
          {this.props.workshops.length > 0 && <div className="vl-col--6-12">
            <h2 className="vl-h2 vl-u-spacer">{intl.get('welkomstpagina.workshops.titel')}</h2>
            {this.props.workshops.filter(workshop => new Date(workshop.tijdstip.toDateString()) >= new Date(new Date().toDateString())).slice(0, 2).map((workshop, index) =>
              <AgendaItem key={`workshop-${index}`} title={workshop.titel}
                tekst={`${workshop.beschrijving} (${workshop.locatie})`} date={workshop.tijdstip}
                layout="fullClickable" className="welkomstpagina-zoeken vl-u-spacer--small"
                onClick={() => window.open(workshop.inschrijvingslink.url, '_blank')}/>
            )}
          </div>}
        </div>
        <div className="vl-grid vl-u-spacer">
          {this.props.nieuws.length > 0 && <div className="vl-col--6-12 show-all-link-container">
            <div className="vl-u-hr vl-u-spacer--tiny"/>
            <Link to="/welkomstpagina/nieuws" className="vl-link--icon--caret">
              {intl.get('welkomstpagina.nieuws.alle.titel')}
            </Link>
          </div>}
          {this.props.workshops.length > 0 && <div className="vl-col--6-12 show-all-link-container">
            <div className="vl-u-hr vl-u-spacer--tiny"/>
            <Link to="/welkomstpagina/workshops" className="vl-link--icon--caret">
              {intl.get('welkomstpagina.workshops.alle.titel')}
            </Link>
          </div>}
        </div>

        {this.props.industrieleSymbiosePaginas.length > 0 && <section className="vl-region vl-region--alt vl-u-spacer">
          <div className="vl-layout vl-layout--wide">
            <h2 className="vl-h2 vl-u-spacer">{intl.get('welkomstpagina.industriele-symbiose.titel')}</h2>
            <ul className="vl-grid vl-grid--is-stacked js-vl-equal-height-container">
              {this.props.industrieleSymbiosePaginas.map(pagina =>
                <Doormat titel={pagina.titel} key={pagina.referentie}
                  url={`/#/welkomstpagina/industriele-symbiose/${pagina.referentie}`}
                  className="vl-col--4-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs js-vl-equal-height"
                  layout="default"/>
              )}
            </ul>
          </div>
        </section>}

        {this.props.publicaties && this.props.publicaties.bestanden.length > 0 &&
        <section className="vl-region vl-u-spacer">
          <div className="vl-layout vl-layout--wide vl-u-hr vl-u-spacer--tiny">
            <h2 className="vl-h2 vl-u-spacer">{this.props.publicaties.titel}</h2>
            <div className="vl-grid vl-grid--is-stacked vl-u-spacer js-vl-equal-height-container">
              {this.props.publicaties.bestanden.map((bestand, index) =>
                <div key={`publicatie${index}`}
                  className="vl-col--4-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs js-vl-equal-height">
                  <SpotlightDocument docType={bestand.extension} bytes={bestand.size}
                    onClick={() => window.open(bestand.url, '_blank')}>
                    {bestand.title}
                  </SpotlightDocument>
                </div>
              )}
            </div>
          </div>
          <div className="show-all-link-container">
            <Link to="/welkomstpagina/publicaties" className="vl-link--icon--caret">
              {intl.get('welkomstpagina.publicaties.alle.titel')}
            </Link>
          </div>
        </section>}

        {this.props.faqs.length > 0 && <InfoBlock titel={intl.get('welkomstpagina.faq.titel')} layout="faq">
          <div className="vl-u-hr vl-u-spacer--tiny">
            {this.props.faqs.map(faq =>
              <Accordion key={faq.vraag} layout="accordion" className="relevantie-accordion" title={faq.vraag}
                defaultOpen={false}>
                <RichText content={faq.antwoord}/>
              </Accordion>
            )}
            <div className="vl-u-spacer"/>
          </div>
          <div className="show-all-link-container">
            <Link to="/welkomstpagina/faq" className="vl-link--icon--caret">
              {intl.get('welkomstpagina.faq.alle.titel')}
            </Link>
          </div>
        </InfoBlock>}
        <ContactInformatieBlock/>
      </div>
    );
  }

  handleAanbodZoeken = () => {
    this.props.history.push('/aanbod/zoeken');
  };

  handleVraagZoeken = () => {
    this.props.history.push('/vraag/zoeken');
  };

  handleTechnologieZoeken = () => {
    this.props.history.push('/technologie/zoeken');
  };

  handleOpenNieuws = referentie => {
    this.props.history.push(`/welkomstpagina/nieuws/${referentie}`);
  };
}

const mapStateToProps = storeState => ({
  faqs: storeState.informatieveWebsite.faqs.faq,
  industrieleSymbiosePaginas: storeState.informatieveWebsite.industrieleSymbiosePaginas.industrieleSymbiosePaginas,
  nieuws: storeState.informatieveWebsite.nieuws.nieuws,
  publicaties: storeState.informatieveWebsite.publicaties.publicaties,
  quotes: storeState.informatieveWebsite.quotes.quotes,
  workshops: storeState.informatieveWebsite.workshops.workshops,
  landingsPagina: storeState.informatieveWebsite.landingsPagina.landingsPagina,
  generalSettings: storeState.application.application.general,
  authentication: storeState.authentication
});

const mapDispatchToProps = {
  getHighlightedFaqs,
  getIndustrieleSymbiosePaginas,
  getNieuws,
  getQuotes,
  getWorkshops,
  getHighlightedPublicaties
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(Welkomstpagina));
