import axios from 'axios';
import {ActionCreator, AnyAction} from 'redux';
import {FAILURE, REQUEST, SUCCESS} from '../../action-type.util';

export const ACTION_TYPES = {
  FETCH_AANBIEDINGEN: 'aanbod/FETCH_AANBIEDINGEN',
  FETCH_GEARCHIVEERDE_AANBIEDINGEN: 'aanbod/FETCH_GEARCHIVEERDE_AANBIEDINGEN'
};

export interface IAanbiedingenList {
  loading: boolean;
  errorMessage: any;
  aanbiedingen: any[];
}

const initialState: IAanbiedingenList = {
  loading: false,
  errorMessage: null,
  aanbiedingen: []
};

export const aanbiedingenListReducer = (state = initialState, action: AnyAction): IAanbiedingenList => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_AANBIEDINGEN):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_AANBIEDINGEN):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_AANBIEDINGEN):
    return {
      ...state,
      loading: false,
      aanbiedingen: action.payload.data
    };
  default:
    return state;
  }
};

export const gearchiveerdeAanbiedingenListReducer = (state = initialState, action: AnyAction): IAanbiedingenList => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_GEARCHIVEERDE_AANBIEDINGEN):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_GEARCHIVEERDE_AANBIEDINGEN):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_GEARCHIVEERDE_AANBIEDINGEN):
    return {
      ...state,
      loading: false,
      aanbiedingen: action.payload.data
    };
  default:
    return state;
  }
};

// Actions
export const getAanbiedingen: ActionCreator<any> = () => ({
  type: ACTION_TYPES.FETCH_AANBIEDINGEN,
  payload: axios.get('/ui/aanbiedingen/mijn-aanbod')
});

export const getGearchiveerdeAanbiedingen: ActionCreator<any> = () => ({
  type: ACTION_TYPES.FETCH_GEARCHIVEERDE_AANBIEDINGEN,
  payload: axios.get('/ui/aanbiedingen/mijn-gearchiveerd-aanbod')
});
