import {isFullyAuthenticated} from 'src/has-permission';
import {VertalingStatus} from 'src/modules/taal/vertaling-status-enum';
import {updateApplicatieTaal} from 'src/reducers/application';
import {getStore} from 'src/redux-store';
import {loadLocales} from 'src/translations/i18n-utils';
import intl from 'react-intl-universal';

export interface ITaalSpecifiek {
  taal: string;
}

export interface IVertaling extends ITaalSpecifiek {
  bronTaal: string;
  vertalingStatus: string;
}

export const autoTranslationFieldInfo = (vertaling: IVertaling): string => {
  if (vertaling.vertalingStatus === VertalingStatus.BRON) {
    return null;
  }
  const key = vertaling.vertalingStatus === VertalingStatus.VOLLEDIG ?
    'applicatie.taal.autotranslation.complete.field-info' :
    'applicatie.taal.autotranslation.incomplete.field-info';
  return intl.get(key, { LANG: vertaling.bronTaal ? intl.get('applicatie.taal.values.' + vertaling.bronTaal) : '' });
};

export const autoTranslationWarning = (vertaling: IVertaling): string => {
  if (vertaling.vertalingStatus !== VertalingStatus.ONVOLLEDIG) {
    return null;
  }
  const messageArgs = { LANG: intl.get('applicatie.taal.values.' + vertaling.bronTaal) };
  return intl.get('applicatie.taal.autotranslation.incomplete.error', messageArgs);
};

export const initialiseerApplicatieTaal = (authentication = getStore().getState().authentication): string => {
  let applicatieTaal = getLocalApplicatieTaal();
  if (!applicatieTaal) {
    applicatieTaal = isFullyAuthenticated(authentication.security) ? authentication.contactPersoon.taal : document.documentElement.lang;
  }
  wijzigApplicatieTaal(applicatieTaal);
  return applicatieTaal;
};

export const wijzigApplicatieTaal = (taal: string): void => {
  document.documentElement.lang = taal;
  loadLocales(taal);
  getStore().dispatch(updateApplicatieTaal(taal));
  setLocalApplicatieTaal(taal);
  setKeycloakLocale(taal);
};

const setKeycloakLocale = (taal: string): void => {
  document.cookie = `KEYCLOAK_LOCALE=${taal}; path=/auth/realms/symbiose/`;
};

export const getLocalApplicatieTaal = (): string => localStorage.getItem('applicatie.taal');

export const setLocalApplicatieTaal = (applicatieTaal: string): void => {
  if (applicatieTaal) {
    localStorage.setItem('applicatie.taal', applicatieTaal);
  } else {
    localStorage.removeItem('applicatie.taal');
  }
};
