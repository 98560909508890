import { ActionCreator, AnyAction } from 'redux';

export const ACTION_TYPES = {
  CHANGE_HEADER: 'header/CHANGE_HEADER'
};

export const enum HeaderType {
  TAB_NAVIGATION,
  BACK_NAVIGATION,
  BACK_TAB_NAVIGATION
}

export interface INavigationTab {
  title: string;
  isActive: boolean;
  redirectUrl?: string;
}

export interface IHeaderState {
  headerType: HeaderType;
  backArrowTitle: string;
  subTitle: string;
  tabNavigationTitles: INavigationTab[];

  onBackClick: (e: any) => void;
}

const initialState: IHeaderState = {
  headerType: HeaderType.TAB_NAVIGATION,
  backArrowTitle: '',
  subTitle: '',
  tabNavigationTitles: [],

  onBackClick: () => {
  }
};

export const changeHeaderReducer = (state = initialState, action: AnyAction): IHeaderState => {
  switch (action.type) {
  case ACTION_TYPES.CHANGE_HEADER:
    return {
      ...state,
      headerType: action.payload.headerType,
      backArrowTitle: action.payload.backArrowTitle,
      subTitle: action.payload.subTitle,
      tabNavigationTitles: action.payload.tabNavigationTitles,
      onBackClick: action.payload.onBackClick
    };
  default:
    return state;
  }
};

// Actions
export const changeHeader: ActionCreator<any> = (
  headerType: HeaderType,
  backArrowTitle?: string,
  subTitle?: string,
  tabNavigationTitles?: INavigationTab[],
  onBackClick?: Function) => ({
  type: ACTION_TYPES.CHANGE_HEADER,
  payload: {
    headerType,
    backArrowTitle: backArrowTitle ? backArrowTitle : '',
    subTitle: subTitle ? subTitle : '',
    tabNavigationTitles: tabNavigationTitles ? tabNavigationTitles : [],
    onBackClick: onBackClick ? onBackClick : () => {
    }
  }
});
