import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {Button, Loading} from 'webuniversum-react';
import {Link} from 'react-router-dom';
import {getVragen, IVragenList} from 'src/reducers/vraag/materiaal/list';
import {extractUid} from 'src/modules/referentie-uri';
import {MateriaalTile} from 'src/modules/materiaal/materiaal-tile';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {VraagOverviewHulpvraagModal} from 'src/modules/bericht/bericht-modal';
import {createHulpvraagForOnderneming, getHulpvraagForOnderneming, IConversatie} from 'src/reducers/bericht/conversatie';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {VRAAG_OVERVIEW} from 'src/reducers/vraag';
import {isSymbioseTeam} from 'src/has-permission';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IMateriaalVraagOverviewProps {
  getVragen: Function;
  getHulpvraagForOnderneming: Function;
  createHulpvraagForOnderneming: Function;
  showModal: Function;
  hideModal: Function;
  queryResult: IVragenList;
  conversatie: IConversatie;
  history: any;
  changeHeader: Function;
}

export class OverviewMateriaalVraag extends React.Component<IMateriaalVraagOverviewProps, undefined> {

  componentDidMount() {
    this.props.getHulpvraagForOnderneming();
    this.props.getVragen();
    this.props.changeHeader(
      HeaderType.TAB_NAVIGATION
    );
  }

  componentWillUnmount(): void {
    this.props.hideModal(VRAAG_OVERVIEW);
  }

  render(): React.ReactNode {
    return (
      <>
        <div id="buttonSection" className="vl-u-spacer vl-grid">
          <h2 className="vl-h2 vl-col--6-12">{intl.get('vraag.overview.title')}</h2>
          <div className="vl-col--6-12 vl-grid">
            <div className="vl-col--12-12">
              <Link to="/vraag/nieuw" className="vl-col--6-12">
                <Button type="button" color="primary" className="nieuw-button buttons-overview">
                  {intl.get('vraag.overview.buttons.nieuwe-vraag')}
                </Button>
              </Link>
            </div>
            <div className="vl-col--12-12">
              <Link to="/vraag/gearchiveerd/overzicht">
                <Button type="button" color="secondary" className="nieuw-button buttons-overview">
                  {intl.get('vraag.overview.buttons.mijn-gearchiveerde-vraag')}
                </Button>
              </Link>
            </div>
            {
              !isSymbioseTeam() &&
                    <div className="vl-col--12-12">
                      <VraagHulpButton handleClick={this.handleOpenModal} className="buttons-overview" />
                    </div>
            }
          </div>
        </div>
        <Loading loading={this.props.queryResult.loading}>
          {this.props.queryResult.vragen.length > 0 ?
            <div className="vl-grid vl-grid--is-stacked vl-u-spacer">
              {this.props.queryResult.vragen.map(vraag =>
                <div className="vl-col--4-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs"
                  key={vraag.vraagReferentie}>
                  <MateriaalTile layout="block" type="vraag" data={vraag} onClick={this.navigateToAanbodForVraag}/>
                </div>)}
            </div>
            :
            <div>
              <h3 className="vl-h3">{intl.get('vraag.relevante-aanbiedingen.overview.geen-vragen.titel')}</h3>
              <p className="vl-u-spacer--tiny">{intl.get('vraag.relevante-aanbiedingen.overview.geen-vragen.paragraaf-1')}</p>
              <ul>
                <li className="empty-overview-opsomming">{intl.get('vraag.relevante-aanbiedingen.overview.geen-vragen.bullet-1')}</li>
                <li className="empty-overview-opsomming">{intl.get('vraag.relevante-aanbiedingen.overview.geen-vragen.bullet-2')}</li>
                <li className="empty-overview-opsomming">{intl.get('vraag.relevante-aanbiedingen.overview.geen-vragen.bullet-3')}</li>
                <li className="empty-overview-opsomming">...</li>
              </ul>
              <p className="vl-u-spacer--tiny">{intl.getHTML('vraag.relevante-aanbiedingen.overview.geen-vragen.paragraaf-2', { url: '/#/vraag/nieuw' })}</p>
              <p className="vl-u-spacer--tiny">{intl.get('vraag.relevante-aanbiedingen.overview.geen-vragen.paragraaf-3')}</p>
            </div>
          }
        </Loading>
        <VraagOverviewHulpvraagModal/>
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }

    this.props.showModal(VRAAG_OVERVIEW, {
      cancel: () => this.props.hideModal(VRAAG_OVERVIEW)
    });
  };

  private navigateToAanbodForVraag = (vraagReferentie: string) => {
    this.props.history.push(`/vraag/${extractUid('vraag', vraagReferentie)}/aanbiedingen`);
  };
}

const mapStateToProps = storeState => ({
  queryResult: storeState.vraag.vragenList,
  conversatie: storeState.bericht.bericht.conversatie
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  getVragen,
  getHulpvraagForOnderneming,
  createHulpvraagForOnderneming,
  changeHeader
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(OverviewMateriaalVraag));
