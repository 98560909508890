import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import intl from 'react-intl-universal';
import { IAuthenticationState, login, logout } from 'src/reducers/authentication';
import { generateUsername } from './generate-username';
import { Login } from 'webuniversum-react';
import { getLandingsPagina, ILandingsPagina } from 'src/reducers/informatieve_website/landings-pagina';

export interface IWelkomstHeaderProps {
  location: any;
  authentication: IAuthenticationState;
  login: Function;
  logout: Function;
  taal: string;
  getLandingsPagina: Function;
  landingsPagina: ILandingsPagina;
}

export class WelkomstHeader extends React.Component<IWelkomstHeaderProps> {
  componentDidMount() {
    this.props.getLandingsPagina(this.props.taal);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authentication.contactPersoon.taal !== this.props.authentication.contactPersoon.taal) {
      this.props.getLandingsPagina(this.props.authentication.contactPersoon.taal);
    }
  }

  render(): React.ReactNode {
    const { authentication, landingsPagina } = this.props;
    return (
      <>
        <header className="vl-content-header vl-content-header--large vl-u-spacer--small welkompagina-header-login">
          <div className="vl-content-header__wrapper">
            <picture className="vl-content-header__bg">
              <img sizes="100vw" src="//images.ctfassets.net/259qye6k5vck/1dkbcjDWnScK740vhbWzD7/ba3d2c086ad20878a04901d185bd868d/paper-2568647_1920_2.jpg"
                alt="" title=""/>
            </picture>
            <div className="vl-layout vl-layout--wide">
              <div className="vl-functional-header vl-functional-header--has-actions welkomstpagina-funtional-header-aanmelden" role="banner">
                <div className="vl-layout vl-layout--wide welkomstpagina-aanmelden-backgrond">
                  <Login
                    title={this.generateLoginTitle()}
                    userName={generateUsername(authentication)}
                    buttonOnClick={() => this.getLoginAction()()}
                  />
                </div>
              </div>
              <div className="vl-content-header__content">
                <div className="vl-content-header__logo-wrapper">
                  <h1 className="vl-content-header__entity-logo">
                    <span className="vl-content-header__entity-logo__prefix">{landingsPagina && landingsPagina.titel}</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }

  generateLoginTitle() {
    return this.props.authentication.security.isAuthenticated ? intl.get('applicatie.header.afmelden') : intl.get('applicatie.header.aanmelden');
  }

  getLoginAction() {
    return this.props.authentication.security.isAuthenticated ? this.props.logout : this.props.login;
  }
}

const mapStateToProps = storeState => ({
  landingsPagina: storeState.informatieveWebsite.landingsPagina.landingsPagina,
  taal: storeState.application.application.general.taal,
  authentication: storeState.authentication
});

const mapDispatchToProps = {
  login,
  logout,
  getLandingsPagina
};

const ConnectedWelkomstHeader = connect(mapStateToProps, mapDispatchToProps)(WelkomstHeader);

export default withRouter(props =>
  <ConnectedWelkomstHeader location={props.location}/>
);
