import React from 'react';
import intl from 'react-intl-universal';
import {IRelevanteVraag} from 'src/reducers/aanbod/materiaal/relevante-vragen';
import {Accordion} from 'webuniversum-react';
import {OverzichtTegel} from 'src/modules/materiaal/overzicht-tegel';
import {translateKey} from 'src/modules/form-components/translatable-select-options';
import {downloadVraagBestand} from 'src/reducers/vraag/materiaal/get';
import {extractUid} from 'src/modules/referentie-uri';
import {blobUrl} from 'src/reducers/bestand/download';

export interface IRelevanteVragenAccordionProps {
  title: string;
  defaultOpen?: boolean;
  vragen: IRelevanteVraag[];
  navigateToVraag: Function;
}

export class RelevanteVragenAccordion extends React.Component<IRelevanteVragenAccordionProps, undefined> {
  render(): React.ReactNode {
    const { title, defaultOpen, vragen, navigateToVraag } = this.props;
    return vragen.length > 0 && (
      <Accordion layout="toggle" className="relevantie-accordion" title={title} defaultOpen={defaultOpen}>
        {vragen.map(vraag =>
          <OverzichtTegel
            {...vraag}
            key={vraag.vraagReferentie}
            coverFotoUrlFetcher={() => this.resolveFotoSourceUrl(vraag)}
            verwerkingswijze={intl.get(
              translateKey(vraag.beoogdeVerwerkingswijze,
                'aanbod.relevante-vragen.overview.table.rows.beoogde-verwerkingswijze.values'))
            }
            onClick={() => navigateToVraag(vraag.vraagReferentie)}
            beschikbaarheid={vraag.beschikbaarheid}
          />
        )}
      </Accordion>
    );
  }

  private resolveFotoSourceUrl = (vraag: IRelevanteVraag): Promise<string> =>
    vraag.coverFotoReferentie ?
      downloadVraagBestand(extractUid('vraag', vraag.vraagReferentie), extractUid('bestand', vraag.coverFotoReferentie)).then(blobUrl) :
      Promise.reject(`no cover photo for relevante vraag ${vraag.vraagReferentie}`);
}
