import axios from 'axios';
import {ActionCreator, AnyAction} from 'redux';
import {FAILURE, REQUEST, SUCCESS} from '../../action-type.util';

export const ACTION_TYPES = {
  FETCH_VRAGEN: 'vraag/FETCH_VRAGEN',
  FETCH_GEARCHIVEERDE_VRAGEN: 'vraag/FETCH_GEARCHIVEERDE_VRAGEN'
};

export interface IVragenList {
  loading: boolean;
  errorMessage: any;
  vragen: any[];
}

const initialState: IVragenList = {
  loading: false,
  errorMessage: null,
  vragen: []
};

export const vragenListReducer = (state = initialState, action: AnyAction): IVragenList => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_VRAGEN):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_VRAGEN):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_VRAGEN):
    return {
      ...state,
      loading: false,
      vragen: action.payload.data
    };
  default:
    return state;
  }
};

export const gearchiveerdeVragenListReducer = (state = initialState, action: AnyAction): IVragenList => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_GEARCHIVEERDE_VRAGEN):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_GEARCHIVEERDE_VRAGEN):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_GEARCHIVEERDE_VRAGEN):
    return {
      ...state,
      loading: false,
      vragen: action.payload.data
    };
  default:
    return state;
  }
};

// Actions
export const getVragen: ActionCreator<any> = () => ({
  type: ACTION_TYPES.FETCH_VRAGEN,
  payload: axios.get('/ui/vragen/mijn-vragen')
});

export const getGearchiveerdeVragen: ActionCreator<any> = () => ({
  type: ACTION_TYPES.FETCH_GEARCHIVEERDE_VRAGEN,
  payload: axios.get('/ui/vragen/mijn-gearchiveerd-vragen')
});
