import {ActionCreator, AnyAction} from 'redux';
import {FAILURE, REQUEST, SUCCESS} from '../action-type.util';
import {euralCodeClient, extractEuralCodes, IEural} from 'src/modules/eural/eural-code';
import {CONTENT_APPLICATION_LD_JSON, IGraph} from 'src/linked-data';

export const ACTION_TYPES = {
  FETCH_EURAL_CODES: 'eural/FETCH_EURAL_CODES'
};

export interface ICrudState<T> {
  loading: boolean;
  errorMessage: any;
  data: T | null;
}

const initialState = <T>(initialValue: T | null = null): ICrudState<T> => ({
  loading: false,
  errorMessage: null,
  data: initialValue
});

export const euralCodes = (state: ICrudState<IEural[]> = initialState(), action: AnyAction): ICrudState<IEural[]> => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_EURAL_CODES):
    return {
      ...state,
      loading: true,
      errorMessage: null
    };
  case FAILURE(ACTION_TYPES.FETCH_EURAL_CODES):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_EURAL_CODES):
    return {
      ...state,
      loading: false,
      data: extractEuralCodes(action.payload.data)
    };
  default:
    return state;
  }
};

export const getEuralCodes: ActionCreator<any> = (baseUrl: string) => ({
  type: ACTION_TYPES.FETCH_EURAL_CODES,
  payload: euralCodeClient(baseUrl).get<IGraph>('/doc/euralcode', { headers: { Accept: CONTENT_APPLICATION_LD_JSON } }),
  meta: { baseUrl }
});
