import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {getGearchiveerdeAanbiedingen, IAanbiedingenList} from 'src/reducers/aanbod/materiaal/list';
import {createHulpvraagForOnderneming, getHulpvraagForOnderneming, IConversatie} from 'src/reducers/bericht/conversatie';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {Loading} from 'webuniversum-react';
import {isSymbioseTeam} from 'src/has-permission';
import {MateriaalTile} from 'src/modules/materiaal/materiaal-tile';
import {GearchiveerdAanbodHulpvraagModal} from 'src/modules/bericht/bericht-modal';
import {GEARCHIVEERD_AANBOD_OVERVIEW} from 'src/reducers/aanbod';
import {extractUid} from 'src/modules/referentie-uri';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IOverviewMateriaalAanbodProps {
  getGearchiveerdeAanbiedingen: Function;
  getHulpvraagForOnderneming: Function;
  createHulpvraagForOnderneming: Function;
  showModal: Function;
  hideModal: Function;
  queryResult: IAanbiedingenList;
  conversatie: IConversatie;
  history: any;
  changeHeader: Function;
}

export class OverviewGearchiveerdMateriaalAanbod extends React.Component<IOverviewMateriaalAanbodProps> {
  componentDidMount(): void {
    this.props.getHulpvraagForOnderneming();
    this.props.getGearchiveerdeAanbiedingen();
    this.changeHeader();
  }

  componentDidUpdate(prevProps: Readonly<IOverviewMateriaalAanbodProps>, prevState: Readonly<undefined>, snapshot?: any): void {
    if (this.props.queryResult.aanbiedingen.length > 0) {
      this.changeHeader();
    }
  }

  componentWillUnmount(): void {
    this.props.hideModal(GEARCHIVEERD_AANBOD_OVERVIEW);
  }

  render(): React.ReactNode {
    return (
      <>
        <div id="buttonSection" className="vl-u-spacer vl-grid">
          <h2 className="vl-h2 vl-col--6-12">{intl.get('aanbod.overview-gearchiveerd.title')}</h2>
          <div className="vl-col--6-12 vl-grid">
            {
              !isSymbioseTeam() &&
                <div className="vl-col--12-12">
                  <VraagHulpButton handleClick={this.handleOpenModal} className="buttons-overview" />
                </div>
            }
          </div>
        </div>
        <Loading loading={this.props.queryResult.loading && this.props.queryResult.aanbiedingen.length <= 0}>
          <div className="vl-grid vl-grid--is-stacked vl-u-spacer">
            {this.props.queryResult.aanbiedingen.map(aanbieding =>
              <div className="vl-col--4-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs"
                key={aanbieding.aanbodReferentie}>
                <MateriaalTile layout="block" type="aanbod-gearchiveerd" data={aanbieding} onClick={this.navigateToGearchiveerdAanbod} />
              </div>)}
          </div>
        </Loading>
        <GearchiveerdAanbodHulpvraagModal/>
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }

    this.props.showModal(GEARCHIVEERD_AANBOD_OVERVIEW, {
      cancel: () => this.props.hideModal(GEARCHIVEERD_AANBOD_OVERVIEW)
    });
  };

  private navigateToGearchiveerdAanbod = (aanbodReferentie: string) =>
    this.props.history.push(`/aanbod/gearchiveerd/${extractUid('aanbod', aanbodReferentie)}`);

  private changeHeader() {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'aanbod.overview-gearchiveerd.header.overzicht-aanbod',
      '',
      [
        {
          title: 'aanbod.overview-gearchiveerd.header.active',
          isActive: true
        }
      ],
      () => {
        this.props.history.push('/aanbod/overzicht');
      });
  }
}

const mapStateToProps = storeState => ({
  queryResult: storeState.aanbod.gearchiveerdeAanbiedingenList,
  conversatie: storeState.bericht.bericht.conversatie
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  getGearchiveerdeAanbiedingen,
  getHulpvraagForOnderneming,
  createHulpvraagForOnderneming,
  changeHeader
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(OverviewGearchiveerdMateriaalAanbod));
