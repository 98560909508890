import { loadingBarMiddleware } from 'react-redux-loading-bar';
import {
  applyMiddleware,
  compose,
  createStore
} from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import reducer from '.';

const defaultMiddlewares = [
  thunkMiddleware,
  promiseMiddleware,
  loadingBarMiddleware()
];

const composedMiddlewares = middlewares => compose(
  applyMiddleware(...defaultMiddlewares, ...middlewares),
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__() : f => f
);

const initialize = (initialState = {}, middlewares = []) =>
  createStore(reducer, initialState, composedMiddlewares(middlewares));

export default initialize;
