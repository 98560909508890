import React from 'react';
import intl from 'react-intl-universal';
import {Alert, Button} from 'webuniversum-react';
import {login} from 'src/reducers/authentication';
import {connect} from 'react-redux';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import ContactInformatieBlock from '../welkomstpagina/contact-informatie';

export interface IInvalidSessionProps {
  login: Function;
  history: any;
  changeHeader: Function;
}

export class InvalidSession extends React.Component<IInvalidSessionProps, undefined> {
  componentDidMount(): void {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'welkomstpagina.terug',
      '',
      [
        {
          title: 'applicatie.tablink.symbioseteam.aanbod',
          isActive: false,
          redirectUrl: '/aanbod/zoeken'

        },
        {
          title: 'applicatie.tablink.symbioseteam.vraag',
          isActive: true
        }
      ], () => {
        this.props.history.push('/');
      }
    );
  }

  render(): React.ReactNode {
    return (
      <div>
        <Alert type="error" title={intl.get('error.session.expired.title')}>
          <p className="vl-u-spacer--small">{intl.get('error.session.expired.inhoud')}</p>
          <Button color="primary" onClick={this.handleLogin}>
            {intl.get('welkomstpagina.buttons.aanmelden')}
          </Button>
        </Alert>
        <ContactInformatieBlock/>
      </div>
    );
  }

  handleLogin = () => {
    this.props.login();
  };
}

const mapDispatchToProps = {
  changeHeader,
  login
};

export default connect(undefined, mapDispatchToProps)(InvalidSession);
