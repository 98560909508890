import {wijzigApplicatieTaal} from 'src/reducers/taal/vertalingen';
import axios from 'axios';
import Keycloak from 'keycloak-js';
import {ActionCreator, AnyAction} from 'redux';
import {PermissiesEnum} from '../has-permission';
import {FAILURE, REQUEST, SUCCESS} from './action-type.util';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  CHECK_AUTHENTICATED: 'authentication/CHECK_AUTHENTICATED',
  GET_SESSION: 'authentication/GET_SESSION',
  UNSET_AUTHORIZED: 'authentication/UNSET_AUTHORIZED',
  LOGOUT: 'authentication/LOGOUT',
  UPDATE_TAAL: 'gebruikers/UPDATE_TAAL',
  AANVAARD_ALGEMENE_VOORWAARDEN: 'algemene_voorwaarden/AANVAARD'
};

interface IOidcSession {
  token: string;
  refreshToken: string;
  idToken: string;
}

export interface IAuthenticationState {
  loading: boolean;
  errorMessage: any;
  contactPersoon: IContactPersoon;
  security: ISecurity;
}

export interface IContactPersoon {
  referentie: string;
  onderneming: IOnderneming;
  persoonId: string;
  vlaamseOverheidId: string;
  voornaam: string;
  familienaam: string;
  email: string;
  taal: string;
  tijdstipAanvaardingAlgemeneVoorwaarden: string;
}

export interface IOnderneming {
  referentie: string;
  code: string;
  naam: string;
  authenticatieNiveau: AuthenticatieNiveau;
}

export enum AuthenticatieNiveau {
  GEEN = 'GEEN',
  NIET_GEVERIFIEERD = 'NIET_GEVERIFIEERD',
  GEVERIFIEERD = 'GEVERIFIEERD'
}

export interface ISecurity {
  isAuthenticated: boolean;
  hasMinimumRequiredLevelOfAuthentication: boolean;
  logoutRedirectUri: string;
  permissies: PermissiesEnum[];
}

export const initialAuthenticationState = (): IAuthenticationState => ({
  loading: false,
  errorMessage: null,
  contactPersoon: initialContactPersoon(),
  security: initialSecurity()
});

export const initialContactPersoon = (): IContactPersoon => ({
  referentie: '',
  onderneming: initialOnderneming(),
  persoonId: '',
  vlaamseOverheidId: '',
  voornaam: '',
  familienaam: '',
  email: '',
  taal: 'nl',
  tijdstipAanvaardingAlgemeneVoorwaarden: ''
});

export const initialOnderneming = (): IOnderneming => ({
  referentie: '',
  code: '',
  naam: '',
  authenticatieNiveau: AuthenticatieNiveau.GEEN
});

export const initialSecurity = (): ISecurity => ({
  isAuthenticated: false,
  hasMinimumRequiredLevelOfAuthentication: false,
  logoutRedirectUri: '',
  permissies: []
});

export const authenticationReducer = (state = initialAuthenticationState(), action: AnyAction): IAuthenticationState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.LOGIN):
  case REQUEST(ACTION_TYPES.GET_SESSION):
  case REQUEST(ACTION_TYPES.LOGOUT):
  case REQUEST(ACTION_TYPES.UPDATE_TAAL):
  case REQUEST(ACTION_TYPES.AANVAARD_ALGEMENE_VOORWAARDEN):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case SUCCESS(ACTION_TYPES.LOGIN):
    return {
      ...state,
      loading: false
    };
  case SUCCESS(ACTION_TYPES.LOGOUT):
    return {
      ...state,
      loading: false,
      security: initialSecurity(),
      contactPersoon: initialContactPersoon()
    };
  case SUCCESS(ACTION_TYPES.GET_SESSION): {
    const { permissies, ...cp } = action.payload.data;
    return {
      ...state,
      loading: false,
      security: {
        isAuthenticated: true,
        hasMinimumRequiredLevelOfAuthentication: true,
        logoutRedirectUri: keycloak.tokenParsed && keycloak.tokenParsed[ 'logout_redirect_uri' ],
        permissies
      },
      contactPersoon: cp
    };
  }
  case SUCCESS(ACTION_TYPES.UPDATE_TAAL):
    return {
      ...state,
      loading: false,
      contactPersoon: {
        ...state.contactPersoon,
        taal: action.payload.taal
      }
    };
  case SUCCESS(ACTION_TYPES.AANVAARD_ALGEMENE_VOORWAARDEN):
    return {
      ...state,
      loading: false,
      contactPersoon: action.payload.data
    };
  case FAILURE(ACTION_TYPES.LOGIN):
  case FAILURE(ACTION_TYPES.GET_SESSION):
  case FAILURE(ACTION_TYPES.LOGOUT):
  case FAILURE(ACTION_TYPES.UPDATE_TAAL):
  case FAILURE(ACTION_TYPES.AANVAARD_ALGEMENE_VOORWAARDEN):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case ACTION_TYPES.UNSET_AUTHORIZED:
    return {
      ...state,
      errorMessage: action.payload,
      security: {
        isAuthenticated: true,
        hasMinimumRequiredLevelOfAuthentication: false,
        logoutRedirectUri: keycloak.tokenParsed && keycloak.tokenParsed[ 'logout_redirect_uri' ],
        permissies: []
      }
    };
  default:
    return state;
  }
};

export const keycloak = Keycloak({
  url: '/auth',
  realm: 'symbiose',
  clientId: 'symbiose-platform'
});

const reloadWidgets = widgets => {
  // Iterate through the resolved widgets and perform a reload operation.
  for (const widget of widgets) {
    widget.reload();
  }
};

export const reloadAvailableWidgets = () => {
  // Check whether the WidgetApi is ready for usage.
  // Get a list of the already available widget instances.
  const instances = (global as any).WidgetApi.Core.getInstances();
  // Ensure that all widgets are correctly showing the current language.
  reloadWidgets(instances);
};

export const register: ActionCreator<any> = () => (taal: string) => {
  keycloak.register({
    locale: taal
  }).catch(ex => console.error('Failed to register: ' + ex));
};

export const login: ActionCreator<any> = () => () => {
  keycloak.init({
    onLoad: 'login-required'
  }).catch(ex => console.error('Failed to login: ' + ex));
};

export const logout: ActionCreator<any> = () => (dispatch, getState) => {
  localStorage.removeItem('oidc');
  const security = getState().authentication.security;
  const options = security && security.logoutRedirectUri ? { redirectUri: security.logoutRedirectUri } : {};
  keycloak.logout(options);
};

keycloak.onAuthLogout = logout;

export const checkAuthenticated: ActionCreator<any> = () => dispatch => {
  const oidcString: string = localStorage.getItem('oidc');
  const oidc: IOidcSession = oidcString ? JSON.parse(oidcString) : {};
  dispatch({
    type: ACTION_TYPES.CHECK_AUTHENTICATED,
    payload: keycloak.init({
      onLoad: 'check-sso',
      ...oidc
    })
  }).then(response => {
    const authenticated = response.value;
    if (authenticated) {
      localStorage.setItem('oidc', JSON.stringify({
        token: keycloak.token,
        refreshToken: keycloak.refreshToken,
        idToken: keycloak.idToken
      }));
      return dispatch(getSession());
    }
  });
};

export const getSession: ActionCreator<any> = () => dispatch => {
  dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('/ui/gebruikers/current')
  }).then(response => {
    wijzigApplicatieTaal(response.value.data.taal);
    reloadAvailableWidgets();
    return response;
  });
};

export const unsetAuthorization: ActionCreator<any> = (reason: string) => ({
  type: ACTION_TYPES.UNSET_AUTHORIZED,
  payload: reason
});

export const selecteerTaal: ActionCreator<any> = (taalkeuze: string) => (dispatch, getState) => {
  if (getState().authentication.security.isAuthenticated) {
    return dispatch({
      type: ACTION_TYPES.UPDATE_TAAL,
      payload: axios.put('/ui/gebruikers/taal', { taal: taalkeuze }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => ({ taal: response.data.taal }))
    });
  } else {
    return dispatch({
      type: ACTION_TYPES.UPDATE_TAAL,
      payload: Promise.resolve({ taal: taalkeuze })
    });
  }
};

export const aanvaardAlgemeneVoorwaarden: ActionCreator<any> = () => ({
  type: ACTION_TYPES.AANVAARD_ALGEMENE_VOORWAARDEN,
  payload: axios.post('/ui/algemenevoorwaarden/aanvaard')
});
