import React from 'react';
import {Alert, Button, ButtonGroup, Modal, ModalButtons, ModalContent, ModalTitle} from 'webuniversum-react';
import {connect} from 'react-redux';
import {createSymbiose} from 'src/reducers/symbiose/create';
import {IModalContainerProps} from 'src/reducers/modal/modal';

export interface IInviteSymbioseModalProps {
  modalProps: IModalContainerProps;
  vraagReferentie: string;
  aanbodReferentie: string;
  warning?: string;
  createSymbiose?: Function;
  loading: boolean;
}

export class InviteSymbioseModal extends React.Component<IInviteSymbioseModalProps, undefined> {

  handleSend = () => {
    const symbiose = {
      vraagReferentie: this.props.vraagReferentie,
      aanbodReferentie: this.props.aanbodReferentie
    };
    this.props.createSymbiose(symbiose)
      .then(this.props.modalProps.submit)
      .finally(this.handleCancel);
  };

  handleCancel = () => {
    this.props.modalProps.cancel();
  };

  render(): React.ReactNode {
    const { visible, title, submitText, cancelText, text } = this.props.modalProps;
    const { warning } = this.props;
    return (
      <Modal hidden={!visible} overlayHidden={!visible} style={{ width: '65rem' }} onClose={this.handleCancel}>
        <ModalTitle>{title}</ModalTitle>
        <ModalContent>
          {warning && <Alert type="alert">{warning}</Alert>}
          <p>{text}</p>
        </ModalContent>
        <ModalButtons>
          <ButtonGroup>
            <Button type="button" onClick={this.handleSend} loading={this.props.loading}>
              {submitText}
            </Button>
            <Button type="button" color="secondary" onClick={this.handleCancel} loading={this.props.loading}>
              {cancelText}
            </Button>
          </ButtonGroup>
        </ModalButtons>
      </Modal>
    );
  }
}

const mapStateToProps = storeState => ({
  modalProps: storeState.aanbod.symbioseCreateModal,
  loading: storeState.symbiose.create.loading
});

const mapDispatchToProps = {
  createSymbiose
};

export const InviteModal = connect(mapStateToProps, mapDispatchToProps)(InviteSymbioseModal);
