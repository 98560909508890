import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { Document } from '@contentful/rich-text-types';

export const ACTION_TYPES = {
  GET: 'informatieve-website/contactinformatie/GET'
};

export interface IContactInformatie {
  titel: string;
  kolom1: Document;
  kolom2: Document;
}

export interface IGetContactInformatieState {
  loading: boolean;
  errorMessage: any;
  contactInformatie: IContactInformatie;
}

export const initialState: IGetContactInformatieState = {
  loading: false,
  errorMessage: null,
  contactInformatie: null
};

export const contactInformatieReducer = (state = initialState, action: AnyAction): IGetContactInformatieState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET):
    return {
      ...state,
      errorMessage: null,
      loading: true,
      contactInformatie: null
    };
  case FAILURE(ACTION_TYPES.GET):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET):
    return {
      ...state,
      loading: false,
      contactInformatie: action.payload.data
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite';

export const getContactInformatie: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET,
  payload: axios.get(`${apiUrl}/contactinformatie/${taal}`)
});
