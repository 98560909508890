import qs from 'qs';
import React from 'react';

export interface IMailtoProps {
  email: string;
  subject?: string;
  body?: string;
  children: string | React.ReactChildren;
}

export const Mailto: React.FunctionComponent<IMailtoProps>  = (props: IMailtoProps): React.ReactElement => {
  const {email, subject, body, children} = props;
  const params = qs.stringify({ subject, body }, { addQueryPrefix: true });
  return <a href={`mailto:${email}${params}`}>{children}</a>;
};
