import React from 'react';
import intl from 'react-intl-universal';
import {ICategorie} from '../materiaal/materiaal-categorie';
import {DetailCategorie} from './detail-categorie';
import {DetailField, IGridColumnFields} from './detail-field';
import {DetailEuralTree} from 'src/modules/detail-components/detail-eural-tree';

export interface IDetailCategorieProps extends IGridColumnFields {
  categorieen: ICategorie[];
  euralCode: string;
}

export class DetailClassificatie extends React.Component<IDetailCategorieProps> {
  render(): React.ReactNode {
    const {label_column_class, value_column_class, categorieen, euralCode} = this.props;

    return (
      <section>
        <DetailField label={intl.get('detail.materiaal-info.classificatie.categorie.label')}
          label_column_class={label_column_class}
          value_column_class={value_column_class}>
          {categorieen.sort((a, b) => a.code.localeCompare(b.code))
            .map((cat, index) => <DetailCategorie key={`${String(index).padStart(3, '0')}-${cat.code}`} categorie={cat}/>)
          }
        </DetailField>

        {euralCode &&
        <DetailField label={intl.get('detail.materiaal-info.classificatie.eural.label')}
          label_column_class={label_column_class}
          value_column_class={value_column_class}>
          <DetailEuralTree eural={euralCode}/>
        </DetailField>
        }

        <div className="vl-u-spacer--small"/>
      </section>
    );
  }
}
