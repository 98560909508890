import React from 'react';
import intl from 'react-intl-universal';
import {DetailField, IGridColumnFields} from './detail-field';

export interface IDetailFooterProps extends IGridColumnFields {
  onderneming_naam: string;
  contact_persoon: string;
}

export class DetailFooter extends React.Component<IDetailFooterProps> {

  render(): React.ReactNode {
    const { label_column_class, value_column_class, onderneming_naam, contact_persoon } = this.props;

    return (
      <footer>

        <h2 className="vl-h2">{intl.get('detail.contact-info.title')}</h2>

        <div className="vl-properties">
          <DetailField label={intl.get('detail.contact-info.onderneming.label')}
            value={onderneming_naam}
            label_column_class={label_column_class}
            value_column_class={value_column_class}/>

          <div className="vl-u-spacer--tiny"/>

          <DetailField label={intl.get('detail.contact-info.contact-persoon.label')}
            value={contact_persoon}
            label_column_class={label_column_class}
            value_column_class={value_column_class}/>
        </div>
      </footer>
    );
  }
}
