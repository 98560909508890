import moment from 'moment';

export const DATEPICKER_DATE = 'DD.MM.YYYY';
export const ISO_LOCAL_DATE = 'YYYY-MM-DD';

export const changeDateFormat = (date: string, inputFormat = DATEPICKER_DATE, outputFormat = ISO_LOCAL_DATE) => date && moment(date, inputFormat).format(outputFormat);

export const parseDate = (date: string, pattern = DATEPICKER_DATE) => date && moment(date, pattern).toDate();

export const formatDate = (date: Date, pattern = ISO_LOCAL_DATE) => date && moment(date).format(pattern);

export const composeDatepickerPlaceholder = () => moment().format(DATEPICKER_DATE);
