import React from 'react';
import intl from 'react-intl-universal';

import { EnumWrapper } from './enum-wrapper';

export class TranslatableSelectOptions<E> {

  constructor(private optionsEnum: EnumWrapper<E>,
              private translateKeyRoot: string) {
  }

  translateKey(value: E | string): string {
    return `${this.translateKeyRoot}.${value.toString().toLocaleLowerCase()}`;
  }

  optionsWithPlaceholder(placeholderTranslateKey: string): JSX.Element[] {
    return [
      <option key={placeholderTranslateKey} value="" disabled>-- {intl.get(placeholderTranslateKey)} --</option>,
      ...this.options()
    ];
  }

  options(): JSX.Element[] {
    return this.optionsEnum.values().map(value => ({
      value,
      translateKey: this.translateKey(value)
    })).map(option => (
      <option key={option.translateKey} value={option.value.toString()}>{intl.get(option.translateKey)}</option>
    ));
  }
}

export const enumOptions = (enumType: any, translationRoot: string) => new TranslatableSelectOptions(new EnumWrapper(enumType, enumType.toString()), translationRoot);

export const translateKey = (value: any, translationRoot: string): string => `${translationRoot}.${value.toString().toLocaleLowerCase()}`;
