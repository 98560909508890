import React from 'react';
import {Button, Loading} from 'webuniversum-react';
import {IAuthenticationState, login} from 'src/reducers/authentication';
import {connect} from 'react-redux';
import {ActieBlock} from './actie-block';
import intl from 'react-intl-universal';
import {Link} from 'react-router-dom';

export interface IAuthenticatieBlockProps {
  authentication: IAuthenticationState;
  login: Function;
}

export class AuthenticatieBlock extends React.Component<IAuthenticatieBlockProps, any> {
  render(): React.ReactNode {
    const { authentication } = this.props;

    return !authentication.security.isAuthenticated && (
      <Loading loading={authentication.loading}>
        <ActieBlock title={intl.get('welkomstpagina.buttons.titel')}>
          <Button color="primary" onClick={this.handleLogin} style={{ width: '100%' }}>
            {intl.get('welkomstpagina.buttons.aanmelden')}
          </Button>
          <Link to="/welkomstpagina/registratie">
            <Button color="secondary" style={{ width: '100%' }}>
              {intl.get('welkomstpagina.buttons.registreren')}
            </Button>
          </Link>
        </ActieBlock>
      </Loading>
    );
  }

  handleLogin = () => {
    this.props.login();
  };
}

const mapStateToProps = storeState => ({
  authentication: storeState.authentication
});

const mapDispatchToProps = {
  login
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatieBlock);
