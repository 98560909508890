import axios from 'axios';
import {ActionCreator, AnyAction} from 'redux';
import {formReducer, initialFormState} from 'redux-form-library';
import {FAILURE, REQUEST, SUCCESS} from 'src/reducers/action-type.util';

export const ACTION_TYPES = {
  SEARCH_TECHNOLOGIE: 'technologie/SEARCH_TECHNOLOGIE',
  CLEAR_SEARCH_RESULT: 'technologie/CLEAR_SEARCH_RESULT'
};

export const SEARCH_TECHNOLOGIE_FORM = 'SEARCH_TECHNOLOGIE_FORM';

export interface ISearchTechnologieForm {
  titel: string;
  beschrijvingTeVerwerkenMateriaal: string;
  beschrijvingVerwerktMateriaal: string;
}

export const initialSearchTechnologieFormState = (): ISearchTechnologieForm => ({
  titel: '',
  beschrijvingTeVerwerkenMateriaal: '',
  beschrijvingVerwerktMateriaal: ''
});

export interface ITechnologieSearch {
  loading: boolean;
  searched: boolean;
  errorMessage: any;
  results: any[];
}

const initialTechnologieSearchState = (): ITechnologieSearch => ({
  loading: false,
  searched: false,
  errorMessage: null,
  results: []
});

export const searchTechnologieForm = formReducer(SEARCH_TECHNOLOGIE_FORM, initialFormState(initialSearchTechnologieFormState()));

export const searchTechnologieReducer = (state = initialTechnologieSearchState(), action: AnyAction): ITechnologieSearch => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.SEARCH_TECHNOLOGIE):
    return {
      ...state,
      errorMessage: null,
      searched: false,
      loading: true
    };
  case FAILURE(ACTION_TYPES.SEARCH_TECHNOLOGIE):
    return {
      ...state,
      loading: false,
      searched: true,
      errorMessage: action.payload.data
    };
  case SUCCESS(ACTION_TYPES.SEARCH_TECHNOLOGIE):
    return {
      ...state,
      loading: false,
      searched: true,
      results: action.payload.data
    };
  case ACTION_TYPES.CLEAR_SEARCH_RESULT: {
    return initialTechnologieSearchState();
  }
  default:
    return state;
  }
};

export const zoekenTechnologie: ActionCreator<any> = (taal: string, searchParams: any) => ({
  type: ACTION_TYPES.SEARCH_TECHNOLOGIE,
  payload: axios.get(`/ui/technologie/zoeken/${taal}?${new URLSearchParams(searchParams).toString()}`)
});

export const clearSearchResults: ActionCreator<any> = () => ({
  type: ACTION_TYPES.CLEAR_SEARCH_RESULT
});
