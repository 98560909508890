import {Button} from 'webuniversum-react';
import intl from 'react-intl-universal';
import React from 'react';

interface IVraagHulpButtonProps {
  handleClick: () => void;
  className?: string;
}

export const VraagHulpButton = (props: IVraagHulpButtonProps) => (
  <Button type="button" color="primary" className={`hulp-button ${props.className ?? ''}`} onClick={props.handleClick}>
    <span className="operator-logo hulp-button-logo"/>
    {intl.get('bericht.hulpvraag.hulp-nodig')}
  </Button>
);
