import React from 'react';
import {connect} from 'react-redux';
import {IError} from 'src/reducers/error';
import ContactInformatieBlock from '../welkomstpagina/contact-informatie';
import {Redirect} from 'react-router-dom';
import {withErrorClear} from './error-clearer';

export interface IErrorPaginaProps {
  error: IError;
}

export class ErrorPagina extends React.Component<IErrorPaginaProps, undefined> {
  render(): React.ReactNode {
    const { error } = this.props;
    return <>
      {!error?.message && <Redirect to={{ pathname: '/' }}/>}
      <ContactInformatieBlock/>
    </>;
  }
}

const mapStateToProps = storeState => ({
  error: storeState.error
});

export default withErrorClear(connect(mapStateToProps)(ErrorPagina));
