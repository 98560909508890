export const componentOffset = (formFields, myrefs, returnName = false) => {
  let offset = null;
  let name = null;
  for (const fieldName in formFields) {
    if (formFields[ fieldName ].valid !== undefined) {
      if (!formFields[ fieldName ].valid) {
        const offsetField = myrefs[ fieldName ] ? myrefs[ fieldName ].current.offsetTop : offset;
        if (offset === null || offset > offsetField) {
          offset = offsetField;
          name = fieldName;
        }
      }
    } else {
      let valid = true;
      const formField = formFields[ fieldName ];
      for (const nestedFieldName in formField) {
        if (Object.prototype.hasOwnProperty.call(formField, nestedFieldName)) {
          const nestedField = formField[ nestedFieldName ];
          if (nestedField.valid === false) {
            valid = false;
          }
        }
      }
      if (!valid) {
        const offsetField = myrefs[ fieldName ] ? myrefs[ fieldName ].current.offsetTop : offset;
        if (offset === null || offset > offsetField) {
          offset = offsetField;
          name = fieldName;
        }
      }
    }
  }

  if (returnName) {
    return name;
  } else {
    return offset;
  }
};
