import {combineReducers} from 'redux';
import {gearchiveerdeVragenListReducer, vragenListReducer} from './materiaal/list';
import {relevanteAanbiedingenListReducer} from 'src/reducers/vraag/materiaal/relevante-aanbiedingen';
import {getMateriaalVraagReducer} from 'src/reducers/vraag/materiaal/get';
import {initialModalState, modalReducer} from 'src/reducers/modal/modal';
import {searchMateriaalVraagForm, searchMateriaalVraagReducer} from 'src/reducers/vraag/materiaal/search';
import {formReducer, initialFormState} from 'redux-form-library';
import {initialMateriaalVraagFormState} from 'src/reducers/vraag/materiaal/materiaal-vraag-form';

export const CREATE_MATERIAAL_VRAAG_FORM = 'CREATE_MATERIAAL_VRAAG_FORM';
export const UPDATE_MATERIAAL_VRAAG_FORM = 'UPDATE_MATERIAAL_VRAAG_FORM';
export const COPY_MATERIAAL_VRAAG_FORM = 'COPY_MATERIAAL_VRAAG_FORM';
export const CREATE_MATERIAAL_VRAAG_FROM_AANBOD_FORM = 'CREATE_MATERIAAL_VRAAG_FROM_AANBOD_FORM';

export const VRAAG_OVERVIEW = 'vraag-overzicht/conversatie/hulpvraag';
export const GEARCHIVEERD_VRAAG_OVERVIEW = 'gearchiveerd-vraag-overzicht/conversatie/hulpvraag';
export const VRAAG_DETAIL_HULPVRAAG = 'aanbod-bewerken/conversatie/hulpvraag';
export const GEARCHIVEERDE_VRAAG_DETAIL_HULPVRAAG = 'gearchiveerde-vraag/conversatie/hulpvraag';
export const VRAAG_BEWERKEN_ONDERNEMING_HULPVRAAG = 'vraag-bewerken/onderneming/conversatie/hulpvraag';
export const VRAAG_KOPIEREN_ONDERNEMING_HULPVRAAG = 'vraag-kopieren/onderneming/conversatie/hlpvraag';
export const VRAAG_MAKEN_UIT_AANBOD_ONDERNEMING_HULPVRAAG = 'vraag-maken-uit-aanbod/onderneming/conversatie/hulpvraag';
export const VRAAG_BEWERKEN_VRAAG_HULPVRAAG = 'vraag-bewerken/vraag/conversatie/hulpvraag';
export const RELEVANTE_AANBIEDINGEN_OVERVIEW = 'relevante-aanbiedingen-overzicht/conversatie/hulpvraag';
export const RELEVANT_AANBOD_HULPVRAAG = 'relevante-aanbiedingen/conversatie/hulpvraag';
export const RELEVANT_AANBOD_SEND_BERICHT = 'relevant-aanbod/conversatie/send';
export const RELEVANT_AANBOD_SYMBIOSE_INVITE = 'symbiose/create';
export const ARCHIVEER_VRAAG = 'vraag/archiveer';

export const vraag = combineReducers({
  details: getMateriaalVraagReducer,
  createMateriaalVraagForm: formReducer(CREATE_MATERIAAL_VRAAG_FORM, initialFormState(initialMateriaalVraagFormState())),
  updateMateriaalVraagForm: formReducer(UPDATE_MATERIAAL_VRAAG_FORM, initialFormState(initialMateriaalVraagFormState())),
  copyMateriaalVraagForm: formReducer(COPY_MATERIAAL_VRAAG_FORM, initialFormState(initialMateriaalVraagFormState())),
  createMateriaalVraagFromAanbodForm: formReducer(CREATE_MATERIAAL_VRAAG_FROM_AANBOD_FORM, initialFormState(initialMateriaalVraagFormState())),
  searchMateriaalVraagForm,
  materiaal: combineReducers({
    search: searchMateriaalVraagReducer
  }),
  vragenList: vragenListReducer,
  gearchiveerdeVragenList: gearchiveerdeVragenListReducer,
  relevanteAanbiedingenList: relevanteAanbiedingenListReducer,
  vraagOverviewModal: modalReducer(VRAAG_OVERVIEW, initialModalState()),
  gearchiveerdVraagOverviewModal: modalReducer(GEARCHIVEERD_VRAAG_OVERVIEW, initialModalState()),
  vraagDetailHulpvraagModal: modalReducer(VRAAG_DETAIL_HULPVRAAG, initialModalState()),
  gearchiveerdeVraagDetailHulpvraagModal: modalReducer(GEARCHIVEERDE_VRAAG_DETAIL_HULPVRAAG, initialModalState()),
  vraagBewerkenOndernemingHulpvraagModal: modalReducer(VRAAG_BEWERKEN_ONDERNEMING_HULPVRAAG, initialModalState()),
  vraagCopyOndernemingHulpvraagModal: modalReducer(VRAAG_KOPIEREN_ONDERNEMING_HULPVRAAG, initialModalState()),
  vraagMakenUitAanbodOndernemingHulpvraagModal: modalReducer(VRAAG_MAKEN_UIT_AANBOD_ONDERNEMING_HULPVRAAG, initialModalState()),
  vraagBewerkenVraagHulpvraagModal: modalReducer(VRAAG_BEWERKEN_VRAAG_HULPVRAAG, initialModalState()),
  relevanteAanbiedingenOverviewModal: modalReducer(RELEVANTE_AANBIEDINGEN_OVERVIEW, initialModalState()),
  relevantAanbodHulpvraagModal: modalReducer(RELEVANT_AANBOD_HULPVRAAG, initialModalState()),
  relevantAanbodModal: modalReducer(RELEVANT_AANBOD_SEND_BERICHT, initialModalState()),
  symbioseCreateModal: modalReducer(RELEVANT_AANBOD_SYMBIOSE_INVITE, initialModalState()),
  archiveerVraagModal: modalReducer(ARCHIVEER_VRAAG, initialModalState())
});
