import React from 'react';
import { getPropertyFromObject, ICellGenerator } from 'webuniversum-react';
import { chunkPreservingWords } from './chunk-preserving-words';

export class BerichtInhoudCellGenerator implements ICellGenerator<any> {

  constructor() {
  }

  generateCell(row: any): string | React.ReactElement<HTMLElement> | null {
    const geformateerdBericht = chunkPreservingWords(getPropertyFromObject('bericht', row), 60);
    return (
      <>
        <p className="bericht-inhoud-cell">
          {geformateerdBericht.line1}
          <br/>
          {geformateerdBericht.line2}
        </p>
      </>
    );
  }
}
