import React from 'react';
import {CreateSymbioseButton} from 'src/modules/form-components/create-symbiose-button';
import intl from 'react-intl-universal';
import {Link} from 'react-router-dom';
import {extractUid} from 'src/modules/referentie-uri';
import {ISymbiose} from 'src/reducers/symbiose/create';

export interface ISymbioseFrameProps {
  onClickButton: Function;
  symbiose: ISymbiose;
}

export class SymbioseFrame extends React.Component<ISymbioseFrameProps> {
  render(): React.ReactNode {
    const { onClickButton, symbiose } = this.props;

    if (['UITGENODIGD_VOOR_SYMBIOSE', 'IN_SYMBIOSE'].includes(symbiose.status)) {
      return <LopendeSymbiose symbiose={symbiose}/>;
    } else {
      return <SymbioseCreatieFrame handleCreate={onClickButton}/>;
    }
  }
}

interface ILopendeSymbioseProps {
  symbiose: ISymbiose;
}

const LopendeSymbiose = (props: ILopendeSymbioseProps) => (
  <div className='symbiose-frameless'>
    <p style={{ color: 'grey' }}>Status:</p>
    <p><strong>{props.symbiose.status ? intl.get(`symbiose.status.values.${props.symbiose.status}`) : ''}</strong></p>
    <p>{intl.get('validation.date.format', { date: new Date(props.symbiose.datumStatus) })}</p>
    <Link to={`/symbiose/${extractUid('symbiose', props.symbiose.symbioseReferentie)}`}>
      <span style={{ fontSize: 'smaller' }}>{intl.get('symbiose.link')}</span>
    </Link>
  </div>
);

interface ISymbioseCreatieFrameProps {
  handleCreate: Function;
}

const SymbioseCreatieFrame = (props: ISymbioseCreatieFrameProps) => (
  <div className='symbiose-frame'>
    <p>{intl.get('detail.symbiose.frame.text')}</p>
    <CreateSymbioseButton onClick={props.handleCreate}/>
  </div>
);
