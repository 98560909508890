import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import Breadcrumbs from 'src/modules/welkomstpagina/breadcrumbs';
import ContactInformatieBlock from 'src/modules/welkomstpagina/contact-informatie';
import {register} from 'src/reducers/authentication';
import {Button, InfoTile, InfoTileContent, InfoTileFooter, InfoTileHeader} from 'webuniversum-react';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IRegistratiePaginaProps extends RouteComponentProps {
  taal: string;
  register: Function;
}

export class RegistratiePagina extends React.Component<IRegistratiePaginaProps, undefined> {
  render(): React.ReactNode {
    return <>
      <Breadcrumbs breadcrumbs={[
        { title: intl.get('welkomstpagina.titel'), location: '/' },
        { title: intl.get('welkomstpagina.registratie.titel') }
      ]}/>

      <div className="vl-grid vl-u-spacer equal-height-container">
        <div className="vl-col--6-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs equal-height-col">
          <InfoTile size="l" center onClick={this.handleNieuweGebruiker} className="fill-container">
            <InfoTileHeader key="niet-geverifieerd.header" title={intl.get('welkomstpagina.registratie.geverifieerd.titel')}/>
            <InfoTileContent>
              <Button color="primary" onClick={this.handleNieuweGebruiker}>
                {intl.get('welkomstpagina.registratie.geverifieerd.button')}
              </Button>
            </InfoTileContent>
            <InfoTileFooter key="niet-geverifieerd.footer">
              <div className="vl-u-spacer--small vl-typography">{intl.getHTML('welkomstpagina.registratie.geverifieerd.beschrijving')}</div>
            </InfoTileFooter>
          </InfoTile>
        </div>
        <div className="vl-col--6-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs equal-height-col">
          <InfoTile size="l" center onClick={this.handleRegister} className="fill-container">
            <InfoTileHeader key="geverifieerd.header" title={intl.get('welkomstpagina.registratie.niet-geverifieerd.titel')}/>
            <InfoTileContent>
              <Button color="primary" onClick={this.handleRegister}>
                {intl.get('welkomstpagina.registratie.niet-geverifieerd.button')}
              </Button>
            </InfoTileContent>
            <InfoTileFooter key="geverifieerd.footer">
              <span className="vl-u-spacer--small vl-typography">{intl.getHTML('welkomstpagina.registratie.niet-geverifieerd.beschrijving')}</span>
            </InfoTileFooter>
          </InfoTile>
        </div>
      </div>

      <ContactInformatieBlock/>
    </>;
  }

  handleRegister = () => {
    this.props.register(this.props.taal);
  };

  handleNieuweGebruiker = () => {
    this.props.history.push('/welkomstpagina/registratie/instructies/nieuwe-gebruiker');
  };
}

const mapStateToProps = storeState => ({
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  register
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(RegistratiePagina));
