import React from 'react';
import { Button } from 'webuniversum-react';
import intl from 'react-intl-universal';

export interface ICreateSymbioseButtonProps {
  onClick: any;
}

export class CreateSymbioseButton extends React.Component<ICreateSymbioseButtonProps> {

  render(): React.ReactNode {
    return (
      <Button type="button" color="primary" className="symbiose-button" onClick={this.props.onClick}>
        {intl.get('detail.symbiose.invite.label')}
      </Button>
    );
  }
}
