import {IBestand} from 'src/reducers/bestand';
import React from 'react';
import 'antd/dist/antd.min.css';
import 'src/modules/foto/foto-carrousel.css';
import {Image} from 'antd';
import {blobUrl} from 'src/reducers/bestand/download';
import {extractUid} from 'src/modules/referentie-uri';
import {AxiosPromise} from 'axios';

export interface IFotoCarrouselProps {
  fotos: IBestand[];
  handleDownload: (referentie: string) => AxiosPromise;
}

export interface IFotoCarrouselState {
  fotos: IBestand[];
}

export class FotoCarrousel extends React.Component<IFotoCarrouselProps, IFotoCarrouselState> {
  _isMounted = false;

  constructor(props: Readonly<IFotoCarrouselProps>) {
    super(props);
    this.state = {
      fotos: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.downloadFotos(this.props.fotos).catch(ex => console.error(ex));
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render(): React.ReactNode {
    const {fotos} = this.state;

    return fotos.length > 0 &&
      <div className="vl-properties vl-u-spacer--tiny">
        <Image.PreviewGroup>
          {fotos.map((file, index) =>
            <Image key={`carousel-image-${index}`} src={file.url} className="foto-thumbnail" wrapperClassName="foto-container"/>
          )}
        </Image.PreviewGroup>
      </div>;
  }

  private downloadFotos = async (bestanden: IBestand[]) =>
    Promise.all(bestanden.map(this.addDownloadData))
      .then(fotos => {
        if (this._isMounted) {
          this.setState({fotos});
        }
      });

  private addDownloadData = (foto: IBestand): Promise<IBestand> =>
    this.resolveFotoSourceUrl(foto).then(url => ({...foto, url, uid: extractUid('bestand', foto.referentie)}));

  private resolveFotoSourceUrl = (foto: IBestand): Promise<string> => foto.url ?
    Promise.resolve(foto.url) :
    this.props.handleDownload(foto.referentie).then(blobUrl);
}
