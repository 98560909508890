import intl from 'react-intl-universal';

export interface IFlatCategorie {
  niveau: number;
  hoofdCategorieReferentie: string;
  standaardCategorieReferentie?: string;
  subCategorieReferentie?: string;
  beschijving: string;
  code: string;
}

export const categorieTreeToList = tree => {
  const list: IFlatCategorie[] = [];

  tree.hoofdCategorieen.forEach(hoofdCategorie => {
    const hoofdCategorieReferentie = hoofdCategorie.referentie;
    const flatHoofdCategorie: IFlatCategorie = {
      niveau: 0,
      hoofdCategorieReferentie,
      beschijving: intl.get(hoofdCategorieReferentie),
      code: hoofdCategorie.code
    };
    list.push(flatHoofdCategorie);

    if (hoofdCategorie.childCategories) {
      hoofdCategorie.childCategories.forEach(standaardCategorie => {
        const standaardCategorieReferentie = standaardCategorie.referentie;
        const flatStandaardCategorie: IFlatCategorie = {
          niveau: 1,
          hoofdCategorieReferentie,
          standaardCategorieReferentie,
          beschijving: intl.get(standaardCategorieReferentie),
          code: standaardCategorie.code
        };
        list.push(flatStandaardCategorie);

        if (standaardCategorie.childCategories) {
          standaardCategorie.childCategories.forEach(subCategorie => {
            const subCategorieReferentie = subCategorie.referentie;
            const flatSubCategorie: IFlatCategorie = {
              niveau: 2,
              hoofdCategorieReferentie,
              standaardCategorieReferentie,
              subCategorieReferentie,
              beschijving: intl.get(subCategorieReferentie),
              code: subCategorie.code
            };
            list.push(flatSubCategorie);
          });
        }
      });
    }
  });

  return list;
};
