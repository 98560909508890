import { isSymbioseTeam } from 'src/has-permission';
import {
  archiveerMateriaalAanbod,
  downloadAanbodBestand,
  getMateriaalAanbod,
  IGetMateriaalAanbodState
} from 'src/reducers/aanbod/materiaal/get';
import {
  changeHeader,
  HeaderType,
  INavigationTab
} from 'src/reducers/app/header/change';
import { IContactPersoon } from 'src/reducers/authentication';
import { createHulpvraagForAanbod } from 'src/reducers/bericht/conversatie';
import {
  hideModal,
  showModal
} from 'src/reducers/modal/modal';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  Loading
} from 'webuniversum-react';
import {
  AANBOD_DETAIL_HULPVRAAG,
  ARCHIVEER_AANBOD
} from 'src/reducers/aanbod';
import { AanbodDetailHulpvraagModal } from 'src/modules/bericht/bericht-modal';
import { extractUid } from 'src/modules/referentie-uri';
import { ArchiveerAanbodModal } from 'src/modules/aanbod/materiaal/archiveer-modal';
import { DetailMateriaalAanbod } from 'src/modules/aanbod/materiaal/detail-materiaal-aanbod';
import { RouteComponentProps } from 'react-router';
import { VraagHulpButton } from 'src/modules/bericht/vraag-hulp-button';
import { withErrorClear } from 'src/modules/error/error-clearer';
import cx from 'classnames';

export interface IMateriaalAanbodDetailPaginaProps extends RouteComponentProps<{ aanbodUid: string }> {
  getMateriaalAanbod: Function;
  archiveerMateriaalAanbod: Function;
  createHulpvraagForAanbod: Function;
  queryResult: IGetMateriaalAanbodState;
  isAuthenticated: boolean;
  currentUser: IContactPersoon;
  showModal: Function;
  hideModal: Function;
  changeHeader: Function;
}

export class MateriaalAanbodDetailPagina extends React.Component<IMateriaalAanbodDetailPaginaProps, undefined> {
  componentDidMount(): void {
    this.props.getMateriaalAanbod(this.props.match.params.aanbodUid);
    this.changeHeader();
  }

  componentDidUpdate(prevProps: Readonly<IMateriaalAanbodDetailPaginaProps>, prevState: Readonly<undefined>, snapshot?: any): void {
    this.changeHeader();
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
    this.props.hideModal(AANBOD_DETAIL_HULPVRAAG);
  }

  render(): React.ReactNode {
    const { queryResult, isAuthenticated, currentUser } = this.props;
    const { aanbod, loading, errorMessage } = queryResult;
    const showVraagMakenVanuitAanbodButton = aanbod.onderneming.referentie !== currentUser.onderneming.referentie || isSymbioseTeam();
    return (
      <>
        {isAuthenticated &&
          <>
            <Loading loading={loading}>
              <div className="vl-grid">
                <div className="vl-col--8-12">
                  <DetailMateriaalAanbod aanbod={aanbod} downloadBestand={this.downloadBestand} />
                </div>
                <div className={cx('vl-col--4-12', 'detail-buttons', {'detail-buttons--wide': showVraagMakenVanuitAanbodButton})}>
                  <ButtonGroup>
                    {(aanbod.onderneming.referentie === currentUser.onderneming.referentie || isSymbioseTeam()) &&
                      <>
                        <VraagHulpButton handleClick={this.handleOpenModal} />
                        <Button type="button" color="primary" onClick={this.navigateToUpdateAanbod}>
                          {intl.get('aanbod.detail.aanbod-bewerken')}
                        </Button>
                        <Button type="button" color="secondary" onClick={this.handleOpenArchiveerModal}>
                          {intl.get('aanbod.detail.aanbod-archiveren')}
                        </Button>
                        <Button type="button" color="secondary" onClick={this.handleCopyAanbod}>
                          {intl.get('aanbod.detail.aanbod-copy')}
                        </Button>
                      </>}
                    {showVraagMakenVanuitAanbodButton &&
                      <Button type="button" color="secondary" onClick={this.handleVraagAanmakenVanuitAanbod}>
                        {intl.get('aanbod.detail.vraag-maken-vanuit-aanbod')}
                      </Button>
                    }
                  </ButtonGroup>
                </div>
              </div>
              <ArchiveerAanbodModal error={errorMessage ? intl.get(errorMessage) : ''} />
            </Loading>
            <AanbodDetailHulpvraagModal />
          </>
        }
      </>
    );
  }

  private getAanbodUid = () => extractUid('aanbod', this.props.queryResult.aanbod.aanbodReferentie);

  private handleOpenModal = () => {
    this.props.createHulpvraagForAanbod(this.getAanbodUid()).then(
      () => this.props.showModal(AANBOD_DETAIL_HULPVRAAG, {
        cancel: () => this.props.hideModal(AANBOD_DETAIL_HULPVRAAG)
      })
    );
  };

  private handleCopyAanbod = () => {
    this.props.history.push(`/aanbod/${this.getAanbodUid()}/copy`);
  };

  private handleVraagAanmakenVanuitAanbod = () => this.props.history.push(`/vraag/nieuw-vanuit-aanbod/${this.getAanbodUid()}`);

  private changeHeader = () => {
    let tabNavigationTitles: INavigationTab[] = [{
      title: 'aanbod.detail.title',
      isActive: true
    }];
    if (this.props.queryResult.aanbod.onderneming.referentie === this.props.currentUser.onderneming.referentie || isSymbioseTeam()) {
      tabNavigationTitles = [
        {
          title: 'aanbod.relevante-vragen.header.title',
          isActive: false,
          redirectUrl: `${this.getAanbodUid()}/vragen`
        },
        ...tabNavigationTitles
      ];
    }

    if (this.props.location.pathname.startsWith('/aanbod/zoeken')) {
      this.props.changeHeader(HeaderType.BACK_TAB_NAVIGATION, 'aanbod.relevante-vragen.header.return.zoeken', '', tabNavigationTitles, () => {
        this.props.history.push('/aanbod/zoeken');
      });
    } else {
      this.props.changeHeader(HeaderType.BACK_TAB_NAVIGATION, 'aanbod.relevante-vragen.header.return.mijn', '', tabNavigationTitles, () => {
        this.props.history.push('/aanbod/overzicht');
      });
    }
  };

  private navigateToUpdateAanbod = () => {
    this.props.history.push(this.props.location.pathname + '/update');
  };

  private downloadBestand = (uid: string) => downloadAanbodBestand(this.getAanbodUid(), extractUid('bestand', uid));

  private handleOpenArchiveerModal = () => {
    this.props.showModal(ARCHIVEER_AANBOD, {
      submit: () => {
        this.props.archiveerMateriaalAanbod(this.getAanbodUid()).then(() => {
          if (!this.props.queryResult.errorMessage) {
            this.props.history.push('/aanbod/overzicht');
            this.props.hideModal(ARCHIVEER_AANBOD);
          }
        });
      },
      cancel: () => this.props.hideModal(ARCHIVEER_AANBOD),
      submitText: intl.get('aanbod.archiveer-modal.submitTekst'),
      cancelText: intl.get('aanbod.archiveer-modal.cancelTekst'),
      title: intl.get('aanbod.archiveer-modal.title'),
      text: intl.get('aanbod.archiveer-modal.text')
    });
  };
}

const mapStateToProps = storeState => ({
  queryResult: storeState.aanbod.details,
  isAuthenticated: storeState.authentication.security.isAuthenticated,
  currentUser: storeState.authentication.contactPersoon
});

const mapDispatchToProps = {
  getMateriaalAanbod,
  showModal,
  hideModal,
  archiveerMateriaalAanbod,
  changeHeader,
  createHulpvraagForAanbod
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(MateriaalAanbodDetailPagina));
