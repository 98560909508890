import {combineReducers} from 'redux';
import {categorieenListReducer} from './list';
import {initialModalState, modalReducer} from '../modal/modal';

export const CATEGORIE_MODAL = 'categorie/zoek';

export const categorie = combineReducers({
  categorieenList: categorieenListReducer,
  categorieModal: modalReducer(CATEGORIE_MODAL, initialModalState())
});
