import React from 'react';
import 'antd/dist/antd.min.css';
import intl from 'react-intl-universal';

export const UploadFotoButton = () => (
  <div>
    <i className="fas fa-plus"/>
    <div style={{marginTop: 8}} className="vl-toggle--plus-minus">{intl.get('materiaal.edit.form.fotos.button.add')}</div>
  </div>
);
