import Validator from 'validatorjs';
import {isValidEuralCode} from 'src/modules/eural/eural-filter';
import {parseDate} from '../../dates';

export const scale = input => {
  if (input === null) return -1;
  const inputNumber: number = (typeof(input) === 'number') ? input : Number(input);
  if (!isFinite(inputNumber) || isNaN(inputNumber)) return -1;
  let e = 1;
  let p = 0;
  while (Math.round(inputNumber * e) / e !== inputNumber) {
    e *= 10;
    p++;
  }
  return p;
};

export const isValidPhoneNumber = (phone: string): boolean => !!phone && /^(?:(\+[1-9])|0)\d{1,14}$/g.test(phone.replace(/\s+/g, ''));

export const isValidUrl = (url: string): boolean => /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,63}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/i.test(url);

export const isAfter=(value: string, otherDate: string) => parseDate(value).getTime() >= parseDate(otherDate).getTime();

export const registerValidators = () => {
  Validator.register('maxScale', (value, requirement) => (scale(value) <= Number(requirement)), defaultValidatorMessage(':attribute.maxScale'));
  Validator.register('is_eural_code', (value: string) => isValidEuralCode(value), defaultValidatorMessage(':attribute.is_eural_code'));
  Validator.register('phone', (value: string) => isValidPhoneNumber(value), defaultValidatorMessage(':attribute.phone'));
  Validator.register('is_after', (value: string, otherDate) => isAfter(value, otherDate), defaultValidatorMessage(':is_after'));
};

const defaultValidatorMessage = (validator: string): string => `Default message for the ${validator} validator, please add a custom message for this locale.`;
