import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {ButtonGroup, Loading} from 'webuniversum-react';
import {downloadAanbodBestand, getMateriaalAanbod, IGetMateriaalAanbodState} from 'src/reducers/aanbod/materiaal/get';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {AuthenticatieNiveau} from 'src/reducers/authentication';
import {createConversatie, createHulpvraagForRelevantAanbod, getHulpvraagForRelevantAanbod, getOverleg, IConversatie} from 'src/reducers/bericht/conversatie';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {getRelevantie, IRelevantie} from 'src/reducers/relevantie';
import {RELEVANT_AANBOD_HULPVRAAG, RELEVANT_AANBOD_SEND_BERICHT, RELEVANT_AANBOD_SYMBIOSE_INVITE} from 'src/reducers/vraag';
import {getMateriaalVraag, IGetMateriaalVraagState} from 'src/reducers/vraag/materiaal/get';
import {RelevantAanbodHulpvraagModal, RelevantAanbodSendBerichtModal} from 'src/modules/bericht/bericht-modal';
import {SymbioseFrame} from 'src/modules/form-components/symbiose-frame';
import {extractUid} from 'src/modules/referentie-uri';
import {InviteModal} from 'src/modules/symbiose/invite-symbiose-modal';
import {DetailMateriaalAanbod} from 'src/modules/aanbod/materiaal/detail-materiaal-aanbod';
import {AxiosPromise} from 'axios';
import {getSymbioseVoorVraagEnAanbod} from 'src/reducers/symbiose/get';
import {ISymbiose} from 'src/reducers/symbiose/create';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {ToonConversatieButton} from 'src/modules/bericht/toon-conversatie-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

interface IRelevantMateriaalAanbodProps {
  queryResult: IGetMateriaalAanbodState;
  vraag: IGetMateriaalVraagState;
  relevantie: IRelevantie;
  symbiose: ISymbiose;
  conversatie: IConversatie;
  afstandRanges: number[];
  isAuthenticated: boolean;
  getMateriaalAanbod: Function;
  getMateriaalVraag: Function;
  getRelevantie: Function;
  getSymbioseVoorVraagEnAanbod: Function;
  getHulpvraagForRelevantAanbod: Function;
  createHulpvraagForRelevantAanbod: Function;
  getOverleg: Function;
  createConversatie: Function;
  changeHeader: Function;
  showModal: Function;
  hideModal: Function;
  history: any;
  match: any;
}

export class RelevantMateriaalAanbod extends React.Component<IRelevantMateriaalAanbodProps, undefined> {
  componentDidMount() {
    this.props.getMateriaalAanbod(this.props.match.params.aanbodUid);
    this.props.getMateriaalVraag(this.props.match.params.vraagUid);
    this.props.getRelevantie(this.props.match.params.aanbodUid, this.props.match.params.vraagUid);
    this.props.getSymbioseVoorVraagEnAanbod(this.props.match.params.vraagUid, this.props.match.params.aanbodUid);
    this.props.getOverleg(this.props.match.params.aanbodUid, this.props.match.params.vraagUid);

    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'detail.header.return.aanbod',
      '',
      [
        {
          title: 'detail.header.title.aanbod',
          isActive: true
        }
      ],
      () => {
        this.props.history.push('../aanbiedingen');
      });
  }

  componentWillUnmount(): void {
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
    this.props.hideModal(RELEVANT_AANBOD_SEND_BERICHT);
    this.props.hideModal(RELEVANT_AANBOD_HULPVRAAG);
  }

  getAanbodUid = () => extractUid('aanbod', this.props.queryResult.aanbod.aanbodReferentie);

  getVraagUid = () => extractUid('vraag', this.props.vraag.vraag.vraagReferentie);

  downloadBestand = (uid: string): AxiosPromise => downloadAanbodBestand(this.getAanbodUid(), extractUid('bestand', uid));

  private handleOpenBerichtModal = () => {
    this.props.getOverleg(this.getAanbodUid(), this.getVraagUid())
      .then(() => {
        if (!this.props.conversatie.referentie) {
          this.props.createConversatie(this.getAanbodUid(), this.getVraagUid());
        }
      });

    this.props.showModal(RELEVANT_AANBOD_SEND_BERICHT, {
      cancel: () => this.props.hideModal(RELEVANT_AANBOD_SEND_BERICHT)
    });
  };

  private handleOpenHulpvraagModal = () => {
    this.props.getHulpvraagForRelevantAanbod(this.getVraagUid(), this.getAanbodUid())
      .then(() => {
        if (!this.props.conversatie.referentie) {
          this.props.createHulpvraagForRelevantAanbod(this.getVraagUid(), this.getAanbodUid());
        }
      });

    this.props.showModal(RELEVANT_AANBOD_HULPVRAAG, {
      cancel: () => {
        this.props.hideModal(RELEVANT_AANBOD_HULPVRAAG);
        this.props.getOverleg(this.getAanbodUid(), this.getVraagUid());
      }
    });
  };

  private createSymbioseOpenModal = () => {
    this.props.showModal(RELEVANT_AANBOD_SYMBIOSE_INVITE, {
      submitText: intl.get('detail.symbiose.invite.send.label'),
      submit: () => this.props.getSymbioseVoorVraagEnAanbod(this.getVraagUid(), this.getAanbodUid()),
      cancel: () => this.props.hideModal(RELEVANT_AANBOD_SYMBIOSE_INVITE),
      cancelText: intl.get('detail.symbiose.invite.cancel.label'),
      title: intl.get('detail.symbiose.invite.label'),
      text: intl.get('detail.symbiose.invite.symbiose-invite.label.aanbod')
    });
  };

  render(): React.ReactNode {
    const { queryResult, symbiose } = this.props;
    const { loading, aanbod } = queryResult;
    return (
      <>
        {this.props.isAuthenticated &&
        <div className="vl-grid">
          <Loading loading={loading && aanbod !== null}>

            <div className="vl-col--8-12">
              <div className="vl-grid">
                <div className="vl-col--12-12">
                  <DetailMateriaalAanbod aanbod={aanbod} downloadBestand={this.downloadBestand}
                    relevantie={this.props.relevantie}
                    vraag={this.props.vraag}
                    afstandRanges={this.props.afstandRanges}/>
                </div>
              </div>
            </div>

            <div className="vl-col--3-12 vl-push--1-12">
              <ButtonGroup>
                <VraagHulpButton handleClick={this.handleOpenHulpvraagModal} className="bericht-button" />
                <ToonConversatieButton conversatie={this.props.conversatie} handleClick={this.handleOpenBerichtModal} />
              </ButtonGroup>

              <SymbioseFrame onClickButton={this.createSymbioseOpenModal} symbiose={symbiose}/>
            </div>
          </Loading>

          <RelevantAanbodSendBerichtModal/>
          <RelevantAanbodHulpvraagModal/>
          <InviteModal vraagReferentie={this.getVraagUid()}
            aanbodReferentie={this.getAanbodUid()}
            warning={aanbod?.onderneming?.authenticatieNiveau !== AuthenticatieNiveau.GEVERIFIEERD && intl.get('detail.symbiose.invite.warning.unverified')}/>
        </div>
        }
      </>
    );
  }
}

const mapStateToProps = storeState => ({
  queryResult: storeState.aanbod.details,
  vraag: storeState.vraag.details,
  relevantie: storeState.relevantie.relevantie,
  symbiose: storeState.symbiose.get.symbiose,
  conversatie: storeState.bericht.bericht.conversatie,
  afstandRanges: storeState.application.application.afstandRanges,
  isAuthenticated: storeState.authentication.security.isAuthenticated
});

const mapDispatchToProps = {
  getMateriaalAanbod,
  getMateriaalVraag,
  getRelevantie,
  getSymbioseVoorVraagEnAanbod,
  getHulpvraagForRelevantAanbod,
  createHulpvraagForRelevantAanbod,
  getOverleg,
  createConversatie,
  changeHeader,
  showModal,
  hideModal
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(RelevantMateriaalAanbod));
