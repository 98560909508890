import React from 'react';
import {Button, ButtonGroup, FormComponent, ModalContent, Select, Textarea} from 'webuniversum-react';
import './bericht-modal.css';
import {IContactPersoon} from 'src/reducers/authentication';
import {getStore} from 'src/redux-store';
import {createValidateFunction, formEdit, FormPropsFactory, FormPropsFactoryBuilder, formState, formValidate, IFormActions, IFormState, initialFormState} from 'redux-form-library';
import {enumOptions} from 'src/modules/form-components/translatable-select-options';
import {AfsluitOnderwerpen} from 'src/modules/bericht/afsluit-onderwerpen';
import {initialSluitHulpvraagAfFormState, ISluitAfForm, SLUIT_AF_FORM, sluitHulpvraagAf} from 'src/reducers/bericht/afsluiten';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import {extractUid} from 'src/modules/referentie-uri';
import {IConversatie} from 'src/reducers/bericht/conversatie';

export interface IBerichtModalContentAfsluitenProps extends IFormActions<ISluitAfForm> {
  dispatch: Dispatch;
  sluitHulpvraagAf: Function;
  contactPersoon: IContactPersoon;
  conversatie: IConversatie;
  form: IFormState<ISluitAfForm>;
  toggleAfsluiten: Function;
  bewaren: Function;
}

export class BerichtModalContentAfsluiten extends React.Component<IBerichtModalContentAfsluitenProps, undefined> {
  createValidationRules = () => ({
    onderwerp: 'string|required'
  });

  generateErrorMessages = () => ({
    'required.onderwerp': intl.get('validation.form.field.required', {
      NAME: intl.get('bericht.sluit-af.form.fields.onderwerp.label')
    })
  });

  handleSubmit = formData => {
    this.props.sluitHulpvraagAf(extractUid('conversatie', this.props.conversatie.referentie), formData.onderwerp, formData.opmerkingen)
      .then(() => {
        this.props.bewaren();
        this.props.formState(initialFormState(initialSluitHulpvraagAfFormState()));
      });
  };

  handleCancel = () => {
    this.props.formState(initialFormState(initialSluitHulpvraagAfFormState()));
    this.props.toggleAfsluiten();
  };

  createValidation = (errormessages, validationRules) => createValidateFunction(validationRules, errormessages);

  render(): React.ReactNode {
    const { contactPersoon } = this.props;
    const fields = this.props.form.fields;
    const errorMessages = this.generateErrorMessages();
    const validationRules = this.createValidationRules();

    const formPropsFactory: FormPropsFactory<ISluitAfForm> = new FormPropsFactoryBuilder()
      .setFieldsObject(fields)
      .setDispatch(this.props.dispatch)
      .setValidate(this.createValidation(errorMessages, validationRules))
      .setSelector(formSelector)
      .setStore(getStore())
      .setFormName(SLUIT_AF_FORM)
      .build();

    return (
      <>
        {
          contactPersoon.onderneming.naam === 'Symbioseteam' &&
          <div className="send-bericht-modal-dialog__content">
            <ModalContent>
              <h1 className="symbioseteam-hulpvraag-content-title">{intl.get('bericht.sluit-af.form.title')}</h1>
              <form onSubmit={formPropsFactory.createSubmitHandler(this.handleSubmit)}>

                <FormComponent id="onderwerp"
                  label={intl.get('bericht.sluit-af.form.fields.onderwerp.label')}
                  required={errorMessages[ 'required.onderwerp' ]}
                  error={fields.onderwerp.errors} block>
                  <Select {...formPropsFactory.createInputProps('onderwerp')} block>
                    {enumOptions(AfsluitOnderwerpen, 'bericht.sluit-af.form.fields.onderwerp.values')
                      .optionsWithPlaceholder('bericht.sluit-af.form.fields.onderwerp.placeholder')}
                  </Select>
                </FormComponent>

                <div className="vl-u-spacer--small"/>

                <FormComponent id="opmerkingen"
                  label={intl.get('bericht.sluit-af.form.fields.opmerkingen.label')}
                  block>
                  <Textarea {...formPropsFactory.createInputProps('opmerkingen')}
                    block
                    rows={5}
                    placeholder={intl.get('bericht.sluit-af.form.fields.opmerkingen.placeholder')}/>
                </FormComponent>

                <ButtonGroup>

                  <Button type="submit">
                    {intl.get('materiaal.edit.form.button.save')}
                  </Button>
                  <a className="close-hulpvraag" onClick={() => this.handleCancel()}>
                    <i className="vl-link--icon--close"/>
                    <span>{intl.get('bericht.sluit-af.cancel')}</span>
                  </a>
                </ButtonGroup>
              </form>

            </ModalContent>
          </div>
        }
      </>
    );
  }
}

const formSelector = storeState => storeState.bericht.hulpvraagAfsluitenForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState)
});

const mapDispatchToProps = dispatch => {
  const actions = {
    sluitHulpvraagAf,
    formState: formState(SLUIT_AF_FORM),
    formValidate: formValidate<ISluitAfForm>(SLUIT_AF_FORM),
    formEdit: formEdit(SLUIT_AF_FORM)
  };
  return { ...bindActionCreators(actions, dispatch), dispatch };
};

export default connect(mapStateToProps, mapDispatchToProps)(BerichtModalContentAfsluiten);
