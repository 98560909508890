import React from 'react';
import { IModalContainerProps } from 'src/reducers/modal/modal';
import { Button, ButtonGroup, Label, Modal, ModalButtons, ModalContent, ModalTitle } from 'webuniversum-react';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';
import { getAlgemeneVoorwaarden } from 'src/reducers/algemene_voorwaarden/getPdf';
import { isFullyAuthenticated } from 'src/has-permission';
import { ISecurity } from 'src/reducers/authentication';

export interface IAlgemeneVoorwaardenModalProps {
  modalProps: IModalContainerProps;
  urlAlgemeneVoorwaardenPdf: string;
  acceptatieTekst: string;
  getAlgemeneVoorwaarden: Function;
  taal: string;
  security: ISecurity;
}

export class AanvaardAlgemeneVoorwaardenModal extends React.Component<IAlgemeneVoorwaardenModalProps> {
  componentDidUpdate(prevProps: Readonly<IAlgemeneVoorwaardenModalProps>, prevState: Readonly<{}>, snapshot?: any) {
    if (!this.props.urlAlgemeneVoorwaardenPdf && isFullyAuthenticated(this.props.security)) {
      this.props.getAlgemeneVoorwaarden(this.props.taal);
    }
  }

  state = {
    numPages: 0
  };

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  render(): React.ReactNode {
    const { visible, submit, cancelText, title, submitText, cancel } = this.props.modalProps;

    return (
      <Modal hidden={!visible} overlayHidden={!visible} style={{ width: '85rem' }}>
        <ModalTitle>{title}</ModalTitle>
        <ModalContent>
          <div className="algemene-voorwaarden vl-description-data--bordered vl-u-spacer">
            <Document file={this.props.urlAlgemeneVoorwaardenPdf} onLoadSuccess={this.onDocumentLoadSuccess}>
              {
                Array(...Array(this.state.numPages)).map((_, i) =>
                  <Page pageNumber={i + 1} key={i + 1} scale={1.2}/>)
              }
            </Document>
          </div>
          <Label>{this.props.acceptatieTekst}</Label>
        </ModalContent>
        <ModalButtons>
          <ButtonGroup>
            <Button type="button" onClick={submit}>
              {submitText}
            </Button>
            <Button type="button" color="secondary" onClick={cancel}>
              {cancelText}
            </Button>
          </ButtonGroup>
        </ModalButtons>
      </Modal>
    );
  }
}

const mapAlgemeneVoorwaardenStateToProps = storeState => ({
  modalProps: storeState.algemeneVoorwaardenModal,
  urlAlgemeneVoorwaardenPdf: storeState.algemeneVoorwaarden.get.pdf,
  taal: storeState.application.application.general.taal,
  security: storeState.authentication.security
});

const mapDispatchToProps = {
  getAlgemeneVoorwaarden
};

export const AanvaardAlgemeneVoorwaarden = connect(mapAlgemeneVoorwaardenStateToProps, mapDispatchToProps)(AanvaardAlgemeneVoorwaardenModal);
