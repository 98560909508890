import { reloadAvailableWidgets, selecteerTaal } from 'src/reducers/authentication';
import { wijzigApplicatieTaal } from 'src/reducers/taal/vertalingen';
import React from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

export interface ITaalkeuzeProps {
  location: any;
  history: any;
  selecteerTaal?: ActionCreator<any>;
}

export class Taalkeuze extends React.Component<ITaalkeuzeProps, undefined> {
  componentDidMount(): void {
    this.changeTaal(this.props.location.pathname.slice(-2));
  }

  private changeTaal(taal: string) {
    this.props.selecteerTaal(taal).then(() => {
      wijzigApplicatieTaal(taal);
      reloadAvailableWidgets();
    }).finally(() => this.props.history.goBack());
  }

  render(): React.ReactNode {
    return <div/>;
  }
}

const mapDispatchToProps = {
  selecteerTaal
};

export default connect(undefined, mapDispatchToProps)(Taalkeuze);
