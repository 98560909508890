import React from 'react';
import intl from 'react-intl-universal';
import {Alert} from 'webuniversum-react';
import {IGetMateriaalAanbodState} from 'src/reducers/aanbod/materiaal/get';
import {IRelevantie} from 'src/reducers/relevantie';
import {IMateriaalVraag} from 'src/reducers/vraag/materiaal/get';
import {DetailAdres} from 'src/modules/detail-components/detail-adres';
import {DetailClassificatie} from 'src/modules/detail-components/detail-classificatie';
import {DetailField} from 'src/modules/detail-components/detail-field';
import {DetailFooter} from 'src/modules/detail-components/detail-footer';
import {DetailHeader} from 'src/modules/detail-components/detail-header';
import {DetailHoeveelheid} from 'src/modules/detail-components/detail-hoeveelheid';
import {DetailRelevantie} from 'src/modules/detail-components/detail-relevantie';
import {translateKey} from 'src/modules/form-components/translatable-select-options';
import {GevaarlijkeStoffenEnum} from 'src/modules/materiaal/gevaarlijke-stoffen-enum';
import {MateriaalStatusEnum} from 'src/modules/materiaal/materiaal-status-enum';
import {Autotranslated} from 'src/modules/taal/autotranslated';
import {AutotranslationWarning} from 'src/modules/taal/autotranslation-warning';
import {Preformatted} from 'src/modules/detail-components/preformatted';
import {FotoCarrousel} from 'src/modules/foto/foto-carrousel';
import {BijlagenLijst} from 'src/modules/bijlage/bijlagen-lijst';
import {AxiosPromise} from 'axios';
import DetailBeschikbaarheid from '../../detail-components/detail-beschikbaarheid';

export interface IDetailVraag {
  status?: MateriaalStatusEnum;
  vraag: IMateriaalVraag;
  relevantie?: IRelevantie;
  aanbod?: IGetMateriaalAanbodState;
  afstandRanges?: number[];
  downloadBestand: (referentie: string) => AxiosPromise;
}

const label_column_class = 'vl-col--3-12';
const value_column_class = 'vl-col--9-12';

export class DetailMateriaalVraag extends React.Component<IDetailVraag> {

  render(): React.ReactNode {
    const {vraag, downloadBestand, relevantie, aanbod, afstandRanges, status} = this.props;
    const autotranslated = <Autotranslated vertaling={vraag}/>;

    return (
      <>
        <div className="vl-page">
          <div className="vl-col--12-12">
            <DetailHeader
              title_translate_key={'detail.title.vraag'}
              beschrijving={vraag.titel}
              img_src={status === MateriaalStatusEnum.GEARCHIVEERD ? '/static/images/vraag-logo-gearchiveerd.png' : '/static/images/vraag-logo.png'}>
              {autotranslated}
            </DetailHeader>
          </div>

          {relevantie && <DetailRelevantie relevantie={relevantie}
            titel={intl.get('vraag.detail.relevant-aanbod.titel', {titel: aanbod.aanbod.titel})}
            afstandRanges={afstandRanges}/>}

          <AutotranslationWarning vertaling={vraag}/>

          <FotoCarrousel fotos={vraag.fotos} handleDownload={downloadBestand}/>
          <section id="materiaal-info">

            <h2 className="vl-h2 vl-u-spacer--tiny">{intl.get('detail.materiaal-info.title')}</h2>
            <DetailField label={intl.get('detail.beschrijving.title')}
              value={vraag.beschrijving}
              label_column_class={label_column_class}
              value_column_class={value_column_class}>
              {autotranslated}
            </DetailField>

            <div className="vl-u-spacer--small"/>

            <DetailClassificatie categorieen={vraag.categorieen}
              euralCode={vraag.euralCode}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>

            <div className="vl-u-spacer--small"/>

            <DetailField label={intl.get('detail.materiaal-info.materiaaltoestand.label')}
              value={vraag.materiaalToestand && intl.get(translateKey(vraag.materiaalToestand,
                'detail.materiaal-info.materiaaltoestand.values'))}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>

            <div className="vl-u-spacer--small"/>

            {vraag.kleur &&
            <DetailField label={intl.get('detail.materiaal-info.kleur.label')}
              value={vraag.kleur}
              label_column_class={label_column_class}
              value_column_class={value_column_class}>
              {autotranslated}
            </DetailField>
            }

            <div className="vl-u-spacer--small"/>

            <DetailField label={intl.get('detail.materiaal-info.beoogde-verwerkingswijze.label')}
              value={vraag.beoogdeVerwerkingswijze && intl.get(translateKey(vraag.beoogdeVerwerkingswijze,
                'detail.materiaal-info.beoogde-verwerkingswijze.values'))}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>

            <div className="vl-u-spacer--small"/>

            <h3 className="vl-h3">{intl.get('detail.materiaal-samenstelling.title')}</h3>

            <DetailField label={intl.get('detail.materiaal-samenstelling.samenstelling')}
              value={vraag.materiaalSamenstelling}
              label_column_class={label_column_class}
              value_column_class={value_column_class}>
              {autotranslated}
            </DetailField>

            <div className="vl-u-spacer--small"/>

            {(vraag.productieProces !== null || vraag.idealeOorsprong !== null) &&
            <h3 className="vl-h3">{intl.get('detail.oorsprong-doel.title-vraag')}</h3>
            }
            {vraag.productieProces &&
            <DetailField label={intl.get('detail.oorsprong-doel.productie-proces')}
              value={vraag.productieProces}
              label_column_class={label_column_class}
              value_column_class={value_column_class}>
              {autotranslated}
            </DetailField>
            }
            {vraag.idealeOorsprong &&
            <DetailField label={intl.get('detail.oorsprong-doel.ideale-oorsprong')}
              value={vraag.idealeOorsprong}
              label_column_class={label_column_class}
              value_column_class={value_column_class}>
              {autotranslated}
            </DetailField>
            }

            {(vraag.beschikbaarheid.van || vraag.beschikbaarheid.tot) &&
              <div className="vl-u-spacer--small"/> &&
              <DetailBeschikbaarheid
                title={intl.get('detail.beschikbaarheid.title')}
                label={intl.get('detail.beschikbaarheid.label')}
                value={vraag.beschikbaarheid}
                label_column_class={label_column_class}
                value_column_class={value_column_class} />}

            <div className="vl-u-spacer--small"/>

            {vraag.hoeveelheid.eenheid &&
            <DetailHoeveelheid title={intl.get('detail.materiaal-info.hoeveelheid.title.vraag')}
              frequentie={vraag.hoeveelheid.frequentie && intl.get(translateKey(vraag.hoeveelheid.frequentie,
                'detail.materiaal-info.hoeveelheid.frequentie.values'))}
              kwantiteit={vraag.hoeveelheid.kwantiteit}
              eenheid={vraag.hoeveelheid.eenheid && intl.get(translateKey(vraag.hoeveelheid.eenheid,
                'detail.materiaal-info.hoeveelheid.eenheid.values'))}
              label_column_class={label_column_class}
              value_column_class={value_column_class}/>
            }

            <div className="vl-u-spacer--small"/>

            {vraag.adres.land &&
            <DetailAdres title={intl.get('detail.materiaal-info.adres.title.vraag')}
              label={intl.get('detail.materiaal-info.adres.label')}
              value={vraag.adres}
              label_column_class={label_column_class}
              value_column_class={value_column_class}
            />
            }

            <div className="vl-u-spacer--small"/>

            {(vraag.bijkomendeInfo
                || vraag.gevaarlijkeStoffen.gevaarlijkeStoffen === GevaarlijkeStoffenEnum.MET_GEVAARLIJKE_STOFFEN
                || vraag.privateBijlagen.length > 0
                || vraag.publiekeBijlagen.length > 0) &&
              <h3 className="vl-h3">{intl.get('detail.materiaal-info.meer-info.title')}</h3>
            }

            {vraag.gevaarlijkeStoffen.gevaarlijkeStoffen === GevaarlijkeStoffenEnum.MET_GEVAARLIJKE_STOFFEN &&
            <>
              <div className="vl-u-spacer--small"/>
              <DetailField
                label={intl.get('detail.gevaarlijke-stoffen.label.vraag')}
                value={vraag.gevaarlijkeStoffen.beschrijvingGevaarlijkeStoffen}
                label_column_class={label_column_class}
                value_column_class={value_column_class}>
                {autotranslated}
              </DetailField>
            </>
            }

            {vraag.bijkomendeInfo &&
            <>
              <div className="vl-u-spacer--small"/>
              <DetailField label={intl.get('detail.materiaal-info.meer-info.bijkomendeinfo.label')}
                label_column_class={label_column_class}
                value_column_class={value_column_class}>
                <Preformatted text={vraag.bijkomendeInfo}/>
                {autotranslated}
              </DetailField>
            </>
            }
            {vraag.publiekeBijlagen.length > 0 &&
              <>
                <div className="vl-u-spacer--small" />
                <DetailField
                  label={intl.get('detail.materiaal-info.meer-info.publieke-bijlagen.label')}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}
                >
                  <BijlagenLijst bijlagen={vraag.publiekeBijlagen} handleDownload={downloadBestand} />
                </DetailField>
              </>
            }
            {vraag.privateBijlagen.length > 0 &&
              <>
                <div className="vl-u-spacer--small" />
                <DetailField
                  label={intl.get('detail.materiaal-info.meer-info.private-bijlagen.label')}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}
                >
                  <BijlagenLijst bijlagen={vraag.privateBijlagen} handleDownload={downloadBestand} />
                </DetailField>
              </>
            }
          </section>
          <section id="contact-info">

            <div className="vl-u-spacer--small"/>

            {vraag.onderneming.naam ?
              <>
                <DetailFooter
                  onderneming_naam={vraag.onderneming.naam}
                  contact_persoon={vraag.contactPersoon}
                  label_column_class={label_column_class}
                  value_column_class={value_column_class}/>
              </>
              :
              <div style={{marginTop: '5rem'}}>
                <Alert title={intl.get('detail.alert.title')}>
                  {intl.get('detail.alert.content')}
                </Alert>
              </div>
            }
          </section>
        </div>
      </>
    );
  }
}
