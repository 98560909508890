import React from 'react';
import {InfoBlock, Loading} from 'webuniversum-react';
import {getContactInformatie, IGetContactInformatieState} from 'src/reducers/informatieve_website/contact-informatie';
import {connect} from 'react-redux';
import {RichText} from 'src/modules/rich-text';
import {createHulpvraagForOnderneming, getHulpvraagForOnderneming, IConversatie} from 'src/reducers/bericht/conversatie';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {STARTPAGINA} from 'src/reducers/startpagina';
import {isFullyAuthenticated, isSymbioseTeam} from 'src/has-permission';
import {StartpaginaHulpvraagModal} from 'src/modules/bericht/bericht-modal';
import {ISecurity} from 'src/reducers/authentication';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';

export interface IContactInformatieProps {
  getContactInformatie: Function;
  taal: string;
  contactInformatie: IGetContactInformatieState;
  security: ISecurity;
  getHulpvraagForOnderneming: Function;
  createHulpvraagForOnderneming: Function;
  conversatie: IConversatie;
  showModal: Function;
  hideModal: Function;
}

export class ContactInformatieBlock extends React.Component<IContactInformatieProps, undefined> {
  componentDidMount() {
    this.props.getContactInformatie(this.props.taal);
    if (isFullyAuthenticated(this.props.security)) {
      this.props.getHulpvraagForOnderneming();
    }
  }

  render(): React.ReactNode {
    const info = this.props.contactInformatie.contactInformatie;
    return (
      <>
        <Loading loading={this.props.contactInformatie.loading}>
          {info && info.titel && <InfoBlock titel={info.titel} layout="contact">
            <div className="vl-grid vl-u-spacer--small">
              <div className="vl-col--6-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs">
                <RichText content={info.kolom1}/>
              </div>
              <div className="vl-col--6-12 vl-col--6-12--m vl-col--6-12--s vl-col--1-1--xs">
                <RichText content={info.kolom2}/>
              </div>
            </div>
            <div className="vl-grid vl-u-spacer">
              <div className="vl-col--10-12"/>
              <div className="vl-col--2-12">
                {
                  isFullyAuthenticated(this.props.security) && !isSymbioseTeam() &&
                  <VraagHulpButton handleClick={this.handleOpenModal} />
                }
              </div>
            </div>
          </InfoBlock>}
        </Loading>
        <StartpaginaHulpvraagModal/>
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }

    this.props.showModal(STARTPAGINA, {
      cancel: () => this.props.hideModal(STARTPAGINA)
    });
  };
}

const mapStateToProps = storeState => ({
  security: storeState.authentication.security,
  conversatie: storeState.bericht.bericht.conversatie,
  contactInformatie: storeState.informatieveWebsite.contactInformatie,
  taal: storeState.application.application.general.taal
});

const mapDispatchToProps = {
  getContactInformatie,
  showModal,
  hideModal,
  getHulpvraagForOnderneming,
  createHulpvraagForOnderneming
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInformatieBlock);
