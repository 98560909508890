import React from 'react';
import intl from 'react-intl-universal';
import { InfoTile, Label } from 'webuniversum-react';
import ActieGevraagdAanvaardNegeer from './actie-gevraagd-aanvaard-negeer';
import ActieGevraagdGerealiseerdNietGerealiseerd from './actie-gevraagd-gerealiseerd-niet-gerealiseerd';
import { ISymbiose } from 'src/reducers/symbiose/create';
import ActieGevraagdDocumenteerMeerwaarde from './actie-gevraagd-documenteer-meerwaarde';
import ActieGevraagdDocumenteerNietRealisatie from './actie-gevraagd-documenteer-niet-realisatie';

export interface IStatusViewProps {
  symbiose: ISymbiose;
  currentOndernemingReferentie: string;
  documentUrl: string;
}

export class StatusView extends React.Component<IStatusViewProps> {
  render(): React.ReactNode {
    const { symbiose } = this.props;

    return (
      <div>
        <div className="vl-u-spacer--small">
          <Label>{intl.get('symbiose.status.label')}</Label>
          <Label
            className="vl-description-data__value">{symbiose.status ? intl.get(`symbiose.status.values.${symbiose.status}`) : ''}</Label>
          <Label>{intl.get('validation.date.format', { date: new Date(symbiose.datumStatus) })}</Label>
        </div>
        <InfoTile>
          <p className="checklist-afval-grondstof-url">{intl.getHTML('symbiose.actie-gevraagd.checklist',
            { url: this.props.documentUrl }
          )}</p>
        </InfoTile>

        {symbiose.status === 'UITGENODIGD_VOOR_SYMBIOSE' &&
        symbiose.genodigdeReferentie === this.props.currentOndernemingReferentie &&
        <ActieGevraagdAanvaardNegeer symbiose={symbiose}
          currentOndernemingReferentie={this.props.currentOndernemingReferentie}/>}

        {symbiose.status === 'IN_SYMBIOSE' &&
        <>
          <div className="vl-u-spacer--small"/>
          <ActieGevraagdGerealiseerdNietGerealiseerd symbiose={symbiose}/>
        </>}

        {(symbiose.status === 'GEREALISEERD' && symbiose.meerwaardes.length === 0 ||
          symbiose.status === 'GEREALISEERD' && symbiose.meerwaardes.length === 1
          && symbiose.meerwaardes[ 0 ].ondernemingsReferentie !== this.props.currentOndernemingReferentie) &&
        <ActieGevraagdDocumenteerMeerwaarde symbiose={symbiose}/>}

        {(symbiose.status === 'NIET_GEREALISEERD' && symbiose.nietRealisatieRedenen.length === 0 ||
          symbiose.status === 'NIET_GEREALISEERD' &&
          symbiose.nietRealisatieRedenen.length === 1 &&
          symbiose.nietRealisatieRedenen[ 0 ].ondernemingsReferentie !== this.props.currentOndernemingReferentie) &&
        <ActieGevraagdDocumenteerNietRealisatie symbiose={symbiose}/>}
      </div>
    );
  }
}
