import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { Document } from '@contentful/rich-text-types';

export const ACTION_TYPES = {
  GET: 'informatieve-website/landingspagina/GET'
};

export interface ILandingsPagina {
  titel: string;
  inhoud: Document;
}

export interface IGetLandingsPaginaState {
  loading: boolean;
  errorMessage: any;
  landingsPagina: ILandingsPagina;
}

export const initialState: IGetLandingsPaginaState = {
  loading: false,
  errorMessage: null,
  landingsPagina: null
};

export const landingsPaginaReducer = (state = initialState, action: AnyAction): IGetLandingsPaginaState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.GET):
    return {
      ...state,
      errorMessage: null,
      loading: true,
      landingsPagina: null
    };
  case FAILURE(ACTION_TYPES.GET):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.GET):
    return {
      ...state,
      loading: false,
      landingsPagina: action.payload.data
    };
  default:
    return state;
  }
};

const apiUrl = '/ui/informatievewebsite';

export const getLandingsPagina: ActionCreator<any> = (taal: string) => ({
  type: ACTION_TYPES.GET,
  payload: axios.get(`${apiUrl}/landingspagina/${taal}`)
});
