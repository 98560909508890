import React, { PropsWithChildren } from 'react';

export interface IGridColumnFields {
  label_column_class: string;
  value_column_class: string;
}

export interface IDetailFieldProps extends IGridColumnFields, PropsWithChildren<any> {
  label: string;
  value?: string;
}

export class DetailField extends React.Component<IDetailFieldProps> {

  render(): React.ReactNode {
    const { label_column_class, value_column_class, label, value, children } = this.props;
    return (
      <div className="vl-grid">
        <div className={label_column_class}>
          <label>{label}</label>
        </div>
        <div className={value_column_class}>
          {value}
          {children}
        </div>
      </div>
    );
  }
}
