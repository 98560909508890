import axios from 'axios';
import {ActionCreator, AnyAction} from 'redux';
import {formReducer, initialFormState} from 'redux-form-library';
import {ICategorieForm} from 'src/modules/categorie/categorie-form';
import {FAILURE, REQUEST, SUCCESS} from '../../action-type.util';
import {ITaalSpecifiek} from '../../taal/vertalingen';
import {IMateriaalBeschikbaarheid} from '../../../modules/beschikbaarheid/materiaal-beschikbaarheid';
import { DATEPICKER_DATE } from '../../../dates';
import moment from 'moment';

export const ACTION_TYPES = {
  SEARCH_MATERIAAL_VRAAG: 'vraag/SEARCH_MATERIAAL_VRAAG',
  CLEAR_SEARCH_RESULT: 'vraag/CLEAR_SEARCH_RESULT'
};

export const SEARCH_MATERIAAL_VRAAG_FORM = 'SEARCH_MATERIAAL_VRAAG_FORM';

export interface ISearchMateriaalVraagForm {
  titel: string;
  onderneming: string;
  categorie: ICategorieForm;
  beginDatum: string;
  eindDatum: string;
  beschikbaarheidVan: string;
  beschikbaarheidTot: string;
}

export const initialSearchMateriaalVraagFormState = (): ISearchMateriaalVraagForm => ({
  titel: '',
  onderneming: '',
  categorie: {
    code: '',
    hoofdCategorie: '',
    standaardCategorie: '',
    subCategorie: ''
  },
  beginDatum: '',
  eindDatum: '',
  beschikbaarheidVan: moment().format(DATEPICKER_DATE),
  beschikbaarheidTot: ''
});

export interface IFoundVraag extends ITaalSpecifiek {
  vraagReferentie: string;
  coverFotoReferentie: string;
  titel: string;
  beschrijving: string;
  onderneming: string;
  beoogdeVerwerkingswijze: string;
  beschikbaarheid?: IMateriaalBeschikbaarheid;
}

export interface IFoundVragenList {
  loading: boolean;
  searched: boolean;
  errorMessage: any;
  vragen: IFoundVraag[];
}

const initialState: IFoundVragenList = {
  loading: false,
  searched: false,
  errorMessage: null,
  vragen: []
};

export const searchMateriaalVraagForm = formReducer(SEARCH_MATERIAAL_VRAAG_FORM, initialFormState(initialSearchMateriaalVraagFormState()));

export const searchMateriaalVraagReducer = (state = initialState, action: AnyAction): IFoundVragenList => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.SEARCH_MATERIAAL_VRAAG):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.SEARCH_MATERIAAL_VRAAG):
    return {
      ...state,
      loading: false,
      searched: true,
      errorMessage: action.payload.data
    };
  case SUCCESS(ACTION_TYPES.SEARCH_MATERIAAL_VRAAG):
    return {
      ...state,
      loading: false,
      searched: true,
      vragen: action.payload.data
    };
  case ACTION_TYPES.CLEAR_SEARCH_RESULT: {
    return {
      ...state,
      loading: false,
      searched: false,
      vragen: []
    };
  }
  default:
    return state;
  }
};

const apiUrl = '/ui/vragen/zoeken';

// Actions
export const findMateriaalVraag: ActionCreator<any> = (searchParams: any) => ({
  type: ACTION_TYPES.SEARCH_MATERIAAL_VRAAG,
  payload: axios.get(`${apiUrl}?${new URLSearchParams(searchParams).toString()}`)
});

export const clearSearchResults: ActionCreator<any> = () => ({
  type: ACTION_TYPES.CLEAR_SEARCH_RESULT
});
