import React from 'react';
import {Button, DataTable, IColumns, Loading, SortDirection} from 'webuniversum-react';
import {connect} from 'react-redux';
import {getConversatiesForUser, IGetConversatiesState} from 'src/reducers/bericht/conversaties';
import intl from 'react-intl-universal';
import {BerichtOverviewHulpvraagModal, ConversatieSendBerichtModal} from 'src/modules/bericht/bericht-modal';
import {createHulpvraagForOnderneming, getConversatie, getHulpvraagForOnderneming, IConversatie} from 'src/reducers/bericht/conversatie';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {BERICHT_OVERVIEW, CONVERSATIES_SEND_BERICHT} from 'src/reducers/bericht';
import {extractUid} from 'src/modules/referentie-uri';
import {TitelSubtitelCellGenerator} from 'src/modules/table-components/titel-subtitel-cell-generator';
import {PillCellGenerator} from 'src/modules/table-components/pill-cell-generator';
import {PartijenCellGenerator} from 'src/modules/table-components/partijen-cell-generator';
import {IAuthenticationState} from 'src/reducers/authentication';
import {DateCellGenerator} from 'src/modules/table-components/date-cell-generator';
import {BerichtInhoudCellGenerator} from 'src/modules/table-components/bericht-inhoud-cell-generator';
import {ActieStatusCellGenerator} from 'src/modules/table-components/actie-status-cell-generator';
import {isSymbioseTeam} from 'src/has-permission';
import {Link} from 'react-router-dom';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IBerichtenOverviewProps {
  getConversatiesForUser: Function;
  queryResult: IGetConversatiesState;
  getConversatie: Function;
  getHulpvraagForOnderneming: Function;
  createHulpvraagForOnderneming: Function;
  conversatie: IConversatie;
  showModal: Function;
  hideModal: Function;
  authentication: IAuthenticationState;
}

export interface IRow {
  referentie: string;
  aantalOngelezenBerichten: number;
  titel: string;
  subTitel: string;
  titelType: string;
  type: string;
  actieStatus: string;
  gesprekPartners: string[];
  timestamp: string;
  bericht: string;
}

export interface IBerichtenOverviewState {
  data: IRow[];
  sort: {
    actieStatus: SortDirection;
    gesprekPartners: SortDirection;
    timestamp: SortDirection;
  };
}

export class OverviewBerichten extends React.Component<IBerichtenOverviewProps, IBerichtenOverviewState> {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      sort: this.getCleanSortState()
    };
  }

  private mapConversatieToRow(conversatie: IConversatie): IRow {
    const bericht = conversatie.berichten[ conversatie.berichten.length - 1 ];
    return {
      referentie: conversatie.referentie,
      aantalOngelezenBerichten: conversatie.aantalOngelezenBerichten,
      titel: conversatie.titel,
      subTitel: conversatie.subTitel,
      titelType: conversatie.titelType,
      type: conversatie.type,
      actieStatus: conversatie.actieStatus,
      gesprekPartners: conversatie.ondernemingen
        .filter(onderneming => onderneming.referentie !== this.props.authentication.contactPersoon.onderneming.referentie)
        .map(onderneming => onderneming.naam),
      timestamp: bericht.timestamp,
      bericht: bericht.type === 'GEBRUIKERS_BERICHT' ? bericht.inhoud : intl.get(bericht.inhoud)
    };
  }

  componentDidMount() {
    this.refresh();
  }

  componentWillUnmount(): void {
    this.props.hideModal(CONVERSATIES_SEND_BERICHT);
    this.props.hideModal(BERICHT_OVERVIEW);
  }

  handleOpenModal = (conversatieReferentie: string) => {
    this.props.getConversatie(extractUid('conversatie', conversatieReferentie));
    this.props.showModal(CONVERSATIES_SEND_BERICHT, {
      cancel: () => {
        this.props.hideModal(CONVERSATIES_SEND_BERICHT);
        this.refresh();
      }
    });
  };

  private handleOpenHulpvraagModal = () => {
    this.props.getHulpvraagForOnderneming();
    if (!this.props.conversatie.referentie) {
      this.props.createHulpvraagForOnderneming();
    }

    this.props.showModal(BERICHT_OVERVIEW, {
      cancel: () => {
        this.props.hideModal(BERICHT_OVERVIEW);
        this.refresh();
      }
    });
  };

  private refresh() {
    this.props.getConversatiesForUser()
      .then(() => this.changeSort('descending', 'timestamp', 'timestamp'));
  }

  getCleanSortState = (): {
    actieStatus: SortDirection;
    gesprekPartners: SortDirection;
    timestamp: SortDirection;
  } => ({
    actieStatus: 'none',
    gesprekPartners: 'none',
    timestamp: 'none'
  });

  private changeSort = (direction, column, datatype?) => {
    this.setState((prevState, props) => {
      const newSortState = this.getCleanSortState();
      newSortState[ column ] = direction;

      const newData = props.queryResult.conversaties
        .map(conversatie => this.mapConversatieToRow(conversatie))
        .sort((a, b) => OverviewBerichten.sort(a[ column ], b[ column ], datatype));

      return {
        data: direction === 'ascending' ? newData : direction === 'descending' ? newData.reverse() : props.queryResult.conversaties
          .map(conversatie => this.mapConversatieToRow(conversatie)),
        sort: newSortState
      };
    });
  };

  private static sort(a, b, datatype?) {
    if (!a) {
      return -1;
    }

    if (!b) {
      return +1;
    }
    if (datatype === 'timestamp') {
      return new Date(a).getTime() - new Date(b).getTime();
    }
    if (datatype === 'array') {
      return OverviewBerichten.sort(a[ 0 ], b[ 0 ]);
    }

    return a.localeCompare(b);
  }

  render(): React.ReactNode {
    const columnDefinition: IColumns<IRow>[] = [
      {
        cellObject: new PillCellGenerator('aantalOngelezenBerichten', 'pill--ongelezen-berichten'), header: {
          sortable: false,
          title: '',
          hidden: true,
          width: 3
        }
      },
      {
        cellObject: new TitelSubtitelCellGenerator(), header: {
          sortable: false,
          title: intl.get('bericht.overview.table.header.aanbod-vraag')
        }
      },
      {
        cellObject: new PartijenCellGenerator(), header: {
          sortable: true,
          sortDirection: this.state.sort.gesprekPartners,
          onSortChange: direction => {
            this.changeSort(direction, 'gesprekPartners', 'array');
          },
          title: intl.get('bericht.overview.table.header.gesprekspartner')
        }
      },
      ...(isSymbioseTeam() ?
        [
          {
            cellObject: new ActieStatusCellGenerator(), header: {
              sortable: true,
              sortDirection: this.state.sort.actieStatus,
              onSortChange: direction => {
                this.changeSort(direction, 'actieStatus');
              },
              title: intl.get('bericht.overview.table.header.status')
            }
          }
        ] :
        []
      ),
      {
        cellObject: new DateCellGenerator('timestamp'), header: {
          sortable: true,
          sortDirection: this.state.sort.timestamp,
          onSortChange: direction => {
            this.changeSort(direction, 'timestamp', 'timestamp');
          },
          title: intl.get('bericht.overview.table.header.datum')
        }
      },
      {
        cellObject: new BerichtInhoudCellGenerator(), header: {
          sortable: false,
          title: intl.get('bericht.overview.table.header.bericht')
        }
      }
    ];

    return (
      <>
        <div id="buttonSection" className="vl-u-spacer vl-grid">
          <h2 className="vl-h2 vl-col--6-12">{intl.get('bericht.overview.title')}</h2>
          <div className="vl-col--6-12">
            {!isSymbioseTeam() && <VraagHulpButton handleClick={this.handleOpenHulpvraagModal} />}
            {isSymbioseTeam() &&
            <Link to="/berichten/afgesloten-overzicht">
              <Button type="button" color="secondary" className="nieuw-button buttons-overview">
                {intl.get('bericht.overview.buttons.gesloten-conversaties')}
              </Button>
            </Link>
            }
          </div>
        </div>
        <Loading loading={this.props.queryResult.loading}>
          {this.props.queryResult.conversaties.length > 0 ?
            <DataTable data={this.state.data}
              columns={columnDefinition}
              rowIdAccessor="referentie"
              onRowClick={conversatie => this.handleOpenModal(conversatie)}/>
            :
            <div>
              <h3 className="vl-h3">{intl.get('bericht.overview.geen-berichten.titel')}</h3>
              <p className="vl-u-spacer--tiny">{intl.get('bericht.overview.geen-berichten.paragraaf-1')}</p>
            </div>
          }
        </Loading>
        <ConversatieSendBerichtModal/>
        <BerichtOverviewHulpvraagModal/>
      </>
    );
  }
}

const mapStateToProps = storeState => ({
  queryResult: storeState.bericht.berichtenList,
  conversatie: storeState.bericht.bericht.conversatie,
  authentication: storeState.authentication
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  getConversatiesForUser,
  getConversatie,
  getHulpvraagForOnderneming,
  createHulpvraagForOnderneming
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(OverviewBerichten));
