import React from 'react';
import intl from 'react-intl-universal';
import { Alert } from 'webuniversum-react';
import AuthenticatieBlock from './authenticatie-block';

export class RegistratieHint extends React.Component<any, any> {
  render(): React.ReactNode {
    return (
      <div className="vl-grid vl-u-spacer">
        <div className="vl-col--9-12 vl-col--1-1--xs">
          <Alert type="warning">
            {intl.getHTML('welkomstpagina.registratie.hint', { registratieUrl: '/#/welkomstpagina/registratie' })}
          </Alert>
        </div>
        <div className="vl-col--3-12">
          <AuthenticatieBlock />
        </div>
      </div>
    );
  }
}
