import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {createValidateFunction, formEdit, FormPropsFactoryBuilder, formState, formValidate, IFormActions, IFormState, initialFormState} from 'redux-form-library';
import {initialSendBerichtFormState, ISendBerichtForm, SEND_BERICHT_FORM, sendBericht} from 'src/reducers/bericht/send';
import {getStore} from 'src/redux-store';
import {Button, FormComponent, Modal, ModalButtons, ModalTitle, PlacementType, Textarea} from 'webuniversum-react';
import {createHulpvraagForBemiddeling, IConversatie} from 'src/reducers/bericht/conversatie';
import {updateBerichtenGelezen} from 'src/reducers/bericht/berichten-gelezen';
import {extractUid} from 'src/modules/referentie-uri';
import {getConversatiesForUser} from 'src/reducers/bericht/conversaties';
import {getAantalOngelezenBerichten} from 'src/reducers/bericht/ongelezen-berichten';
import 'src/modules/bericht/bericht-modal.css';
import {IContactPersoon} from 'src/reducers/authentication';
import {IModalContainerProps} from 'src/reducers/modal/modal';
import {BerichtModalContentBerichten} from 'src/modules/bericht/bericht-modal-content-berichten';
import BerichtModalContentAfsluiten from 'src/modules/bericht/bericht-modal-content-afsluiten';
import logo from 'src/modules/bericht/send-button.svg';
import {bindActionCreators} from 'redux';

export interface IBerichtModalProps extends IFormActions<ISendBerichtForm> {
  modalProps: IModalContainerProps;
  sendBericht?: Function;
  updateBerichtenGelezen?: Function;
  getConversatiesForUser?: Function;
  getAantalOngelezenBerichten?: Function;
  createHulpvraagForBemiddeling: Function;
  conversatie?: IConversatie;
  contactPersoon?: IContactPersoon;
  dispatch?: Function;
  loading: boolean;
  form: IFormState<ISendBerichtForm>;
}

export interface IBerichtModalState {
  afsluiten: boolean;
  modalExpanded: boolean;
}

export class BerichtModal extends React.Component<IBerichtModalProps, IBerichtModalState> {
  constructor(props) {
    super(props);
    this.state = {
      afsluiten: false,
      modalExpanded: false
    };
  }

  createValidationRules = () => ({
    bericht: 'string|required|max:5000'
  });

  generateErrorMessages = () => ({
    'required.bericht': intl.get('validation.form.field.required', {
      NAME: intl.get('bericht.send.form.content.fields.bericht.label')
    }),
    'max.bericht': intl.get('validation.form.field.text.max-length', {
      NAME: intl.get('bericht.send.form.content.fields.bericht.label'),
      MAX: '5000'
    })
  });

  createValidation = (errormessages, validationRules) => createValidateFunction(validationRules, errormessages);

  handleSubmit = formData => {
    this.props.sendBericht(extractUid('conversatie', this.props.conversatie.referentie), formData.bericht)
      .then(this.props.formState(initialFormState(initialSendBerichtFormState())))
      .catch(() => {
        this.props.modalProps.cancel();
      });
  };

  handleCancel = () => {
    this.props.formState(initialFormState(initialSendBerichtFormState()));
    if (this.props.conversatie.referentie !== ''
      && this.props.conversatie.referentie !== null
      && this.props.conversatie.referentie !== undefined) {
      this.props.updateBerichtenGelezen(extractUid('conversatie', this.props.conversatie.referentie)).then(() => {
        this.props.getConversatiesForUser();
        this.props.getAantalOngelezenBerichten();
      });
    }
    this.setState({ afsluiten: false });
    this.props.modalProps.cancel();
  };

  vergrootModal = () => {
    this.setState({ modalExpanded: !this.state.modalExpanded });
  };

  openHulpvraag = () => {
    if (this.props.conversatie.type === 'OVERLEG' ||
      (this.props.conversatie.type === 'BEMIDDELING_HULPVRAAG' && this.props.conversatie.actieStatus === 'GESLOTEN')) {
      this.props.createHulpvraagForBemiddeling(extractUid('conversatie', this.props.conversatie.referentie));
    }
  };

  toggleAfsluiten = () => this.setState({ afsluiten: !this.state.afsluiten });

  render(): React.ReactNode {
    const { loading, form, conversatie, contactPersoon } = this.props;
    const { visible } = this.props.modalProps;
    const formPropsFactory = new FormPropsFactoryBuilder()
      .setFieldsObject(form.fields)
      .setDispatch(this.props.dispatch)
      .setValidate(this.createValidation(this.generateErrorMessages(), this.createValidationRules()))
      .setSelector(formSelector)
      .setStore(getStore())
      .setFormName(SEND_BERICHT_FORM)
      .build();

    const modalActions = [ {
      action: () => this.vergrootModal(), icon: 'vi vi-expand',
      tooltipProps: {
        content: intl.get('bericht.send.header.tooltip.wijzig-grootte'),
        placement: 'bottom' as PlacementType
      }
    } ];

    if (conversatie.type === 'OVERLEG' ||
      (conversatie.type === 'BEMIDDELING_HULPVRAAG' && conversatie.actieStatus === 'GESLOTEN')) {
      modalActions.push({
        action: (): void => this.openHulpvraag(),
        icon: 'operator-logo',
        tooltipProps: {
          content: intl.get('bericht.send.header.tooltip.hulp-vragen'),
          placement: 'bottom' as PlacementType
        }
      });
    }

    const deelnemerConversatie: boolean = conversatie.ondernemingen.some(onderneming => onderneming.referentie === contactPersoon.onderneming.referentie);

    return (
      <Modal hidden={!visible}
        onClose={this.handleCancel}
        modalActions={modalActions}
        style={{ width: `${this.state.modalExpanded ? 90 : 60}rem`, right: `${this.state.modalExpanded ? -410 : -260}px` }}
        className="send-bericht-modal-dialog">
        <ul className="send-bericht-modal-dialog__title">
          {
            (conversatie && (conversatie.type === 'OVERLEG' || conversatie.type === 'BEMIDDELING_HULPVRAAG')) &&
            <ModalTitle>
              <li className="bold title">{conversatie.subTitel}</li>
              <li className="normal">{`${conversatie.titelType === 'AANBOD_ONDERWERP' ?
                intl.get('bericht.send.header.label.aanbod') :
                intl.get('bericht.send.header.label.vraag')}: ${conversatie.titel}`}
              </li>
              <li className="normal">{conversatie.ondernemingen &&
                Array.from(new Set(conversatie.ondernemingen
                  .map(onderneming => onderneming.naam)
                  .map(naam => naam !== null ? naam : intl.get('bericht.send.bericht.sender.anonymous.label'))
                  .map(naam => naam.toLowerCase())
                  .map(naam => naam.charAt(0).toUpperCase() + naam.slice(1)))).join(', ')}</li>
            </ModalTitle>
          }

          {
            (conversatie && conversatie.type === 'OPEN_HULPVRAAG') &&
            <ModalTitle>
              <li className="bold title">{intl.get('bericht.open-vraag.title')}</li>
              <li className="bold">{intl.get('bericht.open-vraag.label')}</li>
            </ModalTitle>
          }
          {
            (conversatie && conversatie.type === 'AANBOD_HULPVRAAG') &&
            <ModalTitle>
              <li className="bold title">{intl.get('bericht.open-vraag.title')}</li>
              <li className="bold">{intl.get('bericht.open-vraag.betreffend.label')}</li>
              <li className="normal">{`${intl.get('bericht.send.header.label.aanbod')}: ${conversatie.titel}`}</li>
            </ModalTitle>
          }
          {
            (conversatie && conversatie.type === 'VRAAG_HULPVRAAG') &&
            <ModalTitle>
              <li className="bold title">{intl.get('bericht.open-vraag.title')}</li>
              <li className="bold">{intl.get('bericht.open-vraag.betreffend.label')}</li>
              <li className="normal">{`${intl.get('bericht.send.header.label.vraag')}: ${conversatie.titel}`}</li>
            </ModalTitle>
          }
          {
            (conversatie && conversatie.type === 'RELEVANT_AANBOD_HULPVRAAG' || conversatie && conversatie.type === 'RELEVANTE_VRAAG_HULPVRAAG') &&
            <ModalTitle>
              <li className="bold title">{intl.get('bericht.open-vraag.title')}</li>
              <li className="bold">{conversatie.subTitel}</li>
              <li className="normal">{`${conversatie.titelType === 'AANBOD_ONDERWERP' ?
                intl.get('bericht.send.header.label.aanbod') :
                intl.get('bericht.send.header.label.vraag')}: ${conversatie.titel}`}
              </li>
            </ModalTitle>
          }
        </ul>

        {
          this.state.afsluiten ?
            <BerichtModalContentAfsluiten conversatie={conversatie}
              contactPersoon={contactPersoon}
              toggleAfsluiten={this.toggleAfsluiten}
              bewaren={this.handleCancel}/>
            :
            <BerichtModalContentBerichten conversatie={conversatie}
              contactPersoon={contactPersoon}
              toggleAfsluiten={this.toggleAfsluiten}/>
        }

        {deelnemerConversatie && <div className="send-bericht-modal-dialog__buttons">
          <ModalButtons>
            <form onSubmit={formPropsFactory.createSubmitHandler(this.handleSubmit)}>
              <FormComponent id="bericht"
                label={intl.get('bericht.send.form.content.label')}
                error={form.fields.bericht.errors} block>
                <Textarea {...formPropsFactory.createInputProps('bericht')}
                  className="send-message-text-area"
                  block
                  rows={5}
                  placeholder={intl.get('bericht.send.form.content.placeholder')}/>
              </FormComponent>
              <Button className="send-button" type="submit" loading={loading} disabled={!this.props.form.dirty || !this.props.form.valid}>
                <img src={logo.toString()} alt="send-button"/>
              </Button>
            </form>
          </ModalButtons>
        </div>}
      </Modal>
    );
  }
}

const formSelector = storeState => storeState.bericht.sendBerichtForm;

const mapAanbodOverviewHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.aanbodOverviewModal);
const mapGearchiveerdAanbodOverviewHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.gearchiveerdAanbodOverviewModal);
const mapVraagOverviewHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.vraagOverviewModal);
const mapGearchiveerdVraagOverviewHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.gearchiveerdVraagOverviewModal);
const mapRelevanteVragenOverviewHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.relevanteVragenOverviewModal);
const mapRelevanteAanbiedingenOverviewHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.relevanteAanbiedingenOverviewModal);
const mapRelevanteVraagHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.relevanteVraagHulpvraagModal);
const mapRelevantAanbodHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.relevantAanbodHulpvraagModal);
const mapAanbodDetailHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.aanbodDetailHulpvraagModal);
const mapGearchiveerdAanbodDetailHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.gearchiveerdAanbodDetailHulpvraagModal);
const mapAanbodBewerkenOndernemingHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.aanbodBewerkenOndernemingHulpvraagModal);
const mapAanbodCopyOndernemingHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.aanbodCopyOndernemingHulpvraagModal);
const mapAanbodMakenUitVraagOndernemingHulpvraagStateToProps = storeState =>
  mapStateToProps(storeState, storeState.aanbod.aanbodMakenUitVraagOndernemingHulpvraagModal);
const mapAanbodBewerkenAanbodHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.aanbodBewerkenAanbodHulpvraagModal);
const mapVraagDetailHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.vraagDetailHulpvraagModal);
const mapGearchiveerdeVraagDetailHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.gearchiveerdeVraagDetailHulpvraagModal);
const mapVraagBewerkenOndernemingHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.vraagBewerkenOndernemingHulpvraagModal);
const mapVraagCopyOndernmingHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.vraagCopyOndernemingHulpvraagModal);
const mapVraagMakenUitAanbodOndernemingHulpvraagStateToProps = storeState =>
  mapStateToProps(storeState, storeState.vraag.vraagMakenUitAanbodOndernemingHulpvraagModal);
const mapVraagBewerkenVraagHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.vraagBewerkenVraagHulpvraagModal);
const mapSymbioseOverviewHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.symbiose.symbioseOverviewModal);
const mapSymbioseDetailHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.symbiose.symbioseHulpvraagModal);
const mapBerichtOverviewHulpvraagStateToProps = storeState => mapStateToProps(storeState, storeState.bericht.berichtOverviewModal);
const mapStartpaginaStateHulpvraagToProps = storeState => mapStateToProps(storeState, storeState.startpagina.startpaginaModal);
const mapRelevantAanbodStateToProps = storeState => mapStateToProps(storeState, storeState.vraag.relevantAanbodModal);
const mapRelevanteVraagStateToProps = storeState => mapStateToProps(storeState, storeState.aanbod.relevanteVraagModal);
const mapConversatieStateToProps = storeState => mapStateToProps(storeState, storeState.bericht.conversatieBerichtModal);
const mapSymbioseStateToProps = storeState => mapStateToProps(storeState, storeState.symbiose.symbioseBerichtModal);

const mapStateToProps = (storeState, modalProps) => ({
  form: formSelector(storeState),
  modalProps,
  conversatie: storeState.bericht.bericht.conversatie,
  contactPersoon: storeState.authentication.contactPersoon,
  loading: storeState.bericht.send.loading
});

const mapDispatchToProps = dispatch => {
  const actions = {
    sendBericht,
    updateBerichtenGelezen,
    getConversatiesForUser,
    getAantalOngelezenBerichten,
    createHulpvraagForBemiddeling,
    formState: formState(SEND_BERICHT_FORM),
    formValidate: formValidate<ISendBerichtForm>(SEND_BERICHT_FORM),
    formEdit: formEdit(SEND_BERICHT_FORM)
  };
  return { ...bindActionCreators(actions, dispatch), dispatch };
};

export const AanbodOverviewHulpvraagModal = connect(mapAanbodOverviewHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const GearchiveerdAanbodHulpvraagModal = connect(mapGearchiveerdAanbodOverviewHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const VraagOverviewHulpvraagModal = connect(mapVraagOverviewHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const GearchiveerdVraagHulpvraagModal = connect(mapGearchiveerdVraagOverviewHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const AanbodDetailHulpvraagModal = connect(mapAanbodDetailHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const GearchiveerdAanbodDetailHulpvraagModal = connect(mapGearchiveerdAanbodDetailHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const VraagDetailHulpvraagModal = connect(mapVraagDetailHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const GearchiveerdVraagDetailHulpvraagModal = connect(mapGearchiveerdeVraagDetailHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const AanbodBewerkenOndernemingHulpvraagModal = connect(mapAanbodBewerkenOndernemingHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const AanbodCopyOndernemingHulpvraagModal = connect(mapAanbodCopyOndernemingHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const AanbodMakenUitVraagOndernemingHulpvraagModal = connect(mapAanbodMakenUitVraagOndernemingHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const AanbodBewerkenAanbodHulpvraagModal = connect(mapAanbodBewerkenAanbodHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const VraagBewerkenOndernemingHulpvraagModal = connect(mapVraagBewerkenOndernemingHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const VraagCopyOndernemingHulpvraagModal = connect(mapVraagCopyOndernmingHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const VraagMakenUitAanbodOndernemingHulpvraagModal = connect(mapVraagMakenUitAanbodOndernemingHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const VraagBewerkenVraagHulpvraagModal = connect(mapVraagBewerkenVraagHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const RelevanteVragenOverviewHulpvraagModal = connect(mapRelevanteVragenOverviewHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const RelevanteVraagHulpvraagModal = connect(mapRelevanteVraagHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const RelevanteAanbiedingenOverviewHulpvraagModal = connect(mapRelevanteAanbiedingenOverviewHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const RelevantAanbodHulpvraagModal = connect(mapRelevantAanbodHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const SymbioseOverviewHulpvraagModal = connect(mapSymbioseOverviewHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const SymbioseDetailHulpvraagModal = connect(mapSymbioseDetailHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const BerichtOverviewHulpvraagModal = connect(mapBerichtOverviewHulpvraagStateToProps, mapDispatchToProps)(BerichtModal);
export const StartpaginaHulpvraagModal = connect(mapStartpaginaStateHulpvraagToProps, mapDispatchToProps)(BerichtModal);
export const RelevantAanbodSendBerichtModal = connect(mapRelevantAanbodStateToProps, mapDispatchToProps)(BerichtModal);
export const RelevanteVraagSendBerichtModal = connect(mapRelevanteVraagStateToProps, mapDispatchToProps)(BerichtModal);
export const ConversatieSendBerichtModal = connect(mapConversatieStateToProps, mapDispatchToProps)(BerichtModal);
export const SymbioseSendBerichtModal = connect(mapSymbioseStateToProps, mapDispatchToProps)(BerichtModal);
