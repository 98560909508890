import React from 'react';
import intl from 'react-intl-universal';
import './text-tile.css';

export interface ISysteemTextTileProps {
  content: string;
}

export class SysteemTextTile extends React.Component<ISysteemTextTileProps> {
  render(): React.ReactNode {
    const { content } = this.props;

    return (
      <div className={'systeem-text-tile'}>
        <pre>{intl.get(content)}</pre>
      </div>
    );
  }
}
