import React from 'react';
import intl from 'react-intl-universal';
import { Alert, Modal, ModalContent, ModalTitle, SearchModule } from 'webuniversum-react';
import { hideModal, IModalContainerProps } from 'src/reducers/modal/modal';
import { connect } from 'react-redux';
import { ICategorieenMap, updateHoofdCategorie, updateStandaardCategorie } from 'src/reducers/categorie/list';
import { categorieTreeToList, IFlatCategorie } from './categorie-tree-to-list';
import CategorieList from './categorie-list';
import { CATEGORIE_MODAL } from 'src/reducers/categorie';
import { addParents } from './format-gefilterde-categorieen';

export interface ICategorieModalProps {
  modalProps: IModalContainerProps;
  categorieen: ICategorieenMap;
  hideModal: Function;
  formEditPropertyValue: Function;
  updateHoofdCategorie: Function;
  updateStandaardCategorie: Function;
}

export interface ICategorieModalState {
  searchInput: string;
  filterString: string;
  volledigeCategorieLijst: IFlatCategorie[];
}

export class CategorieModal extends React.Component<ICategorieModalProps, ICategorieModalState> {

  constructor(props) {
    super(props);
    this.state = {
      searchInput: '',
      filterString: '',
      volledigeCategorieLijst: categorieTreeToList(this.props.categorieen)
    };
  }

  render(): React.ReactNode {
    const { visible, title } = this.props.modalProps;
    const gefilterdeCategorieen = (this.state.filterString === '') ?
      this.state.volledigeCategorieLijst :
      addParents(this.state.volledigeCategorieLijst.filter(this.filterCategorie), categorieTreeToList(this.props.categorieen));

    return (
      <Modal className="categorie-modal" hidden={!visible} overlayHidden={!visible}
        style={{ width: '150rem' }}
        onClose={this.cancel}>
        <div>
          <ModalTitle>{title}</ModalTitle>
          <p>{intl.get('materiaal.edit.form.classificatie.modal.tekst')}</p>
          <div className="vl-u-spacer--tiny"/>
        </div>
        <SearchModule
          buttonLabel={intl.get('materiaal.edit.form.classificatie.modal.zoek-button')}
          placeholder={intl.get('materiaal.edit.form.classificatie.zoek')}
          value={this.state.searchInput}
          onChange={this.handleChange}
          layout="block"
          onSubmit={this.handleSubmit}
        />
        <div className="vl-u-spacer--small"/>
        <div id="categorie-modal-content" className="categorie-modal-content">
          <ModalContent>
            {(gefilterdeCategorieen.length > 0) ?
              <CategorieList className="categorie-list" categorieen={gefilterdeCategorieen} elementsPerLoad={100}
                handleClick={this.handleSelectCategorie}/> :
              <Alert>
                <div className="alert-zoeken">
                  {intl.get('materiaal.edit.form.classificatie.modal.geen-resultaten')}
                </div>
              </Alert>
            }
          </ModalContent>
        </div>
      </Modal>
    );
  }

  handleChange = event => {
    this.setState({ searchInput: event.target.value });
  };

  handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.setState({ filterString: this.state.searchInput });
  };

  private filterCategorie = (categorie: IFlatCategorie) => {
    const searchString = this.state.filterString.toLocaleLowerCase();
    const formattedCode = categorie.code.length === 6 ? categorie.code.slice(0, 4) + ' ' + categorie.code.slice(-2) : categorie.code;
    return categorie.beschijving.toLocaleLowerCase().includes(searchString) || formattedCode.includes(searchString) ;
  };

  private handleSelectCategorie = (categorie: IFlatCategorie) => {
    this.props.hideModal(CATEGORIE_MODAL);
    this.setState({ searchInput: '', filterString: '' });
    this.props.formEditPropertyValue('categorie.hoofdCategorie', categorie.hoofdCategorieReferentie);
    this.props.updateHoofdCategorie(categorie.hoofdCategorieReferentie);
    this.props.formEditPropertyValue('categorie.standaardCategorie', '');
    this.props.formEditPropertyValue('categorie.subCategorie', '');
    if (categorie.niveau > 0) {
      this.props.formEditPropertyValue('categorie.standaardCategorie', categorie.standaardCategorieReferentie);
      this.props.updateStandaardCategorie(categorie.standaardCategorieReferentie);
    }
    if (categorie.niveau > 1) {
      this.props.formEditPropertyValue('categorie.subCategorie', categorie.subCategorieReferentie);
    }
  };

  private cancel = () => {
    this.setState({ searchInput: '', filterString: '' });
    this.props.modalProps.cancel();
  };
}

const mapStateToProps = storeState => ({
  modalProps: storeState.categorie.categorieModal,
  categorieen: storeState.categorie.categorieenList
});

const mapDispatchToProps = {
  hideModal,
  updateHoofdCategorie,
  updateStandaardCategorie
};

export default connect(mapStateToProps, mapDispatchToProps)(CategorieModal);
