import {
  IMateriaalVraagForm,
  initialMateriaalVraagFormState
} from 'src/reducers/vraag/materiaal/materiaal-vraag-form';
import { RouteComponentProps } from 'react-router';
import {
  formEdit,
  formEditPropertyValue,
  formState,
  formValidate,
  IFormActions,
  IFormState,
  initialFormState
} from 'redux-form-library';
import React from 'react';
import intl from 'react-intl-universal';
import { isSymbioseTeam } from '../../../has-permission';
import { VraagHulpButton } from '../../bericht/vraag-hulp-button';
import { MateriaalVraagForm } from './materiaal-vraag-form';
import {
  CREATE_MATERIAAL_VRAAG_FROM_AANBOD_FORM,
  VRAAG_MAKEN_UIT_AANBOD_ONDERNEMING_HULPVRAAG
} from 'src/reducers/vraag';
import { VraagMakenUitAanbodOndernemingHulpvraagModal } from '../../bericht/bericht-modal';
import { withErrorClear } from '../../error/error-clearer';
import { connect } from 'react-redux';
import {
  hideModal,
  showModal
} from 'src/reducers/modal/modal';
import {
  createHulpvraagForOnderneming,
  getHulpvraagForOnderneming,
  IConversatie
} from 'src/reducers/bericht/conversatie';
import {
  createMateriaalVraag,
  IGetMateriaalVraagState,
  uploadMateriaalVraagBestanden
} from 'src/reducers/vraag/materiaal/get';
import {
  ICategorieenMap,
  resetCategorieen,
  updateHoofdCategorie,
  updateStandaardCategorie
} from 'src/reducers/categorie/list';
import {
  changeHeader,
  HeaderType
} from 'src/reducers/app/header/change';
import {
  bindActionCreators,
  Dispatch
} from 'redux';
import { Bestandstype } from '../../bestand/bestandstype';
import { extractUid } from '../../referentie-uri';
import {
  materiaalVraagFormDataToRequest,
  materiaalVraagFromMateriaalAanbodResponse
} from './materiaal-vraag-factory';
import { getMateriaalAanbod } from 'src/reducers/aanbod/materiaal/get';

export interface ICreateVraagFromAanbodProps extends IFormActions<IMateriaalVraagForm>, RouteComponentProps<{ aanbodUid: string }> {
  materiaalVraag: IGetMateriaalVraagState;
  showModal: Function;
  hideModal: Function;
  conversatie: IConversatie;
  createHulpvraagForOnderneming: Function;
  getHulpvraagForOnderneming: Function;
  updateHoofdCategorie: Function;
  updateStandaardCategorie: Function;
  categorieQueryResult: ICategorieenMap;
  form: IFormState<IMateriaalVraagForm>;
  dispatch: Dispatch;
  formEditPropertyValue: Function;
  resetCategorieen: Function;
  changeHeader: Function;
  getMateriaalAanbod: Function;
  createMateriaalVraag: Function;
}

export class CreateVraagFromAanbod extends React.Component<ICreateVraagFromAanbodProps> {

  componentDidMount(): void {
    this.changeHeader('/vraag/overzicht', 'materiaal.edit.header.return.vraag.update.mijn');
    this.props.getHulpvraagForOnderneming();
    this.props.getMateriaalAanbod(this.props.match.params.aanbodUid)
      .then(response => this.props.formState(materiaalVraagFromMateriaalAanbodResponse(response)));
  }

  componentWillUnmount(): void {
    this.props.formState(initialFormState(initialMateriaalVraagFormState()));
    this.props.changeHeader(HeaderType.TAB_NAVIGATION);
  }

  render(): React.ReactNode {
    return (
      <>
        <div id="formSection">
          <div className="vl-grid">
            <h1
              className="vl-h1 vl-col--6-12 vl-u-spacer--tiny">{intl.get('materiaal.edit.form.title.create.vraag')}</h1>
            {
              !isSymbioseTeam() &&
              <div className="vl-col--6-12">
                <VraagHulpButton handleClick={this.handleOpenModal} />
              </div>
            }
          </div>
          <fieldset>
            <MateriaalVraagForm
              categorieQueryResult={this.props.categorieQueryResult}
              materiaalVraag={this.props.materiaalVraag}
              form={this.props.form}
              dispatch={this.props.dispatch}
              handleSave={this.handleSave}
              handleUpload={this.handleUpload}
              handleDownload={this.handleFotoDownload}
              updateHoofdCategorie={this.props.updateHoofdCategorie}
              updateStandaardCategorie={this.props.updateStandaardCategorie}
              resetCategorieen={this.props.resetCategorieen}
              formEditPropertyValue={this.props.formEditPropertyValue}
              handleCancel={this.handleCancel}
              formSelector={formSelector}
              formName={CREATE_MATERIAAL_VRAAG_FROM_AANBOD_FORM}
              formEdit={this.props.formEdit}
              formState={this.props.formState}
              formValidate={this.props.formValidate}
            />
          </fieldset>
        </div>
        <VraagMakenUitAanbodOndernemingHulpvraagModal />
      </>
    );
  }

  private handleOpenModal = () => {
    if (!this.props.conversatie.referentie) {
      createHulpvraagForOnderneming();
    }
    this.props.showModal(VRAAG_MAKEN_UIT_AANBOD_ONDERNEMING_HULPVRAAG, {
      cancel: () => this.props.hideModal(VRAAG_MAKEN_UIT_AANBOD_ONDERNEMING_HULPVRAAG)
    });
  };

  private handleSave = formData => {
    this.props.createMateriaalVraag(materiaalVraagFormDataToRequest(formData))
      .then(response => {
        const vraagUid = extractUid('vraag', response.value.data.vraagReferentie);
        this.resetFormAndRedirect(`/vraag/${vraagUid}/aanbiedingen`);
      });
  };

  private handleUpload = (files: File[], bestandstype: Bestandstype) => uploadMateriaalVraagBestanden(files, bestandstype);

  private handleFotoDownload = foto => Promise.resolve(foto.url);

  private handleCancel = () => this.resetFormAndRedirect(`/aanbod/${this.props.match.params.aanbodUid}`);

  private resetFormAndRedirect = (redirectUrl: string) => {
    this.props.formState(initialFormState(initialMateriaalVraagFormState()));
    this.props.resetCategorieen();
    this.props.history.push(redirectUrl);
  };

  private changeHeader(backUrl: string, backTranslation) {
    changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      backTranslation,
      '',
      [
        {
          title: 'materiaal.edit.header.title.vraag.create',
          isActive: true
        }
      ],
      () => {
        this.resetFormAndRedirect(backUrl);
      });
  }
}

const formSelector = storeState => storeState.vraag.createMateriaalVraagFromAanbodForm;

const mapStateToProps = storeState => ({
  form: formSelector(storeState),
  materiaalVraag: storeState.vraag.details,
  conversatie: storeState.bericht.bericht.conversatie,
  categorieQueryResult: storeState.categorie.categorieenList
});

const mapDispatchToProps = dispatch => {
  const actions = {
    showModal,
    hideModal,
    createHulpvraagForOnderneming,
    getHulpvraagForOnderneming,
    updateHoofdCategorie,
    updateStandaardCategorie,
    resetCategorieen,
    changeHeader,
    getMateriaalAanbod,
    createMateriaalVraag,
    formState: formState(CREATE_MATERIAAL_VRAAG_FROM_AANBOD_FORM),
    formValidate: formValidate<IMateriaalVraagForm>(CREATE_MATERIAAL_VRAAG_FROM_AANBOD_FORM),
    formEdit: formEdit(CREATE_MATERIAAL_VRAAG_FROM_AANBOD_FORM),
    formEditPropertyValue: formEditPropertyValue(CREATE_MATERIAAL_VRAAG_FROM_AANBOD_FORM)
  };
  return { ...bindActionCreators(actions, dispatch), dispatch };
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(CreateVraagFromAanbod));
