import React from 'react';
import intl from 'react-intl-universal';
import {ISymbiose} from 'src/reducers/symbiose/create';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {connect} from 'react-redux';
import {DOCUMENTEER_NIET_REALISATIE} from 'src/reducers/symbiose';
import {extractUid} from 'src/modules/referentie-uri';
import {formFieldsToObject, formState, initialFormState} from 'redux-form-library';
import {DOCUMENTEER_NIET_REALISATIE_FORM, initialDocumenteerNietRealisatieFormState, symbioseDocumenteerNietRealisatie} from 'src/reducers/symbiose/change-status';
import {Badge, Button, ButtonGroup, Label} from 'webuniversum-react';

export interface IActieGevraagdDocumenteerNietRealisatieProps {
  symbiose: ISymbiose;
  showModal: Function;
  hideModal: Function;
  symbioseDocumenteerNietRealisatie: Function;
  nietRealisatie: any;
  nietRealisatieFormState: any;
}

export class ActieGevraagdDocumenteerNietRealisatie extends React.Component<IActieGevraagdDocumenteerNietRealisatieProps> {
  render(): React.ReactNode {
    return (
      <div className="detail-symbiose-actie-gevraagd">
        <div className="detail-symbiose-actie-gevraagd-wraper">
          <div className="detail-symbiose-actie-gevraagd-element">
            <Badge size="s" type="initials" className="detail-symbiose-actie-gevraagd-badge">
              <span>
                <p className="detail-symbiose-actie-gevraagd-badge-content">!</p>
              </span>
            </Badge>
          </div>
          <div>
            <Label className="detail-symbiose-actie-gevraagd-label">{intl.get('symbiose.actie-gevraagd.titel')}</Label>
          </div>
        </div>
        <div className="vl-u-spacer--small ">
          <p>{intl.get('symbiose.actie-gevraagd.tekst-documenteer-niet-realisatie')}</p>
        </div>
        <ButtonGroup>
          <Button className="detail-symbiose-button" onClick={this.documenteerNietRealisatieOpenModal}>{intl.get('symbiose.detail.documenteer-niet-realisatie')}</Button>
        </ButtonGroup>
      </div>
    );
  }

  private documenteerNietRealisatieOpenModal = () => {
    this.props.showModal(DOCUMENTEER_NIET_REALISATIE, {
      submit: () => {
        this.props.symbioseDocumenteerNietRealisatie(extractUid('symbiose',
          this.props.symbiose.symbioseReferentie), formFieldsToObject(this.props.nietRealisatie.fields));
        this.props.hideModal(DOCUMENTEER_NIET_REALISATIE);
        this.props.nietRealisatieFormState(initialFormState(initialDocumenteerNietRealisatieFormState()));
      },
      cancel: () => this.props.hideModal(DOCUMENTEER_NIET_REALISATIE),
      submitText: intl.get('symbiose.niet-gerealiseerd-modal.submit-text'),
      cancelText: intl.get('detail.bericht.send.cancel.label'),
      title: intl.get('symbiose.niet-gerealiseerd-modal.titel')
    });
  };
}

const mapStateToProps = storeState => ({
  nietRealisatie: storeState.symbiose.documenteerNietRealisatieForm
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  symbioseDocumenteerNietRealisatie,
  nietRealisatieFormState: formState(DOCUMENTEER_NIET_REALISATIE_FORM)
};

export default connect(mapStateToProps, mapDispatchToProps)(ActieGevraagdDocumenteerNietRealisatie);
