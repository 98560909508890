import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';

export const ACTION_TYPES = {
  FETCH_MIJN_SYMBIOSES: 'symbiose/FETCH_MIJN_SYMBIOSES'
};

interface ISymbiose {
  symbioseReferentie: string;
  vraag: {
    naam: string;
    ondernemingsNaam: string;
    ondernemingsReferentie: string;
    referentie: string;
  };
  aanbod: {
    naam: string;
    ondernemingsNaam: string;
    ondernemingsReferentie: string;
    referentie: string;
  };
  initiatiefnemerOndernemingReferentie: string;
  genodigdeOndernemingReferentie: string;
  status: string;
  datumStatus: string;
}

export interface IMijnSymbioseState {
  loading: boolean;
  errorMessage: string;
  symbioses: ISymbiose[];
}

export const initialState: IMijnSymbioseState = {
  loading: false,
  errorMessage: null,
  symbioses: []
};

export const mijnSymbiosesReducer = (state = initialState, action: AnyAction): IMijnSymbioseState => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_MIJN_SYMBIOSES):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_MIJN_SYMBIOSES):
    return {
      ...state,
      errorMessage: action.payload,
      loading: false
    };
  case SUCCESS(ACTION_TYPES.FETCH_MIJN_SYMBIOSES):
    return {
      ...state,
      errorMessage: null,
      loading: false,
      symbioses: action.payload.data
    };
  default:
    return state;
  }
};

export const getMijnSymbioses: ActionCreator<any> = () => ({
  type: ACTION_TYPES.FETCH_MIJN_SYMBIOSES,
  payload: axios.get('/ui/symbioses/mijn-symbioses')
});
