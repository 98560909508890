import React, { PropsWithChildren } from 'react';
import {
  Button,
  ButtonGroup
} from 'webuniversum-react';

export type SearchFilterLayout = 'none' | 'alt';

export interface IExtraLink extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  linkText: string;
}

export interface ISearchFilterProps extends PropsWithChildren<any> {
  intro?: string;
  layout?: SearchFilterLayout;
  extraLink?: IExtraLink;
  searchButtonText: string;
  resetButtonText: string;
  onSubmit: (event) => void;
  onReset: (event) => void;
  loading?: boolean;
}

export class SearchFilterMetResetButton extends React.Component<ISearchFilterProps> {

  public static defaultProps: Partial<ISearchFilterProps> = {
    layout: 'none',
    loading: false
  };

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  render(): React.ReactNode {
    const { children, intro, layout, searchButtonText, resetButtonText } = this.props;

    let className = 'vl-filter';
    if (layout === 'alt') {
      className += ' vl-filter--alt';
    }
    return (
      <div className={className}>
        {intro && <p className="vl-filter__intro">{intro}</p>}
        <form className="vl-filter__form" onSubmit={this.handleSubmit}>
          {children}
          <div className="vl-filter__field">
            <ButtonGroup>
              <Button className="button-search-component" type="submit" layout="wide" loading={this.props.loading}>{searchButtonText}</Button>
              <Button className="button-search-component" type="button" layout="wide" color="secondary" onClick={this.handleReset}>{resetButtonText}</Button>
            </ButtonGroup>
          </div>
        </form>
        {this.generateExtraLink()}
      </div>
    );
  }

  private handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSubmit(event);
  }

  private handleReset(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onReset(event);
  }

  private generateExtraLink() {
    if (this.props.extraLink) {
      const { linkText, ...linkHTMLProps } = this.props.extraLink;
      return (
        <div className="vl-filter__footer">
          <ul className="vl-link-list--small">
            <li>
              <a className="vl-link--icon--caret vl-link--block" {...linkHTMLProps}>{linkText}</a>
            </li>
          </ul>
        </div>
      );
    } else {
      return;
    }
  }
}
