import { ActionCreator, AnyAction } from 'redux';

export const ACTION_TYPES = {
  SET_ERROR: 'error/SET_ERROR',
  CLEAR_ERROR: 'error/CLEAR_ERROR'
};

export interface IError {
  uuid: string;
  code: string;
  title: string;
  subtitle: string;
  message: string;
}

export const initialErrorState = (): IError => ({
  uuid: null,
  code: null,
  title: null,
  subtitle: null,
  message: null
});

export const errorReducer = (state = initialErrorState(), action: AnyAction): IError => {
  switch (action.type) {
  case ACTION_TYPES.SET_ERROR:
    return action.payload;
  case ACTION_TYPES.CLEAR_ERROR:
    return initialErrorState();
  default:
    return state;
  }
};

export const setError: ActionCreator<any> = (newError: IError) => ({
  type: ACTION_TYPES.SET_ERROR,
  payload: newError
});

export const clearError: ActionCreator<any> = () => ({
  type: ACTION_TYPES.CLEAR_ERROR
});
