import React from 'react';
import intl from 'react-intl-universal';
import { FormFields, SubFormPropsFactory } from 'redux-form-library';
import { FormComponent, InputField, Select } from 'webuniversum-react';
import { enumOptions } from 'src/modules/form-components/translatable-select-options';

export enum Land {
  BELGIE = 'BELGIE',
  NEDERLAND = 'NEDERLAND',
  FRANKRIJK = 'FRANKRIJK',
  DUITSLAND = 'DUITSLAND',
  LUXEMBURG = 'LUXEMBURG'
}

export interface IAdresForm {
  straatnaam: string;
  huisnummer: string;
  adresLijn2: string;
  postcode: string;
  gemeente: string;
  land: Land | '';
}

export const initialAdresFormState = (): IAdresForm => ({
  straatnaam: '',
  huisnummer: '',
  adresLijn2: '',
  postcode: '',
  gemeente: '',
  land: Land.BELGIE
});

export interface IAdresFormProps {
  fields: FormFields<IAdresForm>;
  formPropsFactory: SubFormPropsFactory<IAdresForm>;
}

export class AdresForm extends React.Component<IAdresFormProps> {
  generateTooltipMessages = () => ({
    'required.adres.straatnaam': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.straatnaam.label')
    }),
    'required.adres.huisnummer': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.huisnummer.label')
    }),
    'required.adres.postcode': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.postcode.label')
    }),
    'required.adres.gemeente': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.gemeente.label')
    }),
    'required.adres.land': intl.get('validation.form.field.required', {
      NAME: intl.get('materiaal.edit.form.adres.fields.land.label')
    })
  });

  render(): React.ReactNode {
    const { fields, formPropsFactory } = this.props;
    const tooltipMessages = this.generateTooltipMessages();

    return (
      <>
        <div className="vl-grid">
          <div className="vl-col--6-12">
            <FormComponent id="straatnaam"
              label={intl.get('materiaal.edit.form.adres.fields.straatnaam.label')}
              required={tooltipMessages[ 'required.adres.straatnaam' ]}
              error={fields.straatnaam.errors} block>
              <InputField {...formPropsFactory.createInputProps('straatnaam')} layout="block"/>
            </FormComponent>
          </div>
          <div className="vl-col--6-12">
            <FormComponent id="huisnummer"
              label={intl.get('materiaal.edit.form.adres.fields.huisnummer.label')}
              required={tooltipMessages[ 'required.adres.huisnummer' ]}
              error={fields.huisnummer.errors} block>
              <InputField {...formPropsFactory.createInputProps('huisnummer')} layout="block"/>
            </FormComponent>
          </div>
        </div>
        <div>
          <FormComponent id="adresLijn2"
            label={intl.get('materiaal.edit.form.adres.fields.adreslijn2.label')}
            error={fields.adresLijn2.errors} block>
            <InputField {...formPropsFactory.createInputProps('adresLijn2')} layout="block"/>
          </FormComponent>
        </div>
        <div>
          <FormComponent id="postcode"
            label={intl.get('materiaal.edit.form.adres.fields.postcode.label')}
            required={tooltipMessages[ 'required.adres.postcode' ]}
            error={fields.postcode.errors} block>
            <InputField {...formPropsFactory.createInputProps('postcode')} layout="block"/>
          </FormComponent>
        </div>
        <div>
          <FormComponent id="gemeente"
            label={intl.get('materiaal.edit.form.adres.fields.gemeente.label')}
            required={tooltipMessages[ 'required.adres.gemeente' ]}
            error={fields.gemeente.errors} block>
            <InputField {...formPropsFactory.createInputProps('gemeente')} layout="block"/>
          </FormComponent>
        </div>
        <div>
          <FormComponent id="land"
            label={intl.get('materiaal.edit.form.adres.fields.land.label')}
            required={tooltipMessages[ 'required.adres.land' ]}
            error={fields.land.errors} block>
            <Select block {...formPropsFactory.createSelectProps('land')}>
              {enumOptions(Land, 'materiaal.edit.form.adres.fields.land.values')
                .options()}
            </Select>
          </FormComponent>
        </div>
      </>
    );
  }
}
