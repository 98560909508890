import {isSymbioseTeam} from 'src/has-permission';
import {downloadAanbodBestand, getMateriaalAanbod, IGetMateriaalAanbodState} from 'src/reducers/aanbod/materiaal/get';
import {changeHeader, HeaderType} from 'src/reducers/app/header/change';
import {IApplication} from 'src/reducers/application';
import {createConversatie, createHulpvraagForSymbiose, getHulpvraagForSymbiose, getOverleg, IConversatie} from 'src/reducers/bericht/conversatie';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {IMeerwaarde, INietRealisatieReden, ISymbioseState} from 'src/reducers/symbiose/create';
import {getSymbiose} from 'src/reducers/symbiose/get';
import {downloadVraagBestand, getMateriaalVraag, IGetMateriaalVraagState} from 'src/reducers/vraag/materiaal/get';
import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {ButtonGroup, Loading, Tab, TabNavigation} from 'webuniversum-react';
import {SYMBIOSE_HULPVRAAG, SYMBIOSE_SEND_BERICHT} from 'src/reducers/symbiose';
import {SymbioseDetailHulpvraagModal, SymbioseSendBerichtModal} from 'src/modules/bericht/bericht-modal';
import {extractUid} from 'src/modules/referentie-uri';
import {DetailSymbioseHeader} from 'src/modules/symbiose/detail-symbiose-header';
import {DetailSymbioseMeerwaarde} from 'src/modules/symbiose/detail-symbiose-meerwaarde';
import {DetailSymbioseNietRealisatie} from 'src/modules/symbiose/detail-symbiose-niet-realisatie-reden';
import {DocumenteerMeerwaardeModal} from 'src/modules/symbiose/documenteer-meerwaarde-modal';
import {DocumenteerNietRealisatieModal} from 'src/modules/symbiose/niet-realisatie-modal';
import {StatusView} from 'src/modules/symbiose/status-components/status-view';
import {DetailMateriaalAanbod} from 'src/modules/aanbod/materiaal/detail-materiaal-aanbod';
import {DetailMateriaalVraag} from 'src/modules/vraag/materiaal/detail-materiaal-vraag';
import {AxiosPromise} from 'axios';
import {VraagHulpButton} from 'src/modules/bericht/vraag-hulp-button';
import {ToonConversatieButton} from 'src/modules/bericht/toon-conversatie-button';
import {withErrorClear} from 'src/modules/error/error-clearer';
import {IBestand} from 'src/reducers/bestand';

export interface IDetailSymbioseProps extends RouteComponentProps<{ symbioseUid: string }> {
  symbioseQueryResult: ISymbioseState;
  vraagQueryResult: IGetMateriaalVraagState;
  aanbodQueryResult: IGetMateriaalAanbodState;
  conversatie: IConversatie;
  meerwaarde: any;
  nietRealisatie: any;

  currentOndernemingReferentie;

  getSymbiose: Function;
  getMateriaalVraag: Function;
  getMateriaalAanbod: Function;
  getConversatieForAanbodAndVraag: Function;
  getHulpvraagForSymbiose: Function;
  createConversatie: Function;
  createHulpvraagForSymbiose: Function;
  changeHeader: Function;
  showModal: Function;
  hideModal: Function;

  isAuthenticated: boolean;
  applicationSettings: IApplication;
}

export class DetailSymbiose extends React.Component<IDetailSymbioseProps> {
  componentDidMount(): void {
    this.props.changeHeader(
      HeaderType.BACK_TAB_NAVIGATION,
      'symbiose.detail.header.return',
      '',
      [
        {
          title: 'symbiose.detail.header.title',
          isActive: true
        }
      ],
      () => {
        this.props.history.push('./overzicht');
      });

    this.loadState();
  }

  componentWillUnmount() {
    this.props.changeHeader(
      HeaderType.TAB_NAVIGATION
    );
    this.props.hideModal(SYMBIOSE_SEND_BERICHT);
    this.props.hideModal(SYMBIOSE_HULPVRAAG);
  }

  componentDidUpdate(prevProps: Readonly<IDetailSymbioseProps>, prevState: Readonly<{}>, prevContext: any): void {
    if (this.props.currentOndernemingReferentie !== prevProps.currentOndernemingReferentie) {
      this.loadState();
    }
  }

  render(): React.ReactNode {
    const { symbioseQueryResult, vraagQueryResult, aanbodQueryResult, conversatie } = this.props;
    const meerwaarde_1 = DetailSymbiose.getMeerwaarde(symbioseQueryResult.symbiose.meerwaardes[ 0 ], this.props.currentOndernemingReferentie);
    const meerwaarde_2 = DetailSymbiose.getMeerwaarde(symbioseQueryResult.symbiose.meerwaardes[ 1 ], this.props.currentOndernemingReferentie);
    const niet_realisatie_reden_1 = DetailSymbiose.getNietRealisatieReden(symbioseQueryResult.symbiose.nietRealisatieRedenen[ 0 ], this.props.currentOndernemingReferentie);
    const niet_realisatie_reden_2 = DetailSymbiose.getNietRealisatieReden(symbioseQueryResult.symbiose.nietRealisatieRedenen[ 1 ], this.props.currentOndernemingReferentie);

    return (
      <>
        {this.props.isAuthenticated &&
        <div className="vl-page">
          <Loading loading={symbioseQueryResult.loading}>
            <div className="vl-grid">
              <div className="vl-col--8-12">
                <div className="vl-grid">
                  <div className="vl-col--12-12">
                    <header className="vl-u-spacer--small">
                      <div>
                        <h1 className="vl-h1 vl-u-spacer--tiny">
                          {intl.get('symbiose.detail.titel')}
                        </h1>
                      </div>
                    </header>

                    <Loading loading={vraagQueryResult.loading}>
                      <DetailSymbioseHeader aanbod={aanbodQueryResult.aanbod}
                        aanbod_alignment={this.isMijnVraag() ? 'right' : 'left'}
                        vraag={vraagQueryResult.vraag}
                        vraag_alignment={this.isMijnVraag() ? 'left' : 'right'}
                      />
                    </Loading>
                  </div>

                  <div className="vl-col--12-12">
                    <Loading loading={vraagQueryResult.loading}>
                      <div className="detail-symbiose-tabnavigation">
                        <TabNavigation>
                          <Tab contentTitle=""
                            title={this.isMijnVraag() ? intl.get('symbiose.detail.vraag.mijn-vraag') : intl.get('symbiose.detail.aanbod.mijn-aanbod')}>
                            {
                              this.isMijnVraag() ?
                                <DetailMateriaalVraag vraag={vraagQueryResult.vraag} downloadBestand={this.handleDownloadVraagBestand}/> :
                                <DetailMateriaalAanbod aanbod={aanbodQueryResult.aanbod} downloadBestand={this.handleDownloadAanbodBestand}/>
                            }
                          </Tab>
                          <Tab contentTitle=""
                            title={this.isMijnVraag() ? intl.get('symbiose.detail.aanbod.aanbod') : intl.get('symbiose.detail.vraag.vraag')}>
                            {
                              this.isMijnVraag() ?
                                <DetailMateriaalAanbod aanbod={aanbodQueryResult.aanbod} downloadBestand={this.handleDownloadAanbodBestand}/> :
                                <DetailMateriaalVraag vraag={vraagQueryResult.vraag} downloadBestand={this.handleDownloadVraagBestand}/>
                            }
                          </Tab>
                          {
                            (meerwaarde_1 || meerwaarde_2) &&
                            <Tab title={intl.get('symbiose.detail.gerealiseerde-meerwaarde')} contentTitle="">
                              <DetailSymbioseMeerwaarde meerwaarde={meerwaarde_1 || meerwaarde_2} loading={vraagQueryResult.loading}/>
                            </Tab>
                          }

                          {
                            (niet_realisatie_reden_1 || niet_realisatie_reden_2) &&
                            <Tab title={intl.get('symbiose.detail.reden-niet-gerealiseerd')} contentTitle="">
                              <DetailSymbioseNietRealisatie niet_realisatie_reden={niet_realisatie_reden_1 || niet_realisatie_reden_2} loading={vraagQueryResult.loading}/>
                            </Tab>
                          }
                        </TabNavigation>
                      </div>
                    </Loading>
                  </div>
                </div>
              </div>
              <div className="vl-col--3-12 vl-push--1-12">
                <div className="vl-description-data--bordered vl-u-spacer">
                  <ButtonGroup>
                    {!isSymbioseTeam() && <VraagHulpButton handleClick={this.handleOpenHulpvraagModal} className="bericht-button" />}
                    <ToonConversatieButton conversatie={conversatie} handleClick={this.handleOpenBerichtModal} />
                  </ButtonGroup>
                  <StatusView symbiose={symbioseQueryResult.symbiose}
                    currentOndernemingReferentie={this.props.currentOndernemingReferentie}
                    documentUrl={this.props.applicationSettings.documentUrls.checklistVoorRegelgeving[ this.props.applicationSettings.general.taal ]}
                  />
                </div>
              </div>
            </div>
          </Loading>
          <SymbioseSendBerichtModal/>
          <SymbioseDetailHulpvraagModal/>
          <DocumenteerMeerwaardeModal symbioseReferentie={this.props.symbioseQueryResult.symbiose.symbioseReferentie}/>
          <DocumenteerNietRealisatieModal/>
        </div>
        }
      </>
    );
  }

  private static getMeerwaarde(meerwaarde, ondernemingsReferentie): IMeerwaarde {
    return meerwaarde
    && meerwaarde.ondernemingsReferentie === ondernemingsReferentie
    && meerwaarde.beschrijving.length > 0 ? meerwaarde : undefined;
  }

  private static getNietRealisatieReden(nietRealisatieReden, ondernemingsReferentie): INietRealisatieReden {
    return nietRealisatieReden &&
    nietRealisatieReden.ondernemingsReferentie === ondernemingsReferentie ? nietRealisatieReden : undefined;
  }

  private loadState = () => {
    this.props.getSymbiose(this.props.match.params.symbioseUid).then(symbiose => {
      const vraagUid = extractUid('vraag', symbiose.value.data.vraagReferentie);
      const aanbodUid = extractUid('aanbod', symbiose.value.data.aanbodReferentie);
      Promise.all([
        this.props.getMateriaalAanbod(aanbodUid),
        this.props.getMateriaalVraag(vraagUid)
      ]).then(() => this.props.getConversatieForAanbodAndVraag(aanbodUid, vraagUid));
    });
  };

  private isMijnVraag = () => this.props.vraagQueryResult.vraag.onderneming.referentie === this.props.currentOndernemingReferentie;

  private handleDownloadAanbodBestand = (referentie: string): AxiosPromise => {
    const aanbod = this.props.aanbodQueryResult.aanbod;
    const aanbodUid = extractUid('aanbod', aanbod.aanbodReferentie);
    const bestandUid = extractUid('bestand', referentie);
    return this.hasBestand(aanbod.fotos, aanbod.privateBijlagen, referentie) ? downloadAanbodBestand(aanbodUid, bestandUid) : null;
  };

  private handleDownloadVraagBestand = (referentie: string): AxiosPromise => {
    const vraag = this.props.vraagQueryResult.vraag;
    const vraagUid = extractUid('vraag', vraag.vraagReferentie);
    const bestandUid = extractUid('bestand', referentie);
    return this.hasBestand(vraag.fotos, vraag.privateBijlagen, referentie) ? downloadVraagBestand(vraagUid, bestandUid) : null;
  };

  private hasBestand = (fotos: IBestand[], bijlagen: IBestand[], uid: string): boolean => fotos.concat(bijlagen).findIndex(bestand => bestand.referentie === uid) !== -1;

  private handleOpenBerichtModal = () => {
    const aanbodUid = extractUid('aanbod', this.props.symbioseQueryResult.symbiose.aanbodReferentie);
    const vraagUid = extractUid('vraag', this.props.symbioseQueryResult.symbiose.vraagReferentie);
    this.props.getConversatieForAanbodAndVraag(aanbodUid, vraagUid)
      .then(() => {
        if (!this.props.conversatie.referentie) {
          this.props.createConversatie(aanbodUid, vraagUid);
        }
      });

    this.props.showModal(SYMBIOSE_SEND_BERICHT, {
      cancel: () => this.props.hideModal(SYMBIOSE_SEND_BERICHT)
    });
  };

  private handleOpenHulpvraagModal = () => {
    this.props.getHulpvraagForSymbiose(this.props.match.params.symbioseUid)
      .then(() => {
        if (!this.props.conversatie.referentie) {
          this.props.createHulpvraagForSymbiose(this.props.match.params.symbioseUid);
        }
      });

    this.props.showModal(SYMBIOSE_HULPVRAAG, {
      cancel: () => {
        this.props.hideModal(SYMBIOSE_HULPVRAAG);
        const aanbodUid = extractUid('aanbod', this.props.symbioseQueryResult.symbiose.aanbodReferentie);
        const vraagUid = extractUid('vraag', this.props.symbioseQueryResult.symbiose.vraagReferentie);
        this.props.getConversatieForAanbodAndVraag(aanbodUid, vraagUid);
      }
    });
  };
}

const mapStateToProps = storeState => ({
  symbioseQueryResult: storeState.symbiose.get,
  vraagQueryResult: storeState.vraag.details,
  aanbodQueryResult: storeState.aanbod.details,
  conversatie: storeState.bericht.bericht.conversatie,
  meerwaarde: storeState.symbiose.documenteerMeerwaardeForm,
  nietRealisatie: storeState.symbiose.documenteerNietRealisatieForm,
  currentOndernemingReferentie: storeState.authentication.contactPersoon.onderneming.referentie,
  isAuthenticated: storeState.authentication.security.isAuthenticated,
  applicationSettings: storeState.application.application
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  getSymbiose,
  getMateriaalVraag,
  getMateriaalAanbod,
  getConversatieForAanbodAndVraag: getOverleg,
  getHulpvraagForSymbiose,
  createConversatie,
  createHulpvraagForSymbiose,
  changeHeader
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(DetailSymbiose));
