import React from 'react';
import intl from 'react-intl-universal';
import { Alert } from 'webuniversum-react';

export class BrowserOndersteund extends React.Component<any, any> {
  render(): React.ReactNode {
    const browser = this.browserOndersteund();
    return (
      <>
        {browser &&
        <Alert>
          <div className="alert-zoeken">
            {intl.getHTML(browser.tekst, { url: 'https://www.google.com/chrome', browser: intl.get(browser.browser) })}
          </div>
        </Alert>
        }
      </>
    );
  }

  private browserOndersteund = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/opera|opr/g.test(userAgent)) {
      return {
        browser: 'applicatie.browser.opera',
        tekst: 'applicatie.browser.tekst-niet-gesupporteerde-browser'
      };
    } else if (/edge/g.test(userAgent)) {
      return {
        browser: 'applicatie.browser.edge',
        tekst: 'applicatie.browser.tekst-niet-gesupporteerde-browser'
      };
    } else if (/^(?!.*chrome).*safari/i.test(navigator.userAgent)) {
      return {
        browser: 'applicatie.browser.safari',
        tekst: 'applicatie.browser.tekst-niet-gesupporteerde-browser'
      };
    } else if (/firefox/g.test(userAgent)) {
      return {
        browser: 'applicatie.browser.firefox',
        tekst: 'applicatie.browser.tekst-niet-gesupporteerde-browser'
      };
    } else {
      const match = userAgent.match(/chrome\/([0-9]+)/);
      if (match && match.length > 1) {
        const majorVersion = parseInt(match[ 1 ], 10);
        if (majorVersion < 64) {
          return {
            browser: 'applicatie.browser.chrome',
            tekst: 'applicatie.browser.tekst-verouderde-versie-chrome'
          };
        } else {
          return null;
        }
      }
    }
  };
}
