import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Badge, Button, InfoTile, InfoTileFooter, InfoTileHeader} from 'webuniversum-react';
import {isFullyAuthenticated} from 'src/has-permission';
import {ALGEMENE_VOORWAARDEN} from 'src/reducers';
import {aanvaardAlgemeneVoorwaarden, IAuthenticationState, logout} from 'src/reducers/authentication';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {STARTPAGINA} from 'src/reducers/startpagina';
import {AanvaardAlgemeneVoorwaarden} from 'src/modules/algemene-voorwaarden/aanvaard-algemene-voorwaarden-modal';
import ContactInformatieBlock from 'src/modules/welkomstpagina/contact-informatie';
import {withErrorClear} from 'src/modules/error/error-clearer';

export interface IStartPaginaProps {
  authentication: IAuthenticationState;
  showModal: Function;
  hideModal: Function;
  logout: Function;
  aanvaardAlgemeneVoorwaarden: Function;
}

export class Startpagina extends React.Component<IStartPaginaProps, undefined> {
  componentDidMount() {
    this.onInitialLogon();
  }

  componentDidUpdate(prevProps: Readonly<IStartPaginaProps>, prevState: Readonly<undefined>, snapshot?: any) {
    this.onInitialLogon();
  }

  private onInitialLogon() {
    const { authentication } = this.props;

    if (isFullyAuthenticated(authentication.security)) {
      if (!authentication.loading && !authentication.contactPersoon.tijdstipAanvaardingAlgemeneVoorwaarden) {
        this.handleOpenAlgemeneVoorwaardenModal();
      }
    }
  }

  componentWillUnmount(): void {
    this.props.hideModal(STARTPAGINA);
    this.props.hideModal(ALGEMENE_VOORWAARDEN);
  }

  render(): React.ReactNode {
    return (
      <div>
        <p className="vl-u-spacer--tiny">{intl.get('applicatie.startpagina.info-text.paragraph1')}</p>
        <p className="vl-u-spacer">{intl.get('applicatie.startpagina.info-text.paragraph2')}</p>
        <div className="vl-grid vl-u-spacer">
          <div className="vl-col--4-12 vl-col--4-12--m vl-col--4-12--s vl-col--1-1--xs">
            <InfoTile size="m" center>
              <InfoTileHeader key={'aanbod.header'} title={intl.get('applicatie.startpagina.tile.aanbod.title')}>
                <Badge size="l">
                  <img className="vl-badge__img" src="/static/images/aanbod-logo.png" alt="aanbod"/>
                </Badge>
              </InfoTileHeader>
              <InfoTileFooter key={'aanbod.footer'}>
                <div className="vl-u-spacer--small">
                  <Link to="/aanbod/overzicht">
                    <Button className="elements-startpagina-tile">
                      {intl.get('applicatie.startpagina.tile.aanbod.bekijk-aanbod')}
                    </Button>
                  </Link>
                </div>
                <div className="startpagina-tile vl-u-spacer--small">
                  <Link className="elements-startpagina-tile" to="/aanbod/nieuw">
                    {intl.get('applicatie.startpagina.tile.aanbod.nieuw-aanbod')}
                  </Link>
                </div>
              </InfoTileFooter>
            </InfoTile>
          </div>
          <div className="vl-col--4-12 vl-col--4-12--m vl-col--4-12--s vl-col--1-1--xs">
            <InfoTile size="m" center>
              <InfoTileHeader key={'vraag.header'} title={intl.get('applicatie.startpagina.tile.vraag.title')}>
                <Badge size="l">
                  <img className="vl-badge__img" src="/static/images/vraag-logo.png" alt="vraag"/>
                </Badge>
              </InfoTileHeader>
              <InfoTileFooter key={'vraag.footer'}>
                <div className="vl-u-spacer--small">
                  <Link to="/vraag/overzicht">
                    <Button className="elements-startpagina-tile">
                      {intl.get('applicatie.startpagina.tile.vraag.bekijk-vraag')}
                    </Button>
                  </Link>
                </div>
                <div className="startpagina-tile vl-u-spacer--small">
                  <Link className="elements-startpagina-tile" to="/vraag/nieuw">
                    {intl.get('applicatie.startpagina.tile.vraag.nieuwe-vraag')}
                  </Link>
                </div>
              </InfoTileFooter>
            </InfoTile>
          </div>
          <div className="vl-col--4-12 vl-col--4-12--m vl-col--4-12--s vl-col--1-1--xs">
            <InfoTile size="m" center>
              <InfoTileHeader key={'technologie.header'} title={intl.get('applicatie.startpagina.tile.technologie.title')}>
                <Badge size="l">
                  <img className="vl-badge__img" src="/static/images/technologie-logo.png" alt="technologie"/>
                </Badge>
              </InfoTileHeader>
              <InfoTileFooter key={'technologie.footer'}>
                <div className="vl-u-spacer--small">
                  <Link to="/technologie/overzicht">
                    <Button className="elements-startpagina-tile">
                      {intl.get('applicatie.startpagina.tile.technologie.bekijk-technologie')}
                    </Button>
                  </Link>
                </div>
                <div className="startpagina-tile vl-u-spacer--small">
                  <Link className="elements-startpagina-tile" to="/technologie/nieuw">
                    {intl.get('applicatie.startpagina.tile.technologie.nieuwe-technologie')}
                  </Link>
                </div>
              </InfoTileFooter>
            </InfoTile>
          </div>
        </div>
        <ContactInformatieBlock/>
        <AanvaardAlgemeneVoorwaarden acceptatieTekst={intl.get('algemene-voorwaarden.acceptatie-tekst')}/>
      </div>
    );
  }

  private handleOpenAlgemeneVoorwaardenModal = () => {
    this.props.showModal(ALGEMENE_VOORWAARDEN, {
      submitText: intl.get('algemene-voorwaarden.submit-text'),
      cancelText: intl.get('algemene-voorwaarden.cancel-text'),
      title: intl.get('algemene-voorwaarden.title'),
      cancel: () => {
        this.props.hideModal(ALGEMENE_VOORWAARDEN);
        this.props.logout();
      },
      submit: () => {
        this.props.aanvaardAlgemeneVoorwaarden().then(() => {
          this.props.hideModal(ALGEMENE_VOORWAARDEN);
        });
      }
    });
  };
}

const mapStateToProps = storeState => ({
  authentication: storeState.authentication
});

const mapDispatchToProps = {
  showModal,
  hideModal,
  aanvaardAlgemeneVoorwaarden,
  logout
};

export default withErrorClear(connect(mapStateToProps, mapDispatchToProps)(Startpagina));
