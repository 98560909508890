import axios from 'axios';
import { ActionCreator, AnyAction } from 'redux';
import { FAILURE, REQUEST, SUCCESS } from '../action-type.util';
import { IGetAantalOngelezenBerichtenState } from './ongelezen-berichten';

const ACTION_TYPES = {
  UPDATE_BERICHTEN_GELEZEN: 'conversatie/UPDATE_BERICHTEN_GELEZEN'
};
export const BERICHTEN_GELEZEN_ACTION_TYPES = ACTION_TYPES;

export const initialState: IGetAantalOngelezenBerichtenState = {
  loading: false,
  errorMessage: null,
  aantalOngelezenBerichten: 0
};

export const berichtenGelezenReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.UPDATE_BERICHTEN_GELEZEN):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.UPDATE_BERICHTEN_GELEZEN):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.UPDATE_BERICHTEN_GELEZEN):
    return {
      ...state
    };
  default:
    return false;
  }
};

export const updateBerichtenGelezen: ActionCreator<any> = (conversatieUid: string) => ({
  type: ACTION_TYPES.UPDATE_BERICHTEN_GELEZEN,
  payload: axios.put(`/ui/berichten/conversatie/${conversatieUid}/gebruiker/ongelezen`)
});
