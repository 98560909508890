import axios from 'axios';
import {ActionCreator, AnyAction} from 'redux';
import {FAILURE, REQUEST, SUCCESS} from 'src/reducers/action-type.util';
import {IBegrensdeWaarde} from 'src/reducers/relevantie';
import {IFoundAanbod} from 'src/reducers/aanbod/materiaal/search';
import {IFoundVraag} from 'src/reducers/vraag/materiaal/search';

export const ACTION_TYPES = {
  FETCH_RELEVANTE_AANBIEDINGEN: 'vraag/FETCH_RELEVANTE_AANBIEDINGEN'
};

export interface IRelevantAanbod extends IFoundAanbod {
  relevantieScore: IBegrensdeWaarde;
  afstand: IBegrensdeWaarde;
}

export interface IRelevanteAanbiedingenList extends IFoundVraag {
  loading: boolean;
  errorMessage: any;
  vraagReferentie: string;
  titel: string;
  beschrijving: string;
  topRelevanties: IRelevantAanbod[];
  interessanteRelevanties: IRelevantAanbod[];
  andereRelevanties: IRelevantAanbod[];
}

const initialState: IRelevanteAanbiedingenList = {
  loading: false,
  errorMessage: null,
  vraagReferentie: null,
  taal: null,
  coverFotoReferentie: null,
  onderneming: null,
  titel: null,
  beschrijving: null,
  beoogdeVerwerkingswijze: null,
  topRelevanties: [],
  interessanteRelevanties: [],
  andereRelevanties: []
};

export const relevanteAanbiedingenListReducer = (state = initialState, action: AnyAction): IRelevanteAanbiedingenList => {
  switch (action.type) {
  case REQUEST(ACTION_TYPES.FETCH_RELEVANTE_AANBIEDINGEN):
    return {
      ...state,
      errorMessage: null,
      loading: true
    };
  case FAILURE(ACTION_TYPES.FETCH_RELEVANTE_AANBIEDINGEN):
    return {
      ...state,
      loading: false,
      errorMessage: action.payload
    };
  case SUCCESS(ACTION_TYPES.FETCH_RELEVANTE_AANBIEDINGEN):
    return {
      ...state,
      loading: false,
      vraagReferentie: action.payload.data.vraagReferentie,
      taal: action.payload.data.taal,
      coverFotoReferentie: action.payload.data.coverFotoReferentie,
      titel: action.payload.data.titel,
      beschrijving: action.payload.data.beschrijving,
      onderneming: action.payload.data.onderneming,
      topRelevanties: action.payload.data.topRelevanties,
      interessanteRelevanties: action.payload.data.interessanteRelevanties,
      andereRelevanties: action.payload.data.andereRelevanties
    };
  default:
    return state;
  }
};

export const getRelevanteAanbiedingen: ActionCreator<any> = (vraagUid: string) => ({
  type: ACTION_TYPES.FETCH_RELEVANTE_AANBIEDINGEN,
  payload: axios.get(`/ui/vragen/${vraagUid}/relevante-aanbiedingen`)
});
