import React from 'react';
import intl from 'react-intl-universal';
import {connect} from 'react-redux';
import {Button, ButtonGroup, Radiobutton} from 'webuniversum-react';
import {
  DOCUMENTEER_MEERWAARDE_FORM,
  DOCUMENTEER_NIET_REALISATIE_FORM,
  initialDocumenteerMeerwaardeFormState,
  initialDocumenteerNietRealisatieFormState,
  symbioseDocumenteerMeerwaarde,
  symbioseDocumenteerNietRealisatie,
  symbioseGerealiseerd,
  symbioseNietGerealiseerd
} from 'src/reducers/symbiose/change-status';
import {ISymbiose} from 'src/reducers/symbiose/create';
import {hideModal, showModal} from 'src/reducers/modal/modal';
import {DOCUMENTEER_MEERWAARDE, DOCUMENTEER_NIET_REALISATIE} from 'src/reducers/symbiose';
import {extractUid} from 'src/modules/referentie-uri';
import {formFieldsToObject, formState, initialFormState} from 'redux-form-library';

export interface IActieGevraagdGerealiseerdNietGerealiseerdProps {
  symbioseGerealiseerd: Function;
  symbioseNietGerealiseerd: Function;
  symbiose: ISymbiose;
  showModal: Function;
  hideModal: Function;
  symbioseDocumenteerNietRealisatie: Function;
  symbioseDocumenteerMeerwaarde: Function;
  nietRealisatie: any;
  documenteerMeerwaarde: any;
  meerwaardeFormState: any;
  nietRealisatieFormState: any;
}

export class ActieGevraagdGerealiseerdNietGerealiseerd extends React.Component<IActieGevraagdGerealiseerdNietGerealiseerdProps> {

  state = {
    realisatiekeuze: undefined
  };

  render(): React.ReactNode {
    return (
      <div className="detail-symbiose-actie-gevraagd">
        <div>
          <p>{intl.get('symbiose.actie-gevraagd.tekst-gerealiseerd-niet-gerealiseerd')}</p>
        </div>
        <div>
          <Radiobutton name="realisatie" onChange={this.handleRadiobuttonSelect} value="GEREALISEERD">{intl.get('symbiose.detail.symbiose-gerealiseerd')}</Radiobutton>
          <Radiobutton name="realisatie" onChange={this.handleRadiobuttonSelect} value="NIET_GEREALISEERD">{intl.get('symbiose.detail.symbiose-niet-gerealiseerd')}</Radiobutton>
        </div>
        <ButtonGroup>
          <Button className="detail-symbiose-button" disabled={this.state.realisatiekeuze === undefined} onClick={this.handleSubmit}>
            {intl.get('symbiose.actie-gevraagd.bevestigen')}
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  private handleRadiobuttonSelect = event => {
    this.setState({ realisatiekeuze: event.target.value });
  };

  private handleSubmit = () => {
    if (this.state.realisatiekeuze === 'GEREALISEERD') {
      this.documenteerMeerwaardeOpenModal();
    }

    if (this.state.realisatiekeuze === 'NIET_GEREALISEERD') {
      this.documenteerNietRealisatieOpenModal();
    }
  };

  private documenteerMeerwaardeOpenModal = () => {
    this.props.showModal(DOCUMENTEER_MEERWAARDE, {
      submit: () => {
        this.props.symbioseGerealiseerd(extractUid('symbiose', this.props.symbiose.symbioseReferentie)).then(() => {
          this.props.symbioseDocumenteerMeerwaarde(extractUid('symbiose', this.props.symbiose.symbioseReferentie), formFieldsToObject(this.props.documenteerMeerwaarde.fields));
          this.props.hideModal(DOCUMENTEER_MEERWAARDE);
          this.props.meerwaardeFormState(initialFormState(initialDocumenteerMeerwaardeFormState()));
        });
      },
      cancel: () => this.props.hideModal(DOCUMENTEER_MEERWAARDE),
      submitText: intl.get('symbiose.documenteer-meerwaarde-modal.submit-text'),
      cancelText: intl.get('detail.bericht.send.cancel.label'),
      title: intl.get('symbiose.documenteer-meerwaarde-modal.titel')
    });
  };

  private documenteerNietRealisatieOpenModal = () => {
    this.props.showModal(DOCUMENTEER_NIET_REALISATIE, {
      submit: () => {
        this.props.symbioseNietGerealiseerd(extractUid('symbiose', this.props.symbiose.symbioseReferentie)).then(() => {
          this.props.symbioseDocumenteerNietRealisatie(extractUid('symbiose', this.props.symbiose.symbioseReferentie), formFieldsToObject(this.props.nietRealisatie.fields));
          this.props.hideModal(DOCUMENTEER_NIET_REALISATIE);
          this.props.nietRealisatieFormState(initialFormState(initialDocumenteerNietRealisatieFormState()));
        });
      },
      cancel: () => this.props.hideModal(DOCUMENTEER_NIET_REALISATIE),
      submitText: intl.get('symbiose.niet-gerealiseerd-modal.submit-text'),
      cancelText: intl.get('detail.bericht.send.cancel.label'),
      title: intl.get('symbiose.niet-gerealiseerd-modal.titel')
    });
  };
}

const mapStateToProps = storeState => ({
  nietRealisatie: storeState.symbiose.documenteerNietRealisatieForm,
  documenteerMeerwaarde: storeState.symbiose.documenteerMeerwaardeForm
});

const mapDispatchToProps = {
  symbioseGerealiseerd,
  symbioseNietGerealiseerd,
  showModal,
  hideModal,
  symbioseDocumenteerNietRealisatie,
  symbioseDocumenteerMeerwaarde,
  meerwaardeFormState: formState(DOCUMENTEER_MEERWAARDE_FORM),
  nietRealisatieFormState: formState(DOCUMENTEER_NIET_REALISATIE_FORM)
};

export default connect(mapStateToProps, mapDispatchToProps)(ActieGevraagdGerealiseerdNietGerealiseerd);
